import React, { Component } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { getByPessoaId } from '../../../API/JanelaHorario';
import { getFormatTimeHMToString } from '../../../Utils/FormatDateTime';
import { LARANJA_DA_GRAFOS } from '../../../Utils/ColorsGrafos';

class JanelaParada extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, janela: '', todasJanelas: [] };
  }

  async componentDidMount() {

    if (this.props.row.destinatarioId) {
      getByPessoaId(this.props.row.destinatarioId).then((value) => {
        if (value !== undefined || value !== null) {
          this.setState({ todasJanelas: value });
        }
        this.setState({ loading: false });
      }).catch((e) => { this.setState({ loading: false }); });
    } else {
      this.setState({ loading: false });
    }
  }

  mostrarJanelaconformeData = (chegadaPrevista) => {
    var lista = [];
    for (let index = 0; index < this.state.todasJanelas.length; index++) {

      var j = {
        inicio: getFormatTimeHMToString(new Date(this.state.todasJanelas[index].inicio)),
        fim: getFormatTimeHMToString(new Date(this.state.todasJanelas[index].fim)),
        diaDaSemana: this.state.todasJanelas[index].diaDaSemana
      };

      var dataChegadaPrevista = new Date(chegadaPrevista);
      if (this.state.todasJanelas[index].diaDaSemana === dataChegadaPrevista.getDay()) {
        lista.push(j);

      }
    }

    return lista;
  }

  formatarDataJanela = (lista) => {
    lista.forEach(janela => {
      var timeFim = janela.fim.split(":");
      var timeInicio = janela.inicio.split(":");
      if (timeFim[1] === "00") {
        janela.fim = timeFim[0] + "h";
      }
      if (timeInicio[1] === "00") {
        janela.inicio = timeInicio[0] + "h";
      }
    });

    var textoFormatado = "";

    if (lista.length === 1) {
      textoFormatado = lista[0].inicio + " às " + lista[0].fim;
    } else if (lista.length > 1) {
      for (let i = 0; i < lista.length; i++) {
        textoFormatado += i !== lista.length - 1 ? lista[i].inicio + " às " + lista[i].fim + " | " : lista[i].inicio + " às " + lista[i].fim;
      }
    }

    return textoFormatado;
  }


  render() {

    if (this.state.loading)
      return <>
        <Skeleton variant="rect" width={150} height={10} />
      </>

    if (this.state.todasJanelas.length === 0)
      return <></>;

    return <span style={{ color: LARANJA_DA_GRAFOS }}>
      Janela:  {this.formatarDataJanela(this.mostrarJanelaconformeData(this.props.row.chegadaPrevista))}
    </span>;
  }
}
export default JanelaParada;