import { Box } from "@mui/material"

export const EllipsisText = ({ children, maxLines, ...props }) => {
    return (
        <Box
            sx={{
                height: "auto",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: String(maxLines),
                WebkitBoxOrient: "vertical"
            }}
            {...props}
        >
            {children}
        </Box>
    )
}