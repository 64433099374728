
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import { formatarAnoData } from '../../Utils/FormatDateTime'
import { formatarNumeroParaReais } from '../../Utils/Money';
import Typography from '@material-ui/core/Typography';
import { MapContainer, TileLayer, Marker, LayerGroup, FeatureGroup, Tooltip } from 'react-leaflet'
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
import { ReactComponent as entregaImagem } from '../../img/icon/localPlanejado.svg';
import { EditControl } from "react-leaflet-draw";
import { getFormatEndereco_01 } from '../../Utils/Endereco';

var pointInPolygon = require('point-in-polygon');

export default class EditControlExample extends Component {
    constructor(props) {
        super(props);
        this.state = { circlemarker: true, idsSelecionados: props.idsSelecionados, mapLayers: [] };
    }

    render() {
        const fillBlueGreenOptions = { fillColor: 'blue', color: 'green' };

        const entregaIco = L.divIcon({
            html: ReactDOMServer.renderToString(<Avatar variant="rounded" style={{
                marginLeft: '-10px',
                marginTop: '-10px',
                height: this.props.tamanhoIcon + 'px',
                width: this.props.tamanhoIcon + 'px',
                // Caso não esteja entregue
                backgroundColor: 'rgb(99 99 99)'
            }}>
                <SvgIcon component={entregaImagem} style={{ width: (this.props.tamanhoIcon - 5) + 'px', height: (this.props.tamanhoIcon - 5) + 'px' }} /></Avatar>)
        });

        const entregaIcoVerde = L.divIcon({
            html: ReactDOMServer.renderToString(<Avatar variant="rounded" style={{
                marginLeft: '-10px',
                marginTop: '-10px',
                backgroundColor: 'rgb(92 182 96)',
                height: this.props.tamanhoIcon + 'px',
                width: this.props.tamanhoIcon + 'px',
            }}>
                <SvgIcon component={entregaImagem} style={{ width: (this.props.tamanhoIcon - 5) + 'px', height: (this.props.tamanhoIcon - 5) + 'px' }} /></Avatar>)
        });

        const _onCreate = (e) => {
            const { layerType, layer } = e;
            if (layerType === "polygon") {
                const { _leaflet_id } = layer;

                this.setState({
                    mapLayers: [
                        ...this.state.mapLayers,
                        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
                    ]
                })
            }
            verificaEntregasNosPoliginos();
        };

        const _onEdited = (e) => {
            const {
                layers: { _layers },
            } = e;

            Object.values(_layers).map(({ _leaflet_id, editing }) => {
                this.setState({
                    mapLayers: this.state.mapLayers.map((l) =>
                        l.id === _leaflet_id
                            ? { id: _leaflet_id, latlngs: editing.latlngs[0][0] }
                            : l
                    )
                });
            });
            verificaEntregasNosPoliginos();
        };

        const verificaEntregasNosPoliginos = () => {
            if (this.props.entregas && Array.isArray(this.props.entregas) && this.props.entregas.length > 0) {
                this.state.mapLayers.map((poly) => {
                    var polygon = poly.latlngs.map((p) => {
                        return [p.lat, p.lng];
                    });

                    var listaIdsSelecionados = this.state.idsSelecionados;
                    this.props.entregas.map((entrega) => {
                        if (pointInPolygon([entrega.endereco.latitude, entrega.endereco.longitude], polygon)) {
                            if (!listaIdsSelecionados.includes(entrega.id)) {
                                listaIdsSelecionados.push(entrega.id);
                            }
                        }
                    });
                    
                    this.setState({ idsSelecionados: listaIdsSelecionados })
                    this.props.setIdsSelecionado(listaIdsSelecionados);
                });
            }
        }

        return (
            <MapContainer center={this.props.location} zoom={13} zoomControl={true} style={{ height: "75vh", width: "100%" }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <FeatureGroup>
                    {this.props.entregas && Array.isArray(this.props.entregas) && this.props.entregas.length > 0 ? <>
                        <LayerGroup >
                            {this.props.entregas.map(entrega => {
                                return <Marker
                                    eventHandlers={{
                                        click: (e) => {
                                            if (this.state.idsSelecionados.includes(entrega.id)) {
                                                this.state.idsSelecionados = this.state.idsSelecionados.filter(function (ele) {
                                                    return ele !== entrega.id;
                                                });
                                            }
                                            else
                                                this.state.idsSelecionados.push(entrega.id);

                                            this.setState({ idsSelecionados: this.state.idsSelecionados })
                                            this.props.setIdsSelecionado(this.state.idsSelecionados);
                                        },
                                    }}
                                    icon={this.state.idsSelecionados.includes(entrega.id) ? entregaIcoVerde : entregaIco} position={[entrega.endereco.latitude, entrega.endereco.longitude]}
                                    pathOptions={fillBlueGreenOptions} radius={15} >
                                    <Tooltip direction="top" style={{ width: '100px' }}>
                                        <Informacao entrega={entrega} />
                                    </Tooltip>
                                </Marker>;;
                            })}
                        </LayerGroup>
                    </>
                        : <></>}
                </FeatureGroup>
                <FeatureGroup>
                    <EditControl
                        position="topleft"
                        onCreated={_onCreate}
                        onEdited={_onEdited}
                        edit={{ remove: false }}
                        draw={{
                            rectangle: false,
                            polyline: false,
                            circle: false,
                            circlemarker: false,
                            marker: false,
                            polygon: {
                                shapeOptions: { color: 'black' }
                            }
                        }}
                    />
                </FeatureGroup>
            </MapContainer>
        );
    }
}

const Informacao = props => {
    if (props.entrega.destinatario === undefined)
        return <></>

    return (
        <div style={{ width: '500px' }}>
            <p style={{ whiteSpace: "pre-line" }}>
                <Typography variant="subtitle1"><b>{props.entrega.numDocumentos}</b></Typography>
                <Typography variant="body2"><b>Cliente: </b>{(props.entrega.destinatario.descricao == null ? props.entrega.descricao : props.entrega.destinatario.descricao)}</Typography>
                <Typography variant="body2"><b>Prazo: </b>{formatarAnoData(props.entrega.previsaoEntrega)}</Typography>
                {formatarAnoData(props.entrega.agendamentoTransportadora) !== "" ?
                    <Typography variant="body2"><b>Agend.</b> {formatarAnoData(props.entrega.agendamentoTransportadora)}</Typography>
                    : <></>}
                <br></br>
                <Typography variant="body2"><b>Peso: </b>{() => { try { return Number(props.entrega.pesoTotal.toFixed(2)).toLocaleString('pt-BR'); } catch (_) { return '' } }} Kg &emsp;<b>Cubagem: </b>{() => { try { return props.entrega.cubagem.toLocaleString('pt-BR'); } catch (_) { return '' } }} m³ &emsp;<b>Volume: </b>{() => { try { return props.entrega.volumeTotal; } catch (_) { return '' } }} Vol</Typography>
                <Typography variant="body2"><b>Carga: </b>{formatarNumeroParaReais(props.entrega.valorTotal)} &emsp;&emsp;<b>Frete: </b>{formatarNumeroParaReais(props.entrega.valorFrete)}</Typography>
                <br></br>
                <Typography variant="body2">
                    <b>Endereço:</b> {getFormatEndereco_01(props.entrega.endereco)}<br />
                </Typography>
            </p>
        </div>
    );
}
