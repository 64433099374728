function distance(lat1, lon1, lat2, lon2) {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    //const radlon1 = Math.PI * lon1 / 180;
    //const radlon2 = Math.PI * lon2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;

    dist = dist * 1609.344

    return dist;
}

export const localizacaoEstaForaDoRario = (latitude, longitude, latitude2, longitude2, distanciaMin) => {
    var distancia = distance(latitude,
        longitude,
        latitude2,
        longitude2);

    if (distancia > distanciaMin && latitude2 !== 0) {
        return distancia;
    }
    return 0;
}