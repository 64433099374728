export const FieldType = {
    TEXT: "text",
    BOOL: "bool",
    OPTION: "option",
    LOOKUP: "lookup",
    LOOKUP_ASSINCRONO: "lookup_assincrono",
    LOOKUP_LOCALIZACAO: "lookup_localizacao",
    PONTO_LOCALIZACAO: "ponto_localizacao",
    DATE: "date",
    DATETIME: "datetime",
    TIME: "time",
    COMPONENT: "component",
    MONEY: "money",
    PESO: "peso",
    CUBAGEM: "cubagem",
    MAP_CIRCLE: "mapCircle"
}