import React, { useCallback, useEffect } from 'react';
import { GridTable } from '../../../Components/GridTable/GridTable';
import { getAllById } from '../../../API/ConhecimentoTransporte';
import { columnsGrid } from './Config';

export default function GridList(props) {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const loadRow = useCallback(async () => {
        var idCTE = '';
        if (props.filter) {
            if (props.filter.documentoId)
                idCTE = props.filter.documentoId;
        }
        if (idCTE === '') {
            throw new Error("Erro ao verificar CT-e")
        }
        setRows([]);
        setLoading(true);
        getAllById(idCTE).then((value) => {

            if (value !== undefined)
                setRows(value);
            setLoading(false);
        });

        setColumns(columnsGrid);
    }, [props.filter]);

    useEffect(() => {
        loadRow();
    }, [loadRow])

    return (
        <GridTable title="Notas Fiscais" titleNovo="Novo"
            rows={rows}
            columns={columns}
            CanRemove={false}
            loading={loading} />
    );
}
