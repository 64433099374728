import { instance } from './axios'

export async function postPessoas(pessoa) {
  try {
    const response = await instance.post('/api/Pessoas', pessoa);
    if (response.status === 201) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function put(id, registro) {
  try {
    const response = await instance.put('/api/Pessoas/' + id, registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAll() {
  try {
    const response = await instance.get('/api/Pessoas');
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}
export async function getAllDestinatario(filtro = '', page = 0, maxRegistro = 50) {
  try {
    const response = await instance.get('/api/Pessoas/destinatarios?filtro=' + filtro + '&page=' + page + '&maxRegistro=' + maxRegistro);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/Pessoas/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getByEnderecoId(enderecoId, empresaId) {
  try {
    const response = await instance.get(`/api/Pessoas/GetByEnderecoId/${enderecoId}?empresaId=${empresaId}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getPessoaByParadaPlabejadaId(paradaPlanejadaId) {
  try {
    const response = await instance.get(`/api/Pessoas/GetByParadaPlanejadaId/${paradaPlanejadaId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}