import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, getByIdPessoaEndereco, getById, put } from '../../API/Enderecos';
import { camposForm as camposF } from './Config';
import { getUser } from '../../Router/auth';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import ButtonSubstituto from './components/ButtonSubstituto';
import { Box, Grid } from '@mui/material';
import { updateStatusRoteirizacao } from '../../API/Rotas';
import { StatusRoteirizacao } from '../../Enums/StatusRoteirizacao';

class FormConfig extends Component {

    constructor(props) {
        super(props);

        let l = false;
        try {
            let id = this.props.match.params.id;

            if (id)
                l = true;
        } catch (e) { }

        this.state = { alertSucess: false, alertError: false, loading: l, loadingField: true, values: {}, camposForm: camposF };
    }

    create = async values => {
        values.endereco = {
            logradouro: values.logradouro,
            numero: values.numero,
            complemento: values.complemento,
            bairro: values.bairro,
            codigoMunicipio: values.codigoMunicipio,
            nomeMunicipio: values.nomeMunicipio,
            sigaUF: values.sigaUF,
            cep: values.cep,
            empresaId: values.empresaId,
            latitude: values.latitude,
            longitude: values.longitude
        };
        values.empresaId = getUser().empresaId;
        var query = new URLSearchParams(this.props.location.search);
        values.pessoaId = query.get('pessoaId');

        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
            this.props.openSnackbar('Criado com sucesso!');

            if (this.props.returnId)
                this.props.returnId(retorno.data.id)
        } else {
            this.setState({ alertError: true });
            this.props.openSnackbar('Erro ao criar! Cadastro não criado.', 'error');
        }
    }

    update = async values => {
        values.endereco = {
            id: values.enderecoId,
            logradouro: values.logradouro,
            numero: values.numero,
            complemento: values.complemento,
            bairro: values.bairro,
            codigoMunicipio: values.codigoMunicipio,
            nomeMunicipio: values.nomeMunicipio,
            sigaUF: values.sigaUF,
            cep: values.cep,
            empresaId: values.empresaId,
            latitude: values.latitude,
            longitude: values.longitude
        };
        values.empresaId = getUser().empresaId;

        var id = this.props.match.params.id;

        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            var query = new URLSearchParams(this.props.location.search);
            var rotaReroteirizar = query.get("rotaReroteirizar");

            if (rotaReroteirizar !== null) {
                try {
                    await updateStatusRoteirizacao(rotaReroteirizar, StatusRoteirizacao.AguardandoRoteirizacao);
                } catch { }
            }

            this.setState({ alertSucess: true });
            this.props.openSnackbar('Atualizado com sucesso!');
        } else {
            this.setState({ alertError: true });
            this.props.openSnackbar('Erro ao atualizar! Cadastro não atualizado.', 'error');
        }
    }

    componentDidMount() {
        let id;
        try {
            id = this.props.match.params.id;
        } catch { }

        if (id) {
            getByIdPessoaEndereco(id).then(async (value) => {
                if (value !== undefined) {
                    let enderecoDetalhes = await getById(value.enderecoId);

                    if (enderecoDetalhes !== undefined) {
                        value = { ...enderecoDetalhes, ...value };
                        value.endereco = { id: value.enderecoId };
                    }

                    value.localizacao = { lat: value.latitude, lng: value.longitude };

                    const updatedCamposForm = this.state.camposForm.map((x) => ({
                        ...x,
                        disabled: !(x.name === "latitude" || x.name === "longitude" || x.name === "pesquisar"),
                        alterarSomenteLatLng: true
                    }));

                    const filteredCamposForm = !value.enderecoOrigemId
                        ? updatedCamposForm.filter((x) => x.name !== 'enderecoOrigemId')
                        : updatedCamposForm;

                    this.setState({
                        camposForm: filteredCamposForm,
                        values: value,
                        loading: false,
                        loadingField: false,
                    });
                } else {
                    this.setState({ loading: false, loadingField: false });
                }
            }).catch((e) => {
                throw new Error('Registro não encontrado!');
            });
        } else {
            const updatedCamposForm = this.state.camposForm.map((x) => ({
                ...x,
                disabled: !(x.name !== 'enderecoOrigemId' && x.name !== 'latitude' && x.name !== 'longitude'),
            }));

            const query = new URLSearchParams(this.props.location.search);
            const enderecoOrigemId = query.get('enderecoOrigemId');

            const filteredCamposForm = enderecoOrigemId
                ? updatedCamposForm
                : updatedCamposForm.filter((x) => x.name !== 'enderecoOrigemId');

            const updatedValues = {
                ...this.state.values,
                tipo: 1,
                enderecoOrigemId: enderecoOrigemId || null,
                empresaId: getUser().empresaId,
                latitude: null,
                longitude: null,
                localizacao: { lat: -26.864373233253218, lng: -49.08949008129802 },
            };

            this.setState({
                camposForm: filteredCamposForm,
                values: updatedValues,
                loadingField: false,
            });
        }
    }

    render() {
        let id;
        try {
            id = this.props.match.params.id;
        } catch { }
        if (id) {
            return (
                <Box>
                    {(this.state.values.enderecoFiscal && this.state.values.enderecoFiscal ? (
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '5px',
                                marginLeft: '11px',
                                marginBottom: '8px'
                            }}
                        >
                            <Grid item xs={3} md={3}>
                                <ButtonSubstituto id={this.state.values.endereco.id} pessoaId={this.state.values.pessoaId} />
                            </Grid>
                        </Grid>
                    ) : <></>)}

                    <Form
                        title="Editar endereço"
                        form="enderecoEdit"
                        CanNotSave={true}
                        fields={this.state.camposForm}
                        onSubmit={this.update}
                        CanBack={true}
                        initialValues={this.state.values}
                        AlertSucess={this.state.alertSucess}
                        AlertError={this.state.alertError}
                        loading={this.state.loading || this.state.loadingField}
                    />
                </Box>
            );
        } else {

            return (
                <Form
                    title="Endereço"
                    form="enderecoForm"
                    CanNotSave={true}
                    fields={this.state.camposForm}
                    onSubmit={this.create}
                    CanBack={true}
                    initialValues={this.state.values}
                    AlertSucess={this.state.alertSucess}
                    AlertError={this.state.alertError}
                    loading={this.state.loadingField}
                />
            );
        }
    }
}

class FormSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <FormConfig openSnackbar={openSnackbar} {...this.props} >
                    </FormConfig>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default FormSnackBar;