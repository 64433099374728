import { instance } from './axios'
import { getFormatDataToDataApi } from '../Utils/FormatDateTime';

export async function getAll(dataInicio, rotaId) {
  try {
    var query ='';
    if (dataInicio)
    {
      query+='data='+getFormatDataToDataApi(dataInicio);
    }
    if (rotaId)
    {
      if (query)
        query+='&';
      query+='rotaId='+rotaId;
    }

    const response = await instance.get('/api/DocumentoImportar?'+query);
    if (response.status === 200){
        return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function post(registro) {
  try {
    const response = await instance.post('/api/DocumentoImportar', registro);
    if (response.status === 201 || response.status === 200) {
        return { data: response.data, result: 'ok'};
    }
  } catch (error) {
    throw error;
  }
}

export async function put(id, registro) {
  try {
    const response = await instance.put('/api/DocumentoImportar/'+id, registro);
    if (response.status === 201 || response.status === 200) {
        return { data: response.data, result: 'ok'};
    }
  } catch (error) {
    throw error;
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/DocumentoImportar/'+id);
    if (response.status === 200){
        return response.data;
    }
  } catch (error) {
    throw error;
  }
}