import React, { Component, useRef, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import Typography from '@material-ui/core/Typography';

export default class EditControlExample extends Component {
    render() {
        return (
            <>
                <Typography variant="subtitle1" id="tableTitle" component="div">
                    <b>
                        Arraste o ícone para o local desejado <font color='red'>(Atenção: para conseguir roteirizar, o ícone precisa estar próximo de uma estrada. Evitar de colocar sobre algum imóvel, por exemplo)</font>
                    </b>
                </Typography>
                <MapContainer
                    center={this.props.input.value === '' ? { lat: -26.864373233253218, lng: -49.08949008129802 } : this.props.input.value}
                    zoom={13}
                    scrollWheelZoom={true}
                    zoomControl={false}
                    style={{ height: '400px' }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <DraggableMarker
                        posi={this.props.input.value}
                        event={(x) => this.props.changePoint(x)}>
                    </DraggableMarker>
                    <ChangeMapView coords={this.props.input.value} />
                </MapContainer>
            </>

        );
    }
}

function ChangeMapView({ coords }) {
    const map = useMap();
    map.flyTo(coords === '' ? { lat: -26.864373233253218, lng: -49.08949008129802 } : coords, map.getZoom());

    return null;
}

function DraggableMarker(props) {
    const map = useMap();
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    props.event(marker.getLatLng())
                    map.flyTo(marker.getLatLng(), map.getZoom());
                }
            },
        }),
        [map, props],
    )

    return (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={props.posi === '' ? { lat: -26.864373233253218, lng: -49.08949008129802 } : props.posi}
            ref={markerRef}>

        </Marker>
    )
}