export const columnsGrid = [
    { label: 'Destinatário', field: 'destinatario.descricao', type: 'text', options: [], padding: 'default' },
    { label: 'Data emissão', field: 'dataEmissao', type: 'datetime', padding: 'default' },
    { label: 'Operação', field: 'naturezaOperacao', type: 'text', padding: 'default' },
    { label: 'Número', field: 'numeroDocumentoFiscal', padding: 'default', type: "text" },
    { label: 'Chave', field: 'chave', padding: 'default', type: "text" },
    { label: 'Total', field: 'total.valorTotal', type: 'money', padding: 'default', numeric: true },
    { label: 'Criado em', field: 'createdDate', type: 'datetime', padding: 'default' }
];

export const TabIndexDocumentos = {
    Documentos: 0,
    Importacao: 1
}