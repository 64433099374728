import React from 'react';
import { SharedSnackbarConsumer } from './SharedSnackbar.context';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SharedSnackbar() {
    const classes = useStyles();
    
    return (
        <SharedSnackbarConsumer>
            {({ snackbarIsOpen, message, closeSnackbar, severity }) => (
            <div className={classes.root}>
            <Snackbar open={snackbarIsOpen} autoHideDuration={6000} anchorOrigin={{ vertical:'top', horizontal:'center' }} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity={severity}>
                {message}
                </Alert>
            </Snackbar>
            </div>
            )}
        </SharedSnackbarConsumer>
    );
  }
