import React, { useCallback, useState } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from "lodash.debounce";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { REGEX_LATLNG, REGEX_LATLNG_V2 } from "../../Utils/RegexUtils";
import { reverse } from "../../API/OpenStreetMap";

function loadScript(src, position, id) {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = src;
	position.appendChild(script);
}

const autocompleteService = { current: null, details: null };

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
}));


export default function Asynchronous(props) {

	const {
		meta: { submitting, error, touched, invalid },
		input: { onBlur, /*value,*/ ...inputProps },
		required,
		validate,
		ref,
		label,
		disabled,
		change,
		formName,
		endpoint,
		alterarSomenteLatLng,
		...others
	} = props;

	const classes = useStyles();
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState('');
	const [options, setOptions] = React.useState([]);
	const [noOptionsText, setNoOptionsText] = useState("Nenhum registro encontrado");

	const loaded = React.useRef(false);
	const key = 'AIzaSyCQvSNSEXS7tAtPkKeDE9xYafaL7hAbyrg';

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				'https://maps.googleapis.com/maps/api/js?key=' + key + '&libraries=places',
				document.querySelector('head'),
				'google-maps',
			);
		}

		loaded.current = true;
	}

	const resetForm = () => {
		change(formName, 'logradouro', "");
		change(formName, 'numero', "");
		change(formName, 'bairro', "");
		change(formName, 'nomeMunicipio', "");
		change(formName, 'sigaUF', "");
		change(formName, 'cep', "");
		change(formName, 'localizacao', "");
	}

	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 200),
		[],
	);

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue, componentRestrictions: { country: 'br' } }, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	const changeHandler = (event, newInputValue) => {
		var lat = 0;
		var lng = 0;

		if (REGEX_LATLNG.exec(newInputValue)) {
			let latlng = newInputValue.split(",");
			lat = !isNaN(Number(latlng[0])) ? latlng[0] : 0;
			lng = !isNaN(Number(latlng[1])) ? latlng[1] : 0;
		} else if (REGEX_LATLNG_V2.exec(newInputValue)) {
			let latlng = newInputValue.split(',');
			lat = parseFloat(latlng[0] + '.' + latlng[1]);
			lng = parseFloat(latlng[2] + '.' + latlng[3]);
		} else if (newInputValue.includes("http") || newInputValue.includes("www")) {
			const url = decodeURIComponent(newInputValue);

			try {
				if (url.includes("google")) {
					// https://www.google.com/maps?q=-26.9084988,-49.081141&z=17&hl=pt-BR
					const urlParams = new URL(url).searchParams;
					const q = urlParams.get("q");

					if (q) {
						[lat, lng] = q.split(",");
					} else {
						// https://www.google.com/maps/place/Rua+Bonifácio+Haendchen,+3833-3821+-+Belchior+Alto,+Gaspar+-+SC,+89114-442/@-26.8323281,-49.0344459,17z/data=!3m1!4b1!4m6!3m5!1s0x94df20229e16c0c9:0xd18becb94441e8f6!8m2!3d-26.8323313!4d-49.0327273!16s/g/11c63lg2xq?hl=pt-BR&entry=ttu&g_ep=EgoyMDI0MTExOC4wIKXMDSoASAFQAw==
						// https://www.google.com/maps/place/26°54'30.6"S+49°04'52.1"W/@-26.9084988,-49.0837159,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-26.9084988!4d-49.081141?hl=pt-BR&entry=ttu&g_ep=EgoyMDI0MTExOC4wIKXMDSoASAFQAw==
						const regexLat = /!3d(-?\d+\.\d+)/;
						const regexLng = /!4d(-?\d+\.\d+)/;
						const latMatch = url.match(regexLat);
						const lngMatch = url.match(regexLng);

						if (latMatch && lngMatch) {
							lat = parseFloat(latMatch[1]);
							lng = parseFloat(lngMatch[1]);
						}
					}
				} else if (url.includes("bing")) {
					// https://www.bing.com/maps?osid=29dc1a5f-0a77-4a92-ad76-98088d897a4f&cp=-26.871319~-49.071835&lvl=17.1&pi=0&v=2&sV=2&form=S00027
					let urlParams = new URL(url).searchParams;
					let cp = urlParams.get("cp");

					if (cp) {
						[lat, lng] = cp.split("~");
					}
				}
			} catch { setNoOptionsText("Nenhum registro encontrado"); }
		} else {
			setNoOptionsText("Nenhum registro encontrado");
			setInputValue(newInputValue);
		}

		if (lat !== 0 && lng !== 0) {
			if (!alterarSomenteLatLng) {
				resetForm();
				reverse(lat, lng)
					.then(resp => {
						if (resp !== null) {
							const addressFields = {
								'logradouro': resp.address?.road,
								'numero': resp.address?.house_number,
								'bairro': resp.address?.suburb || resp.address?.hamlet || resp.address?.city_district,
								'nomeMunicipio': resp.address?.town || resp.address?.city || resp.address?.village || resp.address?.municipality,
								'cep': resp.address?.postcode
							};

							Object.entries(addressFields).forEach(([field, value]) => {
								if (value) {
									change(formName, field, value);
								}
							});
						}
					});
			}

			change(formName, 'latitude', lat);
			change(formName, 'longitude', lng);
			change(formName, 'localizacao', { lat: lat, lng: lng });
			setNoOptionsText("Endereço encontrado");
		}
	};

	const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

	const buscarEndereco = (newValue) => {
		if (newValue == null || newValue.place_id == null)
			return;

		var placeService = new window.google.maps.places.PlacesService(document.querySelector('#google-maps'));
		placeService.getDetails({
			placeId: newValue.place_id,
			key: key,
			fields: ["name", "address_components", "formatted_phone_number", "geometry.location"]
		}, (data) => {
			try {

				if (!alterarSomenteLatLng) {
					resetForm();

					data.address_components.map(x => {
						if (x.types.indexOf("route") > -1)
							change(formName, 'logradouro', x.long_name);
						else if (x.types.indexOf("street_number") > -1)
							change(formName, 'numero', x.long_name);
						else if (x.types.indexOf("sublocality_level_1") > -1)
							change(formName, 'bairro', x.long_name);
						else if (x.types.indexOf("administrative_area_level_2") > -1)
							change(formName, 'nomeMunicipio', x.long_name);
						else if (x.types.indexOf("administrative_area_level_1") > -1)
							change(formName, 'sigaUF', x.short_name);
						else if (x.types.indexOf("postal_code") > -1)
							change(formName, 'cep', x.long_name);
					})
				}

				change(formName, 'latitude', data.geometry.location.lat().toString());
				change(formName, 'longitude', data.geometry.location.lng().toString());
				change(formName, 'localizacao', { lat: data.geometry.location.lat().toString(), lng: data.geometry.location.lng().toString() });
			} catch (e) { }
		});

	}
	return (
		<Autocomplete
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			disabled={disabled}
			loadingText='Carregando...'
			noOptionsText={noOptionsText}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
				buscarEndereco(newValue);
			}}
			onInputChange={debouncedChangeHandler}
			renderInput={(params) => (
				<TextField {...params} label={label} variant="outlined" fullWidth />
			)}
			renderOption={(option) => {
				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid item xs>
							<span style={{ fontWeight: 700 }}>
								{option.structured_formatting.main_text}
							</span>

							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
}
