import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getFormatDataTimeToString } from '../../../../Utils/FormatDateTime';
import { BuscaCompletaContext } from '../../../../Context/BuscaCompletaContext';
import { getById as getRegiaoById } from '../../../../API/Regiao';
import { getById as getMotoristaById } from '../../../../API/Motoristas';
import { BodyTableRow } from "./ResultSearchTable.style";
import { Button, CircularProgress } from '@material-ui/core';
import { getUser } from '../../../../Router/auth';
import { EmpresaIds } from '../../../../Utils/Empresa';
import { getBuscaCompletaEvidencia } from '../../../../API/BuscaCompleta';

export const ResultSearchTable = (props) => {
    const { setMoreInfo, setIsLoading, podeCarregarMais, isLoadingCarregarMais } = useContext(BuscaCompletaContext);

    const handleRowClick = async (row) => {
        try {
            await findMoreInfos(row);
        } catch (error) {
            console.error(error);
        }
    };

    const findMoreInfos = async (row) => {
        setMoreInfo(row);
        
        setIsLoading({
            motorista: true,
            regiao: true,
            evidencias: true
        });

        try {
            if (row.rota) {
                getRegiaoById(row.rota.regiaoId)
                    .then(resp => {
                        setMoreInfo(prevMoreInfo => ({
                            ...prevMoreInfo,
                            rota: {
                                ...prevMoreInfo.rota,
                                regiao: resp
                            }
                        }));

                        setIsLoading(prevIsLoading => ({
                            ...prevIsLoading,
                            regiao: false
                        }));
                    })
                    .catch(error => {
                        setIsLoading(prevIsLoading => ({
                            ...prevIsLoading,
                            regiao: false
                        }));
                    });

                getMotoristaById(row.rota.motoristaId)
                    .then(resp => {
                        setMoreInfo(prevMoreInfo => ({
                            ...prevMoreInfo,
                            rota: {
                                ...prevMoreInfo.rota,
                                motorista: resp.registros[0]
                            }
                        }));

                        setIsLoading(prevIsLoading => ({
                            ...prevIsLoading,
                            motorista: false
                        }));
                    })
                    .catch(error => {
                        setIsLoading(prevIsLoading => ({
                            ...prevIsLoading,
                            motorista: false
                        }));
                    });
            }

            getBuscaCompletaEvidencia(row.entrega.id)
                .then(resp => {
                    setMoreInfo(prevMoreInfo => ({
                        ...prevMoreInfo,
                        evidencias: resp
                    }));

                    setIsLoading(prevIsLoading => ({
                        ...prevIsLoading,
                        evidencias: false
                    }));
                })
                .catch(error => {
                    setIsLoading(prevIsLoading => ({
                        ...prevIsLoading,
                        evidencias: false
                    }));
                });

        } catch (error) {
            console.error(error);
        }
    }

    const handleCarregarMais = async () => {
        await props.carregarMais();
    }

    const canShowNomeFantasia = () => {
        let user = getUser();
        return user.empresaId === EmpresaIds.Construcolor;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {canShowNomeFantasia() && <TableCell>Nome fantasia</TableCell>}
                        <TableCell>Chave</TableCell>
                        <TableCell>Número do Documento Fiscal</TableCell>
                        <TableCell>Destinatário</TableCell>
                        <TableCell>CPF/CNPJ</TableCell>
                        <TableCell>Data Emissao</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row) => (
                        <BodyTableRow
                            key={row.chave}
                            onClick={() => {
                                handleRowClick(row);
                            }}
                        >
                            {canShowNomeFantasia() && <TableCell>{row.documento.nomeFantasiaEmitente ? row.documento.nomeFantasiaEmitente : ""}</TableCell>}
                            <TableCell>{row.documento ? row.documento.chave : ""}</TableCell>
                            <TableCell>{row.documento ? row.documento.numeroDocumentoFiscal : ""}</TableCell>
                            <TableCell>{row.entrega.destinatario.nomeDescricao ?? ""}</TableCell>
                            <TableCell>{row.entrega.destinatario.cpfCnpj ?? ""}</TableCell>
                            <TableCell>{getFormatDataTimeToString(row.documento ? row.documento.dataEmissao : row.entrega.createdDate)}</TableCell>
                        </BodyTableRow>
                    ))}
                </TableBody>
            </Table>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0"
                }}
            >
                {!isLoadingCarregarMais ? (
                    <Button
                        color="primary"
                        disabled={!podeCarregarMais}
                        onClick={handleCarregarMais}
                    >
                        Carregar mais
                    </Button>
                ) : (
                    <CircularProgress size={35} />
                )}
            </div>
        </TableContainer>
    );
}