import React from 'react';
// import Button from '@material-ui/core/Button';
import Button from '../Button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export function DialogConfirm(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClickButton1}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.onClickButton1} loading={props.button1Loading}>
            {props.button1Text}
          </Button>
          <Button onClick={props.onClickButton2} loading={props.button2Loading} >
            {props.button2Text}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
