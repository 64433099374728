import { Map, Marker, GoogleApiWrapper, Polyline, Circle } from 'google-maps-react';
import { Component } from 'react';

const blumenau = { 'lat': -26.8549626, 'lng': -49.165375 };

export class MapContainer extends Component {

    
    render() {
        const containerStyle = {
            position: 'relative',  
            width: '100%',
            height: '100%'
          }

          const style = {
            width: '100%',
            height: '85vh'
          }

        return (
            <div style={style}>
            <Map
                google={this.props.google}
                zoom={12}
                initialCenter={blumenau}
                containerStyle={containerStyle}
                style={style}
            >

                <Polyline
                    path={this.props.coords}
                    strokeColor="#0000FF"
                    strokeOpacity={0.6}
                    strokeWeight={4}
                />

                {this.props.paradasNFe.map(nfe => {
                    var retorno = [];
                    if (nfe.emitente.cnpj !== "12942657000157")
                    {
                        retorno[0] = (<Marker
                        
                            title={nfe.emitente.razaoSocial}
                            position={{ lat: nfe.emitente.endereco.latitude, lng: nfe.emitente.endereco.longitude }}>
                        </Marker>);
                    }
                    if (nfe.destinatario.cnpj !== "12942657000157")
                    {
                        retorno[1] = (<Marker
                            title={nfe.destinatario.razaoSocial}
                            
                            position={{ lat: nfe.destinatario.endereco.latitude, lng: nfe.destinatario.endereco.longitude }}>
                                
                        </Marker>);
                    }
                    return retorno;    
                })}
                <Marker
                    title={'DTudo - Matriz'}
                    position={{ lat: -26.872085267155676, lng: -49.0618655057102 }}>
                </Marker>
                <Marker
                    title={'DTudo - IT'}
                    position={{ lat: -26.82042772003655, lng: -49.087305943350216 }}>
                </Marker>
                <Marker
                    title={'Areia Schramm'}
                    position={{ lat: -26.889505666129764, lng: -48.991726176867076 }}>
                </Marker>
                <Circle
                    radius={30}
                    center={{lat: -26.872085267155676, lng: -49.0618655057102}}
                    strokeColor='transparent'
                    strokeOpacity={0}
                    strokeWeight={5}
                    fillColor='#FF0000'
                    fillOpacity={0.2}
                />
            </Map>
            </div>
        );
    }
}

const LoadingContainer = (props) => (
    <div style={{position: 'relative',  
    width: '100%',
    height: '100%'}}>Carregando mapa!</div>
)

export default GoogleApiWrapper({
    apiKey: ('AIzaSyAcLs5kRKHSwb-jYTQQQaDACrwK4OrhcF0'),
    LoadingContainer: LoadingContainer
})(MapContainer)