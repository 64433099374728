import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddBox';
import ReplayIcon from '@material-ui/icons/Replay';
import { useHistory } from 'react-router-dom';
import { lighten, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import debounce from "lodash.debounce";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  highlight:
  {
    paddingLeft: theme.spacing(2),
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },

  title: {
    flex: '1 1 100%',
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selected } = props;
  const history = useHistory();

  const changeHandler = (event) => {
    props.filtrar(event.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 1000), []);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && props.filtrarDuranteSelecao !== true ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} {numSelected === 1 ? 'selecionado' : 'selecionados'}
        </Typography>
      ) : (
        <>
          {props.LinkNew ?
            <Tooltip title="Clique para criar um novo registro">

              <Button variant="contained" color="primary" startIcon={<AddIcon />} style={{ width: '180px', height: '40px' }} onClick={() => { history.push(props.LinkNew); }}>
                {props.titleNovo}
              </Button>
            </Tooltip> : <></>}
          <>
            {props.TemFiltro ?
              <Grid container spacing={1} alignItems="flex-end" style={{ paddingLeft: '50px' }}>
                <Grid item>
                  <SearchIcon />
                </Grid>
                <Grid item>
                  <TextField label="Pesquisar" style={{ width: '300px', marginTop: '16px', marginBottom: '8px' }} onChange={debouncedChangeHandler} />
                </Grid>

                <Grid item>
                  {numSelected > 0 && props.filtrarDuranteSelecao ? <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} {numSelected === 1 ? 'selecionado' : 'selecionados'}
                  </Typography> : <></>}
                </Grid>

              </Grid> : <></>}

            {props.camposFiltros ?
              <Grid container spacing={1} alignItems="flex-end" style={{ paddingLeft: '50px' }}>

                <Grid item>
                  {props.camposFiltros}

                </Grid>

              </Grid> : <Grid container spacing={1} alignItems="flex-end" style={{ paddingLeft: '50px' }}></Grid>}


            {props.atualizar ?
              <Tooltip title="Atualizar">
                <IconButton onClick={() => props.atualizar()}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip> : <> </>}


          </>
        </>
      )}
      {props.actions && numSelected === 0 ? (
        typeof props.actions === 'function' ? props.actions() : props.actions
      ) : <></>}

      {numSelected > 0 && props.actionsWhenSelect ? (
        props.actionsWhenSelect(selected)
      ) : (
        <></>
      )}

      {numSelected > 0 && props.CanRemove !== false ? (
        <Tooltip title="Excluir">
          <IconButton aria-label="delete" onClick={() => props.clickExcluir()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default TableToolbar;