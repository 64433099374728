import React, { useCallback, useEffect } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll } from '../../API/Regiao';
import { RegioesNewRoute, RegioesEditRoute } from '../../Router/Routing';
import { columnsGrid } from './Config';
import { FiltroAtivo } from '../../Components/FiltroAtivo/FiltroAtivo';

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filtroAtivo, setFiltroAtivo] = React.useState(true);

    const loadRow = useCallback(() => {
        setRows([]);
        setLoading(true);

        getAll(filtroAtivo)
            .then((value) => {
                if (value !== undefined)
                    setRows(value);
                setLoading(false);
            });

        setColumns(columnsGrid);
    }, [filtroAtivo]);

    useEffect(() => {
        loadRow();
    }, [loadRow])

    const camposFiltro = () => {
        return (
            <FiltroAtivo 
                value={filtroAtivo}
                onChange={setFiltroAtivo}
            />
        )
    }

    return (
        <GridTable
            title="Regiões"
            titleNovo="Nova"
            rows={rows}
            columns={columns}
            LinkNew={RegioesNewRoute}
            LinkEdit={RegioesEditRoute}
            loading={loading}
            CanRemove={false}
            camposFiltros={camposFiltro()}
        />
    );
}