import { MapContainer, TileLayer, useMap } from "react-leaflet"
import DraggableMarker from "../../../../Components/Map/DraggableMarker/DraggableMarker"
import { useContext, useEffect, useState } from "react"
import { AjusteEnderecoContext } from "../../../../Context/AjusteEnderecoContext"

export const MapEndereco = () => {
    const { coords, setCoords, setTextCoords } = useContext(AjusteEnderecoContext);
    const [position, setPosition] = useState([0, 0]);

    useEffect(() => {
        setPosition([coords.lat, coords.lng])
    }, [coords]);

    const handleCoords = (newCoords) => {
        setCoords(prevState => ({
            ...prevState,
            lat: newCoords.lat,
            lng: newCoords.lng
        }));

        setTextCoords(`${newCoords.lat}, ${newCoords.lng}`);
    }

    return (
        <MapContainer
            center={position}
            zoom={14}
            scrollWheelZoom={true}
            style={{
                width: "100%",
                height: "600px"
            }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker
                position={position}
                event={handleCoords}
            />
            <ChangeMapView coords={position} />
        </MapContainer>
    )
}

const ChangeMapView = ({ coords }) => {
    const map = useMap();
    map.flyTo(coords, map.getZoom(), {
        duration: 0.5
    });
    return <></>;
}