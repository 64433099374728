import { useEffect, useState } from "react";
import { Avatar, Badge, Box, Tooltip } from "@mui/material";
import InternoIcon from '@material-ui/icons/HomeWork';
import Timer from "./Timer";
import { getEmpresa } from "../../../Router/auth";
import * as ColorsGrafos from "../../../Utils/ColorsGrafos";
import { SituacaoParadaMonitoramento, TipoParadaMonitoramento } from "../Config";
import TruckIcon from '@material-ui/icons/LocalShipping';
import MecanicaIcon from '@material-ui/icons/Build';
import SvgIcon from '@material-ui/core/SvgIcon';
import FimIcon from '@material-ui/icons/Flag';
import VisitaIcon from '@material-ui/icons/HowToReg';
import RecolhaIcon2 from '@material-ui/icons/SaveAlt';
import { ReactComponent as noSignalIcon } from "../../../img/icon/noSignalIcon.svg";
import EntregaIcon from '../../../Icons/EntregaIcon';
import { ReactComponent as localPlanejado_3 } from '../../../img/icon/localPlanejado_4.svg';
import { ReactComponent as alert } from '../../../img/icon/alert_1.svg';
import { ParadaInformacao } from "./ParadaInformacao";
import React from 'react';


export const IconParada = ({ parada }) => {
    const [dadosParada, setDadosParada] = useState({
        icon: null,
        tipo: "",
        style: null,
        emTransito: false
    });

    useEffect(() => {
        processarTipoParadaMonitoramento(parada);
    }, [parada])

    // TODO: melhorar o if
    const verificaTemTimer = () => {
        if (parada.chegadaAoClienteHistorico !== null && parada.chegadaAoClienteHistorico !== undefined && parada.chegadaAoClienteHistorico.length > 0)
        {
            if (parada.chegadaAoClienteHistorico[0].dataSaida == null) return true;
            return false;
        }

        // Esssa verificação aqui de baixo, é para as entregas antigas, antes de ter o historico.
        //Após 30/08/2025, talvez possamos remover, pois vai fechar um ano.
        if (parada.chegadaAoDestinoDate != null && parada.chegadaAoDestinoDate !== undefined
            && (parada.dataRealizada == null || parada.dataRealizada === "0001-01-01T00:00:00") && getEmpresa().chegadaAoClienteObrigatoria === true) {
            return true;
        }
        return false;
    }

    // esse metodo é chamado como callback pelo componente Timer
    const updateColorByTime = () => {
        setDadosParada({
            ...dadosParada,
            style: { backgroundColor: ColorsGrafos.LARANJA_DA_GRAFOS }
        });
    }

    const aplicarStyle = (situacao, maisClaro) => {
        var style = {};
        switch (situacao) {
            case SituacaoParadaMonitoramento.Aguardando:
                style = {};
                break;
            case SituacaoParadaMonitoramento.Realizado:
                style = { backgroundColor: ColorsGrafos.VERDE_PASTEL };
                if (maisClaro)
                    style = { color: ColorsGrafos.VERDE_PASTEL, backgroundColor: ColorsGrafos.VERDE_FRACO };
                break;
            case SituacaoParadaMonitoramento.NaoPlanejado:
                style = { backgroundColor: ColorsGrafos.VERMELHO_FRACO };
                break;
            case SituacaoParadaMonitoramento.EmAndamento:
                style = { backgroundColor: ColorsGrafos.VERDE_PASTEL };
                if (maisClaro)
                    style = { color: ColorsGrafos.VERDE_PASTEL, backgroundColor: ColorsGrafos.VERDE_FRACO };
                break;
            default:
                style = { color: ColorsGrafos.VERDE_PASTEL, backgroundColor: ColorsGrafos.VERDE_FRACO };
                break;
        }

        return style;
    }

    const processarTipoParadaMonitoramento = (parada) => {
        var tipo = "";
        var icon = <InternoIcon />;
        var style = null;
        var maisClaro = false;

        switch (parada.tipo) {
            case TipoParadaMonitoramento.Inicio:
                tipo = 'Inicio de rota';
                icon = <FimIcon />;
                style = { color: ColorsGrafos.VERDE_PASTEL, backgroundColor: ColorsGrafos.VERDE_FRACO };
                break;
            case TipoParadaMonitoramento.Interno:
                icon = <InternoIcon />;
                tipo = 'Parada interna';
                maisClaro = true;
                break;
            case TipoParadaMonitoramento.Mecanica:
                icon = <MecanicaIcon />;
                tipo = 'Posto de Combustivel/Oficina';
                break;
            case TipoParadaMonitoramento.Entrega:
                icon = EntregaIcon;
                tipo = 'Entrega';
                if (parada.situacao === SituacaoParadaMonitoramento.NaoPlanejado)
                    style = { backgroundColor: ColorsGrafos.VERMELHO_PASTEL };
                break;
            case TipoParadaMonitoramento.Fim:
                icon = <FimIcon />;
                tipo = 'Fim de rota';
                style = { color: ColorsGrafos.VERDE_PASTEL, backgroundColor: ColorsGrafos.VERDE_FRACO };
                break;
            case TipoParadaMonitoramento.ParadaNaoPlanejada:
                icon = <SvgIcon component={alert} style={{ backgroundColor: ColorsGrafos.VERMELHO_FRACO, color: 'white' }} />
                tipo = 'Parada não identificada';
                break;
            case TipoParadaMonitoramento.LocalPlanejado:
                icon = <SvgIcon component={localPlanejado_3} />
                tipo = 'Parada em local planejado';
                if (parada.situacao === SituacaoParadaMonitoramento.NaoPlanejado)
                    style = { backgroundColor: ColorsGrafos.VERMELHO_PASTEL };
                break;
            case TipoParadaMonitoramento.Visita:
                icon = <VisitaIcon />
                tipo = 'Visita';
                if (parada.situacao === SituacaoParadaMonitoramento.NaoPlanejado)
                    style = { backgroundColor: ColorsGrafos.VERMELHO_PASTEL };
                break;
            case TipoParadaMonitoramento.Recolha:
                icon = <RecolhaIcon2 />
                tipo = 'Recolha';
                if (parada.situacao === SituacaoParadaMonitoramento.NaoPlanejado)
                    style = { backgroundColor: ColorsGrafos.VERMELHO_PASTEL };
                break;
            case TipoParadaMonitoramento.SemSinalGPS:
                tipo = "Sem sinal de GPS";
                icon = <SvgIcon component={noSignalIcon} style={{ backgroundColor: ColorsGrafos.VERMELHO_FRACO, color: ColorsGrafos.WHITE }} />
                break;
            default:
                break;
        }

        if (style == null) {
            style = aplicarStyle(parada.situacao, maisClaro)
        }

        var emTransito = false;
        if (parada.situacao === SituacaoParadaMonitoramento.EmAndamento) {
            tipo = `Em andamento - ${tipo}`;
            if (parada.tipo === TipoParadaMonitoramento.emTransito) {
                emTransito = true;
                tipo = 'Em trânsito ';
                style = { color: ColorsGrafos.VERDE_PASTEL, backgroundColor: ColorsGrafos.VERDE_FRACO };
                icon = <TruckIcon />;
            }
        }

        setDadosParada({
            icon,
            tipo,
            style,
            emTransito
        });
    }

    var comDoisBadge = false;
    var comVoltarDepois = false;
    if (parada.chegadaAoClienteHistorico !== null && parada.chegadaAoClienteHistorico !== undefined && parada.chegadaAoClienteHistorico.length > 0) // Precisa ter 
    {
        if (parada.situacao === SituacaoParadaMonitoramento.Aguardando)
        {
            comVoltarDepois = !(parada.chegadaAoClienteHistorico[0].dataSaida === null || parada.chegadaAoClienteHistorico[0].dataSaida === undefined);
        }
        else
        {
            comVoltarDepois = parada.chegadaAoClienteHistorico.length > 1;
        }
    }

    if (!(parada.comentario == null || parada.comentario.trim() === "") && comVoltarDepois)
        comDoisBadge = true;
    
    return (
        <>
            <Tooltip
                title={
                    <ParadaInformacao
                        tipo={dadosParada.tipo}
                        parada={parada}
                        emTransito={dadosParada.emTransito} />
                } >
                <Box style={{
                    margin: "0px 5px"
                }}>
                    <Badge
                        color="primary"
                        variant="dot"
                        invisible={parada.comentario == null || parada.comentario.trim() === ""}
                        componentsProps={{
                            badge: {
                                style: {
                                    width: "14px",
                                    height: "14px",
                                    border: "2px solid #fff",
                                    borderRadius: "50%",
                                    marginTop: "2px",
                                    marginRight: "2px",
                                    backgroundColor: ColorsGrafos.AZUL_PASTEL
                                }
                            }
                        }}>
                            <Badge
                        color="primary"
                        variant="dot"
                        invisible={!comVoltarDepois}
                        componentsProps={{
                            badge: {
                                style: {
                                    width: "14px",
                                    height: "14px",
                                    border: "2px solid #fff",
                                    borderRadius: "50%",
                                    marginTop: "2px",
                                    marginRight: (comDoisBadge?"10px":"2px"),
                                    backgroundColor: ColorsGrafos.AMARELO_PASTEL
                                }
                            }
                        }}>
                        <Avatar
                            variant="rounded"
                            style={{
                                ...dadosParada.style,
                                width: "auto",
                                height: "auto"
                            }}>
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "4px"
                                }}>
                                {dadosParada.icon}
                            </Box>
                            {verificaTemTimer() &&
                                <Box
                                    style={{
                                        marginRight: "5px"
                                    }}>
                                    <Timer
                                        currentTime={parada.chegadaAoDestinoDate}
                                        updateColorByTime={updateColorByTime} />
                                </Box>
                            }
                        </Avatar>
                        </Badge>
                    </Badge>
                </Box>
            </Tooltip>
        </>
    )
}