import { DialogEvidenciaMediator } from "../../Components/DialogParaEvidencia";

export const columnsGrid = [
    { label: "Data", field: "dataMobile", type: "datetime" },
    { label: "Km Digitado", field: "kmDigitado", type: "customWithRow", function: (row) => {return row.kmDigitado + " Km"} },
    //{ label: "Status", field: "statusAnalise", type: "customWithRow", function: status },
    { label: "Local", field: "", type: "customWithRow", function: local },
    { label: 'Ações', field: 'acoes', type: 'customWithRow', padding: 'default', function: acessoNoMapa, width: '100px', align: "center" }
];

export const statusAnalise = {
    emAnalise: 0,
    analisado: 1
}

function local(row) {
    return row.latitude + ', ' + row.longitude;
}

function acessoNoMapa(row) {
    return (
        <DialogEvidenciaMediator
            evidencia={{
                nome: "Quilometragem",
                latitude: row.latitude,
                longitude: row.longitude,
                pathServidor: row.pathArquivo
            }}
        />
    )
}

function status(row) {
    let txt = "";

    if (row.statusAnalise === statusAnalise.emAnalise) {
        txt = "Em análise";
    } else if (row.statusAnalise === statusAnalise.analisado) {
        txt = "Analisado";
    }

    return txt;
}