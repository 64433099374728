import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";


const DateField = props => {
    const {
      meta: { submitting, error, touched },
      input: { onBlur, value, ...inputProps },
      required,
      validate,
      ref,
      label,
      ...others
    } = props;
  
    const onChange = date => {
      inputProps.onChange(date);
    };
  
    return (
      <KeyboardDatePicker
        {...inputProps}
        {...others}
        format="dd/MM/yyyy"
        inputVariant="outlined"
        variant='inline'
        value={value ? new Date(value) : null}
        //disabled={submitting}
        //disabled={true}
        error={error && touched}
        onChange={onChange}
        
        invalidDateMessage='Data invalida'
        style={{ width: '100%' }}
        required={required}
        validate={validate}
        ref={ref}
        label={label}
        
      />
    );
  };

  export default DateField;