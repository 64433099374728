import { instance } from './axios';
import { getFormatDataToDataApi } from '../Utils/FormatDateTime';

export async function getAll(maxRegistro, filtro, inicio, fim) {

  var filtroData;
  if (isNaN(inicio) || isNaN(fim)) {
    filtroData = "";
  } else {
    filtroData = `&inicio=${getFormatDataToDataApi(inicio)}&fim=${getFormatDataToDataApi(fim)}`;
  }

  try {
    const response = await instance.get(`api/PacoteEvidencias/ListAll?maxRegistro=${maxRegistro}&filtroIds=${filtro}${filtroData}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function put(id) {
  try {
    const response = await instance.put('/api/PacoteEvidencias/UpdateDownloadRealizado/' + id);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    return { error: error, result: 'error' };
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/PacoteEvidencias/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getByIdCompleto(id) {
  try {
    const response = await instance.get('/api/PacoteEvidencias/GetByIdCompleto/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getByEmitente(emitenteId) {
  try {
    const response = await instance.get('/api/PacoteEvidencias/' + emitenteId)
    if (response.status === 200) {
      return response.status;
    }
  } catch (error) {
    throw error;
  }
}

export async function createPacote(pacoteEvidencia) {
  try {
    const response = await instance.post(`/api/PacoteEvidencias/Create`, pacoteEvidencia);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function updatePacote(id, pacoteEvidencia) {
  try {
    const response = await instance.put(`/api/PacoteEvidencias/Update/${id}`, pacoteEvidencia);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}