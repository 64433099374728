import { KeyboardDatePicker } from '@material-ui/pickers';
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from 'react';
import { getAll } from '../../API/PacoteEvidencias';
import { GridTable } from '../../Components/GridTable/GridTable';
import { columnsGrid } from './Config';
import { Alert, AlertTitle } from '@material-ui/lab';
import MultiplaSelecao from '../../Components/MultiplaSelecao/MultiplaSelecao'
import { PacoteEvidenciasRouteEdit, PacoteEvidenciasRouteNew } from '../../Router/Routing';

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    let [inicio, setInicio] = React.useState(new Date().setDate(new Date().getDate() - 5));
    let [fim, setFim] = React.useState(new Date().setDate(new Date().getDate() + 10));
    const [emitentesIds, setEmitentesIds] = useState([]);

    const handleDataInicioChange = async (date) => {
        inicio = date;
        setInicio(inicio);
        loadRow();
    };

    const handleDataFimChange = (date) => {
        fim = date;
        setFim(fim);
        loadRow();
    };

    useEffect(() => {
        loadRow();
    }, [emitentesIds])

    async function loadRow() {
        setRows([]);
        setLoading(true);

        inicio = new Date(inicio).setHours(0, 0, 1, 0);
        fim = new Date(fim).setHours(23, 59, 59, 999);

        var maxRegistro = 50;
        var filtro = emitentesIds.toString();

        getAll(maxRegistro, filtro, inicio, fim).then((value) => {
            if (value !== undefined) {
                setRows(value);
            }

            setLoading(false);
        });

        setColumns(columnsGrid);
    }

    useEffect(async () => {
        loadRow();
    }, [])

    async function atualizar() {
        await loadRow();
    }

    function emitenteAlterado(ids) {
        setEmitentesIds(ids);
    }

    const debouncedChangeHandler = useCallback(debounce(handleDataInicioChange, 1000), []);
    const debouncedChangeHandlerFim = useCallback(debounce(handleDataFimChange, 1000), []);

    const campos = () => {
        return <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    style={{ marginLeft: '15px', width: '300px' }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data inicial"
                    value={inicio}
                    onChange={debouncedChangeHandler}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />

                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    style={{ marginLeft: '15px', width: '300px' }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data final"
                    value={fim}
                    onChange={debouncedChangeHandlerFim}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />

                <div style={{ marginLeft: '15px', width: '300px', marginTop: '16px', marginBottom: '8px' }}>
                    <MultiplaSelecao

                        label="Emitente"
                        placeholder="Selecione o/os emitentes desejados"
                        localStorageName="Emitente_selecionado"
                        endpoint="/api/Pessoas/lookup"
                        onChange={emitenteAlterado}
                    ></MultiplaSelecao>
                </div>
            </div>
        </>
    }

    return (
        <>
            <div style={{ with: '100%' }} maxWidth='true'>
                <Alert severity="warning" >
                    <AlertTitle>Atenção </AlertTitle>
                    As evidências geradas estarão disponíveis para download (lista abaixo), nesta plataforma por um período de 30 dias corridos.
                    É de sua responsabilidade (empresa) baixar os arquivos e armazená-los em seu computador ou servidor dentro desse prazo.
                    Após a data de exclusão, as evidências serão removidas automaticamente.
                </Alert>
            </div>

            <GridTable 
                title="Download de Evidências"
                titleNovo="Novo"
                rows={rows}
                columns={columns}
                loading={loading}
                atualizar={atualizar}
                camposFiltros={campos()}
                CanRemove={false}
                rowsPerPage={25}
                LinkNew={PacoteEvidenciasRouteNew}
                LinkEdit={`${PacoteEvidenciasRouteEdit}/`}
            />
        </>
    );
}