import { instance } from './axios'

export async function getAllById(cteId) {
	try {
		const response = await instance.get(`/api/ConhecimentoTransporteNf/GetAllById/${cteId}`);
		if (response.status == 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
}