import {instance_ms_pix } from './axios_Pix'
import { getFormatDataToDataApi } from '../Utils/FormatDateTime';

export async function getAll(nomeDocumentoDevedor, dataInicio, dataFim, rotaIds, entregasIds, status, page=0, maxRegistro=500) {
  try {
    var query = '';
    if (nomeDocumentoDevedor)
        query='nomeDocumentoDevedor='+nomeDocumentoDevedor;
    if (dataInicio)
    {
        if (query)
            query+='&';
        query+='dataInicio='+getFormatDataToDataApi(dataInicio);
    }
    if (dataFim)
    {
        if (query)
            query+='&';
        query+='dataFim='+getFormatDataToDataApi(dataFim);
    }

    if (rotaIds && rotaIds.length > 0)
    {
        var queryMoto = '';
        rotaIds.forEach(element => {
            queryMoto+='rotaIds='+element+"&";
        });
        if (query)
            query+='&'+queryMoto.substr(0, queryMoto.length - 1);
        else
            query+=queryMoto.substr(0, queryMoto.length - 1);
    }

    if (entregasIds && entregasIds.length > 0)
    {
        entregasIds.forEach(element => {
            queryMoto+='entregasIds='+element+"&";
        });
        if (query)
            query+='&'+queryMoto.substr(0, queryMoto.length - 1);
        else
            query+=queryMoto.substr(0, queryMoto.length - 1);
    }
    if (status && status.length > 0)
    {
        status.forEach(element => {
            if (element !== '')
                queryMoto+='status='+element+"&";
        });
        if (queryMoto)
        {
        if (query)
            query+='&'+queryMoto.substr(0, queryMoto.length - 1);
        else
            query+=queryMoto.substr(0, queryMoto.length - 1);
        }
    }
    
    if (query)
        query+='&';

    const response = await instance_ms_pix.get('/api/PagamentoPix/GettAll?'+query+'page='+page+'&maxRegistro='+maxRegistro);
    if (response.status ===  200){
        return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}