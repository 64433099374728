import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { getAllRotaAnalise } from '../../../API/RotaAnalise';
import { Skeleton } from '@material-ui/lab';

export default function QuilometragemPrevista_Form(props) {

    const [loading, setLoading] = useState(true);
    const [km, setKm] = useState('');

    useEffect(() => {
        if (props.value.empresaId && props.value.id) {
            getAllRotaAnalise("", "", [], [], [], props.value.id).then((value) => {
                if (value !== undefined) {
                    value.forEach(element => {
                        setKm(element.kmPrevisto);
                    });
                }
                setLoading(false);
            }).catch((e) => { setLoading(false); });
        } else {
            setLoading(false);
        }
    }, [props.value.empresaId, props.value.id])

    if (loading) {
        return <Skeleton variant="rect" width={80} height={10} />
    }

    if (loading === false) {
        return (
            <>
                <Typography variant="caption" display="block" gutterBottom>
                    Km Previsto
                </Typography>

                <div style={{ display: 'block', height: 8, width: '100%' }}></div>
                <Typography variant={'subtitle1'} display="block" gutterBottom>
                    {(km === null || km === undefined || km === '') ?
                        (<>0 Km</>)
                        :
                        (<>{(km).toLocaleString('pt-BR')} Km </>)
                    }
                </Typography>
            </>
        );
    }
}

