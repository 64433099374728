import React from 'react';
import Typography from '@material-ui/core/Typography';
import { opcaoSituacaoRoteirizacao } from '../../Pages/Rota/Config'
import CallSplitIcon from '@material-ui/icons/CallSplit';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { vermelhoPastel, amareloPastel } from '../Map/MarkerColors';
import { Box } from '@mui/material';
import * as ColorGrafos from "../../Utils/ColorsGrafos";
import { AreasGeograficas } from '../../Pages/Rota/utils/enums';

export default function StatusRoteirizacao(props) {
	var t = opcaoSituacaoRoteirizacao.filter(function (item) {
		return item.value === props.statusRoteirizacao;
	});

	if (t.length > 0) {
		if (t[0].value === 0)
			return <></>;
		if (t[0].value === 2) // Roteirizado
		{
			return (
				<Box
					sx={{
						width: "100%",
						"& > *": {
							margin: '0px 5px'
						}
					}}
				>
					<Tooltip title={title(t[0].label)}><CallSplitIcon style={{ color: 'rgb(92 182 96)' }} /></Tooltip>

					{props.comAviso && (
						<Tooltip title={title("Atenção, verifique as paradas.")}><WarningIcon style={{ color: '#ed6c02' }} /></Tooltip>
					)}

					{props.rotaAlerta && (
						<Tooltip title={title(props.areasGeograficas === AreasGeograficas.RodizioSP ?
							props.proibidoNoRodizio ? "Veículo não adequado para a área de rodízio. Verifique!" : "Veículo adequado para a área de rodízio" : "Paradas em área de restricao. Verifique!")}
						>
							<ShareLocationIcon
								sx={{
									color: props.areasGeograficas === AreasGeograficas.RodizioSP ?
										props.proibidoNoRodizio ? ColorGrafos.RED: ColorGrafos.GREEN : ColorGrafos.LARANJA_DA_GRAFOS,
								}} />
						</Tooltip>
					)}
				</Box>
			);
		}
		if (t[0].value === 1)//aguardando
			return <Tooltip title={title(t[0].label)}><AutorenewIcon className="fa fa-refresh fa-spin" style={{ color: 'rgb(3, 169, 244)', marginRight: '20px' }} /></Tooltip>;

		if (t[0].value === 3)//Em Roteirização
			return <Tooltip title={title(t[0].label)}><AutorenewIcon className="fa fa-refresh fa-spin" style={{ color: 'rgb(3, 169, 244)', marginRight: '20px' }} /></Tooltip>;

		if (t[0].value === 4)//Erro ao roteirizar
			return <Tooltip title={title(t[0].label)}><ErrorIcon style={{ color: vermelhoPastel, marginRight: '20px' }} /></Tooltip>;

		if (t[0].value === 5)//Atenção - Ação necessária
			return <Tooltip title={title(t[0].label)}><WarningIcon style={{ color: amareloPastel, marginRight: '20px' }} /></Tooltip>;
	}
}

function title(text, text2) {
	return <div >
		<Typography variant="subtitle1"><b>{text}</b></Typography>
		<Typography variant="body2">{text2}</Typography>
	</div>
}
