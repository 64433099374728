import { useCallback, useEffect, useState } from "react";
import { GridTable } from "../../Components/GridTable/GridTable";
import { getByRotaId } from "../../API/RegistroQuilometragem";
import { columnsGrid } from "./Config";

function GridList({ rotaId }) {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadRow = useCallback(async () => {
        setLoading(true);
        getByRotaId(rotaId)
            .then(resp => {
                setRows(resp);
            }).catch(err => {
                setRows([])
            }).finally(fll => {
                setLoading(false);
            });
    }, [rotaId]);

    useEffect(() => {
        setColumns(columnsGrid);
        loadRow();
    }, [rotaId, loadRow]);

    return (
        <GridTable 
            title="Registro Quilometragem"
            rows={rows}
            columns={columns}
            loading={loading}
            CanRemove={false}
        />
    )
}

export default GridList;