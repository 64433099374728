import React, { useContext, useEffect, useState } from 'react';
import { get_paradas_planejadas } from '../../../API/ParadaPlanejadas';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Avatar } from "@mui/material";
import { MonitoramentoContext } from "../../../Context/MonitoramentoContext";
import { styles } from "../style/styleMonitoramento";
import { IconParada } from './IconParada';
import { SituacaoParadaMonitoramento, TipoParadaMonitoramento } from '../Config';

const ListaParadas = ({ classes, rota, verNaoIdentificada }) => {
	const [loading, setLoading] = useState(true);
	const [paradas, setParadas] = useState([]);
	const [paradasProcessadas, setParadasProcessadas] = useState([]);
	const { atualizarParadas, setAtualizarParadas } = useContext(MonitoramentoContext);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await get_paradas_planejadas(rota.id)
					.then(resp => {
						setParadas([]);
						setParadas(resp);
						setLoading(false);
					}
				);
			} catch (error) {
				setLoading(false);
			}
		};

		fetchData();
	}, [rota.id]);

	useEffect(() => {
		if (atualizarParadas) {
			const fetchData = async () => {
				try {
					await get_paradas_planejadas(rota.id)
						.then(resp => {
							setParadas([]);
							setParadas(resp);
							setLoading(false);
						}
					);
				} catch (error) {
					setLoading(false);
				}
			};

			fetchData();
			setAtualizarParadas(false);
		}
	}, [atualizarParadas, setAtualizarParadas, rota.id]);

	useEffect(() => {
		const processarParadas = () => {
			const novasParadas = [];
			let paradaMaiorData = null;
			let primeiraParada = true;
			for (let i = 0; i < paradas.length; i++) {
				let parada = paradas[i];

				if (primeiraParada) {
					paradaMaiorData = parada;
					primeiraParada = false;
				}

				let naoPlanejadas = parada.tipo === TipoParadaMonitoramento.SemSinalGPS || parada.tipo === TipoParadaMonitoramento.ParadaNaoPlanejada;

				if (!(naoPlanejadas && !verNaoIdentificada) || parada.situacao === SituacaoParadaMonitoramento.EmAndamento) {

					const maior_data = new Date(paradaMaiorData.dataInicio);
					const data_parada = new Date(parada.dataInicio);
					if (maior_data.getFullYear() <= data_parada.getFullYear()
						&& maior_data.getMonth() <= data_parada.getMonth()
						&& maior_data.getDay() < data_parada.getDay()) {
						paradaMaiorData = parada
						novasParadas.push(
							<Avatar
								variant="square"
								style={{
									width: "8px",
									height: "8px",
									backgroundColor: "#bdbdbd",
									color: "#bdbdbd",
									borderRadius: "3px",
									margin: "7px",
									marginTop: "22px"
								}}
							>.</Avatar>
						)
					}

					novasParadas.push(
						<IconParada
							parada={parada}
						/>
					);
				}
			}

			setParadasProcessadas(novasParadas);
		};

		processarParadas();
	}, [paradas, verNaoIdentificada]);

	if (loading) {
		return <div style={{ width: '100px', marginLeft: '10px' }}><Skeleton variant="text" /></div>;
	}

	return <Grid item xs={12} lg={12} className={classes.map}>{paradasProcessadas}</Grid>;
};

export default withStyles(styles)(ListaParadas);
