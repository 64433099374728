import { Box, Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AtualizarComentario } from "../../../API/Rotas";
import TextsmsIcon from '@mui/icons-material/Textsms';
import ErrorIcon from '@mui/icons-material/Error';
import { VERMELHO_PASTEL } from "../../../Utils/ColorsGrafos";
import ButtonCustom from "../../../Components/Button/Button";

function ButtonComentarioRota({ rota }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [comentario, setComentario] = useState("");

    useEffect(() => {
        setComentario(rota.comentario);
    }, [rota])

    const salvar = () => {
        setLoading(true);
        setError("");

        rota.comentario = comentario;

        AtualizarComentario(rota.id, rota)
            .then(resp => {
                setOpen(false);
            }).catch(err => {
                setError("Erro ao salvar. Tente novamente");
            }).finally(e => {
                setLoading(false);
            });
    }

    return (
        <>
            <Tooltip
                disableHoverListener={!rota.comentario}
                title={rota.comentario != null ? (
                    <Typography variant="subtitle1"><b>{rota.comentario}</b></Typography>
                ) : <></>}
            >
                <Button
                    variant="text"
                    color="primary"
                    size="large"
                    startIcon={<TextsmsIcon />}
                    onClick={() => { setOpen(true) }}
                >
                    Comentário para rota
                </Button>
            </Tooltip>

            <Dialog
                open={open}
                onClose={() => { setOpen(false) }}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <DialogTitle align="center">
                        Editar comentário da rota
                    </DialogTitle>

                    <Typography
                        variant="body1"
                        sx={{ marginBottom: "10px" }}
                    >
                        Esse comentário aparecerá na tela de rota no app do motorista
                    </Typography>

                    <TextField
                        label="Comentário para rota"
                        variant="outlined"
                        value={comentario}
                        onChange={(e) => { setComentario(e.target.value) }}
                        sx={{
                            width: "90%"
                        }}
                    />

                    {error ? (
                        <>
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                                sx={{ color: VERMELHO_PASTEL }}
                            >
                                <ErrorIcon />
                                <Typography
                                    variant="h6"
                                    align="center"
                                >
                                    {error}
                                </Typography>
                            </Stack>
                        </>
                    ) : <></>}
                </Box>

                <DialogContent
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Box sx={{ display: "flex" }}>
                        <ButtonCustom
                            variant="contained"
                            color="primary"
                            loading={loading}
                            onClick={salvar}
                            sx={{ marginRight: "5px" }}
                        >
                            Salvar
                        </ButtonCustom>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => { setOpen(false) }}
                            sx={{ marginLeft: "5px" }}
                        >
                            Fechar
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ButtonComentarioRota;