import { createContext, useState } from "react"

export const SequenciaFixadaContext = createContext();

export const SequenciaFixadaProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paradasPlanejadas, setParadasPlanejadas] = useState([]);
    
    return (
        <SequenciaFixadaContext.Provider
            value={{
                open, setOpen,
                loading, setLoading,
                paradasPlanejadas, setParadasPlanejadas
            }}
        >
            {children}
        </SequenciaFixadaContext.Provider>
    );
}