export const columnsGrid = [
    { label: 'Chave', field: 'chave', type: 'text', padding: 'default' },
    { label: 'Número', field: 'numero', type: 'text', padding: 'default' },
    { label: 'Produto Predominante', field: 'produtoPredominante', type: 'text', padding: 'default' },
    { label: 'Quantidade de Volume', field: 'quantidadeDeVolume', type: 'decimal', padding: 'default' },
    { label: 'Peso', field: 'peso', padding: 'default', type: "peso" },
    { label: 'Cubagem', field: 'cubagem', padding: 'default', type: "peso" },
    { label: 'Valor da Mercadoria', field: 'valorMercadoria', type: 'money', padding: 'default', numeric: true }
];

export const camposForm = [
    { name: "remetente.descricao", label: "Remetente", type: "text", size: 4, required: true, disabled: true},
    { name: "destinatario.descricao", label: "Destinatário", type: "text", size: 4, required: true, disabled: true},
    { name: "recebedor.descricao", label: "Recebedor", type: "text", size: 4, required: true, disabled: true},
    { name: "chave", label: "Chave", type: "text", size: 4, required: true, disabled: true},
    { name: "numeroDocumentoFiscal", label: "Número", type: "text", size: 3, disabled: true},
    { name: "idExterno", label: "Número Externo", type: "text", size: 1, disabled: true},
    { name: "naturezaOperacao", label: "Natureza da operação", type: "text", size: 4,  disabled: true},
    { label: 'Valor Total', name: 'total.valorTotal', type: 'money',  size: 2 , disabled: true, numeric: true},
    { label: 'Valor do Frete', name: 'total.valorFrete', type: 'money',  size: 2 , disabled: true, numeric: true},
    { name: "pesoBruto", label: "Peso Bruto", type: "peso", size: 2 , disabled: true},
    { name: "quantidadeVolume", label: "Quantidade de volume", type: "text", size: 2 , disabled: true},
    { name: "cubagem", label: "Cubagem (m3)", type: "text", size: 2 , disabled: true},
    { name: "Quantidade de Pares", label: "Quantidade de Pares", type: "text", size: 2 , disabled: true},
    { name: "dataEmissao", label: "Data Emissão", type: "date", size: 2 , disabled: true},
    { name: "tipoOperacao", label: "Operacao", type: "option", size: 2, required: false, options: [{ value: 0, label: 'Entrada' }, { value: 1, label: 'Saída' }], disabled: true },
    { name: "informacaoComplementar", label: "Informação complementar", type: "text", multiline: true, rowsMax: 4, size: 8 , disabled: true},
    { name: "conhecimentoTransporteNfs", type: "component", component: '', size: 12 , disabled: true},
]
