import { instance } from './axios'
import { getFormatDataToDataApi } from '../Utils/FormatDateTime';

export async function getPosiceosByVeiculo(idVeiculo, idMotorista, dtInicio, dtFim) {
    try {
        //?inicio=2021-01-27T00:35:43&fim=2021-01-31T23:00:00
        var i = getFormatDataToDataApi(dtInicio);
        var f = getFormatDataToDataApi(dtFim);

        var url = `/api/Posicoes/PorVeiculo/${idVeiculo}?&inicio=${i}&fim=${f}`;

        if (idMotorista != null) {
            url = `${url}&motoristaId=${idMotorista}`;
        }

        const response = await instance.get(url);
        
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getPosiceosByVeiculoPost(idVeiculo, idMotorista, dtInicio, dtFim, filtroApi) {
    try {
        var i = getFormatDataToDataApi(dtInicio);
        var f = getFormatDataToDataApi(dtFim);

        const response = await instance.post(`/api/Posicoes/PorVeiculo/${idVeiculo}?motoristaId=${idMotorista}&inicio=${i}&fim=${f}`, filtroApi);
        
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getResumoPosicoes(data){
    try {

        var it = new Date(data);
        var i = it.getFullYear()+'-'+ ('00'+(it.getMonth()+1)).slice(-2) + '-'+
            ('00'+it.getDate()).slice(-2);
        
        const response = await instance.get(`/api/Posicoes/resumo?data=${i}`);
        
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}