import { GridTable } from "../../Components/GridTable/GridTable";
import { createPacote, getByIdCompleto, updatePacote } from "../../API/PacoteEvidencias";
import Form from "../../Components/Form/Form";
import ListDoc from '../Rota/Components/ListDoc';
import { SituacaoPacoteEvidencias } from "../../Enums/SituacaoPacoteEvidencias";
import { getUser } from "../../Router/auth";
import { getFormatDataToDataApi } from "../../Utils/FormatDateTime";
import { camposForm, columnsGrid } from "./Config";
import { Box, Container, Typography } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { useCallback, useEffect, useState } from "react";
import { createByDocumento, deleteById, getByPacoteEvidenciasId } from "../../API/PacoteEvidenciaDocumento";
import { PacoteEvidenciasRouteEdit } from "../../Router/Routing";

function FormPacoteEvidenciaDocumento(props) {
    const [columns, setColumns] = useState([]);
    const [values, setValues] = useState({});
    const [rows, setRows] = useState([]);
    const [docs, setDocs] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);

    const loadRow = useCallback(async (loading = true) => {
        if (loading) {
            setRows([]);
            setLoadingRows(true);
        }

        getByPacoteEvidenciasId(props.match.params.id)
            .then(resp => {
                setRows(resp);
                setLoadingRows(false);
            });
    }, [props.match.params.id]);

    useEffect(() => {
        setColumns(columnsGrid);

        if (props.match.params.id) {
            getByIdCompleto(props.match.params.id)
                .then(resp => {
                    setValues(resp);
                });
        }

        loadRow();
    }, [props.match.params.id, loadRow]);

    const handleCreateUpdatePacote = async (values) => {
        if (!props.match.params.id) {
            var result = await createPacote({
                empresaId: getUser().empresaId,
                descricao: values.descricao,
                inicio: getFormatDataToDataApi(Date.now()),
                fim: getFormatDataToDataApi(Date.now()),
                situacao: SituacaoPacoteEvidencias.AguardandoConclusaoDasEntregas
            });

            props.history.replace(`${PacoteEvidenciasRouteEdit}/${result.id}`);
        } else {
            await updatePacote(props.match.params.id, {
                id: props.match.params.id,
                empresaId: getUser().empresaId,
                descricao: values.descricao
            });
        }
    }

    const handleDropzoneArea = async (files) => {
        files.forEach((doc) => {
            if (!doc.situacao) {
                doc.situacao = 'Aguardando';
            }
        });

        setDocs(files);

        for (let i = 0; i < files.length; i++) {
            let doc = files[i];
            doc.index = i;

            if (doc.situacao === "Aguardando") {
                await processFile(doc);
            }
        }
    }

    const processFile = async (doc) => {
        const reader = new FileReader();
        reader.doc = doc;
        reader.onloadend = handleFileRead;
        reader.onerror = (error) => console.error('Erro ao ler o arquivo:', error);
        reader.readAsText(doc);
    };

    const handleFileRead = async (e) => {
        const { doc } = e.target;

        if (doc.situacao === "Aguardando") {
            if (doc.type === "text/xml") {
                createByDocumento(props.match.params.id, e.target.result)
                    .then(resp => {
                        setDocs((prevState) => {
                            const newList = [...prevState];

                            doc.situacao = "Importado";
                            doc.observacao = resp.message;

                            newList[doc.index] = doc;

                            return newList;
                        });
                    }).catch(error => {
                        setDocs((prevState) => {
                            const newList = [...prevState];

                            doc.situacao = "Erro";
                            //doc.observacao = resp.message;

                            newList[doc.index] = doc;

                            return newList;
                        });
                    }).finally(e => {
                        loadRow();
                    });
            } else {
                setDocs((prevState) => {
                    const newList = [...prevState];

                    doc.situacao = "Erro";
                    doc.observacao = "Arquivo inválido";

                    newList[doc.index] = doc;

                    return newList;
                });
            }
        }
    }

    const handleExcluir = async (ids) => {
        for (let index = 0; index < ids.length; index++) {
            try {
                const id = ids[index];
                await deleteById(id);
            } catch (e) { }
        }
        loadRow();
    }

    return (
        <Container
            maxWidth={true}
            sx={{
                width: "100%"
            }}
        >
            <Form
                title="Criação de pacotes de evidência"
                form="formPacoteEvidenciaDocumento"
                onSubmit={handleCreateUpdatePacote}
                fields={camposForm}
                initialValues={values}
            />

            {props.match.params.id ? (
                <Box
                    sx={{ padding: "0px 24px" }}
                >
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{
                            paddingLeft: '10px',
                            paddingTop: '10px'
                        }}>
                        <b>1. Selecione os arquivos abaixo (XML) e aguarde a confirmação.</b>
                    </Typography>

                    <Box>
                        <DropzoneArea
                            filesLimit={50}
                            dropzoneText="Arraste e solte os arquivos ou clique aqui"
                            maxFileSize={999999}
                            showPreviewsInDropzone={false}
                            showAlerts={false}
                            onChange={handleDropzoneArea}
                        />

                        {docs.length > 0 ? (
                            <ListDoc list={docs} />
                        ) : <></>}
                    </Box>

                    <GridTable
                        title="Arquivos importados"
                        rows={rows ?? []}
                        columns={columns}
                        loading={loadingRows}
                        CanRemove={true}
                        excluirClick={handleExcluir}
                    />
                </Box>
            ) : <></>}
        </Container>
    );
}

export default FormPacoteEvidenciaDocumento;