import StatusRoute from '../../Components/StatusRoute';
import StatusRoteirizacao from '../../Components/StatusRoteirizacao/StatusRoteirizacao';
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Map';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { RotasEditRoute } from '../../Router/Routing';

export const columnsGrid = [
	{
		label: 'Rota',
		field: 'descricao',
		width: '300px',
	},
	{ label: 'Situação', field: 'status', type: 'custom', width: '100px', function: StatusRoute, align: "center" },
	{ label: 'Roteirização', field: 'statusRoteirizacao', type: 'customWithRow', padding: 'default', function: StatusRoteirizacao, width: '100px', align: "center", },
	{
		label: 'Paradas',
		field: 'paradasMonitormaneto',
		type: 'customWithRow',
		padding: 'default',
		width: '300px'
	},
	{ label: 'Ações', field: 'acoes', type: 'custom', padding: 'default', function: AcessoNoMapa, width: '100px', align: "center" },
];

function AcessoNoMapa(field) {
	const history = useHistory();

	return <Tooltip title={title("Visualizar no mapa")}><IconButton aria-label="delete" size="small" >
		<MapIcon onClick={() => { history.push(RotasEditRoute + field + "?tab=mapa"); }} />
	</IconButton></Tooltip>
}

function title(text) {
	return <div >
		<Typography variant="subtitle1"><b>{text}</b></Typography>
	</div>
}

export const TipoParadaMonitoramento = {
	"Inicio": 0,
	"Interno": 1,
	"Mecanica": 2,
	"Entrega": 3,
	"Fim": 4,
	"ParadaNaoPlanejada": 5,
	"LocalPlanejado": 6,
	"Visita": 7,
	"Recolha": 8,
	"SemSinalGPS": 9
}

export const SituacaoParadaMonitoramento = {
	"Aguardando": 0,
	"Realizado": 1,
	"NaoPlanejado": 2,
	"EmAndamento": 3
}