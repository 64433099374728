import { Component } from 'react';
import { MapContainer, TileLayer, Marker, Popup, LayerGroup, Circle, Polyline, GeoJSON } from 'react-leaflet'
import { getFormatSegundosToString, getFormatDataTimeToString, getFormatMinutosToStringExt } from '../Utils/FormatDateTime'
import paradaNaoIdentificadaIcon from '../img/icon/paradaNaoIdentificada_v1.svg';
import entregaIcon from '../img/icon/entrega_v2.svg';
import notaFiscalIcon from '../img/icon/notaFiscal.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as alert } from '../img/icon/alert_1.svg';
import { Icon } from "leaflet";
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import InternoIcon from '@material-ui/icons/HomeWork';
import TruckIcon from '@material-ui/icons/LocalShipping';
import { withRouter } from "react-router-dom";
import { DocumentosEditRoute, EntregasEditRoute2 } from '../Router/Routing';
import { ReactComponent as entregaImagem } from '../img/icon/localPlanejado.svg';
import { ReactComponent as localPlanejado_3 } from '../img/icon/localPlanejado_4.svg';
import MecanicaIcon from '@material-ui/icons/Build';
import { ReactComponent as noSignalIcon } from "../img/icon/noSignalIcon.svg";
import MarkerEntrega from '../Components/Map/MarkerEntrega'
import MarkerLocalPlanejado from '../Components/Map/MarkerLocalPlanejado'
import MarkerRecolha from '../Components/Map/MarkerRecolha';
import { Box, CircularProgress } from '@mui/material';

const Leaflet = window.L;

const pos1 = [-25.473124661927425, -52.2436084396398];
const pos2 = [-28.19484974830116, -48.80280980152817];

export class MapContainerLeft extends Component {
    constructor(props) {
        super(props);
        this.state = { bounds: this.props.bounds ? Leaflet.latLngBounds(this.props.bounds) : Leaflet.latLngBounds([pos1, pos2]), position: null };
    }

    componentDidMount() {
        if (this.props.localInicio && this.props.localFim) {
            this.setState({
                position: [this.props.localInicio.latitude, this.props.localInicio.longitude],
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.localInicio !== this.props.localInicio || prevProps.localFim !== this.props.localFim) {
            if (this.props.localInicio && this.props.localFim) {
                this.setState({
                    position: [this.props.localInicio.latitude, this.props.localInicio.longitude],
                });
            }
        }
    }

    render() {
        const tipoParada = (tipo) => {
            switch (tipo) {
                case 0:
                    return 'Não identificado'
                case 1:
                    return 'Entrega'
                case 2:
                    return 'Interno'
                case 3:
                    return 'Local planejado'
                case 4:
                    return 'Posto /Oficina'
                case 8:
                    return 'Sem sinal de GPS'
                default:
                    return "";
            }
        }

        const paradaHtml = (latitudeCenter, longitudeCenter, totalMinutosParado, tipo, dataInicio, dataFim) => {
            var i = paradaNaoIdentificada;

            if (tipo === 0)
                i = paradaNaoIdentificadaIco;
            if (tipo === 1)
                i = entregaIco;
            if (tipo === 2)
                i = lojaTeste;
            if (tipo === 3)
                i = paradaLocalPlanejado;
            if (tipo === 4)
                i = oficinaIco;
            if (tipo === 8)
                i = falhaGpsIco;

            return <Marker icon={i} position={[latitudeCenter, longitudeCenter]} pathOptions={fillOrangeOptions} radius={radius} >
                <Popup>
                    <Typography variant="subtitle1"><b>Tempo parado: {getFormatMinutosToStringExt(totalMinutosParado)}</b> </Typography>
                    <Typography variant="body2"><b>Tipo:</b> {tipoParada(tipo)}<br />
                        <b>Data inicio:</b> {getFormatDataTimeToString(dataInicio)} <br />
                        <b>Data fim:</b> {getFormatDataTimeToString(dataFim)}
                    </Typography>
                </Popup>
            </Marker>;
        }

        const fillGreenOptions = { fillColor: '', color: '#545454' };
        const fillBlueGreenOptions = { fillColor: 'blue', color: 'green' };
        const fillRedOptions = { fillColor: 'red', color: 'red' };
        const fillBlackOptions = { fillColor: 'orange', color: 'black' };
        const fillOrangeOptions = { fillColor: 'orange', color: 'orange' };

        const paradaNaoIdentificada = new Icon({ iconUrl: paradaNaoIdentificadaIcon, iconSize: [this.props.tamanhoIcon, this.props.tamanhoIcon] });
        const entregaPin = new Icon({ iconUrl: entregaIcon, iconSize: [this.props.tamanhoIcon, this.props.tamanhoIcon] });
        const notaFiscalIconPin = new Icon({ iconUrl: notaFiscalIcon, iconSize: [this.props.tamanhoIcon, this.props.tamanhoIcon] });

        const radius = 15;
        const lojaTeste = L.divIcon({
            className: 'custom-icon',
            html: ReactDOMServer.renderToString(<Avatar variant="rounded" style={{
                marginLeft: '-10px',
                marginTop: '-10px',
                height: (this.props.tamanhoIcon + 5) + 'px',
                width: (this.props.tamanhoIcon + 5) + 'px',
                color: 'rgb(0 156 7)', backgroundColor: 'rgb(237 247 237 / 51%)', border: 'solid', borderWidth: '2px'
            }}>
                <InternoIcon style={{ width: (this.props.tamanhoIcon - 5) + 'px', height: (this.props.tamanhoIcon - 5) + 'px' }} /></Avatar>)
        });

        const caminhaoIcon = L.divIcon({
            html: ReactDOMServer.renderToString(<Avatar variant="circular" style={{ marginLeft: '-17px', marginTop: '-17px', color: 'black', backgroundColor: 'rgb(237 247 237 / 51%)', border: 'solid', borderWidth: '4px' }}>
                <TruckIcon /></Avatar>)
        });

        const entregaIco = L.divIcon({
            html: ReactDOMServer.renderToString(<Avatar variant="rounded" style={{
                marginLeft: '-10px',
                marginTop: '-10px',
                backgroundColor: 'rgb(0 156 7 / 90%)',
                height: this.props.tamanhoIcon + 'px',
                width: this.props.tamanhoIcon + 'px',
            }}>
                <SvgIcon component={entregaImagem} style={{ width: (this.props.tamanhoIcon - 5) + 'px', height: (this.props.tamanhoIcon - 5) + 'px' }} /></Avatar>)
        });

        const paradaNaoIdentificadaIco = L.divIcon({
            html: ReactDOMServer.renderToString(<Avatar variant="rounded" style={{
                marginLeft: '-10px',
                marginTop: '-10px',
                backgroundColor: 'rgb(232 30 15 / 79%)',
                height: this.props.tamanhoIcon + 'px',
                width: this.props.tamanhoIcon + 'px'
            }}>
                <SvgIcon component={alert} style={{ width: (this.props.tamanhoIcon - 5) + 'px', height: (this.props.tamanhoIcon - 5) + 'px' }} /></Avatar>)
        });

        const paradaLocalPlanejado = L.divIcon({
            html: ReactDOMServer.renderToString(<Avatar variant="rounded" style={{
                marginLeft: '-10px',
                marginTop: '-10px',
                backgroundColor: 'rgb(0 156 7 / 90%)',
                height: this.props.tamanhoIcon + 'px',
                width: this.props.tamanhoIcon + 'px'
            }}>
                <SvgIcon component={localPlanejado_3} style={{ width: (this.props.tamanhoIcon - 5) + 'px', height: (this.props.tamanhoIcon - 5) + 'px' }} /></Avatar>)
        });

        const oficinaIco = L.divIcon({
            html: ReactDOMServer.renderToString(<Avatar variant="rounded" style={{
                marginLeft: '-10px',
                marginTop: '-10px',
                backgroundColor: 'rgb(0 156 7 / 90%)',
                height: this.props.tamanhoIcon + 'px',
                width: this.props.tamanhoIcon + 'px'
            }}>
                <MecanicaIcon style={{ width: (this.props.tamanhoIcon - 5) + 'px', height: (this.props.tamanhoIcon - 5) + 'px' }} /></Avatar>)
        });

        const falhaGpsIco = L.divIcon({
            html: ReactDOMServer.renderToString(
                <Avatar variant="rounded" style={{
                    marginLeft: '-10px',
                    marginTop: '-10px',
                    backgroundColor: 'rgb(232 30 15 / 79%)',
                    height: this.props.tamanhoIcon + 'px',
                    width: this.props.tamanhoIcon + 'px'
                }}>
                    <SvgIcon
                        component={noSignalIcon}
                        style={{ width: (this.props.tamanhoIcon - 5) + 'px', height: (this.props.tamanhoIcon - 5) + 'px' }}
                    ></SvgIcon>
                </Avatar>)
        });

        return (
            <>
                {this.state.position ? (
                    <MapContainer
                        key={this.props.keyMAP}
                        zoom={10}
                        scrollWheelZoom={true}
                        center={this.state.position}
                        style={{ height: "75vh", width: "100%" }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {this.props.withRotaPlanejada && this.props.trajetoRota && this.props.trajetoRota !== null ? (
                            <GeoJSON
                                id="tes"
                                data={this.props.trajetoRota}
                                style={{
                                    color: 'OrangeRed',
                                    fillColor: 'red',
                                    weight: 17.0,
                                    opacity: 0.5
                                }}
                            >
                                {this.props.withRotas && this.props.trajetoRotaExecutada && this.props.trajetoRotaExecutada !== null ?
                                    <GeoJSON
                                        id="trajetoRotaExecutada"
                                        data={this.props.trajetoRotaExecutada}
                                        style={{
                                            fillColor: 'black',
                                            color: 'black',
                                            weight: 5.0,
                                            opacity: 0.7
                                        }}
                                    ></GeoJSON> : <></>
                                }
                            </GeoJSON>) : (
                            <>
                                {this.props.withRotas && this.props.trajetoRotaExecutada && this.props.trajetoRotaExecutada !== null ?
                                    <GeoJSON
                                        id="tes2"
                                        data={this.props.trajetoRotaExecutada}
                                        style={{
                                            fillColor: 'black',
                                            color: 'black',
                                            weight: 5.0,
                                            opacity: 0.7
                                        }}
                                    ></GeoJSON> : <></>
                                }
                            </>)}

                        <GeoJSON
                            id="rodizioSP"
                            style={{ opacity: 100, weight: 0, color: 'black' }}
                            onEachFeature={(feature, layer) => {
                                if (feature.properties && feature.properties.nome) {
                                    layer.bindPopup(feature.properties.nome);
                                }
                            }}
                            data={{
                                "type": "FeatureCollection",
                                "name": "SaoPaulo_SP",
                                "crs": {
                                    "type": "name",
                                    "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" }
                                },
                                "features": [
                                    {
                                        "type": "Feature",
                                        "properties": {
                                            "id": 3,
                                            "sigla": "MIAN",
                                            "nome": "Rodizio de São Paulo",
                                            "stroke": "#ff0000",
                                            "stroke-width": 2,
                                            "stroke-opacity": 1,
                                            "fill": "#c5aaaa",
                                            "fill-opacity": 0.2
                                        },
                                        "geometry": {
                                            "type": "Polygon",
                                            "coordinates": [
                                                [
                                                    [-46.587706, -23.52815],
                                                    [-46.587181, -23.52809],
                                                    [-46.584315, -23.527411],
                                                    [-46.58262, -23.528377],
                                                    [-46.582985, -23.528698],
                                                    [-46.583108, -23.528824],
                                                    [-46.583206, -23.528993],
                                                    [-46.583443, -23.52955],
                                                    [-46.583613, -23.530048],
                                                    [-46.583685, -23.530297],
                                                    [-46.583712, -23.530428],
                                                    [-46.583717, -23.530556],
                                                    [-46.583707, -23.530726],
                                                    [-46.583688, -23.530908],
                                                    [-46.583632, -23.531148],
                                                    [-46.58357, -23.531342],
                                                    [-46.583605, -23.531479],
                                                    [-46.583556, -23.531687],
                                                    [-46.583471, -23.532024],
                                                    [-46.583398, -23.532333],
                                                    [-46.583281, -23.532807],
                                                    [-46.583212, -23.533095],
                                                    [-46.583177, -23.533238],
                                                    [-46.583127, -23.53344],
                                                    [-46.583095, -23.533569],
                                                    [-46.583045, -23.533773],
                                                    [-46.582993, -23.533971],
                                                    [-46.58297, -23.534072],
                                                    [-46.582912, -23.534299],
                                                    [-46.582862, -23.534502],
                                                    [-46.582805, -23.534731],
                                                    [-46.582781, -23.534833],
                                                    [-46.582717, -23.535078],
                                                    [-46.582538, -23.535777],
                                                    [-46.582404, -23.536316],
                                                    [-46.582186, -23.53719],
                                                    [-46.582036, -23.537779],
                                                    [-46.582006, -23.537897],
                                                    [-46.581974, -23.537998],
                                                    [-46.581916, -23.538113],
                                                    [-46.581816, -23.538264],
                                                    [-46.581696, -23.538428],
                                                    [-46.581504, -23.538683],
                                                    [-46.581376, -23.538866],
                                                    [-46.581244, -23.539077],
                                                    [-46.581049, -23.539448],
                                                    [-46.580971, -23.539635],
                                                    [-46.580835, -23.540117],
                                                    [-46.580636, -23.540896],
                                                    [-46.580512, -23.541375],
                                                    [-46.580322, -23.542131],
                                                    [-46.580141, -23.542637],
                                                    [-46.579795, -23.544156],
                                                    [-46.579707, -23.544543],
                                                    [-46.579594, -23.545095],
                                                    [-46.579224, -23.546723],
                                                    [-46.579092, -23.547285],
                                                    [-46.578857, -23.548296],
                                                    [-46.578785, -23.548633],
                                                    [-46.578668, -23.54899],
                                                    [-46.578587, -23.549234],
                                                    [-46.578444, -23.549584],
                                                    [-46.57834, -23.549822],
                                                    [-46.578181, -23.550129],
                                                    [-46.577911, -23.550607],
                                                    [-46.5776, -23.551153],
                                                    [-46.577501, -23.551354],
                                                    [-46.577301, -23.551638],
                                                    [-46.577106, -23.551832],
                                                    [-46.576886, -23.552004],
                                                    [-46.576597, -23.552441],
                                                    [-46.576458, -23.552857],
                                                    [-46.576342, -23.553156],
                                                    [-46.576238, -23.553494],
                                                    [-46.576249, -23.553706],
                                                    [-46.576185, -23.554085],
                                                    [-46.576167, -23.554459],
                                                    [-46.57621, -23.554643],
                                                    [-46.576326, -23.554915],
                                                    [-46.576536, -23.555187],
                                                    [-46.576841, -23.555442],
                                                    [-46.576905, -23.55558],
                                                    [-46.576974, -23.55588],
                                                    [-46.577187, -23.556718],
                                                    [-46.577362, -23.557335],
                                                    [-46.577395, -23.557658],
                                                    [-46.577242, -23.558085],
                                                    [-46.57712, -23.558312],
                                                    [-46.576973, -23.558649],
                                                    [-46.576888, -23.558967],
                                                    [-46.576839, -23.559123],
                                                    [-46.576747, -23.559236],
                                                    [-46.576764, -23.559428],
                                                    [-46.576761, -23.559616],
                                                    [-46.576712, -23.559841],
                                                    [-46.576656, -23.559964],
                                                    [-46.576529, -23.560231],
                                                    [-46.576353, -23.560501],
                                                    [-46.576184, -23.560923],
                                                    [-46.576001, -23.561576],
                                                    [-46.575913, -23.561787],
                                                    [-46.575849, -23.561909],
                                                    [-46.575911, -23.562091],
                                                    [-46.575867, -23.562467],
                                                    [-46.575812, -23.562854],
                                                    [-46.575739, -23.563247],
                                                    [-46.575717, -23.563379],
                                                    [-46.575623, -23.563551],
                                                    [-46.575564, -23.564132],
                                                    [-46.575554, -23.564514],
                                                    [-46.5754, -23.565016],
                                                    [-46.575218, -23.5654],
                                                    [-46.57511, -23.565622],
                                                    [-46.574914, -23.565937],
                                                    [-46.574688, -23.566305],
                                                    [-46.57415, -23.567108],
                                                    [-46.573987, -23.567334],
                                                    [-46.573827, -23.567575],
                                                    [-46.573728, -23.567754],
                                                    [-46.573626, -23.567941],
                                                    [-46.573519, -23.568178],
                                                    [-46.573475, -23.568333],
                                                    [-46.573427, -23.568503],
                                                    [-46.57337, -23.568674],
                                                    [-46.573353, -23.568862],
                                                    [-46.573357, -23.569159],
                                                    [-46.573356, -23.569349],
                                                    [-46.573364, -23.569404],
                                                    [-46.573411, -23.569728],
                                                    [-46.573469, -23.570023],
                                                    [-46.573505, -23.570244],
                                                    [-46.573519, -23.570375],
                                                    [-46.573499, -23.570516],
                                                    [-46.573455, -23.570673],
                                                    [-46.573431, -23.570808],
                                                    [-46.573359, -23.57094],
                                                    [-46.573248, -23.571144],
                                                    [-46.573103, -23.571331],
                                                    [-46.572872, -23.571543],
                                                    [-46.572422, -23.571887],
                                                    [-46.572015, -23.572221],
                                                    [-46.571758, -23.572444],
                                                    [-46.571584, -23.572601],
                                                    [-46.571327, -23.572827],
                                                    [-46.571197, -23.572973],
                                                    [-46.571079, -23.573117],
                                                    [-46.571013, -23.573197],
                                                    [-46.570964, -23.573279],
                                                    [-46.570891, -23.573423],
                                                    [-46.570832, -23.573565],
                                                    [-46.570787, -23.573678],
                                                    [-46.570743, -23.573823],
                                                    [-46.570711, -23.573951],
                                                    [-46.570693, -23.574069],
                                                    [-46.570686, -23.574152],
                                                    [-46.570685, -23.574307],
                                                    [-46.570682, -23.574377],
                                                    [-46.570681, -23.574465],
                                                    [-46.57071, -23.575136],
                                                    [-46.570752, -23.575373],
                                                    [-46.570982, -23.576289],
                                                    [-46.570969, -23.576823],
                                                    [-46.570968, -23.577114],
                                                    [-46.571112, -23.577654],
                                                    [-46.571274, -23.578054],
                                                    [-46.571356, -23.578379],
                                                    [-46.571476, -23.578641],
                                                    [-46.571615, -23.578879],
                                                    [-46.57171, -23.579011],
                                                    [-46.571823, -23.579206],
                                                    [-46.5719, -23.57931],
                                                    [-46.572032, -23.579419],
                                                    [-46.572052, -23.579539],
                                                    [-46.57208, -23.579761],
                                                    [-46.572024, -23.580712],
                                                    [-46.571994, -23.581097],
                                                    [-46.571963, -23.581477],
                                                    [-46.571971, -23.581858],
                                                    [-46.571949, -23.581992],
                                                    [-46.571918, -23.582107],
                                                    [-46.571794, -23.582315],
                                                    [-46.571678, -23.58244],
                                                    [-46.571545, -23.582557],
                                                    [-46.571328, -23.582621],
                                                    [-46.571246, -23.582651],
                                                    [-46.570897, -23.582682],
                                                    [-46.570618, -23.582706],
                                                    [-46.57013, -23.582795],
                                                    [-46.570138, -23.582985],
                                                    [-46.570481, -23.582973],
                                                    [-46.571339, -23.582971],
                                                    [-46.572874, -23.582982],
                                                    [-46.573546, -23.583033],
                                                    [-46.574181, -23.583174],
                                                    [-46.574864, -23.583492],
                                                    [-46.575445, -23.583866],
                                                    [-46.575868, -23.584152],
                                                    [-46.576566, -23.584269],
                                                    [-46.576918, -23.584257],
                                                    [-46.577229, -23.584343],
                                                    [-46.578166, -23.584481],
                                                    [-46.5797, -23.584716],
                                                    [-46.580667, -23.584857],
                                                    [-46.582381, -23.585126],
                                                    [-46.582691, -23.585151],
                                                    [-46.582892, -23.585135],
                                                    [-46.58313, -23.585096],
                                                    [-46.583331, -23.585042],
                                                    [-46.58363, -23.584906],
                                                    [-46.584014, -23.584711],
                                                    [-46.584753, -23.584362],
                                                    [-46.584953, -23.584289],
                                                    [-46.585085, -23.584245],
                                                    [-46.585273, -23.584238],
                                                    [-46.585483, -23.584235],
                                                    [-46.585706, -23.58426],
                                                    [-46.585895, -23.584305],
                                                    [-46.586068, -23.584372],
                                                    [-46.586215, -23.584444],
                                                    [-46.586384, -23.584543],
                                                    [-46.586616, -23.584668],
                                                    [-46.58705, -23.584899],
                                                    [-46.58732, -23.585053],
                                                    [-46.587814, -23.585326],
                                                    [-46.588444, -23.585675],
                                                    [-46.588851, -23.585887],
                                                    [-46.589233, -23.586106],
                                                    [-46.589968, -23.586527],
                                                    [-46.590294, -23.586795],
                                                    [-46.590755, -23.587193],
                                                    [-46.590937, -23.58735],
                                                    [-46.591155, -23.587508],
                                                    [-46.591429, -23.587598],
                                                    [-46.591752, -23.587621],
                                                    [-46.59221, -23.58769],
                                                    [-46.592569, -23.587745],
                                                    [-46.592974, -23.587822],
                                                    [-46.59333, -23.587912],
                                                    [-46.594512, -23.58823],
                                                    [-46.594856, -23.588262],
                                                    [-46.594976, -23.588258],
                                                    [-46.59515, -23.588243],
                                                    [-46.595365, -23.5882],
                                                    [-46.595539, -23.588159],
                                                    [-46.596023, -23.587939],
                                                    [-46.596388, -23.587777],
                                                    [-46.596697, -23.587646],
                                                    [-46.596859, -23.587571],
                                                    [-46.597015, -23.587612],
                                                    [-46.597327, -23.587756],
                                                    [-46.597551, -23.587912],
                                                    [-46.597726, -23.588099],
                                                    [-46.597828, -23.588249],
                                                    [-46.597877, -23.588492],
                                                    [-46.597812, -23.588632],
                                                    [-46.597824, -23.588949],
                                                    [-46.597726, -23.589847],
                                                    [-46.597675, -23.590543],
                                                    [-46.597579, -23.591595],
                                                    [-46.597431, -23.592838],
                                                    [-46.597342, -23.593813],
                                                    [-46.59723, -23.595247],
                                                    [-46.597206, -23.59553],
                                                    [-46.597136, -23.596164],
                                                    [-46.597078, -23.596849],
                                                    [-46.597039, -23.59717],
                                                    [-46.596994, -23.597655],
                                                    [-46.596993, -23.597879],
                                                    [-46.597005, -23.598059],
                                                    [-46.597073, -23.598451],
                                                    [-46.59713, -23.598695],
                                                    [-46.597207, -23.598979],
                                                    [-46.597315, -23.599414],
                                                    [-46.597364, -23.599607],
                                                    [-46.597434, -23.599764],
                                                    [-46.597453, -23.599813],
                                                    [-46.597478, -23.59985],
                                                    [-46.59761, -23.600037],
                                                    [-46.597751, -23.600241],
                                                    [-46.598024, -23.600625],
                                                    [-46.598203, -23.600889],
                                                    [-46.598454, -23.601275],
                                                    [-46.598609, -23.601526],
                                                    [-46.598794, -23.601762],
                                                    [-46.599062, -23.60207],
                                                    [-46.599321, -23.602366],
                                                    [-46.599622, -23.6027],
                                                    [-46.599743, -23.602856],
                                                    [-46.599957, -23.603064],
                                                    [-46.600188, -23.603273],
                                                    [-46.600402, -23.603379],
                                                    [-46.600602, -23.603475],
                                                    [-46.600775, -23.603522],
                                                    [-46.600962, -23.603566],
                                                    [-46.601095, -23.603592],
                                                    [-46.601221, -23.60361],
                                                    [-46.601391, -23.603628],
                                                    [-46.601643, -23.603637],
                                                    [-46.601899, -23.603638],
                                                    [-46.602004, -23.603649],
                                                    [-46.602199, -23.603704],
                                                    [-46.602415, -23.60378],
                                                    [-46.602681, -23.603902],
                                                    [-46.602905, -23.604035],
                                                    [-46.603083, -23.60416],
                                                    [-46.603362, -23.604362],
                                                    [-46.6036, -23.604596],
                                                    [-46.603914, -23.604875],
                                                    [-46.604296, -23.605261],
                                                    [-46.604487, -23.605445],
                                                    [-46.604699, -23.605663],
                                                    [-46.604882, -23.605874],
                                                    [-46.605083, -23.606115],
                                                    [-46.605275, -23.606411],
                                                    [-46.605409, -23.606723],
                                                    [-46.605521, -23.606984],
                                                    [-46.605688, -23.607452],
                                                    [-46.60584, -23.607868],
                                                    [-46.606553, -23.60971],
                                                    [-46.606666, -23.610077],
                                                    [-46.606852, -23.610511],
                                                    [-46.60705, -23.611026],
                                                    [-46.607178, -23.611383],
                                                    [-46.607397, -23.611983],
                                                    [-46.607506, -23.612257],
                                                    [-46.608286, -23.614325],
                                                    [-46.608648, -23.615277],
                                                    [-46.608859, -23.61572],
                                                    [-46.609082, -23.61617],
                                                    [-46.609263, -23.616547],
                                                    [-46.609651, -23.617362],
                                                    [-46.609938, -23.617957],
                                                    [-46.61024, -23.618611],
                                                    [-46.610391, -23.618919],
                                                    [-46.61065, -23.619496],
                                                    [-46.610985, -23.620192],
                                                    [-46.611072, -23.620388],
                                                    [-46.611352, -23.620973],
                                                    [-46.61148, -23.621211],
                                                    [-46.611771, -23.621833],
                                                    [-46.612004, -23.622333],
                                                    [-46.612132, -23.622544],
                                                    [-46.612456, -23.622974],
                                                    [-46.612806, -23.623457],
                                                    [-46.613385, -23.624081],
                                                    [-46.613859, -23.624529],
                                                    [-46.614552, -23.62511],
                                                    [-46.616009, -23.626181],
                                                    [-46.616487, -23.626469],
                                                    [-46.616967, -23.626716],
                                                    [-46.6175, -23.626951],
                                                    [-46.618249, -23.627197],
                                                    [-46.619133, -23.627474],
                                                    [-46.619672, -23.6276],
                                                    [-46.620269, -23.627725],
                                                    [-46.62154, -23.627958],
                                                    [-46.623264, -23.628336],
                                                    [-46.623979, -23.628415],
                                                    [-46.625139, -23.628536],
                                                    [-46.625281, -23.62857],
                                                    [-46.626252, -23.628879],
                                                    [-46.626897, -23.629009],
                                                    [-46.627402, -23.629143],
                                                    [-46.627876, -23.629275],
                                                    [-46.628978, -23.629151],
                                                    [-46.629827, -23.629274],
                                                    [-46.63076, -23.629418],
                                                    [-46.631391, -23.629513],
                                                    [-46.633013, -23.629727],
                                                    [-46.633499, -23.629786],
                                                    [-46.633765, -23.629793],
                                                    [-46.634442, -23.629834],
                                                    [-46.635062, -23.629817],
                                                    [-46.635649, -23.629762],
                                                    [-46.637101, -23.629607],
                                                    [-46.638305, -23.629501],
                                                    [-46.640094, -23.629317],
                                                    [-46.640419, -23.629302],
                                                    [-46.640919, -23.629286],
                                                    [-46.641553, -23.629361],
                                                    [-46.641869, -23.629436],
                                                    [-46.642169, -23.629554],
                                                    [-46.642455, -23.629675],
                                                    [-46.642768, -23.629853],
                                                    [-46.643149, -23.630128],
                                                    [-46.643493, -23.630418],
                                                    [-46.643804, -23.630601],
                                                    [-46.644031, -23.630738],
                                                    [-46.64426, -23.630816],
                                                    [-46.64449, -23.630859],
                                                    [-46.6448, -23.630873],
                                                    [-46.645016, -23.630853],
                                                    [-46.645236, -23.630803],
                                                    [-46.645687, -23.630643],
                                                    [-46.64613, -23.630496],
                                                    [-46.646356, -23.630427],
                                                    [-46.64656, -23.630352],
                                                    [-46.646782, -23.630278],
                                                    [-46.646944, -23.630211],
                                                    [-46.647035, -23.63017],
                                                    [-46.647175, -23.630095],
                                                    [-46.647345, -23.629966],
                                                    [-46.647486, -23.629817],
                                                    [-46.647605, -23.629576],
                                                    [-46.647719, -23.629347],
                                                    [-46.647842, -23.629067],
                                                    [-46.647944, -23.628802],
                                                    [-46.648142, -23.628358],
                                                    [-46.648331, -23.627865],
                                                    [-46.648459, -23.627531],
                                                    [-46.648593, -23.627197],
                                                    [-46.648695, -23.626966],
                                                    [-46.648791, -23.626724],
                                                    [-46.649041, -23.626166],
                                                    [-46.649205, -23.625764],
                                                    [-46.649293, -23.625577],
                                                    [-46.649373, -23.625432],
                                                    [-46.649451, -23.625299],
                                                    [-46.649577, -23.625087],
                                                    [-46.649692, -23.624926],
                                                    [-46.649793, -23.624781],
                                                    [-46.649999, -23.624541],
                                                    [-46.650141, -23.62438],
                                                    [-46.650282, -23.624247],
                                                    [-46.650493, -23.624028],
                                                    [-46.650726, -23.623817],
                                                    [-46.650958, -23.623633],
                                                    [-46.651206, -23.623447],
                                                    [-46.651387, -23.623321],
                                                    [-46.651658, -23.62314],
                                                    [-46.652223, -23.622779],
                                                    [-46.654872, -23.621051],
                                                    [-46.655292, -23.620778],
                                                    [-46.656372, -23.620074],
                                                    [-46.656772, -23.619842],
                                                    [-46.659103, -23.618731],
                                                    [-46.659737, -23.618426],
                                                    [-46.660617, -23.618007],
                                                    [-46.661743, -23.61747],
                                                    [-46.662494, -23.617121],
                                                    [-46.663603, -23.616568],
                                                    [-46.663996, -23.616412],
                                                    [-46.66453, -23.616261],
                                                    [-46.665877, -23.615976],
                                                    [-46.666446, -23.615842],
                                                    [-46.666956, -23.615701],
                                                    [-46.667378, -23.615515],
                                                    [-46.667832, -23.615259],
                                                    [-46.668642, -23.614632],
                                                    [-46.669086, -23.614283],
                                                    [-46.669864, -23.613647],
                                                    [-46.670329, -23.613347],
                                                    [-46.671807, -23.612618],
                                                    [-46.672754, -23.61219],
                                                    [-46.673705, -23.611739],
                                                    [-46.674499, -23.611166],
                                                    [-46.674738, -23.61097],
                                                    [-46.674855, -23.61083],
                                                    [-46.675254, -23.610297],
                                                    [-46.675654, -23.60974],
                                                    [-46.67595, -23.609383],
                                                    [-46.676204, -23.609222],
                                                    [-46.676413, -23.609146],
                                                    [-46.676966, -23.608694],
                                                    [-46.677085, -23.608544],
                                                    [-46.677473, -23.608228],
                                                    [-46.677603, -23.608113],
                                                    [-46.677856, -23.607907],
                                                    [-46.678103, -23.607727],
                                                    [-46.678452, -23.607163],
                                                    [-46.679197, -23.605663],
                                                    [-46.679246, -23.605413],
                                                    [-46.679548, -23.604803],
                                                    [-46.679769, -23.604367],
                                                    [-46.679894, -23.604163],
                                                    [-46.679955, -23.604072],
                                                    [-46.680055, -23.603971],
                                                    [-46.680171, -23.603865],
                                                    [-46.680403, -23.603674],
                                                    [-46.680686, -23.603454],
                                                    [-46.681008, -23.603194],
                                                    [-46.681297, -23.602812],
                                                    [-46.682061, -23.602185],
                                                    [-46.682542, -23.601801],
                                                    [-46.682975, -23.601495],
                                                    [-46.683605, -23.600973],
                                                    [-46.684379, -23.600397],
                                                    [-46.685187, -23.599918],
                                                    [-46.686085, -23.599408],
                                                    [-46.687422, -23.598724],
                                                    [-46.687838, -23.598449],
                                                    [-46.688388, -23.598173],
                                                    [-46.689214, -23.597824],
                                                    [-46.690298, -23.597271],
                                                    [-46.691098, -23.596868],
                                                    [-46.691806, -23.596473],
                                                    [-46.691952, -23.596391],
                                                    [-46.692507, -23.595874],
                                                    [-46.693089, -23.595122],
                                                    [-46.693807, -23.594171],
                                                    [-46.694172, -23.593682],
                                                    [-46.694251, -23.593571],
                                                    [-46.694361, -23.593363],
                                                    [-46.69447, -23.593119],
                                                    [-46.694585, -23.592816],
                                                    [-46.694637, -23.592498],
                                                    [-46.694654, -23.592186],
                                                    [-46.694647, -23.591908],
                                                    [-46.69467, -23.591709],
                                                    [-46.694648, -23.59157],
                                                    [-46.694619, -23.591464],
                                                    [-46.694446, -23.591197],
                                                    [-46.694352, -23.591042],
                                                    [-46.694188, -23.59089],
                                                    [-46.693996, -23.590705],
                                                    [-46.69382, -23.590544],
                                                    [-46.693755, -23.590455],
                                                    [-46.693697, -23.59032],
                                                    [-46.693451, -23.589893],
                                                    [-46.693238, -23.589454],
                                                    [-46.692918, -23.588887],
                                                    [-46.692471, -23.58763],
                                                    [-46.692412, -23.587026],
                                                    [-46.692373, -23.586393],
                                                    [-46.69242, -23.586034],
                                                    [-46.692478, -23.585787],
                                                    [-46.692621, -23.585346],
                                                    [-46.692752, -23.584866],
                                                    [-46.693178, -23.584073],
                                                    [-46.69381, -23.582961],
                                                    [-46.694351, -23.582035],
                                                    [-46.694737, -23.581366],
                                                    [-46.695344, -23.580277],
                                                    [-46.696525, -23.578246],
                                                    [-46.696821, -23.577725],
                                                    [-46.697108, -23.577204],
                                                    [-46.697453, -23.576682],
                                                    [-46.697973, -23.576012],
                                                    [-46.699704, -23.574307],
                                                    [-46.700152, -23.573931],
                                                    [-46.700575, -23.573556],
                                                    [-46.701096, -23.573001],
                                                    [-46.70141, -23.57265],
                                                    [-46.701545, -23.572487],
                                                    [-46.701738, -23.572306],
                                                    [-46.702025, -23.572324],
                                                    [-46.702242, -23.572286],
                                                    [-46.70245, -23.572073],
                                                    [-46.702592, -23.571795],
                                                    [-46.703022, -23.570998],
                                                    [-46.703287, -23.57069],
                                                    [-46.703366, -23.570361],
                                                    [-46.703705, -23.569673],
                                                    [-46.703921, -23.569137],
                                                    [-46.704087, -23.568714],
                                                    [-46.704531, -23.567356],
                                                    [-46.704854, -23.566417],
                                                    [-46.705149, -23.565513],
                                                    [-46.705364, -23.564966],
                                                    [-46.705555, -23.564469],
                                                    [-46.705746, -23.564045],
                                                    [-46.706013, -23.563559],
                                                    [-46.706155, -23.563285],
                                                    [-46.706359, -23.563021],
                                                    [-46.706655, -23.562643],
                                                    [-46.707018, -23.562279],
                                                    [-46.707303, -23.561959],
                                                    [-46.707764, -23.561499],
                                                    [-46.708061, -23.561247],
                                                    [-46.708489, -23.560956],
                                                    [-46.708799, -23.560786],
                                                    [-46.708941, -23.560723],
                                                    [-46.709312, -23.560518],
                                                    [-46.709737, -23.560305],
                                                    [-46.710699, -23.559853],
                                                    [-46.710895, -23.559762],
                                                    [-46.711492, -23.559504],
                                                    [-46.712075, -23.559208],
                                                    [-46.712204, -23.55911],
                                                    [-46.712693, -23.558714],
                                                    [-46.713219, -23.55848],
                                                    [-46.719077, -23.555609],
                                                    [-46.726394, -23.55208],
                                                    [-46.729576, -23.550483],
                                                    [-46.732691, -23.548794],
                                                    [-46.734167, -23.547525],
                                                    [-46.735316, -23.546925],
                                                    [-46.735779, -23.546425],
                                                    [-46.735975, -23.546021],
                                                    [-46.736288, -23.545584],
                                                    [-46.736917, -23.544974],
                                                    [-46.738541, -23.543456],
                                                    [-46.739884, -23.542282],
                                                    [-46.740659, -23.541596],
                                                    [-46.744919, -23.537799],
                                                    [-46.745449, -23.537291],
                                                    [-46.747073, -23.53585],
                                                    [-46.74835, -23.53473],
                                                    [-46.748987, -23.534128],
                                                    [-46.749588, -23.533576],
                                                    [-46.75, -23.533007],
                                                    [-46.750376, -23.532322],
                                                    [-46.750498, -23.531996],
                                                    [-46.750602, -23.531616],
                                                    [-46.750754, -23.530486],
                                                    [-46.750882, -23.529387],
                                                    [-46.751065, -23.527993],
                                                    [-46.751029, -23.526973],
                                                    [-46.751037, -23.52647],
                                                    [-46.750943, -23.525714],
                                                    [-46.750835, -23.525143],
                                                    [-46.750556, -23.524357],
                                                    [-46.749917, -23.523356],
                                                    [-46.749637, -23.523135],
                                                    [-46.749442, -23.522936],
                                                    [-46.749221, -23.522722],
                                                    [-46.748962, -23.522439],
                                                    [-46.748661, -23.521925],
                                                    [-46.748626, -23.52177],
                                                    [-46.748385, -23.521286],
                                                    [-46.748142, -23.520717],
                                                    [-46.747955, -23.52022],
                                                    [-46.747678, -23.519688],
                                                    [-46.747281, -23.51912],
                                                    [-46.747012, -23.518775],
                                                    [-46.746693, -23.518416],
                                                    [-46.746328, -23.518079],
                                                    [-46.746091, -23.517881],
                                                    [-46.74579, -23.51768],
                                                    [-46.745308, -23.517376],
                                                    [-46.745156, -23.517285],
                                                    [-46.744783, -23.517121],
                                                    [-46.74437, -23.516955],
                                                    [-46.743764, -23.516765],
                                                    [-46.743231, -23.516678],
                                                    [-46.742779, -23.51662],
                                                    [-46.741974, -23.516617],
                                                    [-46.740981, -23.516659],
                                                    [-46.740612, -23.516673],
                                                    [-46.739992, -23.516665],
                                                    [-46.739761, -23.516849],
                                                    [-46.739408, -23.516898],
                                                    [-46.738855, -23.516898],
                                                    [-46.738224, -23.516874],
                                                    [-46.736874, -23.516847],
                                                    [-46.735225, -23.516881],
                                                    [-46.73454, -23.516902],
                                                    [-46.733744, -23.516936],
                                                    [-46.733385, -23.516924],
                                                    [-46.732846, -23.516878],
                                                    [-46.732631, -23.516834],
                                                    [-46.73212, -23.516708],
                                                    [-46.731602, -23.516519],
                                                    [-46.731193, -23.516323],
                                                    [-46.730933, -23.516167],
                                                    [-46.730285, -23.515801],
                                                    [-46.729409, -23.51532],
                                                    [-46.728459, -23.514772],
                                                    [-46.728231, -23.514641],
                                                    [-46.727954, -23.514486],
                                                    [-46.727457, -23.514296],
                                                    [-46.727257, -23.514217],
                                                    [-46.727044, -23.514103],
                                                    [-46.726965, -23.514029],
                                                    [-46.726786, -23.513971],
                                                    [-46.726606, -23.513851],
                                                    [-46.726484, -23.513783],
                                                    [-46.726218, -23.513635],
                                                    [-46.725954, -23.513503],
                                                    [-46.725566, -23.513283],
                                                    [-46.72513, -23.513013],
                                                    [-46.724756, -23.512728],
                                                    [-46.724641, -23.512595],
                                                    [-46.724365, -23.512316],
                                                    [-46.723909, -23.511946],
                                                    [-46.72362, -23.511758],
                                                    [-46.723275, -23.511465],
                                                    [-46.723055, -23.511237],
                                                    [-46.722874, -23.510984],
                                                    [-46.722602, -23.51056],
                                                    [-46.722241, -23.510022],
                                                    [-46.721908, -23.509543],
                                                    [-46.72181, -23.509403],
                                                    [-46.721661, -23.509209],
                                                    [-46.721354, -23.508855],
                                                    [-46.721123, -23.508666],
                                                    [-46.720987, -23.508473],
                                                    [-46.720598, -23.508224],
                                                    [-46.720198, -23.507977],
                                                    [-46.719712, -23.507768],
                                                    [-46.719189, -23.507557],
                                                    [-46.718537, -23.507387],
                                                    [-46.718096, -23.507301],
                                                    [-46.717323, -23.507197],
                                                    [-46.716952, -23.507172],
                                                    [-46.716189, -23.507179],
                                                    [-46.715462, -23.50724],
                                                    [-46.714959, -23.507306],
                                                    [-46.714777, -23.507321],
                                                    [-46.714176, -23.507343],
                                                    [-46.713909, -23.507342],
                                                    [-46.71312, -23.507376],
                                                    [-46.712815, -23.507444],
                                                    [-46.712751, -23.507447],
                                                    [-46.711979, -23.507503],
                                                    [-46.711436, -23.507579],
                                                    [-46.711133, -23.507596],
                                                    [-46.710806, -23.507626],
                                                    [-46.710166, -23.50769],
                                                    [-46.709823, -23.507723],
                                                    [-46.709274, -23.507775],
                                                    [-46.709111, -23.507789],
                                                    [-46.708951, -23.50772],
                                                    [-46.7088, -23.507697],
                                                    [-46.70867, -23.507658],
                                                    [-46.708624, -23.507644],
                                                    [-46.708323, -23.507651],
                                                    [-46.708234, -23.507648],
                                                    [-46.707969, -23.507659],
                                                    [-46.707729, -23.507676],
                                                    [-46.707487, -23.507678],
                                                    [-46.707383, -23.507727],
                                                    [-46.707178, -23.507778],
                                                    [-46.707037, -23.507833],
                                                    [-46.706934, -23.507904],
                                                    [-46.706745, -23.507908],
                                                    [-46.706533, -23.507884],
                                                    [-46.706296, -23.507862],
                                                    [-46.706057, -23.507844],
                                                    [-46.705853, -23.507828],
                                                    [-46.705663, -23.507798],
                                                    [-46.70521, -23.507746],
                                                    [-46.704841, -23.507715],
                                                    [-46.704098, -23.50763],
                                                    [-46.703935, -23.507612],
                                                    [-46.703808, -23.507634],
                                                    [-46.703216, -23.50756],
                                                    [-46.702181, -23.50744],
                                                    [-46.702057, -23.507385],
                                                    [-46.70153, -23.50734],
                                                    [-46.701462, -23.507262],
                                                    [-46.700824, -23.507214],
                                                    [-46.700749, -23.507239],
                                                    [-46.700204, -23.507218],
                                                    [-46.700062, -23.507239],
                                                    [-46.697954, -23.506975],
                                                    [-46.697665, -23.506975],
                                                    [-46.696838, -23.50691],
                                                    [-46.696532, -23.506894],
                                                    [-46.695895, -23.506906],
                                                    [-46.695447, -23.506918],
                                                    [-46.695014, -23.506957],
                                                    [-46.694303, -23.507061],
                                                    [-46.69358, -23.507216],
                                                    [-46.693036, -23.507342],
                                                    [-46.692702, -23.5074],
                                                    [-46.692032, -23.507585],
                                                    [-46.691706, -23.50767],
                                                    [-46.691545, -23.507658],
                                                    [-46.69115, -23.507748],
                                                    [-46.690924, -23.507795],
                                                    [-46.69073, -23.50783],
                                                    [-46.690608, -23.50785],
                                                    [-46.690278, -23.507881],
                                                    [-46.689855, -23.508038],
                                                    [-46.689715, -23.508058],
                                                    [-46.689559, -23.508078],
                                                    [-46.689371, -23.508133],
                                                    [-46.689299, -23.508143],
                                                    [-46.688962, -23.508213],
                                                    [-46.688734, -23.508259],
                                                    [-46.688453, -23.508303],
                                                    [-46.688238, -23.508348],
                                                    [-46.688054, -23.508382],
                                                    [-46.687874, -23.508415],
                                                    [-46.687706, -23.508441],
                                                    [-46.687522, -23.508446],
                                                    [-46.687459, -23.508476],
                                                    [-46.687392, -23.508508],
                                                    [-46.687165, -23.508543],
                                                    [-46.686958, -23.508573],
                                                    [-46.686751, -23.508603],
                                                    [-46.686507, -23.508637],
                                                    [-46.686278, -23.50867],
                                                    [-46.686174, -23.508687],
                                                    [-46.686043, -23.508712],
                                                    [-46.685924, -23.508731],
                                                    [-46.685865, -23.508743],
                                                    [-46.685753, -23.508761],
                                                    [-46.685657, -23.508778],
                                                    [-46.68532, -23.508838],
                                                    [-46.683953, -23.509088],
                                                    [-46.683319, -23.509284],
                                                    [-46.682781, -23.509514],
                                                    [-46.682727, -23.509573],
                                                    [-46.682514, -23.509648],
                                                    [-46.682056, -23.509889],
                                                    [-46.681603, -23.510149],
                                                    [-46.681237, -23.510393],
                                                    [-46.680726, -23.510785],
                                                    [-46.680037, -23.511299],
                                                    [-46.679397, -23.511766],
                                                    [-46.679137, -23.511966],
                                                    [-46.678825, -23.512167],
                                                    [-46.678725, -23.512214],
                                                    [-46.678532, -23.512339],
                                                    [-46.678402, -23.512414],
                                                    [-46.678158, -23.512519],
                                                    [-46.677881, -23.512629],
                                                    [-46.677641, -23.512713],
                                                    [-46.677559, -23.512733],
                                                    [-46.677413, -23.51274],
                                                    [-46.677172, -23.512841],
                                                    [-46.676749, -23.51294],
                                                    [-46.676276, -23.512997],
                                                    [-46.674603, -23.5132],
                                                    [-46.674372, -23.513246],
                                                    [-46.674176, -23.513285],
                                                    [-46.673924, -23.513303],
                                                    [-46.673743, -23.513318],
                                                    [-46.673527, -23.513342],
                                                    [-46.673181, -23.513383],
                                                    [-46.672932, -23.513409],
                                                    [-46.672656, -23.51345],
                                                    [-46.672295, -23.513501],
                                                    [-46.67183, -23.513545],
                                                    [-46.671257, -23.513613],
                                                    [-46.670729, -23.513686],
                                                    [-46.670071, -23.513778],
                                                    [-46.669655, -23.513835],
                                                    [-46.669197, -23.513908],
                                                    [-46.668214, -23.514063],
                                                    [-46.667766, -23.514144],
                                                    [-46.667246, -23.514224],
                                                    [-46.667123, -23.514243],
                                                    [-46.667041, -23.514237],
                                                    [-46.666773, -23.514282],
                                                    [-46.666622, -23.514297],
                                                    [-46.666525, -23.514311],
                                                    [-46.666355, -23.514328],
                                                    [-46.666051, -23.51436],
                                                    [-46.665649, -23.514402],
                                                    [-46.665473, -23.514423],
                                                    [-46.665257, -23.514435],
                                                    [-46.664943, -23.514461],
                                                    [-46.664547, -23.514494],
                                                    [-46.664315, -23.514514],
                                                    [-46.663583, -23.514592],
                                                    [-46.662895, -23.514684],
                                                    [-46.662211, -23.514777],
                                                    [-46.662156, -23.514785],
                                                    [-46.661922, -23.514773],
                                                    [-46.661235, -23.514864],
                                                    [-46.66079, -23.514916],
                                                    [-46.660459, -23.514966],
                                                    [-46.659879, -23.515039],
                                                    [-46.659399, -23.515103],
                                                    [-46.658723, -23.515194],
                                                    [-46.658303, -23.515254],
                                                    [-46.657825, -23.515326],
                                                    [-46.657431, -23.515395],
                                                    [-46.657125, -23.515452],
                                                    [-46.657068, -23.51548],
                                                    [-46.656967, -23.515459],
                                                    [-46.656756, -23.515489],
                                                    [-46.656509, -23.515516],
                                                    [-46.656272, -23.515554],
                                                    [-46.655921, -23.515608],
                                                    [-46.655654, -23.515652],
                                                    [-46.65535, -23.515697],
                                                    [-46.655208, -23.515722],
                                                    [-46.654947, -23.515754],
                                                    [-46.65473, -23.51578],
                                                    [-46.654506, -23.515793],
                                                    [-46.654323, -23.515809],
                                                    [-46.654196, -23.515827],
                                                    [-46.654054, -23.515843],
                                                    [-46.653961, -23.515859],
                                                    [-46.653849, -23.515877],
                                                    [-46.653773, -23.515884],
                                                    [-46.653536, -23.515898],
                                                    [-46.653421, -23.515946],
                                                    [-46.65315, -23.515962],
                                                    [-46.652893, -23.515966],
                                                    [-46.652803, -23.51596],
                                                    [-46.652685, -23.515952],
                                                    [-46.652609, -23.515948],
                                                    [-46.652425, -23.515889],
                                                    [-46.652253, -23.515852],
                                                    [-46.652122, -23.515823],
                                                    [-46.65191, -23.515729],
                                                    [-46.651737, -23.515729],
                                                    [-46.651635, -23.515731],
                                                    [-46.651506, -23.515774],
                                                    [-46.651416, -23.515796],
                                                    [-46.651308, -23.515836],
                                                    [-46.651169, -23.515967],
                                                    [-46.650937, -23.516153],
                                                    [-46.650752, -23.51631],
                                                    [-46.650563, -23.51642],
                                                    [-46.650394, -23.51649],
                                                    [-46.650175, -23.516534],
                                                    [-46.650008, -23.516573],
                                                    [-46.649939, -23.516591],
                                                    [-46.649817, -23.516656],
                                                    [-46.649747, -23.516697],
                                                    [-46.649563, -23.516644],
                                                    [-46.649332, -23.516657],
                                                    [-46.649012, -23.516694],
                                                    [-46.648625, -23.516749],
                                                    [-46.648211, -23.516801],
                                                    [-46.647677, -23.516873],
                                                    [-46.647131, -23.516936],
                                                    [-46.646652, -23.516996],
                                                    [-46.64616, -23.517053],
                                                    [-46.644511, -23.517256],
                                                    [-46.642807, -23.517463],
                                                    [-46.641769, -23.517563],
                                                    [-46.641203, -23.517616],
                                                    [-46.640738, -23.517655],
                                                    [-46.640454, -23.517686],
                                                    [-46.638533, -23.517793],
                                                    [-46.636887, -23.517907],
                                                    [-46.635102, -23.518026],
                                                    [-46.634625, -23.518066],
                                                    [-46.633616, -23.518154],
                                                    [-46.632481, -23.518262],
                                                    [-46.631945, -23.518307],
                                                    [-46.631597, -23.51831],
                                                    [-46.631168, -23.518203],
                                                    [-46.630878, -23.518121],
                                                    [-46.630594, -23.518017],
                                                    [-46.630453, -23.517986],
                                                    [-46.630222, -23.517988],
                                                    [-46.630118, -23.518016],
                                                    [-46.630048, -23.518078],
                                                    [-46.629884, -23.518088],
                                                    [-46.629569, -23.518112],
                                                    [-46.629348, -23.518137],
                                                    [-46.629005, -23.518179],
                                                    [-46.628777, -23.518204],
                                                    [-46.628525, -23.518228],
                                                    [-46.628143, -23.518261],
                                                    [-46.627724, -23.51829],
                                                    [-46.627247, -23.518323],
                                                    [-46.626907, -23.518344],
                                                    [-46.62673, -23.518365],
                                                    [-46.626525, -23.51839],
                                                    [-46.626345, -23.518434],
                                                    [-46.626184, -23.518446],
                                                    [-46.625934, -23.518466],
                                                    [-46.625633, -23.518491],
                                                    [-46.625453, -23.518509],
                                                    [-46.625348, -23.518524],
                                                    [-46.625099, -23.518552],
                                                    [-46.624894, -23.518581],
                                                    [-46.624554, -23.518588],
                                                    [-46.623938, -23.518633],
                                                    [-46.623781, -23.518641],
                                                    [-46.623664, -23.518566],
                                                    [-46.623372, -23.518534],
                                                    [-46.623169, -23.518542],
                                                    [-46.623029, -23.518526],
                                                    [-46.622858, -23.518501],
                                                    [-46.622606, -23.518457],
                                                    [-46.622335, -23.518384],
                                                    [-46.621854, -23.518281],
                                                    [-46.621333, -23.51818],
                                                    [-46.620852, -23.518104],
                                                    [-46.620308, -23.518054],
                                                    [-46.619708, -23.51799],
                                                    [-46.619499, -23.51801],
                                                    [-46.619241, -23.518009],
                                                    [-46.618886, -23.518028],
                                                    [-46.618518, -23.518053],
                                                    [-46.618283, -23.518069],
                                                    [-46.617917, -23.51812],
                                                    [-46.617582, -23.518168],
                                                    [-46.617127, -23.518308],
                                                    [-46.616797, -23.518411],
                                                    [-46.616543, -23.518526],
                                                    [-46.616313, -23.518625],
                                                    [-46.615934, -23.518792],
                                                    [-46.615797, -23.518874],
                                                    [-46.615573, -23.519103],
                                                    [-46.615474, -23.5192],
                                                    [-46.615027, -23.519436],
                                                    [-46.614765, -23.519591],
                                                    [-46.614578, -23.519704],
                                                    [-46.614258, -23.519916],
                                                    [-46.613111, -23.520782],
                                                    [-46.612642, -23.521145],
                                                    [-46.61234, -23.521266],
                                                    [-46.612088, -23.521433],
                                                    [-46.611764, -23.52166],
                                                    [-46.611674, -23.521709],
                                                    [-46.611197, -23.52195],
                                                    [-46.610876, -23.522064],
                                                    [-46.610679, -23.522124],
                                                    [-46.610458, -23.522157],
                                                    [-46.610261, -23.52223],
                                                    [-46.609862, -23.522318],
                                                    [-46.609546, -23.522371],
                                                    [-46.609294, -23.52249],
                                                    [-46.608892, -23.522492],
                                                    [-46.608522, -23.522453],
                                                    [-46.607911, -23.522315],
                                                    [-46.607345, -23.522197],
                                                    [-46.607211, -23.522177],
                                                    [-46.607061, -23.522047],
                                                    [-46.606921, -23.521971],
                                                    [-46.606694, -23.521935],
                                                    [-46.605833, -23.521805],
                                                    [-46.605481, -23.521777],
                                                    [-46.605168, -23.52179],
                                                    [-46.604693, -23.521816],
                                                    [-46.60421, -23.521872],
                                                    [-46.603684, -23.521935],
                                                    [-46.602794, -23.522164],
                                                    [-46.602469, -23.522299],
                                                    [-46.602049, -23.522621],
                                                    [-46.601316, -23.523018],
                                                    [-46.600855, -23.523329],
                                                    [-46.598874, -23.524952],
                                                    [-46.597881, -23.525751],
                                                    [-46.597526, -23.525992],
                                                    [-46.596443, -23.526458],
                                                    [-46.595168, -23.52682],
                                                    [-46.594536, -23.527037],
                                                    [-46.59238, -23.527615],
                                                    [-46.59181, -23.527728],
                                                    [-46.591596, -23.52782],
                                                    [-46.590934, -23.527966],
                                                    [-46.590605, -23.528195],
                                                    [-46.589882, -23.528423],
                                                    [-46.589675, -23.528452],
                                                    [-46.589451, -23.528483],
                                                    [-46.589192, -23.528475],
                                                    [-46.588995, -23.528454],
                                                    [-46.588469, -23.528343],
                                                    [-46.588152, -23.528258],
                                                    [-46.587706, -23.52815]
                                                ]
                                            ]
                                        }
                                    }
                                ]
                            }}></GeoJSON>

                        {this.props.withNF && this.props.paradasNFe !== undefined ?
                            <LayerGroup>
                                {this.props.paradasNFe.map(nfe => {
                                    return (
                                        <Marker icon={notaFiscalIconPin} position={[nfe.destinatario.endereco.latitude, nfe.destinatario.endereco.longitude]} pathOptions={fillRedOptions} radius={radius} >
                                            <Popup>
                                                <Typography variant="subtitle1"><b>Número: {nfe.numeroDocumentoFiscal}</b> </Typography>
                                                <Typography variant="body2"><b>Nome:</b> {nfe.destinatario.razaoSocial}<br />
                                                    <b>Endereço:</b> {nfe.destinatario.endereco.logradouro}, {nfe.destinatario.endereco.bairro}, {nfe.destinatario.endereco.complemento} </Typography>
                                                <Typography variant="body2"><b>Operação:</b> {nfe.naturezaOperacao}<br />
                                                    <b>Data emissão:</b> {getFormatDataTimeToString(nfe.dataEmissao)}<br />
                                                    <b>Valor total:</b> {nfe.total.valorTotal.toLocaleString('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    })} </Typography>
                                                <Typography variant="body2"><b>Observação:</b> {(nfe.informacaoComplementar.length > 110 ? nfe.informacaoComplementar.substring(0, 110) + '...' : nfe.informacaoComplementar)}</Typography>
                                                <Button variant="contained" color="primary" onClick={() => (this.props.history.push(DocumentosEditRoute + nfe.id))} >Ver nota</Button>
                                            </Popup>
                                        </Marker>
                                    );
                                })}
                            </LayerGroup> : <></>};

                        {this.props.withRotas && this.props.coordsUltimas && this.props.coordsUltimas.length > 2 ? <>
                            <Polyline pathOptions={{ fillColor: 'black', color: 'black', weight: 5.0, opacity: 0.7 }} positions={this.props.coordsUltimas}>

                            </Polyline >
                        </> : <></>}
                        {this.props.withRotas && this.props.coords && this.props.coords.length > 2 ? <>
                            <Polyline pathOptions={{ fillColor: 'black', color: 'black', weight: 5.0, opacity: 0.7 }} positions={this.props.coords}>

                            </Polyline >
                        </> : <></>}

                        {this.props.withRotas && (this.props.coords || this.props.coordsUltimas) ? <>
                            {Array.isArray(this.props.posicoes) && this.props.posicoes.length > 0 ?
                                <LayerGroup>
                                    <Marker icon={caminhaoIcon} position={[parseFloat(this.props.posicoes[this.props.posicoes.length - 1].latitude), parseFloat(this.props.posicoes[this.props.posicoes.length - 1].longitude)]} pathOptions={fillBlackOptions} radius={radius / 2}>
                                        <Popup>
                                            <Typography variant="subtitle1"><b>Placa: {this.props.veiculo ? this.props.veiculo.placa : ''}</b> </Typography>
                                            <Typography variant="body2">
                                                <b>Data:</b> {getFormatDataTimeToString(this.props.posicoes[this.props.posicoes.length - 1].data)} <br />
                                                <b>Tempo:</b> {getFormatSegundosToString(this.props.posicoes[this.props.posicoes.length - 1].tempo)} <br />
                                                <b>Distancia:</b> {this.props.posicoes[this.props.posicoes.length - 1].distancia} KMs
                                            </Typography>

                                        </Popup>
                                    </Marker>
                                </LayerGroup> : <>
                                    {Array.isArray(this.props.coordsUltimas) && this.props.coordsUltimas.length > 0 ?
                                        <LayerGroup>
                                            <Marker icon={caminhaoIcon} position={[parseFloat(this.props.coordsUltimas[this.props.coordsUltimas.length - 1][0]), parseFloat(this.props.coordsUltimas[this.props.coordsUltimas.length - 1][1])]} pathOptions={fillBlackOptions} radius={radius / 2}>
                                                <Popup>
                                                    <Typography variant="subtitle1"><b>Placa: {this.props.veiculo ? this.props.veiculo.placa : ''}</b> </Typography>
                                                </Popup>
                                            </Marker>
                                        </LayerGroup> : <></>}
                                </>}
                            <LayerGroup>
                                {Array.isArray(this.props.posicoes) && this.props.posicoes.map(coord => {
                                    var x = [parseFloat(coord.latitude), parseFloat(coord.longitude)];
                                    if (this.props.withPassoAPasso) {
                                        return <Circle center={x} pathOptions={fillGreenOptions} radius={(coord.paradaId == null ? 1 : 2)}>
                                            <Popup>
                                                Data: {getFormatDataTimeToString(coord.data)} <br />
                                                Tempo: {getFormatSegundosToString(coord.tempo)} <br />
                                                Velocidade média aprox.: {Math.round((coord.distancia / (coord.tempo / 3600)))} KM/h <br />
                                                Distancia: {coord.distancia} KMs
                                                <br />
                                                Parado
                                            </Popup>
                                        </Circle>;
                                    }
                                    return <></>;
                                })}
                            </LayerGroup>
                        </> : <></>}

                        {this.props.withParadas || this.props.withParadasNaoIdentificado || this.props.withParadasPlanejado || this.props.withParadasInterno ? <>
                            <LayerGroup>
                                {this.props.paradas.map(parada => {
                                    if (this.props.withParadas && parada.tipo !== 5)
                                        return paradaHtml(parada.latitudeCenter, parada.longitudeCenter, parada.totalMinutosParado, parada.tipo, parada.dataInicio, parada.dataFim);
                                    else if (this.props.withParadasNaoIdentificado && parada.tipo === 0)
                                        return paradaHtml(parada.latitudeCenter, parada.longitudeCenter, parada.totalMinutosParado, parada.tipo, parada.dataInicio, parada.dataFim);
                                    else if (this.props.withParadasPlanejado && (parada.tipo === 3 || parada.tipo === 4))
                                        return paradaHtml(parada.latitudeCenter, parada.longitudeCenter, parada.totalMinutosParado, parada.tipo, parada.dataInicio, parada.dataFim);
                                    else if (this.props.withParadasInterno && parada.tipo === 2)
                                        return paradaHtml(parada.latitudeCenter, parada.longitudeCenter, parada.totalMinutosParado, parada.tipo, parada.dataInicio, parada.dataFim);
                                    else if (parada.tipo === 8)
                                        return paradaHtml(parada.latitudeCenter, parada.longitudeCenter, parada.totalMinutosParado, parada.tipo, parada.dataInicio, parada.dataFim);
                                })}
                            </LayerGroup>
                        </> : <></>}

                        {this.props.withEntregas && Array.isArray(this.props.entregas) && this.props.entregas.length > 0 ? <>
                            <LayerGroup>
                                {this.props.entregas.map(entrega => {
                                    if (entrega.paradas[0] !== undefined) {
                                        return <Marker icon={entregaIco} position={[entrega.paradas[0].latitudeCenter, entrega.paradas[0].longitudeCenter]} pathOptions={fillGreenOptions} radius={radius} >
                                            <Popup>
                                                <Typography variant="subtitle1"><b> Números: {entrega.nFs[0].numeroDocumentoFiscal}</b><br />
                                                    <b>Situação:</b> {entrega.situacao === 1 ? "Entregue" : "Em rota"}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <b>Nome:</b> {entrega.nFs[0].destinatario.razaoSocial} <br />
                                                    <b>Endereço:</b> {entrega.nFs[0].destinatario.endereco.logradouro}, {entrega.nFs[0].destinatario.endereco.bairro}, {entrega.nFs[0].destinatario.endereco.complemento}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <b>Tempo parado:</b> {getFormatMinutosToStringExt(entrega.paradas[0].totalMinutosParado)}<br />
                                                    <b>Data inicio:</b> {getFormatDataTimeToString(entrega.dataInicio)} <br />
                                                    <b> Data fim:</b> {getFormatDataTimeToString(entrega.dataFim)}
                                                </Typography>

                                                <Button variant="contained" color="primary" onClick={() => (this.props.history.push(EntregasEditRoute2 + entrega.id))} >Ver entrega</Button>
                                            </Popup>
                                        </Marker>;
                                    }
                                    else return <Marker
                                        icon={entregaPin} position={[entrega.nFs[0].destinatario.endereco.latitude, entrega.nFs[0].destinatario.endereco.longitude]}
                                        pathOptions={fillBlueGreenOptions} radius={radius} >
                                        <Popup>Situação: {entrega.tipo === 1 ? "Entregue" : "Em rota"}<br />
                                            <br />
                                            {/* Nome: {entrega.nFs[0].destinatario.razaoSocial} <br /> */}
                                            Operação: {entrega.nFs[0].naturezaOperacao} <br />
                                            Número: {entrega.nFs[0].numeroDocumentoFiscal} <br />
                                        </Popup>
                                    </Marker>;;
                                })}
                            </LayerGroup>
                        </> : <></>}

                        {this.props.withParadasPlanejadas && Array.isArray(this.props.paradasPlanejadas) && this.props.paradasPlanejadas.length > 0 ? (
                            <LayerGroup>
                                {this.props.paradasPlanejadas.map(paradaPlanejada => {
                                    if (paradaPlanejada.entregaId) {
                                        return <MarkerEntrega entregaId={paradaPlanejada.entregaId} entrega={paradaPlanejada.entrega}
                                            paradaPlanejada={paradaPlanejada} radius={radius}
                                            tamanhoIcon={this.props.tamanhoIcon} withJanela={this.props.withJanela}></MarkerEntrega>
                                    }
                                    else if (paradaPlanejada.localId) {
                                        return <MarkerLocalPlanejado localId={paradaPlanejada.localId} local={paradaPlanejada.local} paradaPlanejada={paradaPlanejada} radius={radius} tamanhoIcon={this.props.tamanhoIcon}></MarkerLocalPlanejado>
                                    }
                                    else if (paradaPlanejada.tipo === 2) // Recolha
                                        return <MarkerRecolha paradaPlanejada={paradaPlanejada} radius={radius} tamanhoIcon={this.props.tamanhoIcon} withJanela={this.props.withJanela}></MarkerRecolha>
                                    else if (paradaPlanejada.tipo === 3) // Visita
                                        return <MarkerRecolha paradaPlanejada={paradaPlanejada} radius={radius} tamanhoIcon={this.props.tamanhoIcon} withJanela={this.props.withJanela}></MarkerRecolha>
                                    else
                                        return <></>;
                                })}
                            </LayerGroup>
                        )
                            : <></>}

                        {this.props.localInicio ?
                            <LayerGroup>
                                <Marker icon={lojaTeste} position={[parseFloat(this.props.localInicio.latitude), parseFloat(this.props.localInicio.longitude)]} pathOptions={fillBlackOptions} radius={radius / 2}>
                                    <Popup>
                                        <Typography variant="subtitle1"><b>Local inicio: {this.props.localInicio.descricao}</b> </Typography>

                                    </Popup>
                                </Marker>
                            </LayerGroup>
                            : <></>}
                        {this.props.localFim ?
                            <LayerGroup>
                                <Marker icon={lojaTeste} position={[parseFloat(this.props.localFim.latitude), parseFloat(this.props.localFim.longitude)]} pathOptions={fillBlackOptions} radius={radius / 2}>
                                    <Popup>
                                        <Typography variant="subtitle1"><b>Local fim: {this.props.localFim.descricao}</b> </Typography>
                                    </Popup>
                                </Marker>
                            </LayerGroup>
                            : <></>}
                    </MapContainer>
                ) : (
                    <>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <CircularProgress
                                sx={{
                                    margin: "80px 0px"
                                }}
                            />
                        </Box>
                    </>
                )}
            </>
        );
    }
}

export default withRouter(MapContainerLeft);