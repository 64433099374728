import React, { useState, useEffect } from 'react';
import { getById } from '../../../API/Veiculos';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import ProgressBar from './ProgressBar';
import Tooltip from '@material-ui/core/Tooltip';
import Text from '@material-ui/core/Typography';
import Box from '@mui/material/Box';

export default function CapacidadeRota_Grid(props) {

    const [loading, setLoading] = useState(true);
    const [capacidadeDeCargaQuilos, setCapacidadeDeCargaQuilos] = useState('');
    const [capacidadeDeCargaVolumes, setCapacidadeDeCargaVolumes] = useState('');
    const [capacidadeDeCargaCubagem, setCapacidadeDeCargaCubagem] = useState('');
    const [capacidadeDeCargaValorMaximo, setCapacidadeDeCargaValorMaximo] = useState('');

    useEffect(() => {
        getById(props.row.veiculoId).then((veiculo) => {

            if (veiculo.capacidadeDeCargaQuilos > 0)
                setCapacidadeDeCargaQuilos(veiculo.capacidadeDeCargaQuilos);
            if (veiculo.capacidadeDeCargaVolumes > 0)
                setCapacidadeDeCargaVolumes(veiculo.capacidadeDeCargaVolumes);
            if (veiculo.capacidadeDeCargaCubagem > 0)
                setCapacidadeDeCargaCubagem(veiculo.capacidadeDeCargaCubagem);

            if (veiculo.capacidadeDeCargaValorMaximo > 0)
                setCapacidadeDeCargaValorMaximo(veiculo.capacidadeDeCargaValorMaximo);


            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    });

    function calcular(valorTotal, valor, unidade, unidadeNaDireita = true) {
        var disp = valorTotal - valor;
        if (disp >= 0) {
            let arredondado = Number(disp.toFixed(2));
            arredondado = (arredondado).toLocaleString('pt-BR');
            if (unidadeNaDireita)
                return "Disponível " + (arredondado) + " " + unidade;
            return "Disponível " + unidade.toString() + " " +(arredondado);

        }
        if (disp < 0) {
            let excedido = disp * -1;
            let arredondado = Number(excedido.toFixed(2));
            arredondado = (arredondado).toLocaleString('pt-BR');
            if (unidadeNaDireita)
                return "Excedido " + (arredondado) + " " + unidade;
            return "Excedido " + unidade + " " + (arredondado);
        }
    }
    function calcularPorcentagem(valorTotal, valor) {
        // 200  50
        // 100   x
        return (valor * 100) / valorTotal;
    }

    if (loading)
        return <div style={{ width: '100px', marginLeft: '10px' }}><Skeleton variant="text" /></div>;

    if (props.isPeso && props.row.pesoTotal !== undefined) {
        
        let peso = Number(props.row.pesoTotal.toFixed(2));
        if (capacidadeDeCargaQuilos === '' || capacidadeDeCargaQuilos === 0){
            if (props.row.pesoTotal === undefined || props.row.pesoTotal === null){
                return <Tooltip title={<Text style={{ whiteSpace: 'pre-line' }}>
                {props.tooltipText}
            </Text>}><>
                <ProgressBar completed={0} /><Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                    0 Kg
                </Typography></></Tooltip>
            }
                
            return <><div style={{ display: 'flex', alignItems: 'center' }}>
            <Box style={{width: "100%"}}>
                                <ProgressBar completed={0} />
                <Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                     {peso.toLocaleString('pt-BR')} Kg
                </Typography>
                </Box>
        </div>
        </>

        }
        return <>
        <Tooltip title={<Text style={{ whiteSpace: 'pre-line' }}>
            Utilizado {props.row.pesoTotal.toLocaleString('pt-BR')} Kg <br></br>
            {calcular(capacidadeDeCargaQuilos, props.row.pesoTotal, "Kg")} <br></br>
            <br></br>
            Limite {capacidadeDeCargaQuilos.toLocaleString('pt-BR')} Kg</Text>}>
        <Box style={{width: "100%"}}>
            <ProgressBar completed={calcularPorcentagem(capacidadeDeCargaQuilos, props.row.pesoTotal)} />
            <Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                {calcular(capacidadeDeCargaQuilos, props.row.pesoTotal, "Kg")}
            </Typography>
            </Box>
            </Tooltip>
        </>;
    }
    if (props.isVolume) {
        if (capacidadeDeCargaVolumes === '' || capacidadeDeCargaVolumes === 0)
            return <>
                <ProgressBar completed={0} /><Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                    {props.row.volumeTotal} vol
                </Typography></>

        return <>
            <ProgressBar completed={calcularPorcentagem(capacidadeDeCargaVolumes, props.row.volumeTotal)} />
            <Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                {calcular(capacidadeDeCargaVolumes, props.row.volumeTotal, "vol")}
            </Typography>
        </>;
    }
    if (props.isCubagem) {
        if (capacidadeDeCargaCubagem === '' || capacidadeDeCargaCubagem === 0) {
            if (props.row.cubagemTotal === undefined || props.row.cubagemTotal === null)
                return <>
                <ProgressBar completed={0} /><Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                    0 m³
                </Typography></>

            return <>
                <ProgressBar completed={0} /><Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                    {props.row.cubagemTotal.toLocaleString('pt-BR')} m³
                </Typography></>
        }

        return <>
            <ProgressBar completed={calcularPorcentagem(capacidadeDeCargaCubagem, props.row.cubagemTotal)} />
            <Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                {calcular(capacidadeDeCargaCubagem, props.row.cubagemTotal, "m³")}
            </Typography>
        </>;
    }

    if (props.isValorCarga) {


        let valorCarga = 0;
        if (props.row.valorTotalCarga !== undefined && props.row.valorTotalCarga !== null)
            valorCarga = Number(props.row.valorTotalCarga.toFixed(2));

        if (capacidadeDeCargaValorMaximo === '' || capacidadeDeCargaValorMaximo === 0){
            if (props.row.valorTotalCarga === undefined || props.row.valorTotalCarga === null){
                return <Tooltip title={<Text style={{ whiteSpace: 'pre-line' }}>
                {props.tooltipText}
            </Text>}><>
                <ProgressBar completed={0} /><Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                    
                </Typography></></Tooltip>
            }
                
            return <><div style={{ display: 'flex', alignItems: 'center' }}>
            <Box style={{width: "100%"}}>
                                <ProgressBar completed={0} />
                <Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                     R$ {valorCarga.toLocaleString('pt-BR',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Typography>
                </Box>
        </div>
        </>

        }
        return <>
        <Tooltip title={<Text style={{ whiteSpace: 'pre-line' }}>
            Valor total: R$ {props.row.valorTotalCarga.toLocaleString('pt-BR',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br></br>
            {calcular(capacidadeDeCargaValorMaximo, props.row.valorTotalCarga, "R$", false)} <br></br>
            <br></br>
            Limite: R$ {capacidadeDeCargaValorMaximo.toLocaleString('pt-BR',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>}>
        <Box style={{width: "100%"}}>
            <ProgressBar completed={calcularPorcentagem(capacidadeDeCargaValorMaximo, props.row.valorTotalCarga)} />
            <Typography variant={props.variant ?? "caption"} display="block" gutterBottom>
                R$ {valorCarga.toLocaleString('pt-BR',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>
            </Box>
            </Tooltip>
        </>;
    }
    return <></>;

}



