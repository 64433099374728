

import React, { useEffect, useCallback } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll } from '../../API/Pagamentos';
import { columnsGrid } from './Config';
import TextField from '@material-ui/core/TextField';
import debounce from "lodash.debounce";
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    let [dataInicio, setDataInicio] = React.useState(new Date());
    let [dataFim, setDataFim] = React.useState(new Date());
    let [filtro, setFiltro] = React.useState('');
    let [situacao, setsituacao] = React.useState('Todos');

    const handleDataInicioChange = async (date) => {
        dataInicio = date;
        setDataInicio(dataInicio);
        loadRow();
    };

    const handleFiltroChange = (filtrof) => {
        filtro = filtrof.target.value;
        setFiltro(filtro);
        loadRow();
    };

    const handleDataFimChange = (date) => {
        dataFim = date;
        setDataFim(dataFim);
        loadRow();
    };

    async function loadRow() {
        setRows([]);
        setLoading(true);

        var situacaoF = situacao;
        if (situacao === 'Todos' || situacao === undefined || situacao === null)
            situacaoF = '';

        getAll(filtro, dataInicio, dataFim, '', '', [situacaoF]).then((value) => {
            if (value !== undefined || value !== undefined)
                setRows(value);
            setLoading(false);
        });

        var campos = columnsGrid;
        setColumns(campos);
    }

    useEffect(async () => {
        dataInicio.setDate(new Date().getDate() - 7)
        setDataInicio(dataInicio);
        loadRow();
    }, [])

    async function filtrar(filtrof) {
        setFiltro(filtrof);
        await loadRow();
    }

    const debouncedChangeHandlerFiltro = useCallback(debounce(handleFiltroChange, 1000), []);
    const debouncedChangeHandler = useCallback(debounce(handleDataInicioChange, 1000), []);
    const debouncedChangeHandlerFim = useCallback(debounce(handleDataFimChange, 1000), []);


    const campos = () => {
        return <>
            <TextField label="Pesquisar Nome/CPF/CNPJ" style={{ width: '300px', marginTop: '16px', marginBottom: '8px' }} onChange={debouncedChangeHandlerFiltro} />
            <KeyboardDatePicker
                disableToolbar
                autoOk
                style={{ marginLeft: '15px', width: '150px' }}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Data inicial"
                value={dataInicio}
                onChange={debouncedChangeHandler}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />

            <FormControl style={{ marginLeft: '15px', width: '150px', marginTop: '16px', marginBottom: '8px' }}>
                <InputLabel >Situção</InputLabel>
                <Select
                    labelId="demo-simple-select-label"

                    value={situacao}
                    onChange={(event) => { situacao = event.target.value; setsituacao(situacao); loadRow(); }}
                >
                    <MenuItem value={'Todos'}>Todos</MenuItem>
                    <MenuItem value={'PIX pago com sucesso.'}>Pago com sucesso</MenuItem>
                </Select>
            </FormControl>
        </>
    }

    return (
        <GridTable title="Pagamentos"
            rows={rows}
            columns={columns}
            loading={loading}
            CanRemove={false}
            rowsPerPage={50}
            atualizar={filtrar}
            camposFiltros={(campos())}
        />
    );
}