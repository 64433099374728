import { instance } from './axios'
import { getFormatDataToDataApi } from '../Utils/FormatDateTime';

export async function getResumoParada(data){
    try {
        var it = new Date(data);
        var i = it.getFullYear()+'-'+ ('00'+(it.getMonth()+1)).slice(-2) + '-'+
            ('00'+it.getDate()).slice(-2);
        
        const response = await instance.get(`/api/Paradas/resumo?data=${i}`);
        
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getParadasByVeiculo(idVeiculo, idMotorista, dtInicio, dtFim) {
    try {
        var i = getFormatDataToDataApi(dtInicio);
        var f = getFormatDataToDataApi(dtFim);
        
        var url  = `/api/Paradas/PorVeiculo/${idVeiculo}?&inicio=${i}&fim=${f}`;

        if (idMotorista != null) {
            url = `${url}&motoristaId=${idMotorista}`;
        }

        const response = await instance.get(url);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}
