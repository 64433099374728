import React, { useEffect } from 'react';
import Button from '../../Components/Button/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo2 from '../../img/login/LOGO OFICIAL INPI.png';
import Capa5 from '../../img/login/CAPA05.png';
import Capa6 from '../../img/login/CAPA06.jpg';
import Capa7 from '../../img/login/CAPA07.png';
import Capa8 from '../../img/login/CAPA08.jpg';
import Capa9 from '../../img/login/CAPA09.jpg';
import Capa10 from '../../img/login/CAPA10.jpg';
import Capa11 from '../../img/login/CAPA11.jpg';
import Capa12 from '../../img/login/CAPA12.jpg';
import Capa13 from '../../img/login/CAPA13.jpg';
import { login } from '../../Router/auth';
import { useHistory } from 'react-router-dom';
import { HomeRoute } from '../../Router/Routing';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
    root: {
        Height: '100vh',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center center', // Adicione esta linha
        height: '100vh'

    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    titulo: {
        color: 'black',
        fontSize: '34px'
    }
}));


export default function SignInSide() {
    const classes = useStyles();
    const [senha, setSenha] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [erro, setErro] = React.useState('');
    const history = useHistory();
    const [capa, setCapa] = React.useState(Capa5);

    useEffect(() => {
        const min = 4;
        const max = 14;

        const rand = min + Math.random() * (max - min);
        var index = parseInt(rand);


        switch (index) {
            case 5: setCapa(Capa5); break;
            case 6: setCapa(Capa6); break;
            case 7: setCapa(Capa7); break;
            case 8: setCapa(Capa8); break;
            case 9: setCapa(Capa9); break;
            case 10: setCapa(Capa10); break;
            case 11: setCapa(Capa11); break;
            case 12: setCapa(Capa12); break;
            case 13: setCapa(Capa13); break;
            default: setCapa(Capa5); break;
        }
    }, []);

    return (
        <SharedSnackbarConsumer>
            {({ openSnackbar }) => (
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />
                    <Hidden xsDown={true} smDown>
                        <Grid item xs={0} sm={0} md={7} className={classes.image} style={{ backgroundImage: 'url(' + capa + ')' }}>

                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={5} component={Paper} elevation={0} >
                        <Hidden xsUp smUp>
                            <Box mt={10}>

                            </Box>
                        </Hidden>
                        <div className={classes.paper}>
                            <img
                                src={logo2}
                                alt="new"
                                style={{ width: '35%' }}
                            />
                            <Typography component="h1" variant="h5" className={classes.titulo}>
                                <b></b>
                            </Typography>
                            <div className={classes.form}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        style={{ width: '80%' }}
                                        id="email"
                                        label="Digite seu usuário"
                                        name="email"
                                        autoComplete="email"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        autoFocus
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                setLoading(true);
                                                setErro('');
                                                login(userName, senha)
                                                    .then(e => {
                                                        openSnackbar('Logado!');
                                                        history.push(HomeRoute);
                                                    })
                                                    .catch((e, x) => {
                                                        if (e.response && e.response.status === 404)
                                                            setErro('Usuário inválido ou inativado.');
                                                        else if (e.message)
                                                            setErro(e.message);
                                                    })
                                                    .finally(() => {
                                                        setLoading(false);
                                                    });
                                            }
                                        }}

                                    /></div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        style={{ width: '80%' }}
                                        name="password"
                                        label="Digite sua senha"
                                        type="password"
                                        id="password"
                                        value={senha}
                                        onChange={(e) => setSenha(e.target.value)}
                                        autoComplete="current-password"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                setLoading(true);
                                                setErro('');
                                                login(userName, senha)
                                                    .then(e => {
                                                        openSnackbar('Logado!');
                                                        history.push(HomeRoute);
                                                    })
                                                    .catch((e, x) => {
                                                        if (e.response && e.response.status === 404)
                                                            setErro('Usuário/senha inválido.');
                                                        else if (e.message)
                                                            setErro(e.message);
                                                    })
                                                    .finally(() => {
                                                        setLoading(false);
                                                    });
                                            }
                                        }}
                                    />

                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {erro ?
                                        <Typography variant="subtitle1" color='error'>
                                            {erro}
                                        </Typography>


                                        : <></>}
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', }}>
                                    <div style={{ width: '100%', }}>
                                        <Button
                                            loading={loading}
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            style={{ height: 55, width: '80%' }} // Configurar a cor do botão para preto

                                            onClick={() => {
                                                setLoading(true);
                                                setErro('');
                                                login(userName, senha)
                                                    .then(e => {
                                                        openSnackbar('Logado!');
                                                        history.push(HomeRoute);
                                                    })
                                                    .catch((e, x) => {
                                                        setErro(e.response.data.message);
                                                    })
                                                    .finally(() => {
                                                        setLoading(false);
                                                    });
                                            }
                                            }
                                        >
                                            ACESSAR
                                        </Button>
                                    </div>

                                </div>
                                <Grid container>
                                    <Grid item xs style={{ textAlign: 'center', height: 100 }}>

                                        <i>Esqueceu sua senha? Entre em contato com nosso suporte.</i>

                                    </Grid>
                                    <Grid item>
                                        <Link href="#" variant="body2">
                                            { }
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Box mt={10}>

                                </Box>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )}
        </SharedSnackbarConsumer>
    );
}
