import React from 'react';
import { Marker, Popup } from 'react-leaflet'
import { verdePastel, vermelhoPastel } from './MarkerColors';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import Avatar from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as localPlanejado_3 } from '../../img/icon/localPlanejado_4.svg';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';


function MarkerLocalPlanejado(props) {
    var verde = false; var vermelho = false;


    var latitude = 0; var longitude = 0; 
    
    if (props.paradaPlanejada) {
        if (props.paradaPlanejada.situacao === 1) {
            verde = true;
        }
        if (props.paradaPlanejada.situacao === 2) {
            vermelho = true;
        }
    }

    if (props.local) {
        latitude = props.local.latitude;
        longitude = props.local.longitude;
    }
    if (latitude === 0 || longitude === 0 || latitude == null || longitude == null)
        return <></>;

        const paradaLocalPlanejado = L.divIcon({
            html: ReactDOMServer.renderToString(<Badge badgeContent={props.paradaPlanejada.sequencia+1} color='primary'>
                <Avatar variant="rounded" style={{ 
                marginLeft: '-10px', 
                marginTop: '-5px', 
                backgroundColor: (verde ? verdePastel : vermelho? vermelhoPastel : ''),
                height: props.tamanhoIcon+'px',
                width: props.tamanhoIcon+'px'
                }}>
            <SvgIcon component={localPlanejado_3} style={{ width: (props.tamanhoIcon-5)+'px', height: (props.tamanhoIcon-5)+'px'}}/> </Avatar></Badge>)});


    return <Marker
        id={props.paradaPlanejada.id}
        icon={paradaLocalPlanejado}
        position={[latitude, longitude]}
        radius={props.radius} >
        <Popup>
            <Informacao local={props.local} paradaPlanejada={props.paradaPlanejada} />
        </Popup>
    </Marker>;

}

export default MarkerLocalPlanejado;

const Informacao = props => {

    const tipoParada = (tipo) => {
        switch (tipo){
            case 0:
                return 'Local interno'
            case 1:
                return 'Local planejado'
            case 2:
            case 3:
                return 'Posto/Oficina'
            default:
                return ""
        }
    }

    return (<div >
        <Typography variant="subtitle1"><b>Local planejado</b></Typography>
        <Typography variant="body2">
            <b>Situação: </b>{props.paradaPlanejada.situacao == 1 ? "Realizado" : props.paradaPlanejada.situacao === 2 ?"Não realizado" : "Em rota"}<br />
            <b>Tipo: </b>{tipoParada(props.local.tipo)}<br />
            <b>Sequência planejada: </b>{props.paradaPlanejada.sequencia}<br />
        </Typography>
    </div>);
}



