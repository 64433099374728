export const columnsGrid = [
    { label: 'Placa', field: 'placa', padding: 'default' },
    { label: 'Apelido', field: 'apelido', padding: 'default' },
    {
        label: 'Tipo', field: 'tipo', type: 'option',
        options: [
            { value: 0, label: 'Carro' },
            { value: 1, label: 'Moto' },
            { value: 2, label: 'Van' },
            { value: 3, label: 'Caminhão Toco' },
            { value: 4, label: 'Caminhão truck' },
            { value: 5, label: 'Carreta' }],
        padding: 'default'
    },
    { label: "Ativo", field: 'ativo', type: "boolWithIcon", padding: 'default' }
];

export const camposForm = [
    { name: "placa", label: "Placa", type: "text", size: 2, required: true, autoFocus: true },
    { name: "apelido", label: "Apelido", type: "text", size: 1, required: false },
    {
        name: "tipo",
        label: "Tipo",
        type: "option",
        size: 1,
        required: true,
        options: [
            { value: 0, label: 'Carro' },
            { value: 1, label: 'Moto' },
            { value: 2, label: 'Van' },
            { value: 3, label: 'Caminhão Toco' },
            { value: 4, label: 'Caminhão truck' },
            { value: 5, label: 'Carreta' }]
    },
    { name: "capacidadeDeCargaQuilos", label: "Capacidade de carga (Kg)", type: "text", size: 2, required: false },
    { name: "capacidadeDeCargaVolumes", label: "Capacidade de volumes", type: "text", size: 2, required: false },
    { name: "capacidadeDeCargaCubagem", label: "Capacidade de cubagem", type: "cubagem", size: 2, required: false },
    { name: "capacidadeDeCargaValorMaximo", label: "Valor máximo da carga", type: "money", size: 2, required: false, numeric: true },
    { name: "tipoRoteirizacao", label: "Tipo de roteirização de rotas", type: "option", required: true, size: 3, options: [{ value: 0, label: 'Desativado' }, { value: -1, label: 'Padrão da empresa' }, { value: 1, label: 'Locais Fixos' }, { value: 2, label: 'Locais Dinâmicos' }] },
    { name: "motoristaId", label: "Sugestão de motorista para o veículo", type: "lookup_assincrono", endpoint: "api/Motoristas/lookup", linkToCriar: false, size: 3, required: false, options: [] },
    { name: "localInicioId", label: "Sugestão de local para início de rota", type: "lookup_assincrono", endpoint: "api/Locais/lookup", linkToCriar: false, size: 3, required: false, options: [] },
    { name: "localFimId", label: "Sugestão de local para fim de rota", type: "lookup_assincrono", endpoint: "api/Locais/lookup", linkToCriar: false, size: 3, required: false, options: [] },
    { name: "inicioRota", label: "Hora de início de rota", type: "time", size: 2 },
    { name: "ativo", label: "Ativo", type: "bool", size: 1, required: true }
]
