import React, { useState, useCallback, useContext, useRef } from 'react';
import debounce from "lodash.debounce";
import { makeStyles } from '@material-ui/core/styles';
import { columnsGrid } from './Config';
import { GridTable } from '../../Components/GridTable/GridTable';
import MultiplaSelecao from '../../Components/MultiplaSelecao/MultiplaSelecao'
import { Box, Container, Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { getRotasMonitoramentoPost } from '../../API/Rotas';
import { ReactComponent as alert } from '../../img/icon/alert_1.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router-dom';
import { RotasEditRoute } from '../../Router/Routing';
import { getUser } from '../../Router/auth';
import ListaParadas from './Components/ListaParadas';
import { styles } from "./style/styleMonitoramento";
import { MonitoramentoContext } from "../../Context/MonitoramentoContext";
import PesoVolume from '../../Components/Dashboards/PesoVolume';
import Evidencia from '../../Components/Dashboards/Evidencia';
import Comentario from '../../Components/Dashboards/Comentario';
import Entrega from '../../Components/Dashboards/Entrega';
import { rota_regioes_selecionadas, rota_motoristas_selecionadas, rota_veiculos_selecionadas } from '../../Utils/StorageKeys';
import { Roles } from '../../Utils/Roles';
import { getFormatTimeHMToString } from '../../Utils/FormatDateTime';
import { FiltroSituacaoRota } from '../../Components/FiltroSituacaoRota/FiltroSituacaoRota';

const style = makeStyles(styles);

export default function Monitoramento() {
    const classes = style();
    const { setAtualizarParadas } = useContext(MonitoramentoContext);

    const [data, setDate] = React.useState(Date.now());
    const [time, setTime] = React.useState(Date.now());
    const [loading, setLoading] = React.useState(true);
    const [verNaoIdentificada, setVerNaoIdentificada] = React.useState(localStorage.getItem('verNaoIdentificada') === 'true');
    const [rotas, setRotas] = React.useState([]);
    const [column, setColumn] = React.useState([]);
    const [filtroSituacaoRota, setFiltroSituacaoRota] = useState(-1);

    const history = useHistory();
    const [acesso] = useState(['RLuOKTh1Ko', "Master", "AnaliseDeLogistica", Roles.Monitoramento].indexOf(getUser().role) >= 0);

    const [regioesIds, setRegioesIds] = useState(() => {
        const storedRegioes = localStorage.getItem(rota_regioes_selecionadas);
        return storedRegioes ? storedRegioes : [];
    });

    const [motoristasIds, setMotoristasIds] = useState(() => {
        const storedMotoristas = localStorage.getItem(rota_motoristas_selecionadas);
        return storedMotoristas ? storedMotoristas : [];
    });

    const [veiculosIds, setVeiculosIds] = useState(() => {
        const storedVeiculos = localStorage.getItem(rota_veiculos_selecionadas);
        return storedVeiculos ? storedVeiculos : [];
    });

    const dataRef = useRef(data);
    const regioesIdsRef = useRef(regioesIds);
    const motoristasIdsRef = useRef(motoristasIds);
    const veiculosIdsRef = useRef(veiculosIds);
    const filtroSituacaoRotaRef = useRef(filtroSituacaoRota)

    React.useEffect(() => {
        dataRef.current = data;
        regioesIdsRef.current = regioesIds;
        motoristasIdsRef.current = motoristasIds;
        veiculosIdsRef.current = veiculosIds;
        filtroSituacaoRotaRef.current = filtroSituacaoRota;

        atualizar();
    }, [data, regioesIds, motoristasIds, veiculosIds, filtroSituacaoRota]);

    React.useEffect(() => {
        localStorage.setItem('verNaoIdentificada', verNaoIdentificada);

        var element = columnsGrid.find(x => x.field === 'paradasMonitormaneto');
        element.function = rotaComponent;
        setColumn(columnsGrid);
        atualizar();
    }, [verNaoIdentificada]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now());

            setAtualizarParadas(true);
            atualizar();
        }, 1 * 30000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    async function atualizar() {
        await getRotasMonitoramentoPost(
            dataRef.current,
            regioesIdsRef.current,
            motoristasIdsRef.current,
            veiculosIdsRef.current,
            filtroSituacaoRotaRef.current
        ).then((value) => {
            if (value !== undefined) {
                value.map(x => x.acoes = x.id);
                setRotas(value);
            }
            setLoading(false);
        });
    }

    const rotaComponent = (value) => {
        return <ListaParadas rota={value} verNaoIdentificada={verNaoIdentificada} history={history}></ListaParadas>;
    }

    const handleDataInicioChange = async (date) => {
        setDate(date);
        setLoading(true);
    };

    const debouncedChangeHandler = useCallback(debounce(handleDataInicioChange, 500), []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                }}
            >
                <Box style={{ marginLeft: '22px', marginRight: '22px', width: '188vh', marginBottom: '10px' }}>
                    <Grid container spacing={1}>
                        <Entrega
                            size={4}
                            rotas={rotas}
                        />
                        <PesoVolume
                            size={2}
                            rotas={rotas}
                        />
                        <Evidencia
                            size={2}
                            rotas={rotas}
                        />
                        <Comentario
                            size={2}
                            rotas={rotas}
                        />
                    </Grid>
                </Box>

                <Container maxWidth={false}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={12} lg={12}>
                            <GridTable
                                rows={rotas}
                                columns={column}
                                LinkEdit={(acesso ? RotasEditRoute : undefined)}
                                loading={loading}
                                rowsPerPage={25}
                                CanRemove={false}
                                camposFiltros={
                                    <Box style={{ display: "flex", alignItems: "center", height: "80px" }}>
                                        <KeyboardDatePicker
                                            label="Filtro"
                                            format="dd/MM/yyyy"
                                            value={data}
                                            onChange={debouncedChangeHandler}
                                            showTodayButton
                                            invalidDateMessage='Data invalida'
                                            todayLabel='Hoje'
                                            cancelLabel='Cancelar'
                                            variant="inline"
                                            style={{ marginLeft: '15px', width: '150px' }}
                                            disableToolbar
                                            autoOk
                                        />
                                        <Box style={{ marginLeft: '15px', width: '300px' }}>
                                            <MultiplaSelecao
                                                label="Região"
                                                placeholder="Selecione a/as regiões desejadas"
                                                localStorageName={rota_regioes_selecionadas}
                                                endpoint="api/RotaRegioes/lookupMultiplo"
                                                onChange={(ids) => setRegioesIds(ids)}
                                            ></MultiplaSelecao>
                                        </Box>
                                        <Box style={{ marginLeft: '15px', width: '300px' }}>
                                            <MultiplaSelecao
                                                label="Veiculos"
                                                placeholder="Selecione os veiculos"
                                                localStorageName={rota_veiculos_selecionadas}
                                                endpoint="api/Veiculos/lookupMultiplo"
                                                onChange={(ids) => setVeiculosIds(ids)}
                                            ></MultiplaSelecao>
                                        </Box>
                                        <Box style={{ marginLeft: '15px', width: '300px' }}>
                                            <MultiplaSelecao
                                                label="Motoristas"
                                                placeholder="Selecione os motoristas"
                                                localStorageName={rota_motoristas_selecionadas}
                                                endpoint="api/Motoristas/lookupMultiplo"
                                                onChange={(ids) => setMotoristasIds(ids)}
                                            ></MultiplaSelecao>
                                        </Box>
                                        <Box style={{ marginLeft: '15px' }}>
                                            <FiltroSituacaoRota
                                                value={filtroSituacaoRota}
                                                onChange={setFiltroSituacaoRota}
                                            />
                                        </Box>

                                        <Box style={{ marginLeft: "40px" }}>
                                            <Typography
                                                style={{
                                                    fontSize: "11.5px",
                                                    position: "absolute",
                                                    top: "12px",
                                                    marginLeft: "10px",
                                                    fontWeight: "200",
                                                    color: "rgba(0, 0, 0, 0.54)"
                                                }}>Opcionais</Typography>
                                            {verNaoIdentificada ?
                                                <Tooltip
                                                    title={<Typography variant="subtitle1"><b>Deixar de visualizar paradas não identificadas</b></Typography>}
                                                >
                                                    <IconButton onClick={() => { setVerNaoIdentificada(false) }} style={{ marginTop: "15px" }}>
                                                        <Avatar variant="rounded" style={{ backgroundColor: 'rgb(100 100 100)' }} className={classes.colorTesteAction} >
                                                            <SvgIcon component={alert} />
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>
                                                :
                                                <Tooltip
                                                    title={<Typography variant="subtitle1"><b>Visualizar paradas não identificadas</b></Typography>}
                                                >
                                                    <IconButton onClick={() => { setVerNaoIdentificada(true) }} style={{ marginTop: "15px" }}>
                                                        <Avatar variant="rounded" style={{ backgroundColor: '', }} className={classes.colorTesteAction} >
                                                            <SvgIcon component={alert} />
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </Box>
                                    </Box>
                                }
                                actions={
                                    <Grid
                                        container
                                        xs={12}
                                        lg={9}
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                        style={{
                                            marginRight: "50px"
                                        }}
                                    >
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {'Atualizado em ' + (time === '' ? '' : getFormatTimeHMToString(time))}
                                        </Typography>
                                    </Grid>
                                }
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
