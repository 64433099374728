import { BlobServiceClient } from '@azure/storage-blob';

const containerName = `importacao-notaspedidos`;
const sasToken = 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2124-02-10T04:51:22Z&st=2024-02-09T20:51:22Z&spr=https,http&sig=O4Ndv53tCh7oyUvKGUwOej8zmzUGb1wFj9PXxP8zfpk%3D';//process.env.REACT_APP_STORAGESASTOKEN;
const storageAccountName = 'accountprodblobgeral';//process.env.REACT_APP_STORAGERESOURCENAME;

const uploadFileToBlob = async (file) => {
	if (!file || !file.doc) return [];

	const blobService = new BlobServiceClient(
		`https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
	);

	const containerClient = blobService.getContainerClient(containerName);

	// upload file
	await createBlobInContainer(containerClient, file);
};

const createBlobInContainer = async (containerClient, file) => {
	const blobClient = containerClient.getBlockBlobClient(file.pasta + '/' + file.doc.name);

	const options = {
		blobHTTPHeaders: { blobContentType: file.doc.type },
		onProgress: file.log,
	};

	await blobClient.uploadData(file.doc, options);
}

export default uploadFileToBlob;