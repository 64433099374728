
import React from 'react';
import QRCode from "react-qr-code";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

export default function Aplicativo() {
    const [legenda, setLegenda] = React.useState('Copiar o link!');

    var link = 'https://play.google.com/store/apps/details?id=grafostech.app.grafosapp';

    return (
        <div style={{ background: 'white', padding: '16px' }}>
            <Typography variant="h3">Grafos App - Aplicativo do motorista</Typography>
            <br></br>
            <Typography variant="h5">Passa a passo para instalar o aplicativo para o motorista.</Typography>
            <br></br>
            <Typography variant="h6">1. Utilizando o celular do motorista, abra a câmera e aponte ela para scanear o QR Code abaixo.</Typography>
            <br></br>
            <QRCode value={link} />
            <br></br>
            <br></br>
            <Typography variant="h6">Caso não consiga ler o QR Code, utilizar o link abaixo. Encaminhar o link para o celular do motorista.</Typography>
            <Typography variant="h6">Link para baixar o aplicativo: <a href="url">{link}</a>  <Button variant='contained' color='primary' onClick={() => { navigator.clipboard.writeText(link); setLegenda('Copiado!'); }}>{legenda}</Button></Typography>
            <Typography variant="h6">Outra opção, é acessar o aplicativo Play Store, e procurar por "Grafos App" e instalar. Caso não encontre o aplicativo, filtrar por aplicativos "Novos" ou "Adicionados recentemente".</Typography>
            <br></br>
            <br></br>
            <Typography variant="h6">2. Após acessar o link ou QR code, será aberto a Play Store, na página de instalação do aplicativo. Clique em "Instalar" e aguarde finalizar a instalação.</Typography>
            <Typography variant="h6">3. Pronto! Aplicativo instalado e pronto para acessar. </Typography>
            <br></br>
            <br></br>
            <Typography variant="h6">Atenção: Faça o cadastro do motorista, caso ainda não tenha, para conseguir acessar o aplicativo. </Typography>
        </div>
    );
}