import { instance } from "./axios";

export async function reverse(lat, lng) {
    try {
        const response = await instance.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        if (error.response) {
            return error.response.data;
        }

        throw error;
    }
}