
import React, {useEffect} from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll } from '../../API/Entregas';
import { columnsGrid } from './Config';
import { EntregasEditRoute2 } from '../../Router/Routing';

export default function GridList(props) {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    async function loadRow()
    {
        var idRota;
        if (props.filter)
        {
            if (props.filter.rotaId)
                idRota = props.filter.rotaId;
        }
        setRows([]);
        setLoading(true);
        getAll(idRota).then((value) => {
            if (value != undefined)
                setRows(value);
            setLoading(false);
        });

        setColumns(columnsGrid);
    }
    useEffect(async () => {
        loadRow();
    }, [])
    
    return (
            <GridTable title="Entregas" titleNovo="Nova"
                rows={rows} 
                columns={columns} 
                LinkEdit={EntregasEditRoute2} 
                loading={loading} 
                CanRemove={false}/>
    );
}