import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { postPessoas, put, getById } from '../../API/Pessoas';
import { camposForm as camposF } from './Config';
import { getUser } from '../../Router/auth';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';

class FormConfig extends Component {

    constructor(props) {
        super(props);

        let l = false;
        try{
        let id = this.props.match.params.id;
        
        if (id)
            l = true;
        }catch( e ){}

        this.state = { loading: l, loadingField: true, values: {}, camposForm: camposF };
    }

    create = async values => {
        
        // Ajustes no contrato
        if (values.cpfCnpj !== undefined)
        {
            if (values.cpfCnpj.length <= 11)
                values.cpf = values.cpfCnpj;
            else
                values.cnpj = values.cpfCnpj;
        }

        values.endereco = {};
        values.endereco.logradouro = values.logradouro;
        values.endereco.numero = values.numero;
        values.endereco.bairro = values.bairro;
        values.endereco.nomeMunicipio = values.nomeMunicipio;
        values.endereco.sigaUF = values.sigaUF;
        values.endereco.cep = values.cep;
        values.endereco.complemento = values.complemento;
        values.endereco.latitude = values.latitude;
        values.endereco.longitude = values.longitude;
        
        var retorno = await postPessoas(values);
        
        if (retorno.result === 'ok') {
           this.props.openSnackbar('Criado com sucesso!');
           //this.props.history.replace(EntregasEditRoute + retorno.data);
           if (this.props.returnId)
                this.props.returnId(retorno.data.id)
        } else {
            this.props.openSnackbar('Erro ao criar! Cadastro não criado.', 'error');
        }
    }
    update = async values => {
        
        var id = this.props.match.params.id;
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.props.openSnackbar('Atualizado com sucesso!');
        } else {
            this.props.openSnackbar('Erro ao atualizar! Cadastro não atualizado.', 'error');
        }
    }

    componentDidMount() {
        let id;
        try{
            id = this.props.match.params.id;
        }catch{}

        if (id) {
            getById(id).then(async (value) => {
                
                if (value !== undefined)
                {
                    this.setState({ values: value });
                }
                this.setState({ loading: false });
            }).catch((e) => {
                throw new Error('Registro não encontrado!');
            });
        } else {
            this.state.values.tipo = 1;
            this.state.values.empresaId = getUser().empresaId;
            this.setState({ values: this.state.values });
            this.setState({ loadingField: false });
        }
    }

    render() {
        
        let id;
        try{
            id = this.props.match.params.id;
        }catch{}
        if (id) {
            return (
                <div>
       
                    <Form title="Editar" form="destinatarioEdit"  fields={this.state.camposForm} onSubmit={this.update} CanBack={false} initialValues={this.state.values} loading={this.state.loading || this.state.loadingField} />                    
             
                </div>
            );
        } else {
           
            return (
                <Form title="Destinatário" form="destinatarioForm"  fields={this.state.camposForm} onSubmit={this.create}  CanBack={false} initialValues={this.state.values} loading={this.state.loadingField} >
                </Form>
            );
        }
    }
}

class FormSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <FormConfig openSnackbar={openSnackbar} {...this.props} >
                    </FormConfig>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default FormSnackBar;