import { createContext, useState } from "react";

export const BuscaCompletaContext = createContext();

export const BuscaCompletaProvider = ({children}) => {
    const [moreInfo, setMoreInfo] = useState(null);
    const [podeCarregarMais, setPodeCarregarMais] = useState(true);
    const [isLoadingCarregarMais, setIsLoadingCarregarMais] = useState(false);
    
    const [isLoading, setIsLoading] = useState({
        motorista: false,
        regiao: false,
        evidencias: false
    });

    return (
        <BuscaCompletaContext.Provider
            value={{
                moreInfo, setMoreInfo,
                isLoading, setIsLoading,
                podeCarregarMais, setPodeCarregarMais,
                isLoadingCarregarMais, setIsLoadingCarregarMais
            }}
        >
            {children}
        </BuscaCompletaContext.Provider>
    )
}