export const fields = [
    {
        label: "Logradouro",
        field: "logradouro",
        disabled: true,
        xs: 12,
        sm: 5,
        md: 5,
        lg: 5,
        xl: 5
    },
    {
        label: "Número",
        field: "numero",
        disabled: true,
        xs: 12,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2
    },
    {
        label: "Bairro",
        field: "bairro",
        disabled: true,
        xs: 12,
        sm: 5,
        md: 5,
        lg: 5,
        xl: 5
    },
    {
        label: "Municipio",
        field: "nomeMunicipio",
        disabled: true,
        xs: 12,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4
    },
    {
        label: "CEP",
        field: "cep",
        disabled: true,
        xs: 12,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2
    },
    {
        label: "Estado",
        field: "sigaUF",
        disabled: true,
        xs: 12,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2
    }
]
