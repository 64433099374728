import { useEffect, useState } from "react"
import { Alert, AlertTitle } from '@material-ui/lab';
import "./MensagemRetorno.css";

const CodigoRetorno = {
    Sucesso: 0,
    Erro: 1,
    Aviso: 2
}

export const MensagemRetorno = ({ consulta }) => {
    const [alertSeverity, setAlertSeverity] = useState(null);
    //const { consulta, setMostrarErro } = useContext(NFContext);

    useEffect(() => {
        switch (consulta.code) {
            case CodigoRetorno.Sucesso:
                setAlertSeverity({ Title: "NOTA ADICIONADA COM SUCESSO!", Severity: "success" });
                //setMostrarErro(false);
                break;
            case CodigoRetorno.Erro:
                setAlertSeverity({ Title: "ERRO", Severity: "error" });
                //setMostrarErro(true);
                break;
            case CodigoRetorno.Aviso:
                setAlertSeverity({ Title: "AVISO", Severity: "warning" });
                //setMostrarErro(true);
                break;

            default:
                setAlertSeverity({ Title: "NOTA ADICIONADA COM SUCESSO!", Severity: "success" });
                //setMostrarErro(false);
                break;
        }
    }, [consulta]);

    return (
        <>
            {alertSeverity != null ? (
                <Alert severity={alertSeverity.Severity} className={alertSeverity.Severity}>
                    <AlertTitle><strong className="alert-text">{alertSeverity.Title}</strong></AlertTitle>
                    <span className="alert-text">{consulta.message}</span>
                </Alert>
            ) : <></>}
        </>
    )
}
