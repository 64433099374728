import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { update } from '../../../API/ParadaPlanejadas';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import { vermelhoPastel } from '../../../Components/Map/MarkerColors';
import Button from '../../../Components/Button/Button';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

class ChipMultiplosEnderecos extends Component {

    constructor(props) {
        super(props);

        this.state = { open: false, loading: true, enderecos: [] };
    }

    handleClose2 = () => {
        this.setState({ open: false });
    }
    title = (text, text2) => {
        return <div >
            <Typography variant="subtitle1"><b>{text}</b></Typography>
            <Typography variant="body2">{text2}</Typography>
        </div>
    }

    render() {

        return <>
            <Tooltip title={this.title(this.props.title)}><Chip disabled={this.props.desabilitado} style={{ marginLeft: '5px', marginBottom:'5px', ...this.props.style }} onDelete={(this.props.comDialog? () => { this.setState({ open: true })} : null) } icon={this.props.icon} label={this.props.label} /></Tooltip>
            {this.props.comDialog ?
                <SimpleDialog open={this.state.open} onClose={this.handleClose2} paradaPlanejada={this.props.paradaPlanejada} campo={this.props.campo} />
            :<></>}
        </>
            ;
    }
}
export default ChipMultiplosEnderecos;

export function SimpleDialog(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { onClose, selectedValue, open, enderecos } = props;

    const salvar = () => {
        setLoading(true);
        setError('');

        var parada = props.paradaPlanejada;
        if (props.campo == 'janela') {
            parada.janelaAgendamentoInicio = '0001-01-01T00:00:00';
            parada.janelaAgendamentoFim = '0001-01-01T00:00:00';
        }
        else
            parada[props.campo] = false;

        update(parada.id, parada).then(e => {
            setLoading(false);
            handleClose();
            props.paradaPlanejada.atualizarLista();

        }).catch(e => {
            console.log(e);
            setError('Erro ao salvar. Tente novamente');
            setLoading(false);
        });
    }
    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
            <DialogTitle id="simple-dialog-title">Deseja remover?</DialogTitle>
            <Typography variant="subtitle1" style={{ marginLeft: '20px', marginRight: '10px' }}>Ao remover, a rota será roteirizada novamente.</Typography>
            {error ?
                <div style={{ color: vermelhoPastel }}>
                    <Typography variant="h6" align="center"><ErrorIcon style={{ marginLeft: '20px', marginRight: '10px' }}></ErrorIcon>{error}</Typography>
                </div>
                : <></>}
            <DialogContent>
                <Grid container spacing={2}>

                    <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center">

                        <Button variant="contained" color="primary" onClick={salvar} loading={loading}>
                            remover
                        </Button>
                        <Button style={{ marginLeft: '11px' }} variant="outlined" color="primary" onClick={handleClose} >
                            Fechar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};