import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { getById } from '../../API/DocumentoImportar';
import {camposForm} from './Config';

class FormConfig extends Component {
    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l=true;
        this.state = { alertSucess: false, loading: l, values:{}, camposForm: camposForm };
    }

    async componentDidMount() {
        let id = this.props.match.params.id;
        
        if (id)
        {
            getById(id).then((value) => {
                if (value !== undefined)
                {
                    this.setState({ values: value });
                }
                this.setState({ loading: false });
            });
        } 
        this.setState({ camposForm: camposForm });
    }

    render() {
        let id = this.props.match.params.id;
        if (id)
        {
            return (
                <Form title="Arquivo para importar" 
                form="editDocumento" 
                fields={this.state.camposForm} 
                CanBack={true} 
                CanNotSave={true} 
                CanOnlyBack={true}
                initialValues={this.state.values} 
                loading={this.state.loading} />
                
            );
        }
        return <></>
    }
}
export default FormConfig