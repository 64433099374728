
import React, {useEffect} from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAllByIdByPessoa, deleteById } from '../../API/Enderecos';

import { EnderecosNewRoute, EnderecosEditRoute } from '../../Router/Routing';
import { columnsGrid } from './Config';

export default function GridList(props) {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    async function loadRow()
    {
        setRows([]);
        setLoading(true);

        getAllByIdByPessoa(props.filter.pessoaId).then(async (value) => {
            if (value !== undefined)
            {
                setRows(value);
            }
            setLoading(false);
        });



        setColumns(columnsGrid);

    }
    useEffect(() => {
        loadRow();
    }, [])

    async function filtrar(){
        await loadRow();
    }
    
    async function excluirClick(ids) {
        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            await deleteById(id);
        }
        loadRow();
    }
    
    return (
            <GridTable title="Endereços" titleNovo="Novo"
                rows={rows} 
                columns={columns}  
                LinkEdit={EnderecosEditRoute} 
                excluirClick={excluirClick}
                LinkNew={EnderecosNewRoute+'?pessoaId='+props.filter.pessoaId} 
                loading={loading} 
                TemFiltro={false}
                atualizar={filtrar}/>
    );
}