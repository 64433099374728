import React from 'react'
import { useState,useEffect, useCallback } from "react";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import {vermelhoPastel, verdePastel} from '../../Components/Map/MarkerColors';
import AddIcon from '@material-ui/icons/AddBox';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import throttle from 'lodash/throttle';
import debounce from 'lodash.debounce';
import { post, put, deleteById, getByPessoaId } from '../../API/JanelaHorario';
import { getUser } from '../../Router/auth';
import { elementTypeAcceptingRef } from '@mui/utils';
import ButtonCustom from '../../Components/Button/Button';

export const JanelasEntregasCliente = (props) => {

    const [lista, setLista] = useState([]);
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        (async () => {
            setLoaded(false);
            getByPessoaId(props.clienteId).then(async (value) => {
                
                if (value != undefined) {
                    var l = [...lista];
                    value.forEach(element => {
                        var inicioDate = addZero(new Date(element.inicio).getHours())+':'+addZero(new Date(element.inicio).getMinutes());
                        var fimDate = addZero(new Date(element.fim).getHours())+':'+addZero(new Date(element.fim).getMinutes());
                        
                        var r = l.find(x=> x.inicioDate == inicioDate && x.fimDate == fimDate);
                        if (r){
                            r.selected[element.diaDaSemana] = true;
                            r.ids[element.diaDaSemana] = element.id;
                        }else{
                            element.ids = [-1, -1, -1, -1, -1, -1, -1];
                            element.selected = [false, false, false, false, false, false, false];
                            element.selected[element.diaDaSemana] = true;
                            element.ids[element.diaDaSemana] = element.id;
                            element.inicioDate = inicioDate;
                            element.fimDate = fimDate;
                            element.pessoaId = props.clienteId;
                            
                            l.push(element);
                        }
                    });
                    if (l.length == 0){
                        l.push({
                            ids: [-1, -1, -1, -1, -1, -1, -1],
                            selected: [false, true, true, true, true, true, false],
                            inicioDate: "00:00",
                            fimDate:"00:00",
                            pessoaId: props.clienteId
                        });
                    }
                    setLista(l);
                }
                setLoaded(true);
            });

        })();
    }, []);

    var addZero = (value) => {
        if (value < 10)
            return '0'+value;
        else 
            return value+'';
    }

    var adicionar = () => {
        var l = [...lista];
        l.push({
            ids: [-1, -1, -1, -1, -1, -1, -1],
            selected: [false, true, true, true, true, true, false],
            inicioDate: "00:00",
            fimDate:"00:00",
            pessoaId: props.clienteId
        });
        setLista(l);
    }
    var remover = (index) => {
        var l = [...lista];
        l[index].removido = true;
        setLista(l);
    }

    return (
        <>
            <Typography style={{ paddingLeft: '10px', paddingTop: '10px', flex: '1 1 100%', marginBottom: '10px' }} variant="h5" component="div">
                Selecione os dias e horário para cada janela
            </Typography>
            {!loaded?  <>
                Carregando...<br />
            </> :
            <>
                <>
                    {lista.map((x,i) => { return React.createElement(JanelaEntrega, { janelas: x, remover: remover, index: i} ); })}
                </>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} style={{ width: '220px', height: '40px' }} onClick={adicionar}>
                nova janela
                </Button>
            </>}
        </>
    );
}

const JanelaEntrega = (props) => {
    const lista = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    const [selected, setSelected] = useState(props.janelas.selected);
    const [ids, setIds] = useState(props.janelas.ids);
    const [inicial, setInicial] = useState(props.janelas.inicioDate);
    const [final, setFinal] = useState(props.janelas.fimDate);
    const [erro, setErro] = useState('');
    const [salvando, setSalvando] = useState(false);
    const [salvo, setSalvo] = useState(false);
    const [removendo, setRemovendo] = useState(false);

    const changeDia = (index) => {
        const t = [...selected];
        t[index] = !t[index];
        setSelected(t);
        verificarCampos(t, inicial, final);
    }
    const changeInicial = (i) => {
        verificarCampos(selected, i, final);
    }
    const changeFinal = (f) => {
        verificarCampos(selected, inicial, f);
    }
    

    const verificarCampos = (dias, i, f)=>{
        var e = '';
        setSalvando(true);
        setSalvo(false);
        if (dias.indexOf(true) < 0)
            e = 'Precisa selecionar pelo menos um dia da semana.';
        if (i == '')
            e = 'Hora inicial incorreta.';
        if (f == '')
            e = 'Hora final incorreta.';
        if (Date.parse('01/01/2011 '+i+':00') >= Date.parse('01/01/2011 '+f+':00'))  
            e = 'Hora inicial precisa ser menor que a Hora final';

        setErro(e);
        if (e == '')
            debouncedSave(dias, i, f);
        else
            setSalvando(false);
    }
    const debouncedSave = useCallback(
		debounce((dias, i, f) => salvar(dias, i, f), 1500),
		[], 
	);

    const removerJanelas = async ()=> {
        setRemovendo(true);
        setSalvando(true);
        var e = false;

        for (let index = 0; index < lista.length; index++) {
                // remover
                if (ids[index] != -1)
                {
                    try{
                        await deleteById(ids[index]);
                        ids[index] = -1;
                        setIds(ids);
                    }catch(err){
                        e = true;
                    }
                }
        }

        if (e)
        {
            setErro('Erro ao salvar a janela. Tente novamente.');
            setSalvando(false);
        }else{
            setSalvando(false);
            setSalvo(true);
        }
        setRemovendo(false);
        props.remover(props.index);
    }
    const salvar =  async (dias, i, f)  => {
        setSalvando(true);
        var e = false;

        for (let index = 0; index < dias.length; index++) {
            const element = dias[index];
            if (element){
               // criar ou alterar
               if (ids[index] == -1)
               {
                    try{
                        var result = await post({
                            diaDaSemana: index,
                            fim: "0001-01-01T"+f+":00",
                            inicio: "0001-01-01T"+i+":00",
                            pessoaId: props.janelas.pessoaId,
                            empresaId: getUser().empresaId
                        });
                    
                        if (result.result == 'ok')
                        {
                            ids[index] = result.data.id;
                            setIds(ids);
                        }else

                            e = true;
                    }catch(err){
                        e = true;
                        console.log(err);
                    }
                }else{
                    try{
                        var result = await put(ids[index], {
                            id: ids[index],
                            diaDaSemana: index,
                            fim: "0001-01-01T"+f+":00",
                            inicio: "0001-01-01T"+i+":00",
                            pessoaId: props.janelas.pessoaId,
                            empresaId: getUser().empresaId
                        });
                    
                   
                        if (result.result == 'ok')
                        {
                            ids[index] = result.data.id;
                            setIds(ids);
                        }else
                            e = true;
                    }catch(err){
                        e = true;
                        console.log(err);
                    }
                }
            }
            else{
                // remover
                if (ids[index] != -1)
                {
                    try{
                        await deleteById(ids[index]);
                        ids[index] = -1;
                        setIds(ids);
                    }catch(err){
                        e = true;
                        console.log(err);
                    }
                }
            }
        }

        if (e)
        {
            setErro('Erro ao salvar a janela. Tente novamente.');
            setSalvando(false);
        }else{
            setSalvando(false);
            setSalvo(true);
        }
    }

    return (<>
    {props.janelas.removido ? <></>:
        
            <Card variant="outlined" style={{ marginBottom: '10px' }}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} sm={4} justifyContent="center" alignItems="center" style={{marginLeft:'20px'}}>

                            {lista.map((item, index) => {
                                if (selected[index]) {
                                    return <Button variant="contained" x={selected[index]} onClick={(x) => changeDia(index)} style={{ background: verdePastel, color: 'white', borderRadius: '250px', marginLeft: '12px' }}>
                                        {item}
                                    </Button>
                                } else {
                                    return <Button variant="contained" x={selected[index]} onClick={(x) => changeDia(index)} style={{ background: '#f5f5f5', borderRadius: '250px', marginLeft: '12px' }}>
                                        {item}
                                    </Button>
                                }
                            })}
                        </Grid>

                        <Grid item xs={12} sm={1}>
                            <TextField label={"Hora inicial"} error={(inicial == '')} variant="outlined" type="time" value={inicial} onChange={(c)=> { setInicial(c.target.value); changeInicial(c.target.value) }} fullWidth>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <TextField label={"Hora final"} error={(final == '')} variant="outlined" type="time" value={final} onChange={(c)=> { setFinal(c.target.value); changeFinal(c.target.value)}} fullWidth>
                            </TextField>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" direction="row" xs={12} sm={4} style={{marginLeft:'10px'}}  >
                             {erro? 
                                <div style={{color: vermelhoPastel}}>
                                    <Typography variant="subtitle1"><ErrorIcon style={{marginLeft:'20px', marginRight:'10px'}}></ErrorIcon>{erro}</Typography>
                                </div> : <></>}
                             {salvando? 
                                <div style={{color: 'rgb(3, 169, 244)'}}>
                                    <Typography variant="subtitle1"><AutorenewIcon className="fa fa-refresh fa-spin" style={{color: 'rgb(3, 169, 244)', marginLeft:'20px', marginRight:'10px'}}/>Salvando... Aguarde!</Typography>
                                </div> : <></>}

                                {salvo? 
                                <div style={{color: verdePastel}}>  
                                <Typography variant="subtitle1"><DoneAllIcon style={{marginLeft:'20px', marginRight:'10px'}}/>Janela salva com sucesso</Typography>
                            </div> : <></>}
                        </Grid>

                        <Grid item container xs={12} sm={1} alignItems="center" justifyContent="center">

                            <ButtonCustom style={{ color: vermelhoPastel }} styleloading={{ color: vermelhoPastel }} startIcon={<DeleteIcon />} loading={removendo} onClick={removerJanelas} >Remover janela</ButtonCustom>
                             
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        }</>
    );
}
