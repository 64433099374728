
import React, { useEffect } from 'react';
import MapContainer from '../../Maps/MapContainer';
import MapContainerLeft from '../../Maps/MapContainerLeft';

import Grid from '@material-ui/core/Grid';
//import Button from '@material-ui/core/Button';
import Button from '../../Components/Button/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getById as getByIdVeiculo } from '../../API/Veiculos';
import { getById as getByIdRota } from '../../API/Rotas';
import { getEntregasPendentes, addEntregasInRota, getPesoTotal, getAll } from '../../API/Entregas';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import MapSelectPoint from '../../Components/Map/MapSelectPoint';
import { useHistory } from "react-router-dom";
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import ProgressBar from "@ramonak/react-progress-bar";
import { verdePastel, vermelhoPastel } from '../../Components/Map/MarkerColors';

import {
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    rootChech: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    root: {
        height: '100vh',
    },
    buttonProgress: {
        color: 'black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgress2: {
        color: 'black',
        position: 'absolute',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        height: '100%',
        //margin: theme.spacing(1),
        position: 'relative',
    },
}));

export default function VeiculoMaps(props) {
    const classes = useStyles();

    const [idsSelecionados, setidsSelecionados] = React.useState([]);
    const [tamanho, setTamnho] = React.useState(0);
    const [loadingPeso, setLoadingPeso] = React.useState(false);
    const [inicioDate, setInicioDate] = React.useState(new Date());
    const [fimDate, setFimDate] = React.useState(new Date());
    const [tamanhoIcone, setTamanhoIcone] = React.useState(30);
    const [entregas, setEntregas] = React.useState([]);
    const [loadingAdd, setLoadingAdd] = React.useState(false);
    const [capacidadeDeCargaQuilos, setCapacidadeDeCargaQuilos] = React.useState();
    const [totalPesoEntregas, setTotalPesoEntregas] = React.useState(0);
    const [totalPesoEntregasJaCarregado, setTotalPesoEntregasJaCarregado] = React.useState(0);
    const [loadingEntregas, setLoadingEntregas] = React.useState(false);

    useEffect(() => {

        try {
            setFimDate(fimDate.setHours(23));
            setFimDate(fimDate.setMinutes(57));
        } catch (e) { }

        try {
            setInicioDate(inicioDate.setHours(0));
            setInicioDate(inicioDate.setMinutes(0));
            setInicioDate(inicioDate.setSeconds(0));
        } catch (e) { }
        atualizarMapa();

        atualizarVeiculo();

    }, []);


    let history = useHistory();

    const atualizarVeiculo = (event) => {
        getByIdRota(props.match.params.id).then((value) => {
            getByIdVeiculo(value.veiculoId).then((valueV) => {
                setCapacidadeDeCargaQuilos(valueV.capacidadeDeCargaQuilos);
            }).catch((e) => {
                console.log('Erro ao buscar veiculo');
                console.log(e);
            });

        }).catch((e) => {
            console.log('Erro ao buscar rota');
            console.log(e);
        });

        getAll(props.match.params.id).then(async (value) => {
            var ids = value.map(x => x.id);
            var total = await getPesoTotal(ids);
            setTotalPesoEntregasJaCarregado(total.data)

        }).catch((e) => {
            console.log('Erro ao buscar entregas da rota');
            console.log(e);
        });
    }

    const setCaculaPeso = async (ids) => {
        setLoadingPeso(true);
        var total = await getPesoTotal(ids);
        setTotalPesoEntregas(total.data)

        setLoadingPeso(false);
    }

    const atualizarMapa = (event) => {

        if (fimDate == null || inicioDate == null)
            alert('Precisa selecionar uma data!');
        else {

            setLoadingEntregas(true);

            setEntregas([]);


            getEntregasPendentes(inicioDate, fimDate).then((value) => {
                setEntregas(value);
                setLoadingEntregas(false);
            }).catch((e) => {
                setLoadingEntregas(false);
            });


        }
    };

    async function addEntregaByRota(ids, openSnackbar) {

        try {
            setLoadingAdd(true);
            var idRota = props.match.params.id;

            var retorno = await addEntregasInRota(idRota, ids);
            if (retorno.result == 'ok') {
                openSnackbar('Entregas adicionadas na rota!');
                history.goBack();
            } else {
                openSnackbar('Erro ao adicionar na rota! Recarrega a tela e tente novamente!', 'error');
                setLoadingAdd(false);
                throw true;
            }

            setLoadingAdd(false);
        }
        catch (e) {
            openSnackbar('Erro ao adicionar na rota! Tente novamente!', 'error');
            setLoadingAdd(false);
        }
    }

    function round(num) {
        var m = Number((Math.abs(num) * 100).toPrecision(15));
        var r = Math.round(m) / 100 * Math.sign(num)
        return r.toString().replace('.', ',');
    }

    return (
        <Grid component="main" className={classes.root}>

            <Grid container spacing={3}>
                <Grid item xs={12} lg={12} style={{ alignSelf: "center" }}>
                    <Typography style={{ paddingLeft: '10px', paddingTop: '10px', flex: '1 1 100%' }} variant="h5" component="div">
                        <b> Filtre e selecione as entregas pelo mapa para adicionar na rota</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <KeyboardDateTimePicker
                        label="De"
                        format="dd/MM/yyyy HH:mm"
                        value={inicioDate}
                        onChange={setInicioDate}
                        inputVariant="outlined"
                        showTodayButton
                        invalidDateMessage='Data invalida'
                        todayLabel='Hoje'
                        cancelLabel='Cancelar'
                        variant='inline'
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <KeyboardDateTimePicker
                        label="Até"
                        format="dd/MM/yyyy HH:mm"
                        value={fimDate}
                        onChange={setFimDate}
                        inputVariant="outlined"
                        showTodayButton
                        invalidDateMessage='Data invalida'
                        todayLabel='Hoje'
                        cancelLabel='Cancelar'
                        variant='inline'
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} lg={1}>
                    <div className={classes.wrapper}>
                        <Button variant="contained" color="primary" loading={loadingEntregas} disabled={loadingEntregas} style={{ width: '100%', height: '100%' }} onClick={atualizarMapa}>
                            filtrar
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} lg={3} style={{ textAlign: "center", alignSelf: "center" }}>
                    {!capacidadeDeCargaQuilos ? <></> :
                        (loadingPeso ? <CircularProgress size={24} className={classes.buttonProgress2} /> :
                            (capacidadeDeCargaQuilos >= (totalPesoEntregas + totalPesoEntregasJaCarregado) ?
                                <>

                                    <Typography variant="h6" style={{ color: verdePastel }}>  Disponível: <b>{round(capacidadeDeCargaQuilos - (totalPesoEntregas + totalPesoEntregasJaCarregado))} Kg</b></Typography>
                                    <div style={{ marginLeft: '25%', marginRight: "25%" }}>
                                        <ProgressBar completed={(totalPesoEntregas + totalPesoEntregasJaCarregado)} maxCompleted={capacidadeDeCargaQuilos} customLabel={(Math.round(((totalPesoEntregas + totalPesoEntregasJaCarregado) / capacidadeDeCargaQuilos) * 100)) + " %"} height='20px' bgColor={verdePastel} labelSize="15px"></ProgressBar>
                                    </div>

                                </>
                                :
                                <>
                                    <Typography variant="h6" style={{ color: vermelhoPastel }}>  Excedido: <b>{round((totalPesoEntregas + totalPesoEntregasJaCarregado) - capacidadeDeCargaQuilos)} Kg</b></Typography>
                                    <div style={{ marginLeft: '25%', marginRight: "25%" }}>
                                        <ProgressBar completed={(totalPesoEntregas + totalPesoEntregasJaCarregado)} maxCompleted={capacidadeDeCargaQuilos} customLabel={(Math.round(((totalPesoEntregas + totalPesoEntregasJaCarregado) / capacidadeDeCargaQuilos) * 100)) + " %"} height='20px' bgColor={vermelhoPastel} labelSize="15px"></ProgressBar>
                                    </div>
                                </>)
                        )
                    }
                </Grid>
                <Grid item xs={12} lg={2} style={{ textAlign: "center", alignSelf: "center" }}>
                    {tamanho > 0 ? tamanho == 1 ?
                        <Typography variant="h6">  {tamanho} entrega selecionada</Typography> : <Typography variant="h6">  {tamanho} entregas selecionadas</Typography>
                        : <> </>}

                </Grid>
                <Grid item xs={12} lg={2}>
                    {tamanho > 0 ?
                        <Tooltip title="Adicionar entregas na rota" style={{ width: '100%', height: '100%' }}>
                            <SharedSnackbarConsumer>
                                {({ openSnackbar }) => (
                                    <Tooltip title="Adicionar entregas na rota">
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <Button variant="contained" color="primary" style={{ width: '100%', height: '100%' }} onClick={() => { addEntregaByRota(idsSelecionados, openSnackbar) }} loading={loadingAdd}>
                                                Adicionar na rota
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )}
                            </SharedSnackbarConsumer>

                        </Tooltip> : <></>}
                </Grid>
                <Grid xs={12} style={{ paddingLeft: '10px' }}>
                    <Typography variant="h7"> Total de entregas encontradas: {entregas.length}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MapSelectPoint entregas={entregas} tamanhoIcon={tamanhoIcone} idsSelecionados={idsSelecionados} setIdsSelecionado={(ids) => { setTamnho(ids.length); setidsSelecionados(ids); setCaculaPeso(ids); }}>

                    </MapSelectPoint>
                </Grid>
            </Grid>
        </Grid >
    );
}
