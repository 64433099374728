import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { LinkParaRota } from '../../../Utils/LinkParaRota';

export default function DenseTable(props) {
	return (
		<TableContainer component={Paper} style={{ marginTop: '20px' }}>
			<Table size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell>Arquivos</TableCell>
						<TableCell align="center">Situação</TableCell>
						<TableCell align="center">Observação</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.list.map((row) => {
						var style = {};
						if (row.situacao === undefined) {
							style = { backgroundColor: 'rgb(3, 169, 244)', color: 'white' }
							row.situacao = "Aguardando";
						}
						else if (row.situacao === "Carregado" || row.situacao === "Carregado - 100%" || row.situacao === "Importado")
							style = { backgroundColor: 'rgb(92 182 96)', color: 'white' };
						else if (row.situacao === "Atenção")
							style = { backgroundColor: '#fd7826', color: 'white' };
						else if (row.situacao === "Erro")
							style = { backgroundColor: 'rgb(255 152 0)', color: 'white' };
            
						return <TableRow key={row.name}>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell align="center"><Chip label={row.situacao} color="" style={style}/></TableCell>
							<TableCell align="center">{row.observacao} <LinkParaRota observacao={row.observacao} /></TableCell>
						</TableRow>;

					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
