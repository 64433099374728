
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
import { ReactComponent as localPlanejado_3 } from '../../src/img/icon/localPlanejado_4.svg';

const useStyles = makeStyles((theme) => ({
    
    colorTeste: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '10px'
    },
}));

export default function LocalPlanejadoAvatar(props) {
    const classes = useStyles();

    var style = {};
    if (props.color === 'green')
        style = { backgroundColor: 'rgb(92 182 96)' };
    if (props.color === 'red')
        style = { backgroundColor: 'rgb(245 84 72)' };

    return (
        <Avatar variant="rounded" style={style} className={classes.colorTeste} onClick={props.onClick}>
            <SvgIcon component={localPlanejado_3} />
        </Avatar>
    );
}
