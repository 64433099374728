import { instance } from './axios'

export async function getAll(rotaId = '-1', entregaId = '-1', paradaPlanejadaId = '-1', ids = []) {
	try {

		var parametro = 'rotaId=' + rotaId + '&entregaId=' + entregaId + '&paradaPlanejadaId=' + paradaPlanejadaId;

		const response = await instance.post('/api/Evidencias/getAll?' + parametro, ids);
		if (response.status ===  200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getById(id) {
	try {
		const response = await instance.get('/api/Evidencias/' + id);
		if (response.status ===  200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
		throw error;
	}
}