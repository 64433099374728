import Chip from '@material-ui/core/Chip';
import { saveAs } from "file-saver";
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import * as ColorGrafos from "../../Utils/ColorsGrafos";
import { LinkParaRotaFunction } from '../../Utils/LinkParaRota';

export const camposForm = [
    { name: "nomeArquivo", label: "Arquivo", type: "text", size: 5, disabled: true },
    {
        name: "situacao", label: "Situação", type: "option", size: 3, disabled: true, options:
            [
                { value: 0, label: 'Aguardando processamento' },
                { value: 1, label: 'Em processamento' },
                { value: 2, label: 'Processado com sucesso' },
                { value: 3, label: 'Erro' },
                { value: 4, label: 'Processado com sucesso, com observação' }
            ]
    },
    { name: "situacaoDescricao", label: "Observação", type: "text", size: 3, disabled: true },
    { name: "qtdCadastroImportado", label: "Registros importados", type: "text", size: 2, disabled: true },
    { name: "qtdCadastroTotal", label: "Total de registros", type: "text", size: 2, disabled: true },
    { name: "criadoEm", label: "Data de criação", type: "datetime", size: 2, disabled: true },
    { name: "ImportacaoIniciada", label: "Importação iniciada", type: "datetime", size: 2, disabled: true },
    { name: "importacaoFinalizada", label: "Importação finalizada", type: "datetime", size: 2, disabled: true },
    { name: "resultado", label: "Observação", type: "text", multiline: true, rowsMax: 20, size: 12, disabled: true },
];

export const columnsGrid = [
    {
        label: 'Arquivo',
        field: 'nomeArquivo',
        padding: 'default'
    },
    {
        label: 'Situação',
        field: 'situacao',
        width: '150px',
        type: 'customWithRow',
        padding: 'default',
        function: SituacaoDocumento
    },
    {
        label: "Observação",
        field: "situacaoDescricao",
        type: 'customWithRow',
        function: SituacaoDescricao
    },
    {
        label: 'Registros importados',
        field: 'qtdCadastroImportado',
        type: 'customWithRow',
        padding: 'default',
        function: RegistrosImportados
    },
    {
        label: 'Data de criação',
        field: 'criadoEm',
        padding: 'default',
        type: "datetime"
    },
    {
        label: 'Importação finalizada',
        field: 'importacaoFinalizada',
        padding: 'default',
        type: "datetime"
    },
    { label: 'Download', field: 'acoes', type: 'customWithRow', padding: 'default', function: Download, width: '100px', align: "center" }
];

function Download(row) {
    var localQuebrado = row.localAzure.split("/");
    var localAzure = localQuebrado[0] + "/" + localQuebrado[1] + "/" + row.id + '/' + localQuebrado[2];

    return <Tooltip title={"Download"}><IconButton aria-label="delete" size="small" >
        <MapIcon onClick={() => {
            let url = "https://accountprodblobgeral.blob.core.windows.net/importacao-notaspedidos/" + localAzure + "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwlacupiytfx&se=2124-02-10T02:31:27Z&st=2024-02-09T18:31:27Z&spr=https&sig=R6Y4MGUYENPJsSCAtmEj3o8SMbNhE0yCFL%2BTjA90arU%3D"
            saveAs(url, row.nomeArquivo);
        }} />

    </IconButton></Tooltip>
}

function RegistrosImportados(row) {
    return row.qtdCadastroImportado + ' de ' + row.qtdCadastroTotal + ' registros';
}

function SituacaoDocumento(row) {
    let backgroundColor;
    let label;

    switch (row.situacao) {
        case SituacaoDocumentoImportar.AguardandoImportacao:
            backgroundColor = ColorGrafos.AZUL_PASTEL;
            label = "Aguardando processamento";
            break;
        case SituacaoDocumentoImportar.EmImportacao:
            backgroundColor = ColorGrafos.AZUL_PASTEL;
            label = "Em processamento";
            break;
        case SituacaoDocumentoImportar.ImportadoSucesso:
            backgroundColor = ColorGrafos.VERDE_PASTEL;
            label = "Processado com sucesso";
            break;
        case SituacaoDocumentoImportar.Erro:
            backgroundColor = ColorGrafos.VERMELHO_PASTEL;
            label = "Erro ao processar";
            break;
        case SituacaoDocumentoImportar.ImportadoSucessoComObservacao:
            backgroundColor = ColorGrafos.LARANJA_DA_GRAFOS;
            label = "Processado com sucesso, com observação";
            break;
        default:
            backgroundColor = ColorGrafos.AZUL_PASTEL;
            label = row.situacao;
            break;
    }

    return (
        <Chip
            variant="default"
            label={label}
            style={{
                backgroundColor: backgroundColor,
                color: ColorGrafos.WHITE,
                fontSize: '14px',
                padding: '12px',
                letterSpacing: '1px'
            }}
        />
    )
}

function SituacaoDescricao(row) {
    return (
        <>
            {row.situacaoDescricao} {LinkParaRotaFunction(row)}
        </>
    )
}

const SituacaoDocumentoImportar = {
    AguardandoImportacao: 0,
    EmImportacao: 1,
    ImportadoSucesso: 2,
    Erro: 3,
    ImportadoSucessoComObservacao: 4
}