import { Chip } from "@mui/material";
import { EnderecosEditRoute } from "../../../Router/Routing";

export const ChipEditarEndereco = ({ pessoaEnderecoId, rotaId, history, ...props }) => {
    return (
        <Chip
            {...props}
            onClick={() => {
                history.push(`${EnderecosEditRoute}${pessoaEnderecoId}?rotaReroteirizar=${rotaId}`);
            }}
        />
    )
}