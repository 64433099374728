import React from 'react';
import { useState, useEffect, useCallback} from 'react';
import { instance } from '../../API/axios';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import debounce from "lodash.debounce";
import { getUser } from "../../Router/auth";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiplaSelecao(props) {
  const [opcoes, setOpcoes] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [idsLocalStorage, setIdsLocalStorage] = useState(getIdsLocalStorage);
  const [placeholder, setPlaceholder] = useState(props.placeholder);

  var filtroApi = {
    filtro: "",
    page: 0,
    maxRegistro: 50,
    empresaId: getUser().empresaId,
    ids: []
  }

  function getIdsLocalStorage(){
    const textoArmazenamentoLocal = localStorage.getItem(props.localStorageName);
  
    if (textoArmazenamentoLocal) {
      const textoArmazenamentoLocalEmArray = textoArmazenamentoLocal.split(',');
      if(textoArmazenamentoLocalEmArray === " "){
        return [];
      }
      return  textoArmazenamentoLocalEmArray.map(str => parseInt(str, 10));
    }
    return [];
  }

  async function getAll(idsLocalStorage, filtroText) {
    try {
      var filtro = filtroApi;
      filtro.ids = idsLocalStorage;
      filtro.filtro = filtroText;

      var response = await instance.post(props.endpoint, filtro);
      
       if (response.status === 200) {
         return response.data.registros;
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async function handleDelete(id) {
    var selectedOptionsAtualizada = selectedOptions.filter(x => x.id !== id);
    setSelectedOptions(selectedOptionsAtualizada);
    if(selectedOptionsAtualizada !== undefined && selectedOptionsAtualizada.length > 0){
      var lista = await getAll(selectedOptionsAtualizada.map(x => x.id), "");
      setOpcoes(lista);
    } else{
      var lista = await getAll();
      setOpcoes(lista);
    }
    localStorage.setItem(props.localStorageName, selectedOptionsAtualizada.map(x => x.id));
    props.onChange(selectedOptionsAtualizada.map(x => x.id));
  }

  const handleChange = async (event, newValue) => {
    if(newValue != null && newValue.length > 0){
      localStorage.setItem(props.localStorageName, newValue.map(objeto => objeto.id));
      setPlaceholder("");
    } else{
      localStorage.setItem(props.localStorageName, "");
      setPlaceholder(props.placeholder);
      props.onChange(newValue);
    }
    var lista = await getAll(newValue.map(objeto => objeto.id), "");
    setOpcoes(lista);
    var selectedItems = lista.filter((item) => newValue.map(x => x.id).includes(item.id));
    setSelectedOptions(selectedItems);
  };

  const handleChangeFiltro = (event) => {
    updateLista(event.target.value);
  };

  async function updateLista(filtro) {
    var ids = getIdsLocalStorage();
    var lista = await getAll(ids, filtro);
    if(filtro !== ""){ 
      lista = lista.filter((item) => !ids.includes(item.id));
      setOpcoes(lista); 
    } else{
      setOpcoes(lista); 
      var selectedItems = lista.filter((item) => ids.includes(item.id));
      setSelectedOptions(selectedItems);
    }
  }

  const debouncedHandleChangeFiltro = useCallback(debounce(handleChangeFiltro, 300), []);

  const handleUpdate = (event, values) => {
    const textoArmazenamentoLocal = localStorage.getItem(props.localStorageName);
    var ids;
    if (textoArmazenamentoLocal) {
      const textoArmazenamentoLocalEmArray = textoArmazenamentoLocal.split(',');
      ids = textoArmazenamentoLocalEmArray.map(str => parseInt(str, 10));
    } else{
      ids = [];
    }
    props.onChange(ids);
  };

  useEffect(async () => {
    if(localStorage.getItem(props.localStorageName) != null && localStorage.getItem(props.localStorageName) != ""){
        var retorno = await getAll(idsLocalStorage);
        var novosDados = retorno.map(({ id, descricao }) => ({ id, descricao }));
        setOpcoes(novosDados);
        setPlaceholder("");
        var selectedItems = novosDados.filter((item) => idsLocalStorage.includes(item.id));
        setSelectedOptions(selectedItems);
    } else{
      var retorno = await getAll();
      var novosDados = retorno.map(({ id, descricao }) => ({ id, descricao }));
      setOpcoes(novosDados);
    }

    handleUpdate();
  }, []);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      limitTags={1}
      options={opcoes}
      value={selectedOptions}
      disableCloseOnSelect
      variant = "inline"
      margin="normal"
      getOptionLabel={(option) => option.descricao}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.descricao}
          
        </React.Fragment>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.descricao.length > 15 ? `${option.descricao.substring(0, 15)}...` : option.descricao}
            {...getTagProps({ index })}
            size='small'
            onDelete={() => {
              handleDelete(option.id)
            }}
          />
        ))
      }
      style={{ width: '100%'}}
      onChange={handleChange}
      onClose={handleUpdate}
      renderInput={(params) => (
        <TextField {...params} variant='standard' label={props.label} placeholder={placeholder} style={{marginBottom: '0px'}} onChange={debouncedHandleChangeFiltro} />
      )}
    />
  );
}

