
import React, {useEffect} from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getEntregasPendentes2, addEntregasInRota } from '../../API/Entregas';
import { EntregasEditRoute2 } from '../../Router/Routing';
import { columnsGrid } from './Config';

import Button from '../../Components/Button/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';

export default function GridList(props) {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadingAdd, setLoadingAdd] = React.useState(false);

    async function carregar(filtro)
    {
        var idRota;
        if (props.filter)
        {
            if (props.filter.rotaId)
                idRota = props.filter.rotaId;
        }
        setRows([]);
        setLoading(true);
        getEntregasPendentes2(new Date('2020-01-01T00:00:00Z'), new Date('2222-12-01T00:00:00Z'), filtro).then((value) => {
            
            if (value !== undefined)
            {
                value.forEach(entrega => {
                    
                    if (entrega.nfs != null && entrega.nfs.length > 0)
                    {
                        entrega.dataEmissao = entrega.nfs[0].dataEmissao ?? entrega.data;
                        //entrega.naturezaOperacao = entrega.nfs[0].naturezaOperacao;
                        entrega.numeroDocumento = entrega.nfs[0].numeroDocumentoFiscal ?? entrega.nfs[0].idExterno;
                        entrega.chave = entrega.nfs[0].chave;
                        
                    }else{
                        entrega.dataEmissao = entrega.data;
                    }
                    entrega.endereco.logradouroNumero = entrega.endereco.logradouro + ", "+entrega.endereco.numero;
                    entrega.endereco.nomeMunicipioSigla = entrega.endereco.nomeMunicipio + " - "+entrega.endereco.sigaUF;
                });
                setRows(value);
            }
            setLoading(false);
        });

        setColumns(columnsGrid);
    }

    async function addEntregaByRota(ids, openSnackbar)
    {
        setLoadingAdd(true); 
        var idRota = props.match.params.id;
        
        var retorno = await addEntregasInRota(idRota, ids);
        if (retorno.result === 'ok') {
            openSnackbar('Entregas adicionadas na rota!');
            history.goBack();
        } else {
            openSnackbar('Erro ao adicionar na rota!', 'error');
        }

        setLoadingAdd(false); 
    }

    useEffect(async () => {
        carregar();
    }, [])

    let history = useHistory();

    function adicionarNaRota(ids) { 
        return <>
        <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
         <Tooltip title="Adicionar entregas na rota">
             <div style={{ width: '654px', height:"49px"}}>
             
           <Button variant="contained" color="primary"  onClick={() => { addEntregaByRota(ids, openSnackbar) }} loading={loadingAdd}>
              Adicionar {ids.length} paradas na rota
            </Button>
            
            </div>
        </Tooltip>
         )}
         </SharedSnackbarConsumer>
        </>
        
        }

    
    return (
        
                    <GridTable title="Selecionar entregas para adicionar na rota" 
                        rows={rows} 
                        columns={columns} 
                        LinkEdit={EntregasEditRoute2} 
                        loading={loading} 
                        CanRemove={false}
                        TemFiltro={true}
                        atualizar={carregar}
                        filtrar={carregar}
                        filtrarDuranteSelecao={true}
                        actionsWhenSelect={adicionarNaRota}
                    />
               

            
    );
}