
import React, {useEffect} from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAllDestinatario } from '../../API/Pessoas';
import {countByPessoaId} from '../../API/JanelaHorario';

import { ClientesNewRoute, ClientesEditRoute } from '../../Router/Routing';
import { columnsGrid } from './Config';

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    //const [loadingEmpresa, setLoadingEmpresa] = React.useState(true);

    async function loadRow(filtro)
    {
        setRows([]);
        setLoading(true);

        getAllDestinatario(filtro, 0, 50).then((value) => {
            if (value != undefined)
            {
               
                setRows(value);
            }
            setLoading(false);
        });



        setColumns(columnsGrid);

    }
    useEffect(async () => {
        loadRow();
    }, [])

    async function filtrar(filtro){
        await loadRow(filtro);
    }
    
    
    return (
            <GridTable title="Clientes" titleNovo="Novo"
                rows={rows} 
                columns={columns}  
                LinkEdit={ClientesEditRoute} 
                loading={loading} 
                TemFiltro={true}
                atualizar={filtrar}
                filtrar={filtrar}/>
    );
}