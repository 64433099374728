import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { setupInterceptors } from "../API/axios"

export default function InjectAxiosInterceptors () {
  const history = useHistory()

  useEffect(() => {
    setupInterceptors(history)
  }, [history])

  // not rendering anything
  return null
}