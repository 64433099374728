import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { update } from '../../../API/ParadaPlanejadas';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import { vermelhoPastel } from '../../../Components/Map/MarkerColors';
import Button from '../../../Components/Button/Button';
import ErrorIcon from '@material-ui/icons/Error';

class ComentarioMotorista extends Component {
    constructor(props) {
        super(props);

        this.state = { open: false, loading: true, enderecos: [] };
    }

    handleClose2 = () => {
        this.setState({ open: false });
    }

    render() {
        return (
            <>
                <MenuItem
                    disabled={this.props.disabled}
                    onClick={() => { this.props.onClick(); this.setState({ open: true }); }}
                >
                    {this.props.children}
                </MenuItem>
                <SimpleDialog
                    title={this.props.title}
                    label={this.props.label} campo={this.props.campo}
                    paradaPlanejada={this.props.paradaPlanejada}
                    open={this.state.open}
                    onClose={this.handleClose2}
                />
            </>
        );
    }
}
export default ComentarioMotorista;

export function SimpleDialog(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [comentario, setComentario] = useState(props.paradaPlanejada.observacaoParaAplicativo ?? '');

    const { onClose, selectedValue, open } = props;

    const salvar = () => {
        setLoading(true);
        setError('');

        var parada = props.paradaPlanejada;
        parada.observacaoParaAplicativo = comentario;

        update(parada.id, parada).then(e => {
            if (e.result === 'nok') {
                setError(e.data);
                setLoading(false);
            }

            if (e.result === 'ok') {
                setLoading(false);
                handleClose();
                props.paradaPlanejada.atualizarLista();
            }

        }).catch(e => {
            setError('Erro ao salvar. Tente novamente');
            setLoading(false);
        });
    }
    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
            <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
            <TextField
                style={{ marginLeft: '20px', marginRight: '10px' }}
                id="outlined-textarea"
                label="Comentário para o motorista"
                placeholder=""
                multiline
                variant="outlined"
                value={comentario}
                onChange={(x) => { setComentario(x.target.value) }}
            />
            {error ?
                <div style={{ color: vermelhoPastel }}>
                    <Typography variant="h6" align="center"><ErrorIcon style={{ marginLeft: '20px', marginRight: '10px' }}></ErrorIcon>{error}</Typography>
                </div>
                : <></>}
            <DialogContent>
                <Grid container spacing={2}>

                    <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center">

                        <Button variant="contained" color="primary" onClick={salvar} loading={loading}>
                            Salvar
                        </Button>
                        <Button style={{ marginLeft: '11px' }} variant="outlined" color="primary" onClick={handleClose} >
                            Fechar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};