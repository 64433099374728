import React, { useState, useEffect, useCallback } from 'react';
import uploadFileToBlob from './azure-storage-blob';
import { DropzoneArea } from 'material-ui-dropzone';
import ListDoc from '../Rota/Components/ListDoc';
import { getFormatData2ToString } from '../../Utils/FormatDateTime';
import { getUser } from '../../Router/auth';
import Typography from '@material-ui/core/Typography';
import { post } from '../../API/DocumentoImportar';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll } from '../../API/DocumentoImportar';
import { columnsGrid } from '../DocumentoImportar/Config';
import debounce from "lodash.debounce";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DocumentoImportarEditRoute } from '../../Router/Routing';
import { postNotaFiscal } from '../../API/NotasFiscais';

const Importador = (props) => {
	const [docs, setDocs] = useState([]);
	const [columns, setColumns] = React.useState([]);
	const [rows, setRows] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	let [dataInicio, setDataInicio] = React.useState(new Date());

	let fileReader = [];

	var create = async values => {
		var retorno = await post(values);
		if (retorno.result === "ok") {
			return retorno.data;
		} else {
			throw new Error({ name: 'Erro ao criar documento', _error: 'documento failed!' })
		}
	}

	var getTipoArquivo = (nomeArquivo) => {
		try {
			var t = nomeArquivo.split('.')[1];

			switch (t.toUpperCase()) {
				case 'XLSX': return 0;
				case 'CSV': return 1;
				case 'XLS': return 2;
				case 'XML': return 3;
				default:
					return 4;
			}
		} catch (e) {
			return 4;
		}
	}

	var handleFileRead = async (e) => {
		var index = e.target.teste;
		if (e.target.doc.situacao === 'Aguardando') {
			var xx = e.target.doc;
			try {
				if (xx.type === "text/xml") {
					e.target.doc.situacao = 'Importando...';
					var content = fileReader[index].result;
					var retorno = await postNotaFiscal(content, props.rotaId ?? null);
					if (retorno.result === 'ok') {
						e.target.doc.situacao = 'Importado';
						e.target.doc.observacao = retorno.data;
						if (e.target.doc.observacao.includes('não importada')) {
							e.target.doc.situacao = 'Atenção';
						}
					} else {
						e.target.doc.situacao = 'Erro';
						e.target.doc.observacao = retorno.data;

						if (e.target.doc.observacao.includes('não importada')) {
							e.target.doc.situacao = 'Atenção';
						}
					}
				} else {
					fileReader[index].pasta = getFormatData2ToString(new Date()) + '/' + getUser().empresaId.toString();

					if (e.target.doc.documentoBackend === undefined || e.target.doc.documentoBackend == null) {
						var documentoBackend = await create({
							"nomeArquivo": e.target.doc.name,
							"localAzure": fileReader[index].pasta + "/" + e.target.doc.name,
							"tipo": getTipoArquivo(e.target.doc.name),
							"empresaId": getUser().empresaId,
							"rotaId": props.rotaId ?? ''
						});
						e.target.doc.documentoBackend = documentoBackend;
					}

					fileReader[index].pasta += "/" + e.target.doc.documentoBackend.id;
					e.target.doc.situacao = 'Carregando...';

					fileReader[index].log = (x) => {
						var tamanho = fileReader[index].doc.size;
						var carregado = x.loadedBytes;
						var porcentagem = ((((carregado - tamanho) / tamanho) * 100) + 100).toFixed(0).toString();
						var situacao = 'Carregando... ' + porcentagem + ' %';
						e.target.doc.situacao = situacao;
					}

					await uploadFileToBlob(fileReader[index]);
					var situacao = 'Carregado - 100%';
					xx.situacao = situacao;

					loadRow(false);
				}
			} catch (e) {
				xx.situacao = 'Erro. Tente novamente';
			}
		}
	}

	async function loadRow(mostrarLoading = true) {
		if (mostrarLoading) {
			setRows([]);
			setLoading(true);
		}

		getAll(dataInicio, props.rotaId).then((value) => {
			if (value !== undefined) {
				setRows(value);
			}
			
			setLoading(false);
		});
		setColumns(columnsGrid);
	}

	useEffect(async () => {
		let interval = null;
		interval = setInterval(() => {
			loadRow(false);
		}, 30 * 1000);

		loadRow();

		return () => {
			clearInterval(interval);
		};
	}, [])

	async function filtrar() {
		await loadRow();
	}

	const handleDataInicioChange = async (date) => {
		dataInicio = date;
		setDataInicio(dataInicio);
		loadRow();
	};


	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	const debouncedChangeHandler = useCallback(debounce(handleDataInicioChange, 1000), []);

	const campos = () => {
		return <>
			<KeyboardDatePicker
				disableToolbar
				autoOk
				style={{ marginLeft: '0px', width: '150px' }}
				variant="inline"
				format="dd/MM/yyyy"
				margin="normal"
				id="date-picker-inline"
				label="Data"
				value={dataInicio}
				onChange={debouncedChangeHandler}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</>
	}

	return (
		<div>
			<Typography style={{ paddingLeft: '10px', paddingTop: '10px', flex: '1 1 100%' }} variant="h5" component="div">
				<b>1. Selecione os arquivos abaixo (XML ou planilha) e aguarde a confirmação.</b>
			</Typography>

			<DropzoneArea
				filesLimit={50}
				dropzoneText='Arraste e solte os arquivos ou clique aqui'
				maxFileSize={10000000000}
				showPreviewsInDropzone={false}
				showAlerts={false}
				style={{ height: '178px' }}
				onChange={async (files) => {
					for (let index = 0; index < files.length; index++) {
						const doc = files[index];
						if (!doc.situacao)
							doc.situacao = 'Aguardando';

						setDocs(files);
						if (doc.situacao === 'Aguardando') {
							fileReader[index] = new FileReader();
							fileReader[index].onloadend = handleFileRead;
							fileReader[index].teste = index;
							fileReader[index].doc = doc;
							fileReader[index].readAsText(doc);
						}

						if (index % 5 === 0) {
							await sleep(2000); // 2 segundos
						}
					}
				}}
			/>
			{docs.length > 0 ? <ListDoc list={docs} /> : <></>}
			<Typography style={{ paddingLeft: '10px', paddingTop: '10px', flex: '1 1 100%' }} variant="h5" component="div">
				<b>Caso tenha importado planilha, aguarde a segunda confirmação abaixo. </b>
			</Typography>

			<GridTable title=""
				rows={rows}
				columns={columns}
				LinkEdit={DocumentoImportarEditRoute}
				loading={loading}
				atualizar={filtrar}
				CanRemove={false}
				camposFiltros={(campos())}
			/>
		</div>
	);
};

export default Importador;
