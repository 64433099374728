
import React, { useCallback, useEffect } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getVeiculos, deleteById } from '../../API/Veiculos';
import { VeiculosNewRoute, VeiculosEditRoute } from '../../Router/Routing';
import { columnsGrid } from './Config';
import { FiltroAtivo } from '../../Components/FiltroAtivo/FiltroAtivo';

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filtroAtivo, setFiltroAtivo] = React.useState(true);

    const loadRow = useCallback(() => {
        setRows([]);
        setLoading(true);

        getVeiculos(filtroAtivo)
            .then((value) => {
                if (value !== undefined)
                    setRows(value);
                setLoading(false);
            });

        setColumns(columnsGrid);
    }, [filtroAtivo]);

    useEffect(() => {
        loadRow();
    }, [loadRow])

    async function excluirClick(ids) {
        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            await deleteById(id);
        }

        loadRow();
    }

    const camposFiltro = () => {
        return (
            <FiltroAtivo
                value={filtroAtivo}
                onChange={setFiltroAtivo}
            />
        )
    }

    return (
        <GridTable
            title="Veículos"
            titleNovo="NOVO"
            rows={rows}
            columns={columns}
            LinkNew={VeiculosNewRoute}
            LinkEdit={VeiculosEditRoute}
            loading={loading}
            excluirClick={excluirClick}
            camposFiltros={camposFiltro()}
        />
    );
}