import { useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import { createUrl } from '../../Utils/BlobStorage';
import { saveAs } from "file-saver";
import Button from '../Button/Button';
import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/GetApp';
import MapIcon2 from '@material-ui/icons/Map';
import IconButton from '@material-ui/core/IconButton';


export const DialogEvidencia = (props) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const handleClick = () => {
        let url = createUrl(props.evidencia.pathServidor);
        saveAs(url, props.evidencia.nome + '.jpeg');
    }

    function handleImageLoad(event) {
        const width = event.target.naturalWidth;
        const height = event.target.naturalHeight;

        if (width !== dimensions.width || height !== dimensions.height) {
            setDimensions({ width: width, height: height });
        }
    }

    function openMap() {
        const baseUrl = "https://www.google.com/maps/search/"
        window.open(`${baseUrl}?api=1&query=${props.evidencia.latitude}%2C${props.evidencia.longitude}`, '_blank', 'noreferrer');
    }

    // verifica se a imagem é paisagem
    const isLandscape = dimensions.width > dimensions.height;

    const EvidenciaImage = styled.img`
        width: ${props => (props.$isLandscape ? '100%' : 'auto')};
        height: ${props => (props.isLandscape ? 'auto' : "100%")};
    `;

    return (
        <>
            {(props.open) ? (
                <Dialog
                    fullWidth
                    onClose={props.handleClose}
                    maxWidth={"xl"}
                    open={props.open}
                >
                    <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                        <Grid
                            container
                            justifyContent="space-evenly"
                        >
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">{props.evidencia.nome} - Imagem e local realizado</Typography>
                            </Grid>
                            <Grid container xs={12} sm={6} spacing={0} style={{ display: "flex", justifyContent: "right" }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<MapIcon2 />}
                                    onClick={() => { openMap() }}
                                >
                                    Abrir local no Google Maps
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleClick}
                                >
                                    Baixar imagem
                                </Button>
                                <IconButton
                                    aria-label="close"
                                    onClick={props.handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>

                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            spacing={1}
                            style={{ justifyContent: 'space-between' }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                style={{
                                    maxHeight: "800px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <EvidenciaImage
                                    $isLandscape={isLandscape}
                                    src={createUrl(props.evidencia.pathServidor)}
                                    onLoad={(e) => { handleImageLoad(e) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <>
                                    <MapContainer
                                        center={props.latLng}
                                        style={{
                                            height: '100%',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                        zoom={13}
                                        scrollWheelZoom={true}
                                        zoomControl={false}
                                    >
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Marker
                                            draggable={false}
                                            position={props.latLng}>
                                        </Marker>
                                    </MapContainer>
                                </>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            ) : (
                <></>
            )}
        </>
    )
}