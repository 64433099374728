
import React, { useEffect } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll } from '../../API/NotaFiscalItens';
import { columnsGrid } from './Config';

export default function GridList(props) {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    async function loadRow() {
        var idNotaFiscal = '';
        if (props.filter) {
            if (props.filter.notaFiscalId)
                idNotaFiscal = props.filter.notaFiscalId;
        }

        if (idNotaFiscal === '') {
            throw new Error("Erro ao verificar a nota fiscal");
        }

        setRows([]);
        setLoading(true);
        getAll(idNotaFiscal).then((value) => {
            if (value !== undefined)
                setRows(value);
            setLoading(false);
        });

        setColumns(columnsGrid);
    }
    useEffect(async () => {
        loadRow();
    }, [])

    return (
        <GridTable
            title="Itens"
            titleNovo="Novo"
            rows={rows}
            columns={columns}
            CanRemove={false}
            loading={loading}
        />
    );
}