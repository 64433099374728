import { instance } from './axios'
import { getFormatDataToDataApi } from '../Utils/FormatDateTime';

export async function getNotasFiscais(dtInicio, dtFim) {
	try {
		var i = getFormatDataToDataApi(dtInicio);
		var f = getFormatDataToDataApi(dtFim);
		const response = await instance.get(`/api/NotaFiscal?inicio=${i}&fim=${f}`);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getAll(entregaId = '') {
	try {
		const response = await instance.get('/api/NotaFiscal/grid' + (entregaId !== '' ? '?entregaId=' + entregaId : ''));
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getAll2(entregaId = '', filtro = '') {
	try {
		const response = await instance.post('/api/NotaFiscal/grid2' + (entregaId !== '' ? '?entregaId=' + entregaId : ''),
			{
				filtro: filtro,
				page: 0,
				maxRegistro: 50
			});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getById(id) {
	try {
		const response = await instance.get(`/api/NotaFiscal/${id}`);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		throw error;
	}
}

export async function postNotaFiscal(xml, rotaId = null) {
	try {
		const response = await instance.post('/api/NotaFiscal', { xml: xml, rotaId: rotaId }, { timeout: 30000 });

		if (response.status === 201 || response.status === 200) {
			return { data: response.data, result: 'ok' };
		}
	} catch (error) {
		if (error.response.status === 404) {
			return { data: error.response.data, result: 'error' };
		}
		else {
			throw error;
		}
	}
}

export async function consultarDocumento(chave, rotaId = null, docId = null) {
	const params = new URLSearchParams();

	if (chave) {
		params.append("filtro", chave);
	}

	if (docId) {
		params.append("docId", docId);
	}

	if (rotaId) {
		params.append("rotaId", rotaId);
	}

	try {
		const response = await instance.post(`/api/consultarDocumento?${params.toString()}`);
		return response.data;
	} catch (error) {
		if (error.response) {
			return error.response.data;
		} else {
			throw error;
		}
	}
}

export async function enviarXmlPorEmail(xmlEmailDto) {
	try {
		const response = await instance.post("/api/NotaFiscal/EnviarXmlPorEmail", xmlEmailDto);
		return response.data;
	} catch (error) {
		if (error.response) {
			return error.response.data;
		} else {
			return error.message;
		}
	}
}

export async function atualizarVolume(id, request) {
	try {
		const response = await instance.put(`/api/NotaFiscal/AtualizarVolume/${id}`, request);
		return response.data;
	} catch (error) {
		throw error;
	}
}