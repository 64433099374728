import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@mui/material/Box';
import React from 'react';
import { atualizarStatus } from '../../../API/ParadaPlanejadas';
import Button from '../../../Components/Button/Button';
import { vermelhoPastel } from '../../../Components/Map/MarkerColors';
import EntregaAvatar from '../../../Icons/EntregaAvatar';

const useStyles = makeStyles((theme) => ({
	listItemRoot: {
		"&.Mui-selected": {
			backgroundColor: '#CBCDCB',
		}
	},
}));

export function DialogSelectStatus(props) {
	const { onClose, selectedValue, open, rotaId } = props;

	const [selectedIndex, setSelectedIndex] = React.useState(-1);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [statusInvalido, setStatusInvalido] = React.useState(false);

	const classes = useStyles();

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (index) => {
		setSelectedIndex(index);
	};

	const handleClickConfirmar = async () => {
		setLoading(true);
		if (selectedIndex === -1) {
			setLoading(false);
			setStatusInvalido(true);
			return;
		}

		var retorno = await atualizarStatus(rotaId, selectedIndex, props.idsParadas);

		if (retorno == null) {
			setLoading(false);
			setError('Erro ao atualizar status da parada.');
		} else {
			// atualiza as informações da situação da entrega na BuscaCompleta
			if (props.handleNovaSituacao) {
				if (selectedIndex === 0) {
					props.handleNovaSituacao(7);
				}
				else if (selectedIndex === 1) {
					props.handleNovaSituacao(1);
				}
				else if (selectedIndex === 2) {
					props.handleNovaSituacao(2);
				}
			}

			setLoading(false);
			onClose();
		}
	};

	return (
		<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
			<Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
				<DialogTitle id="simple-dialog-title">Selecione o novo status da(s) parada(s)</DialogTitle>
				<DialogContent dividers>
					{error !== '' ?
						<div style={{ color: vermelhoPastel, height: '100px' }}>
							<Typography variant="h6" align="center"><ErrorIcon style={{ marginLeft: '20px', marginRight: '10px' }}></ErrorIcon>{error}</Typography>
						</div> :
						<div>
							<List style={{ marginBottom: '10px' }}>
								<ListItem selected={selectedIndex === 0} classes={{ selected: classes.listItemRoot }} button onClick={() => handleListItemClick(0)}  >
									<ListItemAvatar>
										<EntregaAvatar color={'gray'} />
									</ListItemAvatar>
									<ListItemText
										primary={"Aguardando"} />
								</ListItem>
								<ListItem selected={selectedIndex === 1} classes={{ selected: classes.listItemRoot }} button onClick={() => handleListItemClick(1)} >
									<ListItemAvatar>
										<EntregaAvatar color={'green'} />
									</ListItemAvatar>
									<ListItemText
										primary={"Entregue"} />
								</ListItem>
								<ListItem selected={selectedIndex === 2} classes={{ selected: classes.listItemRoot }} button onClick={() => handleListItemClick(2)} >
									<ListItemAvatar>
										<EntregaAvatar color={'red'} />
									</ListItemAvatar>
									<ListItemText
										primary={"Devolvido"} />
								</ListItem>
							</List>
							<Alert severity="info" >
								<AlertTitle>LEMBRETE</AlertTitle>
								Ao alterar o status da parada, não será possível retornar ao status original, a não ser de forma manual.
							</Alert>
							{statusInvalido ? <Alert severity="warning" style={{ marginTop: '5px' }} >
								<AlertTitle>AVISO</AlertTitle>
								Selecione um status válido.
							</Alert> : null}
						</div>}
				</DialogContent>
				<DialogActions>
					<Button autoFocus loading={loading} onClick={handleClickConfirmar} color="primary" startIcon={<DoneIcon />} variant="contained">
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}