import { instance } from './axios'
import { instance_ms_posicao } from './axios_Posicao'
import { getFormatDataToDataApi, getFormatDataToDataApi_HoraMinuto } from '../Utils/FormatDateTime';
import { getUser } from "../Router/auth";

export async function getRotaByRota(rotaId) {
  try {
    const response = await instance_ms_posicao.get('/RotaPlanejada?id=' + rotaId);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getRotaExecutadaByRota(rotaId) {
  try {
    const response = await instance_ms_posicao.get('/RotaExecutada?id=' + rotaId);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getAll(dataInicio, dataFim) {
  try {
    var query = '';

    if (dataInicio) {
      if (query)
        query += '&';
      query += 'dataInicio=' + getFormatDataToDataApi_HoraMinuto(dataInicio, 0, 0);
    }
    if (dataFim) {
      if (query)
        query += '&';
      query += 'dataFim=' + getFormatDataToDataApi(dataFim, 23, 59);
    }

    const response = await instance.get('/api/Rotas?' + query);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getRotasByFiltroSimplificado(dataInicio, dataFim, regioesIds, motoristasIds, veiculosIds, statusRota) {
  try {
    var dataInicioConvertida = new Date(dataInicio);
    var dataFimConvertida = new Date(dataFim);
    dataInicioConvertida.setHours(0, 0, 1, 0);
    dataFimConvertida.setHours(23, 59, 59, 999);

    const filtro = {
      dataInicio: getFormatDataToDataApi(dataInicioConvertida),
      dataFim: getFormatDataToDataApi(dataFimConvertida),
      regioesIds,
      motoristasIds,
      veiculosIds: veiculosIds,
      status: statusRota !== -1 ? [statusRota] : [],
      empresaId: getUser().empresaId,
    }

    const response = await instance.post('/api/Rotas/GetRotasByFiltro', filtro);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getRotasByFiltro(filtro) {
  try {
    const response = await instance.post('/api/Rotas/GetRotasByFiltro', filtro);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getRotasEmRoteirizacao() {
  try {
    const response = await instance.get('/api/Rotas/GetRotasEmRoteirizacao');
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function transferirParada(novaRotaIdParam, idsParadasPlanejadas, idsNotasFiscais) {
  try {
    if (idsParadasPlanejadas == null || idsParadasPlanejadas === undefined || idsParadasPlanejadas[0] == null) {
      idsParadasPlanejadas = [];
    }
    if (idsNotasFiscais == null || idsNotasFiscais === undefined || idsNotasFiscais[0] == null) {
      idsNotasFiscais = [];
    }
    var parametro = {
      notasFiscaisIds: idsNotasFiscais,
      paradasPlanejadasIds: idsParadasPlanejadas,
      novaRotaId: novaRotaIdParam
    }
    const response = await instance.post('/api/ParadaPlanejadas/transferirParada', parametro);
    if (response.status === 201 || response.status === 200) {
      if (response.data === true) {
        return { data: response.data, result: 'ok' };
      }
      else {
        return { data: response.data, result: 'erro' };
      }
    }
    return response;
  } catch (error) {
    throw error;
  }
}

export async function post(registro) {
  try {
    const response = await instance.post('/api/Rotas', registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    throw error;
  }
}

export async function put(id, registro) {
  try {
    const response = await instance.put('/api/Rotas/' + id, registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    throw error;
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/Rotas/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getByIdTelaRota(id) {
  try {
    const response = await instance.get(`/api/Rotas/GetByIdTelaRota/${id}`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function deleteById(id) {
  try {
    const response = await instance.delete('/api/Rotas/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function inativarById(ids) {
  try {
    const response = await instance.delete('/api/Rotas/inativar', { data: ids });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getRotasMonitoramento(data) {
  try {
    var d = getFormatDataToDataApi(data);
    const response = await instance.get('/api/Rotas/monitoramento_SomenteRota?data=' + d);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getRotasMonitoramentoPost(data, regioesIds, motoristasIds, veiculosIds, statusRota) {
  try {
    var dataConvertida = new Date(data);

    const filtro = {
      dataInicio: getFormatDataToDataApi(dataConvertida),
      dataFim: getFormatDataToDataApi(dataConvertida),
      regioesIds: regioesIds,
      motoristasIds: motoristasIds,
      veiculosIds: veiculosIds,
      status: statusRota !== -1 ? [statusRota] : [],
      empresaId: getUser().empresaId,
    }

    const response = await instance.post('/api/Rotas/monitoramento_SomenteRotaPost', filtro);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function updateStatusRoteirizacao(id, status) {
  try {
    const response = await instance.put('/api/Rotas/updateStatusRoteirizacao/' + id + '?status=' + status);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    throw error;
  }
}

export async function getRotasIdsForAjusteEnderecoManual(enderecoId, empresaId) {
  try {
    const response = await instance.get(`/api/Rotas/GetRotasForAjusteEnderecoManual?enderecoId=${enderecoId}&empresaId=${empresaId}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function AtualizarComentario(id, registro) {
  try {
    const response = await instance.put('/api/Rotas/AtualizarComentario/' + id, registro);
    if (response.status === 201 || response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}