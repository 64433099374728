export const styles = ((theme) => ({
    root: {
        flexGrow: 1,
    },
    buttonProgress: {
        color: 'black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        height: '100%',
        //margin: theme.spacing(1),
        position: 'relative',
    },
    colorTeste: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '14px'
    },
    colorTesteAction: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: ''
    },
    timelineContentContainer: {
        //textAlign: "left"
        flexDirection: "row"
    },
    "@keyframes blinker": {
        "50%": {
            opacity: .3
        },
        "70%": {
            opacity: 1
        },
    },
    piscar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '14px',
        animation: '$blinker 3.4s linear infinite',
    },
    map: {
        display: 'flex'
    },
    container: {
        width: '100%'
    },
    box1: {
        width: '50%',
        height: '100%',
        textAlign: 'center',
        float: 'left',
        background: '#ff4500'
    },
    box2: {
        width: '50%',
        height: '100%',
        textAlign: 'center',
        float: 'left', background: '#0ffab9',
        '&::before': {
            display: 'inline-block',
            margin: '0',
            marginLeft: '-101px',
            marginTop: '-1px',
            position: 'absolute',
            content: '',
            width: '0',
            height: '0',
            borderBottom: '151px solid #0ffab9',
            borderLeft: '30px solid transparent',
        }
    }
}));