import React from 'react';
import Chip from '@material-ui/core/Chip';

import { opcaoSituacao } from '../../Pages/Rota/Config'

export default function StatusRoute(props) {
	var t = opcaoSituacao.filter(function (item) {
		return item.value === props;
	});

	if (t.length > 0) {
		if (t[0].value === 4) {
			return (
				<Chip
					variant="default"
					label={t.length > 0 ? t[0].label : ''}
					style={{
						backgroundColor: 'rgb(245 84 72)',
						color: 'white',
						fontSize: '14px',
						padding: '12px',
						letterSpacing: '1px'
					}}
				/>
			)
		}
		if (t[0].value === 2) {
			return (
				<Chip
					variant="default"
					label={t.length > 0 ? t[0].label : ''}
					style={{
						backgroundColor: 'rgb(3, 169, 244)',
						color: 'white',
						fontSize: '14px',
						padding: '12px',
						letterSpacing: '1px'
					}}
				/>
			)
		}
		if (t[0].value === 3 || t[0].value === 5) {
			return (
				<Chip
					variant="default"
					label={t.length > 0 ? t[0].label : ''}
					style={{
						backgroundColor: 'rgb(92 182 96)',
						color: 'white',
						fontSize: '14px',
						padding: '12px',
						letterSpacing: '1px'
					}}
				/>
			)
		}
		if (t[0].value === 1) {
			return (
				<Chip
					variant="default"
					label={t.length > 0 ? t[0].label : ''}
					style={{
						fontSize: '14px',
						padding: '12px',
						letterSpacing: '1px'
					}}
				/>
			)
		}
	}
}