import _, { isEmpty } from 'lodash'
import { loginByUserName } from '../API/Usuarios';
import * as storage from "../Utils/StorageKeys";
import { LoginRoute } from './Routing';

var jwt = require('jsonwebtoken');

export const UsuarioComPermissao = (roles) => {
    
    if (roles === undefined)
        return true;
    else
        return !_.isEmpty(sessionStorage.getItem('token')) && hasRole(roles, getUser().role, getUser().empresaId);
        //return !_.isEmpty(sessionStorage.getItem('token')) && roles.indexOf(getUser().role) > -1;
}

function hasRole(rolesArray, role, empresaId) 
{ 
	return rolesArray.some(item => 
		{ 
			if (typeof item === 'string') { 
				return item === role; 
			} else if (typeof item === 'object' && item.role === role) { 
				return item.role === role ? item.empresas.includes(empresaId) : false; 
			} 
			return false; 
		}
	);
}

export const UsuarioComPermissaoComToken = (roles, token) => {
    if (roles === undefined)
        return true;
    else
        return !_.isEmpty(token) && roles.indexOf(jwt.decode(token).role) > -1;
}

export const PodeAcessarRelatorios = () => {
    var token = getToken();

    if (token != null) {
        try {
            var tokenDecodado = jwt.decode(token);

            if (tokenDecodado.RelatoriosLiberados) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            window.open(LoginRoute)
        }
    }
    return false;

}

export const VerificarTokenExpirado = () => {

    var token = sessionStorage.getItem('token');

    if (token != null) {
        try {
            var tokenDecodado = jwt.decode(token);
            var dataExpiracao = new Date(tokenDecodado.exp * 1000); // o exp é dado em segundo desde 1970, já o date() recebe milisegundo, aí precisa converter!!!
            var dataAtual = new Date();

            if (dataExpiracao < dataAtual) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return true;
        }

    } else {
        return true;
    }
}
export const VerificarTokenExpirado2 = (token) => {

    if (token != null) {
        try {
            var tokenDecodado = jwt.decode(token);
            var dataExpiracao = new Date(tokenDecodado.exp * 1000); // o exp é dado em segundo desde 1970, já o date() recebe milisegundo, aí precisa converter!!!
            var dataAtual = new Date();

            if (dataExpiracao < dataAtual) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return true;
        }

    } else {
        return true;
    }
}

export const getToken = () => {
    return sessionStorage.getItem('token')
}
export const getUser = () => {
    var token = sessionStorage.getItem('token');
    if (token != null) {
        try {
            var user = JSON.parse(sessionStorage.getItem('user'));
            user.role = jwt.decode(token).role;
        } catch (e) {
            window.open(LoginRoute)
        }
        return user;
    } else {
        return null;
    }

}
export const getEmpresa = () => {
    return JSON.parse(sessionStorage.getItem('empresa'));
}

export const login = async (username, senha) => {
    sessionStorage.setItem('token', '');
    sessionStorage.setItem('user', '');
    if (isEmpty(username)) {
        throw new Error("Usuário ou e-mail é obrigatório!")
    }
    if (isEmpty(senha)) {
        throw new Error("Senha é obrigatório!")
    }
    var data = await loginByUserName(username, senha);
    var token = data.token;

    if (data.user.role && data.user.role === 'Motorista') {
        throw new Error("Sem acesso!")
    }

    if (data.user.role === false) {
        throw new Error("Sem acesso!")
    }

    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(data.user));
    sessionStorage.setItem('empresa', JSON.stringify(data.empresa));

    localStorage.removeItem(storage.rota_data_inicio);
    localStorage.removeItem(storage.rota_data_fim);
    return data;
}

export const logout = () => {
    return sessionStorage.clear()
}
