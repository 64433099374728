import React, { useCallback, useEffect } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll, deleteById } from '../../API/Empresa';
import { EmpresasNewRoute, EmpresasEditRoute } from '../../Router/Routing';
import { FiltroAtivo } from '../../Components/FiltroAtivo/FiltroAtivo';

export default function GridList() {
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filtroAtivo, setFiltroAtivo] = React.useState(true);

    const columns = [
        {
            label: 'Nome',
            field: 'nome',
            padding: 'default'
        }, {
            label: 'Ativo',
            field: 'ativo',
            type: 'bool',
            padding: 'default'
        }, {
            label: 'Tipo',
            field: 'tipo',
            type: 'option',
            options: [{ value: 1, label: 'Entregas diárias' }, { value: 2, label: 'Outros' }],
            padding: 'default'
        }, {
            label: 'CNPJ/CPF',
            field: 'documento',
            padding: 'default'
        }
    ];

    const loadRow = useCallback(() => {
        setRows([]);
        setLoading(true);

        getAll(filtroAtivo)
            .then((value) => {
                if (value !== undefined)
                    setRows(value);
                setLoading(false);
            });
    }, [filtroAtivo]);

    useEffect(() => {
        loadRow();
    }, [loadRow])

    async function excluirClick(ids) {

        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            await deleteById(id);
        }
        loadRow();
    }

    const camposFiltro = () => {
        return (
            <FiltroAtivo
                value={filtroAtivo}
                onChange={setFiltroAtivo}
            />
        )
    }

    return (
        <GridTable
            title="Empresas"
            titleNovo="Nova"
            rows={rows}
            columns={columns}
            LinkNew={EmpresasNewRoute}
            LinkEdit={EmpresasEditRoute}
            loading={loading}
            excluirClick={excluirClick}
            camposFiltros={camposFiltro()}
        />
    );
}