import React, { useEffect, useCallback, useState } from 'react';
import debounce from "lodash/debounce";
import * as storage from "../../Utils/StorageKeys";
import AdicionarNF from '../RotaAdicionarNF/RotaAdicionarNF';
import GridListRotaAnalise from '../RotaAnalise/GridList';
import { GridTable } from '../../Components/GridTable/GridTable';
import ButtonImprimir from './Components/ButtonImprimir';
import { TabPanel } from '../../Components/TabHorizontal/Tab';
import { getFormatDataToString2 } from '../../Utils/FormatDateTime';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AnaliseIcon from '@material-ui/icons/Equalizer';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as barcode } from '../../img/icon/barcode_icon.svg';
import { inativarById, getRotasByFiltroSimplificado } from '../../API/Rotas';
import MultiplaSelecao from '../../Components/MultiplaSelecao/MultiplaSelecao'
import { getEmpresa } from '../../Router/auth';
import { RotasNewRoute, RotasEditRoute } from '../../Router/Routing';
import { getUser } from '../../Router/auth';
import { columnsGrid } from './Config';
import { TabIndex } from './utils/enums';
import { EhTransportadora, EmpresaIds } from '../../Utils/Empresa';
import { rota_regioes_selecionadas, rota_motoristas_selecionadas } from '../../Utils/StorageKeys';
import { Box } from '@mui/material';
import { FiltroSituacaoRota } from '../../Components/FiltroSituacaoRota/FiltroSituacaoRota';

export default function GridList() {
    const [columns, setColumns] = React.useState([...columnsGrid]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadingEmpresa, setLoadingEmpresa] = React.useState(true);
    const [empresaId, setEmpresaId] = useState(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [filtroSituacaoRota, setFiltroSituacaoRota] = useState(-1);

    const [regioesIds, setRegioesIds] = useState(() => {
        const storedRegioes = localStorage.getItem(rota_regioes_selecionadas);
        return storedRegioes ? storedRegioes : [];
    });

    const [motoristasIds, setMotoristasIds] = useState(() => {
        const storedMotoristas = localStorage.getItem(rota_motoristas_selecionadas);
        return storedMotoristas ? storedMotoristas : [];
    });

    const [veiculosIds, setVeiculosIds] = useState(() => {
        const storedVeiculos = localStorage.getItem(storage.rota_veiculos_selecionadas);
        return storedVeiculos ? storedVeiculos : [];
    });

    const DATA_INICIO = new Date().setDate(new Date().getDate() - 5);
    const DATA_FIM = new Date().setDate(new Date().getDate() + 10);

    let [dataInicio, setDataInicio] = React.useState(getDataLocalStorage(storage.rota_data_inicio) ?? DATA_INICIO);
    let [dataFim, setDataFim] = React.useState(getDataLocalStorage(storage.rota_data_fim) ?? DATA_FIM);

    useEffect(() => {
        setEmpresaId(getUser().empresaId);
    }, []);

    useEffect(() => {
        localStorage.setItem(storage.rota_data_inicio, getFormatDataToString2(dataInicio));
    }, [dataInicio]);

    useEffect(() => {
        localStorage.setItem(storage.rota_data_fim, getFormatDataToString2(dataFim));
    }, [dataFim]);

    useEffect(() => {
        setDataInicio(getDataLocalStorage(storage.rota_data_inicio) ?? DATA_INICIO);
        setDataFim(getDataLocalStorage(storage.rota_data_fim) ?? DATA_FIM);
    }, [tabValue]);

    useEffect(() => {
        loadRow();
    }, [regioesIds, motoristasIds, veiculosIds, filtroSituacaoRota])

    function getDataLocalStorage(localStorageName) {
        const textoArmazenamentoLocal = localStorage.getItem(localStorageName);
        if (textoArmazenamentoLocal) {
            let date = new Date(textoArmazenamentoLocal);

            if (!isNaN(date) && date !== undefined) {
                return date;
            }
            return null;
        }
        return null;
    }

    const handleDataInicioChange = async (date) => {
        dataInicio = date;
        setDataInicio(dataInicio);
        loadRow();
    };

    const handleDataFimChange = (date) => {
        dataFim = date;
        setDataFim(dataFim);
        loadRow();
    };

    async function loadRow() {
        setRows([]);
        setLoading(true);
        setLoadingEmpresa(true);

        getRotasByFiltroSimplificado(
            dataInicio,
            dataFim,
            regioesIds,
            motoristasIds,
            veiculosIds,
            filtroSituacaoRota
        ).then((value) => {
            if (value !== undefined) {
                setRows(value);
            }
            setLoading(false);
        });

        //columnsGrid
        var utilizaCadastroRotaRegiao = getEmpresa().utilizaCadastroRotaRegiao;
        if (!utilizaCadastroRotaRegiao) {
            var index = columns.findIndex(el => {
                return el.field === String('rotaRegiaoDescricao');
            });
            if (index !== -1) {
                columns.splice(index, 1);
            };
        }

        if (!(EhTransportadora(getEmpresa().id))) {
            let indexColFrete = columns.findIndex(el => {
                return el.field === String('valorTotalFrete');
            });

            if (indexColFrete !== -1) {
                columns.splice(indexColFrete, 1);
            };
        }

        // coluna id
        var indexId = columns.findIndex(el => {
            return el.field === String('id');
        });

        if (getUser().role === 'RLuOKTh1Ko' && indexId === -1) {
            columns.unshift({ label: 'Id', field: 'id', type: 'text', padding: 'default' });
        }

        var indexIdExt = columns.findIndex(el => {
            return el.field === String('idExterno');
        });

        if ((getUser().empresaId === EmpresaIds.Rodosuper || getUser().empresaId === 56 || getUser().empresaId === 69) && indexIdExt === -1) {
            columns.unshift({ label: 'Iden. externo', field: 'idExterno', type: 'text', padding: 'default' });
        }

        setColumns(columns);
        setLoadingEmpresa(false);
    }

    async function excluirClick(ids) {
        await inativarById(ids.map(function (x) { return x; }));
        loadRow();
    }

    const debouncedChangeHandler = useCallback(debounce(handleDataInicioChange, 1000), []);
    const debouncedChangeHandlerFim = useCallback(debounce(handleDataFimChange, 1000), []);

    const campos = () => {
        return <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    style={{ width: '150px' }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data inicial"
                    value={dataInicio}
                    onChange={debouncedChangeHandler}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    style={{ marginLeft: '15px', width: '150px' }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data final"
                    value={dataFim}
                    onChange={debouncedChangeHandlerFim}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <Box style={{ marginLeft: '15px', width: '300px', marginTop: '16px', marginBottom: '8px' }}>
                    <MultiplaSelecao
                        label="Região"
                        placeholder="Selecione a/as regiões desejadas"
                        localStorageName="Rota_regioes_selecionadas"
                        endpoint="api/RotaRegioes/lookupMultiplo"
                        onChange={(ids) => setRegioesIds(ids)}
                    ></MultiplaSelecao>
                </Box>
                <Box style={{ marginLeft: '15px', width: '300px', marginTop: '16px', marginBottom: '8px' }}>
                    <MultiplaSelecao
                        label="Veiculos"
                        placeholder="Selecione os veiculos"
                        localStorageName={storage.rota_veiculos_selecionadas}
                        endpoint="api/Veiculos/lookupMultiplo"
                        onChange={(ids) => setVeiculosIds(ids)}
                    ></MultiplaSelecao>
                </Box>
                <Box style={{ marginLeft: '15px', width: '300px', marginTop: '16px', marginBottom: '8px' }}>
                    <MultiplaSelecao
                        label="Motorista"
                        placeholder="Selecione os motoristas"
                        localStorageName="Rota_motoristas_selecionadas"
                        endpoint="api/Motoristas/lookupMultiplo"
                        onChange={(ids) => setMotoristasIds(ids)}
                    ></MultiplaSelecao>
                </Box>
                <Box style={{ marginLeft: '15px' }}>
                    <FiltroSituacaoRota
                        value={filtroSituacaoRota}
                        onChange={setFiltroSituacaoRota}
                    />
                </Box>
            </div>

        </>
    }

    const handleTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const isAnalista = getUser().role === 'RLuOKTh1Ko' || getUser().role === 'Master';

    return (
        <>
            <Tabs value={tabValue} onChange={handleTabValue}>
                <Tab label="Rota" index={TabIndex.Rota} icon={<CallSplitIcon />} />,

                {(isAnalista) ?
                    <Tab label="Análise" index={TabIndex.Analise} icon={<AnaliseIcon />} />
                    :
                    <div style={{ display: "none" }}>
                        <Tab></Tab>
                    </div>
                }

                {(empresaId === EmpresaIds.Construcolor || empresaId === EmpresaIds.EmpresaDoPedro || empresaId === EmpresaIds.Krambeck) ?
                    <Tab label="Adição rápida" index={TabIndex.AdicionarNF} icon={<SvgIcon component={barcode} />} />
                    :
                    <div style={{ display: "none" }}>
                        <Tab></Tab>
                    </div>
                }
            </Tabs>

            <TabPanel value={tabValue} index={TabIndex.Rota}>
                <GridTable title="" titleNovo="Nova"
                    rows={rows}
                    columns={columns}
                    LinkNew={RotasNewRoute}
                    LinkEdit={RotasEditRoute}
                    atualizar={loadRow}
                    rowsPerPage={25}
                    actionsWhenSelect={(ids) => { return <ButtonImprimir ids={ids} /> }}
                    loading={loading || loadingEmpresa}
                    excluirClick={excluirClick}
                    CanRemove={false}
                    camposFiltros={campos()} />
            </TabPanel>

            <TabPanel value={tabValue} index={TabIndex.Analise}>
                <GridListRotaAnalise />
            </TabPanel>

            <TabPanel value={tabValue} index={TabIndex.AdicionarNF}>
                <AdicionarNF />
            </TabPanel>
        </>
    );
}
