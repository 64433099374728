import React, { useState } from 'react'
import Button from '../../../Components/Button/Button';
import PrintIcon from '@material-ui/icons/Print';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { instance } from '../../../API/axios'
import saveAs from 'file-saver';
import { vermelhoPastel } from '../../../Components/Map/MarkerColors';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@mui/material';

export default function ButtonImprimir(props) {
    const [open, setOpen] = React.useState(false);
    const [blobFileLoading, setBlobFileLoading] = useState(false);
    const [erro, setErro] = useState('');
    
    const [tipoRelatorioRota, setTipoRelatorioRota] = React.useState(localStorage.getItem('RelatorioRota_tipo') ?? 'byCarregamento');
    const [agruparParadas, setAgruparParadas] = React.useState(localStorage.getItem('RelatorioRota_agruparParadas') === 'true' ?? true);
    const [comProduto, setComProduto] = React.useState(localStorage.getItem('RelatorioRota_comProduto') === 'true' ?? true);
    const [comObservacao, setComObservacao] = React.useState(localStorage.getItem('RelatorioRota_comObservacao') === 'true' ?? true);
    const [comObservacaoProduto, setComObservacaoProduto] = React.useState(localStorage.getItem('RelatorioRota_comObservacaoProduto') === 'true' ?? true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRadioChange = (event) => {
        setTipoRelatorioRota(event.target.value);
        localStorage.setItem('RelatorioRota_tipo', event.target.value);
    };

    const imprimirClick = async () => {
        try {
            setBlobFileLoading(true);
            setErro('');
            var data = JSON.stringify({
                "rotaids": props.ids,
                "naOrdemDeEntrega": (tipoRelatorioRota === 'byEntrega'),
                "agruparParadas": agruparParadas,
                "mostrarCampos": [
                    (comProduto ? 'produtos' : ''),
                    (comObservacao ? 'observacao' : ''),
                    (comObservacaoProduto ? "observacaoProduto" : "")
                ]
            });

            instance({
                method: 'post',
                responseType: 'arraybuffer', //Force to receive data in a Blob Format
                url: '/api/Relatorios/MapaDeCarregamento',
                data: data,
                timeout: 1000 * 60, // Wait for 5 seconds
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'responseType': 'arraybuffer'
                },
            })
                .then(res => {
                    let fileName = `MapaDeCarregamento_.pdf`;

                    const blob = new Blob([res.data], {
                        type: 'application/octet-stream'
                    })

                    saveAs(blob, fileName);
                    handleClose();
                })
                .catch(error => {
                    setErro('Erro ao gerar relatório. Selecione menos rotas e tente novamente.')
                })
                .finally(x => {
                    setBlobFileLoading(false);
                });
        } catch (error) {

        }
    }

    return (
        <>
            <Button variant="text" color="primary" size="large" startIcon={<PrintIcon />} onClick={handleClickOpen}>
                Imprimir
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Impressão de relatório</DialogTitle>
                <DialogContent dividers>

                    <Typography variant="subtitle1" >1. Escolha o relatório</Typography>
                    <RadioGroup aria-label="quiz" name="quiz" value={tipoRelatorioRota} onChange={handleRadioChange} style={{ marginLeft: '15px' }}>
                        <FormControlLabel value="byEntrega" control={<Radio />} label="Por ordem de entrega" />
                        <FormControlLabel value="byCarregamento" control={<Radio />} label="Por ordem de carregamento (ordem inversa de entrega)" />
                    </RadioGroup>

                    <Typography variant="subtitle1" style={{ marginTop: '15px' }}>2. Selecione as informaçoes adicionais que você deseja</Typography>
                    <FormGroup style={{ marginLeft: '15px' }}>
                        <FormControlLabel
                            label="Listar produtos"
                            control={
                                <Checkbox
                                    name="comProduto"
                                    checked={comProduto}
                                    onChange={() => {
                                        setComProduto(!comProduto);
                                        localStorage.setItem('RelatorioRota_comProduto', !comProduto);
                                    }}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Observação do pedido"
                            control={
                                <Checkbox
                                    name="comObservacao"
                                    checked={comObservacao}
                                    onChange={() => {
                                        setComObservacao(!comObservacao);
                                        localStorage.setItem('RelatorioRota_comObservacao', !comObservacao);
                                    }}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Observação nos produtos"
                            control={
                                <Checkbox
                                    name="observacaoProdutos"
                                    checked={comObservacaoProduto}
                                    onChange={() => {
                                        setComObservacaoProduto(!comObservacaoProduto);
                                        localStorage.setItem('RelatorioRota_comObservacaoProduto', !comObservacaoProduto);
                                    }}
                                />
                            }
                        />
                    </FormGroup>

                    <Typography variant="subtitle1" style={{ marginTop: '15px' }}>3. Selecione opções adicionais</Typography>
                    <FormGroup style={{ marginLeft: '15px' }}>
                    <FormControlLabel
                            label="Agrupar paradas com mesmo cliente e endereço"
                            control={
                                <Checkbox
                                    name="agruparParadas"
                                    checked={agruparParadas}
                                    onChange={() => {
                                        setAgruparParadas(!agruparParadas);
                                        localStorage.setItem('RelatorioRota_agruparParadas', !agruparParadas);
                                    }}
                                />
                            }
                        />
                    </FormGroup>

                    <Alert severity="info" >
                        <AlertTitle>LEMBRETE</AlertTitle>
                        O relatório gerado neste momento, apresentará a atual sequência roteirizada. Caso mais tarde, você deseje fazer uma nova roteirização, a sequência poderá sofrer alterações; indicamos neste caso, que você gere um novo relatório atualizado.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <div style={{ color: vermelhoPastel }}>
                        <Typography variant="subtitle1" color='red'>{erro}</Typography>
                    </div>
                    <Button autoFocus loading={blobFileLoading} onClick={imprimirClick} color="primary" startIcon={<PrintIcon />} variant="contained">
                        Imprimir
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
