export const fonts = {
    primary: "28px",
    secondary: "18px",
    label: "10px",
    big: "48px"
}

export function captalize(texto) {
    if (texto === null || texto === undefined)
        return "";

    return texto
        .split(' ')
        .map(palavra =>
            palavra.charAt(0).toUpperCase() + palavra.slice(1)
        ).join(' ');
}