import React, { Component} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { getAllRotaAnalise } from '../../../API/RotaAnalise';

class QuilometragemPrevista extends Component {

  constructor(props) {
    //props: empresaId e rotaId
    super(props);

    this.state = { loading: true, km: '' };
  }

   async componentDidMount() {

     if (this.props.row.empresaId && this.props.row.id) {
         getAllRotaAnalise("", "", [], [], [], this.props.row.id).then((value) => {
         if (value !== undefined){
            value.forEach(element => {
            this.setState({ km: element.kmPrevisto });
            }); 
         }
         this.setState({ loading: false });
       }).catch((e) => { this.setState({ loading: false }); });
     } else {
       this.setState({ loading: false });
     }
  }
   
  
  render() {
    if (this.state.loading)
      return <>
          <Skeleton variant="rect" width={50} height={10} />
      </>
    if (this.state.km === 0 || this.state.km === null || this.state.km === undefined || this.state.km === '') 
      return <>0 Km</>

    if (this.state.km !== 0 && this.state.km !== null && this.state.km !== undefined) {
      return <>
      {(this.state.km.toLocaleString('pt-BR'))} Km
    </>;
    }
    
  }
}
export default QuilometragemPrevista;