import React, { Component, useState } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as localPlanejado } from '../../../img/icon/localPlanejado.svg';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { SharedSnackbarConsumer } from '../../../Context/SharedSnackbar.context';
import { getAllByIdByPessoa } from '../../../API/Enderecos';
import { update } from '../../../API/ParadaPlanejadas';
import { getFormatEndereco_01 } from '../../../Utils/Endereco';
import { EnderecosNewRoute } from '../../../Router/Routing';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Chip } from '@mui/material';

class ChipMultiplosEnderecos extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false, loading: true, enderecos: [] };
  }

  carregarEnderecos() {
    getAllByIdByPessoa(this.props.paradaPlanejada.destinatarioId).then((value) => {
      if (value !== undefined)
        this.setState({ enderecos: value });
      this.setState({ loading: false });
    }).catch((e) => { this.setState({ loading: false }); });
  }

  handleClose2 = () => {
    this.setState({ open: false });
  }

  render() {
    var enderecosList = this.state.enderecos;
    if (enderecosList && enderecosList.length > 0) {
      var endComOrigem = enderecosList.filter(function (item, index, arr) {
        return item.enderecoOrigemId != null;
      });
      if (endComOrigem.length > 0)
        enderecosList = enderecosList.filter(function (value, index, arr) {
          return endComOrigem.filter(function (item, index, arr) {
            return item.enderecoOrigemId !== value.enderecoId;
          }).length > 0;
        });
    }

    return <>
      <SharedSnackbarConsumer>
        {({ openSnackbar }) => (
          <>
            <Chip
              onClick={() => {
                this.carregarEnderecos();
                this.setState({ open: true });
              }}
              icon={<SvgIcon component={localPlanejado} />}
              label="Trocar por outro endereço"
              {...this.props}>
            </Chip>

            {this.state.open ? (
              <SimpleDialog
                openSnackbar={openSnackbar}
                enderecos={enderecosList}
                open={this.state.open}
                loadingApi={this.state.loading}
                onClose={this.handleClose2}
                paradaPlanejada={this.props.paradaPlanejada}
              />
            ) : <></>}
          </>
        )}
      </SharedSnackbarConsumer>
    </>;
  }
}
export default ChipMultiplosEnderecos;

export function SimpleDialog(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { onClose, selectedValue, open, enderecos } = props;


  const handleListItemClick = async (value) => {
    setLoading(true);
    var paradaPlanejada = props.paradaPlanejada;
    paradaPlanejada.enderecoId = value.enderecoId;

    try {
      var retorno = await update(paradaPlanejada.id, paradaPlanejada);
      if (retorno.result === 'ok') {
        onClose();
        setLoading(false);
        props.openSnackbar('Endereço alterado com sucesso!')
      }
      else
        setError('Erro ao alterar o endereço da parada. Tente novamente!');
    } catch {
      setError('Erro ao alterar o endereço da parada. Tente novamente!');
    }

  };

  let history = useHistory();

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
      <DialogTitle id="simple-dialog-title">Selecione um novo endereço para a entrega</DialogTitle>
      {error ? <Typography variant="h6" color="textSecondary" align="center">{error}
      </Typography> : <>

        {props.loadingApi ?
          <div style={{ textAlign: 'center', height: '100px' }}>
            <div style={{ width: '87%', marginLeft: '30px' }}><Skeleton variant="rect" /></div>
          </div>
          : loading ?
            <div style={{ textAlign: 'center', height: '200px' }}><CircularProgress /></div>
            :
            <List>
              {enderecos.map((endereco) => (
                <ListItem button onClick={() => handleListItemClick(endereco)} key={endereco.id}>
                  <ListItemAvatar>
                    <Avatar >
                      <SvgIcon component={localPlanejado} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={endereco.nome} secondary={getFormatEndereco_01(endereco.endereco)} />
                </ListItem>
              ))}

              <ListItem autoFocus button onClick={() => { history.push(EnderecosNewRoute + "?&pessoaId=" + props.paradaPlanejada.destinatarioId) }}>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Cadastrar um novo endereço para o cliente" />
              </ListItem>
            </List>
        }
      </>}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};