import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/RemoveRedEye';
import Tooltip from '@material-ui/core/Tooltip';
import { DialogEvidencia } from './DialogEvidencia';

export function DialogEvidenciaMediator(props) {
	const [open, setOpen] = React.useState(false);
	const latLng = { lat: props.evidencia.latitude, lng: props.evidencia.longitude, };

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Tooltip title={"Visualizar foto e local"}>
				<IconButton aria-label="delete" size="small" >
					<MapIcon onClick={() => { setOpen(!open) }} />
				</IconButton>
			</Tooltip>

			<DialogEvidencia
				open={open}
				latLng={latLng}
				evidencia={props.evidencia}
				handleClose={handleClose}
			>
			</DialogEvidencia>
		</div>
	);
}
