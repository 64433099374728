
import React, { useEffect } from 'react';
import MapContainerLeft from '../../Maps/MapContainerLeft';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getPosiceosByVeiculoPost } from '../../API/Posicoes';
import { getParadasByVeiculo } from '../../API/Paradas';
import { getAll } from '../../API/Entregas';
import { getAll as getallByRota } from '../../API/ParadaPlanejadas';
import { getById as getByIdLocal } from '../../API/Local';
import { getRotaByRota, getRotaExecutadaByRota } from '../../API/Rotas';

const useStyles = makeStyles((theme) => ({
  rootChech: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  root: {
    height: '100vh',
  },
  buttonProgress: {
    color: 'black',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    height: '100%',
    position: 'relative',
  },
}));

export default function RotaMapa(props) {
  const classes = useStyles();

  const [coords, setCoords] = React.useState([]);
  const [coordsUltimas, setCoordsUltimas] = React.useState([]);
  const [bounds, setBounds] = React.useState();
  const [posicoes, setPosicoes] = React.useState([]);
  const [tamanhoIcone, setTamanhoIcone] = React.useState(30);

  const [withRotas, setWithRotas] = React.useState(true);
  const [withRotaPlanejada, setWithRotaPlanejada] = React.useState(true);
  const [withParadasNaoIdentificado, setWithParadasNaoIdentificado] = React.useState(false);
  
  const [paradas, setParadas] = React.useState([]);
  const [withParadasPlanejadas, setWithParadasPlanejadas] = React.useState(true);
  const [withSequencia, setWithSequencia] = React.useState(true);
  const [withJanela, setWithJanela] = React.useState(true);
  const [withPassoAPasso, setWithPassoAPasso] = React.useState(false);

  const [paradasPlanejadass, setParadasPlanejadas] = React.useState([]);
  const [trajetoRota, setTrajetoRota] = React.useState(false);
  const [trajetoRotaExecutada, setTrajetoRotaExecutada] = React.useState(false);

  const [loadingTrajetoRota, setLoadingTrajetoRota] = React.useState(true);
  const [loadingParadasPlanejadas, setLoadingParadasPlanejadas] = React.useState(true);
  const [loadingParada, setLoadingParada] = React.useState(true);
  const [loadingRotaExecutada, setLoadingRotaExecutada] = React.useState(true);
  const [loadingRotaExecutada2, setLoadingRotaExecutada2] = React.useState(true);
  
  const [localInicio, setLocalInicio] = React.useState();
  const [localFim, setLocalFim] = React.useState();

  useEffect(() => {
    if (props.localInicioId) {
      getByIdLocal(props.localInicioId).then(value => {
        setLocalInicio(value);
        setBounds([value, value]);
      });
    }

    if (props.localFimId)
    {
      getByIdLocal(props.localFimId).then(value => {
        setLocalFim(value);
      }); 
    }

    if (props.dataInicio != null) {
      setLoadingRotaExecutada(true);

      setLoadingRotaExecutada2(true);
      var dateF = new Date(props.dataInicio);
      dateF.setHours(23, 59);
      dateF.setDate(dateF.getDate() + 5);

      getParadasByVeiculo(props.veiculoId, props.motoristaId, props.dataInicio, (props.dataFim == null ? dateF : props.dataFim))
        .then((value) => {
          var paradas = value.sort((a, b) => { return new Date(a.dataFim) - new Date(b.dataFim) });
          setParadas(paradas);
          setLoadingParada(false);
        }).catch((e) => {
          console.log('Erro ao buscar paradas');
          console.log(e);

          setLoadingParada(false);
        });

    } else { 
      setLoadingRotaExecutada(false); setLoadingParada(false); 
    }

    getallByRota(props.rotaId).then(async (value) => {
      setParadasPlanejadas([]);
      var entregas = await getAll(props.rotaId);
      await value.forEach(async parada => {
        if (parada.entregaId) {

          var e = await entregas.find((element) => element.id === parada.entregaId)
          parada.entrega = e;
        }
        if (parada.localId) {
          var e = await getByIdLocal(parada.localId);
          parada.local = e;
        }
      });
      setParadasPlanejadas(value);
      setLoadingParadasPlanejadas(false);
      //setTamanhoIcone(40);
    }).catch((e) => {
      console.log('Erro ao buscar entregas');
      console.log(e);
      setLoadingParadasPlanejadas(false);
    });

    getRotaByRota(props.rotaId).then(async (value) => {
      setLoadingTrajetoRota(true);
      setTrajetoRota(false);
      
      var ar = JSON.parse(value.rotaGeoJson + "");
      setTrajetoRota({
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {
              "stroke": "#000000",
              "stroke-width": 5,
              "stroke-opacity": 0.5
            },
            "geometry": {
              "type": "LineString",
              "coordinates": ar
            }
          }
        ]
      });

      setLoadingTrajetoRota(false);
      //setTamanhoIcone(40);
    }).catch((e) => {
      console.log('Erro ao buscar rota');
      console.log(e);
      setLoadingTrajetoRota(false);
    });

    getRotaExecutadaByRota(props.rotaId).then(async (value) => {
      setLoadingRotaExecutada2(true);
      if (value.rotaGeoJson != null && value.rotaGeoJson !== 'null') {
        var ar = JSON.parse("[" + value.rotaGeoJson + "]");

        setTrajetoRotaExecutada({
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {
                "stroke": "#000000",
                "stroke-width": 15,
                "stroke-opacity": 10.5
              },
              "geometry": {
                "type": "LineString",
                "coordinates": ar
              }
            }
          ]
        });

        setLoadingRotaExecutada2(false);
        carregarUltimasPosicoes(value.dataUltimaParada)
        setLoadingRotaExecutada(false);
      } else {
        carregaTodasPosicoes();
      }
    }).catch((e) => {
      setLoadingRotaExecutada2(false);
      carregaTodasPosicoes();
    });

  }, [])

  function carregaTodasPosicoes() {

    var dateF = new Date(props.dataInicio);
    dateF.setHours(23, 59);
    dateF.setDate(dateF.getDate() + 5);
    
    setLoadingRotaExecutada(true);

    var filtroApi = {
      filtro: '',
      page: 0,
      maxRegistro: 100
    }

    getPosiceosByVeiculoPost(props.veiculoId, props.motoristaId, props.dataInicio, (props.dataFim == null ? dateF : props.dataFim), filtroApi).then(async (value) => {
      var coord = [];
      var listas = value.sort((a, b) => { return new Date(a.data) - new Date(b.data) });

      listas.map((v, i) => {
        coord[i] = [parseFloat(v.latitude), parseFloat(v.longitude)];
      })

      setPosicoes(listas);
      setCoords(coord);

      var qtdRegistros = listas.length;

      while (qtdRegistros >= filtroApi.maxRegistro) {
        filtroApi.page++;

        var value2 = await getPosiceosByVeiculoPost(props.veiculoId, props.motoristaId, props.dataInicio, (props.dataFim == null ? dateF : props.dataFim), filtroApi);
        var listas2 = value2.sort((a, b) => { return new Date(a.data) - new Date(b.data) });

        qtdRegistros = listas2.length;

        var coord2 = [];
        listas2.map((v, i) => {
          coord2[i] = [parseFloat(v.latitude), parseFloat(v.longitude)];
        });

        coord = coord.concat(coord2);
        
        setCoords(coord);

        listas = listas.concat(listas2);
        setPosicoes(listas);
      }

      setLoadingRotaExecutada(false);
    }).catch((e) => {
      console.log('Erro ao buscar posições');
      console.log(e);
      setLoadingRotaExecutada(false);
    });
  }
  function carregarUltimasPosicoes(ultimaPosicao) {

    var dateF = new Date(props.dataInicio);
    dateF.setHours(23, 59);
    dateF.setDate(dateF.getDate() + 5);

    var filtroApi = {
      filtro: '',
      page: 0,
      maxRegistro: 100
    }

    getPosiceosByVeiculoPost(props.veiculoId, props.motoristaId, ultimaPosicao, (props.dataFim == null ? dateF : props.dataFim), filtroApi).then(async (value) => {

      var coord = [];
      var listas = value.sort((a, b) => { return new Date(a.data) - new Date(b.data) });

      listas.map((v, i) => {
        coord[i] = [parseFloat(v.latitude), parseFloat(v.longitude)];
      })

      setCoordsUltimas(coord);
      setPosicoes(listas);
      
      var qtdRegistros = listas.length;

      while (qtdRegistros >= filtroApi.maxRegistro) {
        filtroApi.page++;

        var value2 = await getPosiceosByVeiculoPost(props.veiculoId, props.motoristaId, ultimaPosicao, (props.dataFim == null ? dateF : props.dataFim), filtroApi);
        var listas2 = value2.sort((a, b) => { return new Date(a.data) - new Date(b.data) });

        qtdRegistros = listas2.length;

        var coord2 = [];
        listas2.map((v, i) => {
          coord2[i] = [parseFloat(v.latitude), parseFloat(v.longitude)];
        });

        coord = coord.concat(coord2);
        setCoordsUltimas(coord);

        listas = listas.concat(listas2);
        setPosicoes(listas);
      }
    }).catch((e) => {
      console.log('Erro ao buscar posições');
      console.log(e);
    });
  }

  return (
    <Grid component="main" className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom: '0px' }}>
          <Grid container xs={12} style={{ paddingBottom: '0px' }}>
            <Grid item xs={9} >
              <Grid item xs={3}>
                <Box sx={{ width: 500 }}>
                  <Typography id="input-slider" gutterBottom variant="h7">
                    Visualizar no mapa:
                  </Typography>
                </Box>
              </Grid>
              <Chip variant={withRotas ? "default" : "outlined"} color="primary" size="medium" label="Rota executada - na cor preta" style={{ margin: 5 }} onClick={() => setWithRotas(!withRotas)} />
              <Chip variant={withRotaPlanejada ? "default" : "outlined"} color="primary" size="medium" label="Rota planejada - na cor laranja" style={{ margin: 5 }} onClick={() => setWithRotaPlanejada(!withRotaPlanejada)} />
              <Chip variant={withParadasPlanejadas ? "default" : "outlined"} color="primary" size="medium" label="Paradas planejadas" style={{ margin: 5 }} onClick={() => setWithParadasPlanejadas(!withParadasPlanejadas)} />
              <Chip variant={withPassoAPasso ? "default" : "outlined"} color="primary" size="medium" label="Data/Hora da posição" style={{ margin: 5 }} onClick={() => setWithPassoAPasso(!withPassoAPasso)} />
              <Chip variant={withParadasNaoIdentificado ? "default" : "outlined"} color="primary" size="medium" label="Parada não identificadas" style={{ margin: 5 }} onClick={() => setWithParadasNaoIdentificado(!withParadasNaoIdentificado)} />
              {loadingParadasPlanejadas || loadingTrajetoRota || loadingRotaExecutada || loadingRotaExecutada2 || loadingParada ?
                <Typography variant="h7" style={{ paddingLeft: '15px' }}>
                  Carregando...
                </Typography>
                : <></>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MapContainerLeft
            withRotas={withRotas}
            withRotaPlanejada={withRotaPlanejada}
            withSequencia={withSequencia}
            withJanela={withJanela}
            withPassoAPasso={withPassoAPasso}
            withParadasPlanejadas={withParadasPlanejadas}
            withParadasNaoIdentificado={withParadasNaoIdentificado}
            paradas={paradas}
            paradasPlanejadas={paradasPlanejadass}
            trajetoRota={trajetoRota}
            trajetoRotaExecutada={trajetoRotaExecutada}
            coords={coords}
            posicoes={posicoes}
            coordsUltimas={coordsUltimas}
            bounds={bounds}
            localInicio={localInicio}
            localFim={localFim}
            tamanhoIcon={tamanhoIcone} />
        </Grid>
      </Grid>
    </Grid >
  );
}
