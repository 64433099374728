import { DialogEvidenciaMediator } from '../../Components/DialogParaEvidencia';

export const columnsGrid = [
    { label: 'Nome', field: 'nome', type: 'text', options: [], padding: 'default' },
    { label: 'Entrega', field: 'entrega', type: 'customWithRow', options: [], padding: 'default', function: entrega },
    { label: 'Num. documento', field: 'numeroDocumentoFiscal', type: 'customWithRow', options: [], padding: 'default', function: numDocumento },
    { label: 'Data', field: 'data', type: 'datetime', padding: 'default' },
    { label: 'Local', field: 'latitude', padding: 'default', type: "customWithRow", function: local },
    { label: 'Ações', field: 'acoes', type: 'customWithRow', padding: 'default', function: AcessoNoMapa, width: '100px', align: "center" }
];

function entrega(row) {
    var razaoSocial = row.entrega.destinatario.razaoSocial;
    var nomeFantasia = row.entrega.destinatario.nomeFantasia;

    var documentoDestinatario = '';
    if (row.entrega.destinatario.cnpj != null)
        documentoDestinatario = row.entrega.destinatario.cnpj;
    if (row.entrega.destinatario.cpf != null)
        documentoDestinatario = row.entrega.destinatario.cpf;

    if ((razaoSocial === nomeFantasia || nomeFantasia == null) && razaoSocial != null) {
        return razaoSocial + " (" + documentoDestinatario + ")";
    } else if (nomeFantasia != null && razaoSocial != null) {
        return nomeFantasia + " | " + razaoSocial + " (" + documentoDestinatario + ")";
    } else {
        return razaoSocial;
    }
}

function numDocumento(row) {
    const numeroDocumentos = row.entrega.nFs.map(x => x.numeroDocumentoFiscal);
    return numeroDocumentos.join(", ");
}

function AcessoNoMapa(row) {
    return <DialogEvidenciaMediator evidencia={row}></DialogEvidenciaMediator>
}

function local(row) {
    return row.latitude + ', ' + row.longitude;
}

export const camposForm = [
    { name: "rotaId", label: "Rota", type: "lookup", size: 3, required: false, options: [] },
    { name: "situacao", label: "Situação", type: "option", size: 3, required: true, disabled: true },
    { name: "data", label: "Data", type: "date", size: 2, required: true },
    { name: "dataInicio", label: "Início", type: "datetime", size: 2, disabled: true },
    { name: "dataFim", label: "Fim", type: "datetime", size: 2, disabled: true },
    { name: "destinatarioId", label: "Destinatário", type: "lookup", size: 12, required: true, options: [] },
    { name: "endereco", label: "Endereço", type: "text", size: 12, required: true, disabled: true },
    { name: "empresaId", label: "Empresa", type: "lookup", size: 12, required: true, options: [], disabled: true }
]

