export function formatarNumeroParaReais(numero) {
    if (numero === null || numero === undefined)
        return "";

    const opcoesDeFormatacao = {
        style: 'currency',
        currency: 'BRL',
    };

    const formatador = new Intl.NumberFormat('pt-BR', opcoesDeFormatacao);
    return formatador.format(numero);
}