export const opcaoSituacao = [
    { value: 1, label: 'Entregue' },
    { value: 2, label: 'Devolvido' }, 
    { value: 3, label: 'EntregueParcial' }, 
    { value: 4, label: 'Cancelada' },
    { value: 5, label: 'Reagendada' },
    { value: 7, label: 'Aguardando' }];

export const columnsGrid = [
    { label: 'Destinatário', field: 'destinatario.descricao', type: 'text', options: [], padding: 'default' },
    { label: 'Data emissão', field: 'dataEmissao', type: 'datetime', padding: 'default' },
    { label: 'Número', field: 'numeroDocumento', padding: 'default', type: "text" },
    { label: 'Chave', field: 'chave', padding: 'default', type: "text" },

    // { label: 'Peso', field: 'pesoTotal', padding: 'default' },
	// { label: 'Cubagem', field: 'cubagem', padding: 'default' },
	// { label: 'Volume', field: 'volumeTotal', padding: 'default' },
    // { label: 'Total', field: 'total', padding: 'default', type: "text" },
    
    { label: 'Logradouro', field: 'endereco.logradouroNumero', type: 'text', padding: 'default' },
    { label: 'Bairro', field: 'endereco.bairro', type: 'text', padding: 'default' },
    { label: 'Cidade/UF', field: 'endereco.nomeMunicipioSigla', type: 'text', padding: 'default' },
    { label: 'CEP', field: 'endereco.cep', type: 'text', padding: 'default' },

    ];

