import React, { useEffect } from 'react';
import './Grafico.css';
import Text from '@material-ui/core/Typography';

function BarraComponente(props) {
  
  return (
    
      <div class="progress-bar" style={{backgroundColor: props.cor, width: props.percentual, height: '20px'}}>
        <Text class="text-progress-bar" hidden={props.hideText} style={{fontSize: '14px'}}>
        {props.percentual}
        </Text>
      </div>
    
  );
}


var Grafico = (props) => {

  var element = [];

  props.chartBars.forEach(x => {
    if(x.percentual > 100){
      element.push(<BarraComponente cor={x.cor} percentual='100%' size={props.size} hideText={x.hideText}/>);
    } else{
      element.push(<BarraComponente cor={x.cor} percentual={x.percentual} size={props.size} hideText={x.hideText}/>);
    }
  })
  
  return (
      <div className="progress-container" style={{height: '20px'}}>
        {element}
      </div>
  );
};


export default Grafico;
