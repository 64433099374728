import { BoxContainer, Image } from "./BoxImage.style";

export const BoxImage = (props) => {
    return (
        <BoxContainer
            component="div"
            {...props}
        >
            <Image
                component="img"
                src={props.src}
            />
        </BoxContainer>
    );
}