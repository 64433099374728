import React, { Component, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Marker, Popup } from 'react-leaflet'
import { verdePastel, vermelhoPastel } from './MarkerColors';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import Avatar from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as localPlanejado_3 } from '../../img/icon/localPlanejado_4.svg';
import { getAll } from '../../API/NotaFiscalItens';
import { getAll as getAllNotas } from '../../API/NotasFiscais';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import RecolhaIcon2 from '@material-ui/icons/SaveAlt';
import { withStyles } from '@material-ui/core/styles';
import { getFormatDataTimeToString, getFormatTimeHMToString, getDiasSemanas } from '../../Utils/FormatDateTime'
import VisitaIcon from '@material-ui/icons/HowToReg';

const StyledBadge = withStyles((theme) => ({
    badge: {
        top: '-10px', left: '2px', backgroundColor: "#625b5b", color: "white", fontSize: '13px'
    },
}))(Badge);

function MarkerRecolha(props) {
    var verde = false; var vermelho = false;
    
    var latitude = 0; var longitude = 0; 
    
    if (props.paradaPlanejada) {
        if (props.paradaPlanejada.situacao == 1) {
            verde = true;
        }
        if (props.paradaPlanejada.situacao == 2) {
            vermelho = true;
        }
    }
    if (props.paradaPlanejada && props.paradaPlanejada.endereco) {
        latitude = props.paradaPlanejada.endereco.latitude;
        longitude = props.paradaPlanejada.endereco.longitude;
    }
    var icone = <SvgIcon component={localPlanejado_3} style={{ width: (props.tamanhoIcon-5)+'px', height: (props.tamanhoIcon-5)+'px'}}/>;
    
    if (props.paradaPlanejada.tipo == 2)
        icone = <RecolhaIcon2 />;

    if (props.paradaPlanejada.tipo == 3)
        icone = <VisitaIcon />;

    if (latitude == 0 || longitude == 0 || latitude == null || longitude == null)
        return <></>;

        const paradaLocalPlanejado = L.divIcon({
            html: ReactDOMServer.renderToString(<StyledBadge badgeContent={props.paradaPlanejada.sequencia+1} color='primary'>
                <Avatar variant="rounded" style={{ 
                marginLeft: '-10px', 
                marginTop: '-10px', 
                backgroundColor: (verde ? verdePastel : vermelho? vermelhoPastel : ''),
                height: props.tamanhoIcon+'px',
                width: props.tamanhoIcon+'px'
                }}>
            {icone}
            
            </Avatar></StyledBadge>)});


    return <Marker
        id={props.paradaPlanejada.id}
        icon={paradaLocalPlanejado}
        position={[latitude, longitude]}
        radius={props.radius} >
        <Popup>
            <Informacao local={props.local} paradaPlanejada={props.paradaPlanejada} />
        </Popup>
    </Marker>;

}

export default MarkerRecolha;

const Informacao = props => {

    const tipoParada = (tipo) => {
        switch (tipo){
            case 0:
                return ''
            case 1:
                return 'Entrega'
            case 2:
                return 'Recolha'
            case 3:
                return 'Visita'
        }
        return "";
    }
    const preencherNome = (dest) =>{
        var nomeFantasia = dest.nomeFantasia;
    var razaoSocial = dest.razaoSocial;
    var documentoDestinatario = dest.cnpj?dest.cnpj:dest.cpf;
var descricao = '';
        if (razaoSocial == nomeFantasia && nomeFantasia != undefined && razaoSocial != undefined && nomeFantasia != 'undefined' && razaoSocial != 'undefined'){
            descricao = razaoSocial + " ("+documentoDestinatario+")";
        }else if ( nomeFantasia != undefined && razaoSocial != undefined && nomeFantasia != 'undefined' && razaoSocial != 'undefined' && razaoSocial != null && razaoSocial != 'null' && razaoSocial != '' && nomeFantasia != null && nomeFantasia != 'null' && nomeFantasia != ''){
            descricao = nomeFantasia + " | "+  razaoSocial + " ("+documentoDestinatario+")";
        }else{
            return '';
        }
        return descricao;
    }

    return (<div >
        <Typography variant="subtitle1"><b>{tipoParada(props.paradaPlanejada.tipo)}</b></Typography>
        <Typography variant="body2">
            <b>Situação: </b>{props.paradaPlanejada.situacao == 1 ? "Realizado" : props.paradaPlanejada.situacao == 2 ?"Não realizado" : "Em rota"}<br />
            
            {props.paradaPlanejada != null && props.paradaPlanejada.comentario != ' '?
                                <><b>Comentário: </b> {props.paradaPlanejada.comentario}<br /></>
                                :<></>}
            {props.paradaPlanejada != null && props.paradaPlanejada.observacaoParaAplicativo && props.paradaPlanejada.observacaoParaAplicativo != ''?
                                <><b>Observação para o motorista: </b> {props.paradaPlanejada.observacaoParaAplicativo}<br /></>
                                :<></>}
            <b>Sequência planejada/realizada: </b>{props.paradaPlanejada.sequencia > 0 ? props.paradaPlanejada.sequencia+1 : " - "} / {props.paradaPlanejada.sequenciaRealizada > 0 ? props.paradaPlanejada.sequenciaRealizada : " - "}<br />
        
            <b>Cliente: </b>{props.paradaPlanejada.destinatario?preencherNome(props.paradaPlanejada.destinatario):''}<br />
            {props.paradaPlanejada.dataRealizada == null?
                <><b>Data: </b> {getFormatDataTimeToString(props.paradaPlanejada.dataRealizada)}<br /></>
            :<></>}
            
            <b>Endereço:</b> {props.paradaPlanejada.endereco.logradouro},{props.paradaPlanejada.endereco.numero}, {props.paradaPlanejada.endereco.bairro}, {props.paradaPlanejada.endereco.complemento}
            <br />
            <br />
            <b>Previsão de horário</b> <br />
            Chegada: {getFormatTimeHMToString(props.paradaPlanejada.chegadaPrevista)} ... Saída: {getFormatTimeHMToString(props.paradaPlanejada.saidaPrevista)}
            <br />
        </Typography>

          
            

        <Typography variant="body2">
            <b>Lat/Lng endereço:</b> <span title='Clique para copiar o lat/lng' onClick={()=> {navigator.clipboard.writeText(props.paradaPlanejada.endereco.latitude+","+props.paradaPlanejada.endereco.longitude)}}>{props.paradaPlanejada.endereco.latitude},{props.paradaPlanejada.endereco.longitude}</span><br/>
            <b>Lat/Lng do aplic.:</b> <span title='Clique para copiar o lat/lng' onClick={()=> {navigator.clipboard.writeText(props.paradaPlanejada.latitudeRealizado+","+props.paradaPlanejada.longitudeRealizado)}}>{props.paradaPlanejada.latitudeRealizado},{props.paradaPlanejada.longitudeRealizado}</span>
        </Typography>
    </div>);
}



