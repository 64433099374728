import React from 'react';
import Chip from '@material-ui/core/Chip';

import {opcaoSituacao} from '../../Pages/Rota/Config'

export default function StatusPagamento(props){

  if (props == 'PIX pago com sucesso.')
    return <Chip variant="default" style={{backgroundColor: 'rgb(92 182 96)', color: 'white', fontSize: '14px', padding: '12px', letterSpacing: '1px' }}label={props} />

  if (props.match(/Erro/))
    return <Chip variant="default" style={{backgroundColor: 'rgb(245 84 72)', color: 'white', fontSize: '14px', padding: '12px', letterSpacing: '1px' }} label={props} />

  return <Chip variant="default" style={{ fontSize: '14px', padding: '12px', letterSpacing: '1px' }} label={props} />;

      
}