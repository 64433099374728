import { KeyboardDatePicker } from '@material-ui/pickers';
import debounce from "lodash.debounce";
import React, { useCallback, useEffect } from 'react';
import { getAll } from '../../API/Relatorios';
import { GridTable } from '../../Components/GridTable/GridTable';
import { RelatoriosNewRoute } from '../../Router/Routing';
import { getUser } from '../../Router/auth';
import { getFormatDataToDataApi } from '../../Utils/FormatDateTime';
import { columnsGrid } from './Config';
import Text from '@material-ui/core/Typography';

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    let [dataInicio, setDataInicio] = React.useState(new Date().setDate(new Date().getDate() - 5));
    let [dataFim, setDataFim] = React.useState(new Date().setDate(new Date().getDate() + 10));
    const tiposDeRelatorio = require('./tiposDeRelatorio.json');

    const handleDataInicioChange = async (date) => {
        dataInicio = date;
        setDataInicio(dataInicio);
        loadRow();
    };

    const handleDataFimChange = (date) => {
        dataFim = date;
        setDataFim(dataFim);
        loadRow();
    };

    async function loadRow()
    {
        setRows([]);
        setLoading(true);

        dataInicio = new Date(dataInicio).setHours(0,0,1,0);
        dataFim = new Date(dataFim).setHours(23,59,59,999);

        var filtroObjeto = {
            Page: 0,
            MaxRegistro: 50,
            EmpresaId: getUser().empresaId, 
            DataInicio: getFormatDataToDataApi(dataInicio), // 01/11/2023
            DataFim: getFormatDataToDataApi(dataFim)  // 28/11/2023
        };

        getAll(filtroObjeto).then((value) => {
            if (value != undefined)
                for (let index = 0; index < value.length; index++) {
                    const element = value[index];
                    element.tipoDoRelatorio = tiposDeRelatorio.find(x => x.id == element.tipoDoRelatorio).nome;
                }
                setRows(value);
            setLoading(false);
        });
        
        setColumns(columnsGrid);
    }
    
    useEffect(async () => {
        loadRow();
    }, [])

    async function atualizar(){
        await loadRow();
    }

    const debouncedChangeHandler = useCallback(debounce(handleDataInicioChange, 1000), []);
    const debouncedChangeHandlerFim = useCallback(debounce(handleDataFimChange, 1000), []);

    const campos = () => {
        return <>
            <KeyboardDatePicker
                disableToolbar
                autoOk
                style={{ width: '150px' }}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Data inicial"
                value={dataInicio}
                onChange={debouncedChangeHandler}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
            <KeyboardDatePicker
                disableToolbar
                autoOk
                style={{ marginLeft: '15px', width: '150px' }}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Data final"
                value={dataFim}
                onChange={debouncedChangeHandlerFim}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />

        </>
    }

    return (
    
                <GridTable title="Relatórios" titleNovo="Novo" 
                rows={rows} 
                columns={columns} 
                LinkNew={RelatoriosNewRoute}
                loading={loading }
                atualizar={atualizar}
                camposFiltros={campos()}
                CanRemove={false}
                />
    );
}