export const TabIndex = {
    Rota: 0,
    Analise: 1,
    AdicionarNF: 2
}

export const TabIndexForm = {
    Rota: 0,
    Entregas: 1,
    Evidencias: 2,
    Mapa: 3,
    RegistroQuilometragem: 4
}

export const AreasGeograficas = {
    Outros: 0,
    RodizioSP: 1
}