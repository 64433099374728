
import React, { useEffect } from 'react';
import MapContainerLeft from '../../Maps/MapContainerLeft';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getVeiculos } from '../../API/Veiculos';
import { getPosiceosByVeiculo } from '../../API/Posicoes';
import { getNotasFiscais } from '../../API/NotasFiscais';
import { getParadasByVeiculo } from '../../API/Paradas';
import { getEntregas } from '../../API/Entregas';
import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    rootChech: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    root: {
        height: '100vh',
    },
    buttonProgress: {
        color: 'black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        height: '100%',
        //margin: theme.spacing(1),
        position: 'relative',
    },
}));

export default function VeiculoMaps() {
    const classes = useStyles();

    const [veiculos, setVeiculos] = React.useState([]);
    const [coords, setCoords] = React.useState([]);
    const [posicoes, setPosicoes] = React.useState([]);
    const [paradasNFe, setParadasNFe] = React.useState([]);
    const [veiculo, setVeiculo] = React.useState('');
    const [inicioDate, setInicioDate] = React.useState(new Date());
    const [fimDate, setFimDate] = React.useState(new Date());
    const [withNF, setWithNF] = React.useState(false);
    const [tamanhoIcone, setTamanhoIcone] = React.useState(30);

    const [withRotas, setWithRotas] = React.useState(true);
    const [withParadas, setWithParadas] = React.useState(true);
    const [withParadasNaoIdentificado, setWithParadasNaoIdentificado] = React.useState(false);
    const [withParadasPlanejado, setWithParadasPlanejado] = React.useState(false);
    const [withParadasInterno, setWithParadasInterno] = React.useState(false);

    const [paradas, setParadas] = React.useState([]);
    const [withEntregas, setWithEntregas] = React.useState(false);
    const [entregas, setEntregas] = React.useState([]);

    const handleChange = (event) => {
        setVeiculo(event.target.value);
    };

    useEffect(() => {

        try {
            setFimDate(fimDate.setHours(23));
            setFimDate(fimDate.setMinutes(57));
        } catch (e) { }

        try {
            setInicioDate(inicioDate.setHours(0));
            setInicioDate(inicioDate.setMinutes(0));
        } catch (e) { }

        getVeiculos().then((value) => {
            if (value !== undefined)
                setVeiculos(value);
        });
    }, [])

    const atualizarMapa = (event) => {
        if (fimDate == null || inicioDate == null)
            alert('Precisa selecionar uma data!');
        else {
            setLoadingNF(true);
            setLoadingParada(true);
            setLoadingEntregas(true);

            setCoords([]);
            setPosicoes([]);
            setParadas([]);
            setParadasNFe([]);
            setEntregas([]);

            if (veiculo !== '') {
                getPosiceosByVeiculo(veiculo, null, inicioDate, fimDate).then((value) => {
                    var coord = [];
                    var listas = value.sort((a, b) => { return new Date(a.data) - new Date(b.data) });

                    listas.map((v, i) => {
                        coord[i] = [parseFloat(v.latitude), parseFloat(v.longitude)];
                    })

                    setCoords(coord);
                    setPosicoes(listas);
                }).catch((e) => {
                    console.log('Erro ao buscar posições');
                    console.log(e);
                    alert('Erro ao buscar posições!');
                });

                getParadasByVeiculo(veiculo, null, inicioDate, fimDate).then((value) => {
                    console.log(value);

                    var listas = value.sort((a, b) => { return new Date(a.dataFim) - new Date(b.dataFim) });
                    setParadas(listas);
                    setLoadingParada(false);
                }).catch((e) => {
                    console.log('Erro ao buscar paradas');
                    console.log(e);

                    setLoadingParada(false);
                    alert('Erro ao buscar paradas!');
                });

                getEntregas(inicioDate, fimDate, veiculo).then((value) => {
                    console.log("Entregas");
                    console.log(value);
                    setEntregas(value);
                    setLoadingEntregas(false);
                }).catch((e) => {
                    console.log('Erro ao buscar entregas');
                    console.log(e);
                    setLoadingEntregas(false);
                    alert('Erro ao buscar entregas!');
                });

            } else {
                setLoadingParada(false);

                getEntregas(inicioDate, fimDate).then((value) => {
                    setEntregas(value);
                    setLoadingEntregas(false);
                }).catch((e) => {
                    console.log('Erro ao buscar entregas');
                    console.log(e);
                    setLoadingEntregas(false);
                    alert('Erro ao buscar entregas!');
                });
            }

            getNotasFiscais(inicioDate, fimDate).then((value) => {

                setParadasNFe(value);
                setLoadingNF(false);
            }).catch((e) => {
                console.log('Erro ao buscar notas');
                console.log(e);
                setLoadingNF(false);
                alert('Erro ao buscar notas!');
            });

        }
    };
    const [loadingNF, setLoadingNF] = React.useState(false);
    const [loadingEntregas, setLoadingEntregas] = React.useState(false);
    const [loadingParada, setLoadingParada] = React.useState(false);

    return (
        <Grid component="main" className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={7}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Veiculos</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={veiculo}
                            onChange={handleChange}
                            label="Veiculos"
                        >
                            <MenuItem value="">
                                <em>Nenhum</em>
                            </MenuItem>
                            {veiculos.map((v, i) => {
                                var nome = v.placa + (v.apelido ? ' (' + v.apelido + ')' : '')
                                return <MenuItem value={v.id}>{nome}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <KeyboardDateTimePicker
                        label="De"
                        format="dd/MM/yyyy HH:mm"
                        value={inicioDate}
                        onChange={setInicioDate}
                        inputVariant="outlined"
                        showTodayButton
                        invalidDateMessage='Data invalida'
                        todayLabel='Hoje'
                        cancelLabel='Cancelar'
                        variant='inline'
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <KeyboardDateTimePicker
                        label="Até"
                        format="dd/MM/yyyy HH:mm"
                        value={fimDate}
                        onChange={setFimDate}
                        inputVariant="outlined"
                        showTodayButton
                        invalidDateMessage='Data invalida'
                        todayLabel='Hoje'
                        cancelLabel='Cancelar'
                        variant='inline'
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} lg={1}>
                    <div className={classes.wrapper}>
                        <Button variant="contained" color="primary" disabled={loadingNF || loadingParada || loadingEntregas} style={{ width: '100%', height: '100%' }} onClick={atualizarMapa}>
                            Buscar
                        </Button>
                        {(loadingNF || loadingParada || loadingEntregas) && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>

                    {/* <IconButton aria-label="Filter" color="primary" >
                        <FilterIcon />
                    </IconButton> */}
                </Grid>
                <Grid item xs={12} style={{paddingBottom: '0px'}}>

                    <Grid container xs={12} style={{paddingBottom: '0px'}}>
                        <Grid item xs={9} >
                            <Chip variant={withNF ? "default" : "outlined"} color="primary" size="medium" label="Notas Fiscais" style={{ margin: 5 }} onClick={() => setWithNF(!withNF)} />
                            {/* <Chip variant={withLojas ? "default" : "outlined"} color="primary" size="medium" label="Lojas" style={{ margin: 5 }} onClick={() => setWithLojas(!withLojas)} /> */}
                            <Chip variant={withRotas ? "default" : "outlined"} color="primary" size="medium" label="Rota" style={{ margin: 5 }} onClick={() => setWithRotas(!withRotas)} />
                            <Chip variant={withEntregas ? "default" : "outlined"} color="primary" size="medium" label="Entregas" style={{ margin: 5 }} onClick={() => setWithEntregas(!withEntregas)} />
                            <Chip variant={withParadas ? "default" : "outlined"} color="primary" size="medium" label="Parada - Todas" style={{ margin: 5 }} onClick={() => setWithParadas(!withParadas)} />
                            <Chip variant={withParadasNaoIdentificado ? "default" : "outlined"} color="primary" size="medium" label="Parada - Não identificadas" style={{ margin: 5 }} onClick={() => setWithParadasNaoIdentificado(!withParadasNaoIdentificado)} />
                            <Chip variant={withParadasInterno ? "default" : "outlined"} color="primary" size="medium" label="Parada - Interno" style={{ margin: 5 }} onClick={() => setWithParadasInterno(!withParadasInterno)} />
                            <Chip variant={withParadasPlanejado ? "default" : "outlined"} color="primary" size="medium" label="Parada - Local planejado" style={{ margin: 5 }} onClick={() => setWithParadasPlanejado(!withParadasPlanejado)} />
                        </Grid>
                        <Grid item xs={3} direction='column'>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-end"
                            >
                                <Grid item xs={3}>
                                    <Box sx={{ width: 30 }}>
                                        <Typography id="input-slider" gutterBottom variant="caption">
                                            Tamanho do ícone
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Slider aria-label="Disabled slider" min={10} max={60} value={tamanhoIcone} onChange={(t, v) => { setTamanhoIcone(v) }} style={{ width: '100px' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {fimDate != null && inicioDate != null ?
                    <Grid item xs={12}>
                        <MapContainerLeft
                            coords={coords} posicoes={posicoes}
                            paradasNFe={paradasNFe} withNF={withNF}
                            //withLojas={withLojas}
                            withRotas={withRotas}
                            withParadas={withParadas} paradas={paradas}
                            withParadasNaoIdentificado={withParadasNaoIdentificado} withParadasPlanejado={withParadasPlanejado} withParadasInterno={withParadasInterno}
                            withEntregas={withEntregas} entregas={entregas}
                            veiculo={veiculos.find(x => x.id === veiculo)}
                            tamanhoIcon={tamanhoIcone} />
                    </Grid>
                    :
                    <></>
                }
            </Grid>
        </Grid >
    );
}
