import React from 'react';
import CapacidadeRota_Grid from './CapacidadeRota_Grid';
import Typography from '@material-ui/core/Typography';

export default function CapacidadeRota_Form(props) {
    return (
        <>
            <Typography variant="caption" display="block" gutterBottom>
                {props.label}
            </Typography>
            <CapacidadeRota_Grid
                variant={"subtitle1"}
                row={{
                    veiculoId: props.values.veiculoId,
                    volumeTotal: props.values.volumeTotal,
                    pesoTotal: props.values.pesoTotal,
                    cubagemTotal: props.values.cubagemTotal,
                    valorTotalCarga: props.values.valorTotalCarga
                }}
                isPeso={props.isPeso}
                isVolume={props.isVolume}
                isCubagem={props.isCubagem}
                isValorCarga={props.isValorCarga}
            />
        </>
    )
}

