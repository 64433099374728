
import React, { useCallback, useEffect } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll, deleteById } from '../../API/Usuarios';
import { UsuariosNewRoute, UsuariosEditRoute } from '../../Router/Routing';
import { columnsGrid } from './Config';
import { FiltroAtivo } from '../../Components/FiltroAtivo/FiltroAtivo';
import { getUser } from '../../Router/auth';
import { Roles } from '../../Utils/Roles';

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filtroAtivo, setFiltroAtivo] = React.useState(true);

    const loadRow = useCallback(async () => {
        setLoading(true);

        try {
            const value = await getAll(filtroAtivo);
            setRows(value || []);
        } finally {
            setLoading(false);
        }

        if (columns.length === 0) {
            setColumns(columnsGrid);
        }
    }, [filtroAtivo, columns.length]);

    useEffect(() => {
        loadRow();
    }, [loadRow])

    async function excluirClick(ids) {
        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            await deleteById(id);
        }
        loadRow();
    }

    const camposFiltro = () => {
        return (
            <FiltroAtivo
                value={filtroAtivo}
                onChange={setFiltroAtivo}
            />
        )
    }

    return (
        <GridTable
            title="Usuários"
            titleNovo="NOVO"
            rows={rows}
            columns={columns}
            LinkNew={UsuariosNewRoute}
            LinkEdit={UsuariosEditRoute}
            loading={loading}
            excluirClick={excluirClick}
            camposFiltros={camposFiltro()}
            CanRemove={function () {
                const user = getUser();
                return user.role === Roles.Suporte || user.role === Roles.Master;
            }()}
        />
    );
}