import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MapIcon from '@material-ui/icons/CloudDownload';
import { saveAs } from "file-saver";

export const opcaoSituacaoRelatorio = [
    { value: 0, label: 'Aguardando processamento' },
    { value: 1, label: 'Em processamento' }, 
    { value: 2, label: 'Processado com sucesso' },
    { value: 3, label: 'Erro' }, 
    { value: 4, label: 'Processado com sucesso e com observação' }
];

export const columnsGrid = [
    { label: 'Tipo do relatório', field: 'tipoDoRelatorio', padding: 'default',width: '400px'  },
    { label: 'Período', field: 'periodo', padding: 'default', type: "default" },
    { label: 'Situacao', field: 'situacao', padding: 'default', type: "customWithRow", function: SituacaoRelatorio },
    {label: 'Criado por', field: 'criadoPorNome', padding: 'default' },
    { label: 'Criado em', field: 'createdDate', padding: 'default', type: "datetime"  },
    {label: 'Processamento finalizado', field: 'processamentoFinalizado', padding: 'default', type: 'datetime'},
    { label: 'Download',field: 'acoes', padding: 'default', type: "customWithRow", function : Download, width: '100px', align:"center" },
];

function Download(row){
    if(row.localAzure == null){
        
        return <></>
    }

    var localQuebrado = row.localAzure.split("/");
    var localAzure = localQuebrado[0] + "/" +localQuebrado[1] + "/" + localQuebrado[2] +'/'+localQuebrado[3];

    return <Tooltip title={"Download"}><IconButton aria-label="delete" size="small" >
    <MapIcon onClick={() => {  
        let url = "https://accountprodblobgeral.blob.core.windows.net/relatorios/" + localAzure + "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwlacupiytfx&se=2124-02-10T02:31:27Z&st=2024-02-09T18:31:27Z&spr=https&sig=R6Y4MGUYENPJsSCAtmEj3o8SMbNhE0yCFL%2BTjA90arU%3D"
        saveAs(url, row.tipoDoRelatorio + ".xlsx");
    }} />

  </IconButton></Tooltip>
}  

function SituacaoRelatorio(row) {
    switch (row.situacao) {
        case 0:
            return <Chip variant="default" style={{fontSize: '14px', padding: '12px', letterSpacing: '1px' }}
                label={'Aguardando processamento'} />;
            break;
        case 1:
            return <Chip variant="default" style={{ backgroundColor: 'rgb(3, 169, 244)', color: 'white', fontSize: '14px', padding: '12px', letterSpacing: '1px' }}
                label={'Em processamento'} />;
            break;
        case 2:
            return <Chip variant="default" style={{ backgroundColor: 'rgb(92 182 96)', color: 'white', fontSize: '14px', padding: '12px', letterSpacing: '1px' }}
                label={'Processado com sucesso'} />;
            break;
        case 3:
            return <Chip variant="default" style={{ backgroundColor: 'rgb(245 84 72)', color: 'white', fontSize: '14px', padding: '12px', letterSpacing: '1px' }}
                label={'Erro'} />;
            break;
        case 4:
            return <Chip variant="default" style={{ backgroundColor: 'rgb(92 182 96)', color: 'white', fontSize: '14px', padding: '12px', letterSpacing: '1px' }}
                label={'Processado com sucesso'} />;
            break;
        default:
            break;
    }
    return <Chip variant="default" style={{ backgroundColor: 'rgb(3, 169, 244)', color: 'white', fontSize: '14px', padding: '12px', letterSpacing: '1px' }}
    label={row.situacao} />
}