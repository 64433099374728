import { Component, useContext, useEffect } from "react";
import { Box, Button, Grid, styled, Tooltip } from "@mui/material";
import { EnderecoForm } from "./components/EnderecoForm/EnderecoForm";
import { MapEndereco } from "./components/MapEndereco/MapEndereco";
import { EnderecoList } from "./components/EnderecoList/EnderecoList";
import { AjusteEnderecoContext } from "../../Context/AjusteEnderecoContext";
import { putAjusteEnderecoManual } from "../../API/Enderecos";
import { withRouter } from "react-router-dom";
import { SharedSnackbarConsumer } from "../../Context/SharedSnackbar.context";
import { AjusteEnderecoRouteStr } from "../../Router/Routing";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const ButtonsBox = styled(Box)(({ theme }) => ({
    margin: "10px 0px",
    [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    [theme.breakpoints.down("lg")]: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",

        '& > *:first-child': {
            display: "flex",
            flexDirection: "column"
        },
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    width: "250px",
    margin: "5px",

    [theme.breakpoints.down("lg")]: {
        width: "100%"
    }
}));

function AjusteEndereco(props) {
    const {
        createEnderecoStr,
        enderecoAjuste,
        filtroApi,
        setFiltroApi,
        coords,
        expanded,
        setExpanded,
        setEnderecoAjuste,
        enderecos,
        setEnderecos
    } = useContext(AjusteEnderecoContext);

    const location = useLocation();
    const isByEmpresa = location.pathname.includes(`${AjusteEnderecoRouteStr}/byEmpresa`);

    const openInMapsLatLng = () => {
        let url = "";

        if (enderecoAjuste != null) {
            const baseUrl = "https://www.google.com/maps/search/"
            url = `${baseUrl}?api=1&query=${enderecoAjuste.latitude ?? 0}%2C${enderecoAjuste.longitude ?? 0}`;
        }

        window.open(url, '_blank', 'noreferrer');
    }

    const openInMapsEndereco = () => {
        let url = "";

        if (enderecoAjuste != null) {
            const baseUrl = "https://www.google.com/maps/search/"
            url = `${baseUrl}?api=1&query=${createEnderecoStr(enderecoAjuste)}`;
        }

        window.open(url, '_blank', 'noreferrer');
    }

    const openInBingEndereco = () => {
        let url = "";

        if (enderecoAjuste != null) {
            const baseUrl = "https://bing.com/maps/default.aspx"
            url = `${baseUrl}?where1=${createEnderecoStr(enderecoAjuste)}`;
        }

        window.open(url, '_blank', 'noreferrer');
    }

    const copyToClipboard = () => {
        let text = createEnderecoStr(enderecoAjuste);
        navigator.clipboard.writeText(text);
    }

    const handleSalvar = () => {
        putAjusteEnderecoManual(enderecoAjuste.id, {
            "id": enderecoAjuste.id,
            "empresaId": enderecoAjuste.empresaId,
            "latitude": coords.lat,
            "longitude": coords.lng
        }).then(resp => {
            props.openSnackbar(resp.message);

            setEnderecos(prevState => {
                const updatedEnderecos = prevState.map((endereco, index) => {
                    if (index === expanded) {

                        return {
                            ...endereco,
                            latitude: coords.lat,
                            longitude: coords.lng,
                            ok: true
                        };
                    }
                    return endereco;
                });

                return updatedEnderecos;
            });

            if (expanded + 1 === filtroApi.maxRegistro) {
                setExpanded(0);
                setFiltroApi(prevState => ({
                    ...prevState,
                    page: prevState.page
                }));
            } else {
                setExpanded(prevState => (
                    prevState + 1
                ));
            }
        }).catch(error => {
            props.openSnackbar("Ocorreu algum erro", "error");
        });
    }

    useEffect(() => {
        setEnderecoAjuste(enderecos[expanded]);
    }, [expanded, enderecos, setEnderecoAjuste]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
            >
                <Box>
                    <EnderecoList byEmpresa={isByEmpresa} />
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={8}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        height: "100%"
                    }}
                >
                    <Grid
                        item
                        xs={3}
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <EnderecoForm />
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <MapEndereco />
                        <ButtonsBox>
                            <Box
                                sx={{
                                    width: "100%"
                                }}
                            >
                                <Tooltip title="Copia o endereço para a área de transferência">
                                    <StyledButton
                                        onClick={copyToClipboard}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Copiar endereco
                                    </StyledButton>
                                </Tooltip>
                                <Tooltip title="Abre o endereço no google maps com base na sua latitude e longitude">
                                    <StyledButton
                                        onClick={openInMapsLatLng}
                                        variant="contained"
                                    >
                                        Abrir no maps (lat, lng)
                                    </StyledButton>
                                </Tooltip>
                                <Tooltip title="Abre o endereço no google maps com base no endereço digitado">
                                    <StyledButton
                                        onClick={openInMapsEndereco}
                                        variant="contained"
                                    >
                                        Abrir no maps (endereço)
                                    </StyledButton>
                                </Tooltip>
                                <Tooltip title="Abre o endereço no bing maps com base no endereço digitado">
                                    <StyledButton
                                        onClick={openInBingEndereco}
                                        variant="contained"
                                    >
                                        Abrir no Bing (endereço)
                                    </StyledButton>
                                </Tooltip>
                            </Box>
                            <StyledButton
                                onClick={handleSalvar}
                                variant="contained"
                                color="success"
                            >
                                Confirmar e próximo
                            </StyledButton>
                        </ButtonsBox>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

const ConfigWithRouter = withRouter(AjusteEndereco);

class AjusteEnderecoSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <ConfigWithRouter
                        openSnackbar={openSnackbar}
                        {...this.props} >
                    </ConfigWithRouter>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default AjusteEnderecoSnackBar;