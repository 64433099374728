import { verdePastel, vermelhoPastel } from '../../../Components/Map/MarkerColors';

const ProgressBar = (props) => {
    const { completed } = props;
  
    const containerStyles = {
      height: 8,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 5,
      //margin: 0
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${(completed > 100?100:completed)}%`,
      backgroundColor: (completed >= 100?vermelhoPastel:verdePastel),
      borderRadius: 'inherit',
      textAlign: 'left'
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
      <div style={containerStyles}>
        
        <div style={fillerStyles}>
        <span style={labelStyles}></span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;