import { Component } from "react";
import Text from '@material-ui/core/Typography';
import { getEmpresa } from "../../../Router/auth";
import { EmpresaIds } from "../../../Utils/Empresa";

class Timer extends Component {
    componentDidMount() {
        this.intervalId = setInterval(() => {
            this.forceUpdate(); // Força a atualização do componente a cada segundo
            this.calcularTrocarCor();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    calcularDiferencaEntreDatas(data1, data2) {
        // Calcula a diferença em milissegundos entre as duas datas
        let diferencaEmMilissegundos = Math.abs(data2 - data1);

        // Calcula os minutos e segundos
        let minutos = Math.floor(diferencaEmMilissegundos / 60000);
        let segundos = Math.floor((diferencaEmMilissegundos % 60000) / 1000);

        // Formata a string no formato mm:ss
        let diferencaFormatada = `${minutos < 10 ? '0' : ''}${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
        return diferencaFormatada;
    }

    calcularTrocarCor() {
        const diffEmMilissegundos = Math.abs(new Date(this.props.currentTime).getTime() - new Date(Date.now()).getTime());
        const diffEmMinutos = diffEmMilissegundos / (1000 * 60);

        var limite = 5;
        if (getEmpresa().id === EmpresaIds.Gessner)
            limite = 15;

        if (diffEmMinutos > limite) {
            this.props.updateColorByTime();
        }
    }

    render() {
        const { currentTime } = this.props;
        const timer = this.calcularDiferencaEntreDatas(new Date(currentTime), new Date(Date.now()));

        return (
            <Text style={{ fontSize: '15px' }}><b>{timer}</b></Text>
        );
    }
}

export default Timer;