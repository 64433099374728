import {instance_ms_posicao } from './axios_Posicao'
import { getFormatDataToDataApi } from '../Utils/FormatDateTime';
import { getUser } from '../Router/auth';


export async function getAllRotaAnalise(dataInicioLocal, dataFimLocal, motoristasIds, veiculosIds, regioesIds, rotaId = -1, page=0, maxRegistro=500) {
  try {
    var filtro = {};
    if(dataInicioLocal ===  "" || dataFimLocal ===  ""){
      filtro = {
        motoristasIds, 
        veiculosIds, 
        regioesIds,
        rotaId,
        page,
        maxRegistro,
        empresaId: getUser().empresaId
      };
    }else{
      filtro = {
        dataInicio: getFormatDataToDataApi(dataInicioLocal),
        dataFim: getFormatDataToDataApi(dataFimLocal),
        motoristasIds, 
        veiculosIds, 
        regioesIds,
        rotaId,
        page,
        maxRegistro,
        empresaId: getUser().empresaId
      };
    }
    
    const response = await instance_ms_posicao.post('/RotaAnalise/GetAllByFiltro', filtro);
    if (response.status ===  200){
        return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}