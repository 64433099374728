import { campoParaSepararRegiao } from "../../Enums/CampoParaSepararRegiao"

export const columnsGrid = [
    { label: 'Descrição', field: 'descricao', padding: 'default' },
    { label: 'Configuração de identificador', field: 'configuracaoIdentificador', padding: 'default' },
    { label: "Ativo", field: 'ativo', type: "boolWithIcon", padding: 'default', }
];

export const opcaoCampoParaSeparar = [
    { value: campoParaSepararRegiao.Bairro, label: 'Bairro' },
    { value: campoParaSepararRegiao.CnpjCpf, label: 'CNPJ / CPF' },
    { value: campoParaSepararRegiao.Cidade, label: 'Cidade' },
    { value: campoParaSepararRegiao.InformacaoComplementar, label: 'Informação Complementar' },
    { value: campoParaSepararRegiao.CEP, label: 'CEP' },
    { value: campoParaSepararRegiao.AreaGeografica, label: "Area Geográfica" }
];

export const camposForm = [
    { name: "descricao", label: "Descrição", type: "text", size: 4, required: true, autoFocus: true },
    { name: "configuracaoIdentificador", label: "Configuração de identificador (separado por virgula ',')", type: "text", size: 4, required: false },
    { name: "ativo", label: "Ativo", type: "bool", size: 2, required: true },
    { name: "campoParaSeparar", label: "Campo para Separar Região", type: "option", size: 2, required: false, options: opcaoCampoParaSeparar },
    { name: "regiaoRoteirizada", label: "Regiao Roteirizada", type: "bool", size: 2, required: true },
]