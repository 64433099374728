import { RotasEditRoute } from '../Router/Routing';
import { Link } from '@mui/material';

export function LinkParaRota(props) {
    if (!props.observacao)
        return <></>;

    var start = props.observacao.indexOf("na rota ") + 8;
    if (start < 1)
        return <></>;
    var end = props.observacao.indexOf(",", start);
    if (end < 1)
        return <></>;
    var idRota = props.observacao.substring(start, end);

    return (
        <Link
            href="#"
            onClick={() => {
                window.open(`${RotasEditRoute}${idRota}`);
            }}
            target="_blank"
            rel="noopener noreferrer"
        >Acessar a rota</Link>
    );
}

export function LinkParaRotaFunction(row) {
    if (row.situacaoDescricao === null) {
        return <></>
    }

    var start = row.situacaoDescricao.indexOf("na rota ") + 8;
    if (start < 1)
        return <></>;

    var end = row.situacaoDescricao.indexOf(",", start);
    if (end < 1)
        return <></>;

    var idRota = row.situacaoDescricao.substring(start, end);

    if (!contemApenasNumeros(idRota))
        return <></>;

    return (
        <Link
            href="#"
            onClick={() => {
                window.open(`${RotasEditRoute}${idRota}`);
            }}
            target="_blank"
            rel="noopener noreferrer"
        >Acessar a rota</Link>
    );
}

function contemApenasNumeros(str) {
    return /^[0-9]+$/.test(str);
}
