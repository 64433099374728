import React, { Component } from 'react';
import { MapContainer, TileLayer, Circle, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import { EditControl } from "react-leaflet-draw"



export default class EditControlExample extends Component {
    constructor(props) {
        super(props);
        this.state = { circlemarker: true };
    }

    render() {
        return (
            <MapContainer center={this.props.center} zoom={13} zoomControl={false} style={{ height: '400px' }}>

                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <FeatureGroup>
                    <EditControl
                        position="topleft"
                        edit={{ remove: false }}
                        onCreated={(x) => { this.props.changeCircle(x); this.setState({ circlemarker: false }); }}
                        onEdited={(x,y,z) => { this.props.changeCircle(x);  }}
                        draw={{
                            rectangle: false,
                            circlemarker: false,
                            circle: false, 
                            //!this.props.update && this.state.circlemarker,
                            marker: false,
                            polygon: false,
                            polyline: false
                        }}

                    />
                    {this.props.children}
                    
                </FeatureGroup>
                
            </MapContainer>
        );
    }
}