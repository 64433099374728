import {instance} from './axios'

export async function getAll() {
    try {
      const response = await instance.get('/api/JanelaHorario');
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function post(registro) {
    try {
      
      const response = await instance.post('/api/JanelaHorario', registro);
      if (response.status ===  201 || response.status ===  200) {
          return { data: response.data, result: 'ok'};
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function put(id, registro) {
    try {
      
      const response = await instance.put('/api/JanelaHorario/'+id, registro);
      if (response.status ===  201 || response.status ===  200) {
          return { data: response.data, result: 'ok'};
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function getById(id) {
    try {
      const response = await instance.get('/api/JanelaHorario/'+id);
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  export async function getByPessoaId(pessoaId) {
    try {
      const response = await instance.get('/api/JanelaHorario?somentePessoa=true&somenteLocal=false&pessoaId='+pessoaId);
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  export async function countByPessoaId(pessoaId) {
    try {
      const response = await instance.get('/api/JanelaHorario/count?pessoaId='+pessoaId);
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export async function deleteById(id) {
    try {
      const response = await instance.delete('/api/JanelaHorario/'+id);
      if (response.status ===  200){
          return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }