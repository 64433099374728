import StatusPagamento from '../../Components/StatusPagamento';

export const columnsGrid = [
    { label: 'Nome do cliente', field: 'nomeDevedor', padding: 'default' },
    { label: 'CPF/CNPJ', field: 'documentoDevedor', padding: 'default', type: 'custom', function: cpfCnpjFormatar},
    //{ label: 'Rota', field: 'rotaId', padding: 'default' },
    { label: 'Data', field: 'dataCriacao', padding: 'default', type: "datetime" },
    { label: 'Situação', field: 'status', padding: 'default',type: 'custom', function: StatusPagamento},
    { label: 'Valor', field: 'valor', padding: 'default', type: "money", numeric: true},
];

function cpfCnpjFormatar(valor){
    try{
    valor = valor.replace(/[^\d]/g, "");

    if (valor.length <= 11)
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"); 
    else
        return valor.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5"); 
    }catch(e){
        return valor;
    }
}
