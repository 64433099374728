import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MapIcon from '@material-ui/icons/CloudDownload';
import { saveAs } from "file-saver";
import { put } from '../../API/PacoteEvidencias';
import { createUrl } from '../../Utils/BlobStorage';
import { SituacaoPacoteEvidencias } from '../../Enums/SituacaoPacoteEvidencias';
import { AZUL_PASTEL, GREEN, VERMELHO_PASTEL } from '../../Utils/ColorsGrafos';

export const columnsGrid = [
    { label: 'Descricao', field: 'descricao', padding: 'default', width: '300px' },
    { label: 'CPF/CNPJ', field: 'cpfCpnj', padding: 'default', align: "center", type: "text" },
    { label: 'Período', field: 'periodo', padding: 'default', align: "center", type: "text" },
    { label: 'Último Status', field: 'usuarioUltimoDownload', padding: 'default', align: "center", type: "customWithRow", function: DesignUltimoDownload },
    { label: 'Quantidade de Downloads', field: 'quantidadeDownloads', padding: 'default', align: "center", type: "integer" },
    { label: 'Previsão de Exclusão', field: 'previsaoDeExclusao', padding: 'default', align: "center", type: "date" },
    { label: 'Download', field: 'acoes', padding: 'default', type: "customWithRow", function: Download, width: '100px', align: "center" },
];

export function Download(row) {
    if (row.pathZipAzure == null) {
        return <></>
    }

    return (
        <Tooltip title={"Download"}>
            <IconButton aria-label="delete" size="small" >
                <MapIcon onClick={() => {
                    let url = createUrl(row.pathZipAzure);
                    saveAs(url);

                    put(row.id).then((value) => {
                        console.log("dados atualizados");
                    });
                }} />
            </IconButton>
        </Tooltip>
    )
}

function DesignUltimoDownload(row) {
    let bgcolor = "";
    let label = "";

    if (row.situacao === SituacaoPacoteEvidencias.AguardandoConclusaoDasEntregas) {
        bgcolor = AZUL_PASTEL;
        label = "Aguardando conclusão das entregas/processamento do pacote";
    }

    if (row.situacao === SituacaoPacoteEvidencias.AguardandoProcessamentoZip) {
        bgcolor = AZUL_PASTEL;
        label = "Aguardando processamento";
    }

    if (row.situacao === SituacaoPacoteEvidencias.ZipProcessado) {
        bgcolor = VERMELHO_PASTEL;
        if (row.usuarioUltimoDownloadId !== null) {
            bgcolor = GREEN;
        }

        label = row.usuarioUltimoDownload;
    }

    return (
        <Chip
            variant="default"
            label={label}
            style={{
                backgroundColor: bgcolor,
                color: 'white',
                fontSize: '14px',
                padding: '12px',
                letterSpacing: '1px'
            }}
        />
    )
}