import { TextField } from "@mui/material";
import useAutoFocus from "../../../Hooks/useAutoFocus";

export const TextFieldVolumes = (props) => {
    const inputVolumes = useAutoFocus();

    return (
        <TextField
            inputRef={inputVolumes}
            label="Volumes"
            type='number'
            sx={{
                width: "200px",
                height: "auto"
            }}
            {...props}
        />
    )
}