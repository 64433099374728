import { createContext, useState } from 'react';

export const MonitoramentoContext = createContext();

export const MonitoramentoProvider = ({children}) => {
    const [atualizarParadas, setAtualizarParadas] = useState(false);

    return (
        <MonitoramentoContext.Provider
            value={{
                atualizarParadas, setAtualizarParadas
            }}
        >
            {children}
        </MonitoramentoContext.Provider>
    );
}

