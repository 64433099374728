import React from 'react';
import { useHistory } from 'react-router-dom';
import { EnderecosNewRoute } from '../../../Router/Routing';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import { SwapCalls } from '@mui/icons-material';

export default function ButtonSubstituto(props) {
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        setOpen(false);
        history.push(EnderecosNewRoute + "?enderecoOrigemId=" + props.id + '&pessoaId=' + props.pessoaId);
    };

    return (
        <Box>
            <Tooltip title="Endereço incorreto? Substituir endereço">
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    startIcon={<SwapCalls />}
                    onClick={handleClickOpen}
                >
                    Endereço incorreto? Substituir endereço
                </Button>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{"Deseja substituir esse endereço?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Caso tenha um endereço fiscal incorreto ou desatualizado, você pode substituir esse endereço por um novo. Nesse caso, novas entregas para o endereço fiscal serão automaticamente substituidas com o novo endereço. Deseja substituir esse endereço?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Não
                    </Button>
                    <Button onClick={handleClose2} color="primary" variant="contained" autoFocus>
                        SIM
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}