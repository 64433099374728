import React, { Component } from 'react'
import Button from '../../../Components/Button/Button';
import SwapCalls from '@material-ui/icons/SwapCalls';
import { updateStatusRoteirizacao } from '../../../API/Rotas';

class ButtonRoteirizar extends Component {

    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    render() {
        var legenda = 'Roteirizar'
        switch (this.props.statusRoteirizacao) {
            case 1:
                legenda = 'Cancelar Roteirização'
                break;
            case 2:
                legenda = 'Re-Roteirizar'
                break;
            default:
                break;
        }

        return (
            <Button
                fullWidth
                variant="outlined"
                loading={this.state.loading}
                color="primary"
                startIcon={<SwapCalls />}
                onClick={() => {
                    this.update(this.props.routeId, this.props.statusRoteirizacao)
                }}
                style={{
                    height: '50px',
                    borderColor: '#fd7826',
                    backgroundColor: '#fd7826',
                    color: 'white'
                }}
            >
                {legenda}
            </Button>

        );

    }

    update = (routeId, statusRoteirizacao) => {
        var s = 1;
        if (statusRoteirizacao === 1)
            s = 0;

        this.setState({ loading: true });
        updateStatusRoteirizacao(routeId, s).then(() => {
            this.props.changeStatus(s);
            this.props.statusRoteirizacao = s;
            this.setState({ loading: false });
        }).catch(() => {
            this.setState({ loading: false });
        });
    }
}

export default ButtonRoteirizar;
