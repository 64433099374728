import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { StatusRota } from "../../Enums/StatusRota";

export const FiltroSituacaoRota = ({value, onChange}) => {
    return (
        <FormControl
            variant="standard"
            sx={{
                minWidth: "150px"
            }}
        >
            <InputLabel>Situação</InputLabel>
            <Select
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            >
                <MenuItem value={-1}>Todos</MenuItem>
                <MenuItem value={StatusRota.AguardandoInicio}>Aguardando Início</MenuItem>
                <MenuItem value={StatusRota.EmRota}>Em Rota</MenuItem>
                <MenuItem value={StatusRota.Finalizada}>Finalizada</MenuItem>
            </Select>
        </FormControl>
    )
}