
import Typography from '@material-ui/core/Typography';
import Grafico from '../Grafico';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { Skeleton } from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';
import Text from '@material-ui/core/Typography';

export default function CardInformacoesComGrafico(props) {


    return (
        <>

            <Grid item xs={props.size} lg={props.size} sm={props.size} xl={props.size}>
                <Tooltip title={<Text style={{ whiteSpace: 'pre-line' }}>
                    {props.tooltipText}
                </Text>}>

                    <Card variant='outlined' style={{ height: '80px' }}>
                        <CardContent style={{ alignItems: 'center', padding: '2px', marginTop: '5px' }}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <IconButton aria-label="camera" style={{ marginRight: '4px' }}>
                                    <Avatar style={{ backgroundColor: 'rgb(187 187 187)' }}>
                                        {props.icon}
                                    </Avatar>
                                </IconButton>
                                <div style={{ width: '100%', marginRight: '10px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography
                                            color="textSecondary"
                                            style={{ fontSize: '20px', marginTop: '3px' }}
                                        >
                                            {props.title}:
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '20px',
                                                marginLeft: '10px',
                                                marginTop: '4px',
                                                color: 'rgb(100 100 100)'
                                            }}
                                        >
                                            {props.info === "" ? <Skeleton style={{ width: '50px' }} /> : props.info}
                                        </Typography>
                                    </div>
                                    <div style={{ marginTop: '2px', marginBottom: '5px' }}>
                                        <Grafico id={props.id} chartBars={props.chartBars} tooltipText={props.tooltipText} />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Tooltip>
            </Grid>

        </>
    );
}