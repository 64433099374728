
import React, { Component } from 'react';
import { GridTable } from '../../../Components/GridTable/GridTable';
import { getAll2 } from '../../../API/NotasFiscais';
import { DocumentosEditRoute } from '../../../Router/Routing';
import { columnsGrid, TabIndexDocumentos } from '../Config';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as notaFiscalIcon } from '../../../img/icon/notaFiscal.svg';
import ImportacaoIcon from '@material-ui/icons/Backup';
import { SharedSnackbarConsumer } from '../../../Context/SharedSnackbar.context';
import Importador from '../../ImportarArquivos/Importador';
import TabHorizontal, { TabPanel } from '../../../Components/TabHorizontal/Tab';
import Tab from '@material-ui/core/Tab';

class GridList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [],
            docNfs: [],
            rows: [],
            loading: true,
            mostrarImportador: true
        };
    }

    carregar = (filtro) => {
        var entregaId;
        if (this.props.filter) {
            if (this.props.filter.entregaId) {
                this.setState({ mostrarImportador: false });
                entregaId = this.props.filter.entregaId;
            }
        }

        this.setState({ rows: [] });
        this.setState({ loading: true });

        getAll2(entregaId, filtro).then((value) => {
            if (value !== undefined)
                this.setState({ rows: value });
            this.setState({ loading: false });
        });

    }

    async componentDidMount() {

        this.carregar();

        this.setState({ columns: columnsGrid });
    }

    render() {
        if (this.state.mostrarImportador) {
            return (
                <>
                    <TabHorizontal
                        value={TabIndexDocumentos.Documentos}
                        abas={[
                            <Tab label="Documentos" index={TabIndexDocumentos.Documentos} icon={<SvgIcon component={notaFiscalIcon} />} />,
                            <Tab label="Importação" index={TabIndexDocumentos.Importacao} icon={<ImportacaoIcon />} />
                        ]}>
                        <TabPanel index={TabIndexDocumentos.Documentos}>
                            <GridTable title=""
                                rows={this.state.rows}
                                columns={this.state.columns}
                                //LinkNew={NotasFiscaisNewRoute} 
                                LinkEdit={DocumentosEditRoute}
                                loading={this.state.loading}
                                CanRemove={false}
                                TemFiltro={true}
                                atualizar={this.carregar}
                                filtrar={this.carregar}
                            />
                        </TabPanel>

                        <TabPanel index={TabIndexDocumentos.Importacao}>
                            <Importador></Importador>
                        </TabPanel>
                    </TabHorizontal>
                </>
            );
        }
        else {
            return (
                <>
                    <GridTable title=""
                        rows={this.state.rows}
                        columns={this.state.columns}
                        //LinkNew={NotasFiscaisNewRoute} 
                        LinkEdit={DocumentosEditRoute}
                        loading={this.state.loading}
                        CanRemove={false}
                        atualizar={this.carregar}
                    />
                </>
            );
        }
    }
}

class SnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <GridList openSnackbar={openSnackbar} {...this.props} >
                    </GridList>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default SnackBar;