import React, { Component, useState } from 'react';

import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

import { update } from '../../../API/ParadaPlanejadas';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import { vermelhoPastel } from '../../../Components/Map/MarkerColors';
import Button from '../../../Components/Button/Button';
import ErrorIcon from '@material-ui/icons/Error';
import { getFormatDataToDataApi } from '../../../Utils/FormatDateTime';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Link from '@material-ui/core/Link';


class ChipMultiplosEnderecos extends Component {

    constructor(props) {
        super(props);

        this.state = { open: false, loading: true, enderecos: [] };
    }

    handleClose2 = () => {
        this.setState({ open: false });
    }

    render() {

        return <>
            <><MenuItem disabled={this.props.disabled} onClick={() => { this.props.onClick(); this.setState({ open: true }); }}>

                <ListItemIcon>
                    <AccessAlarmIcon fontSize="default" />
                </ListItemIcon>
                <Typography variant="inherit">Inserir agendamento</Typography>

            </MenuItem></>

            <SimpleDialog open={this.state.open} onClose={this.handleClose2} paradaPlanejada={this.props.paradaPlanejada} />
        </>
            ;
    }
}
export default ChipMultiplosEnderecos;

export function SimpleDialog(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [inicial, setInicial] = useState('');
    const [final, setFinal] = useState('');
    const [dataVisivel, setDataVisivel] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const { onClose, selectedValue, open, enderecos } = props;

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('/');
    }

    const salvarAgendamento = () => {
        setLoading(true);
        setError('');
        if (inicial == '') {
            setError('Hora minima incorreta.');
            setLoading(false);
            return;
        }
        if (final == '') {
            setError('Hora máxima incorreta.');
            setLoading(false);
            return;
        }
        if (Date.parse('01/01/2011 ' + inicial + ':00') >= Date.parse('01/01/2011 ' + final + ':00')) {
            setError('Hora minima precisa ser menor que a Hora máxima');
            setLoading(false);
            return;
        }

        if (dataVisivel) {
            let dataSelecionada = new Date(selectedDate.getTime()); 
            let dataAtual = new Date(new Date().getTime());

            dataSelecionada.setUTCHours(0, 0, 0, 0);
            dataAtual.setUTCHours(0, 0, 0, 0);

            if (dataSelecionada.getTime() < dataAtual.getTime()) {
                {
                    setError('Data precisa ser menor que a data atual.');
                    setLoading(false);
                    return;
                }
            }
        }

        var i = Date.parse('01/01/2011 ' + inicial + ':00');
        var f = Date.parse('01/01/2011 ' + final + ':00');

        if (dataVisivel) {
            i = Date.parse(formatDate(selectedDate) + ' ' + inicial + ':00');
            f = Date.parse(formatDate(selectedDate) + ' ' + final + ':00');

        }

        var parada = props.paradaPlanejada;
        parada.janelaAgendamentoInicio = getFormatDataToDataApi(i);
        parada.janelaAgendamentoFim = getFormatDataToDataApi(f);

        update(parada.id, parada).then(e => {
            console.log(e);
            setLoading(false);
            handleClose();
            props.paradaPlanejada.atualizarLista();

        }).catch(e => {
            console.log(e);
            setError('Erro ao salvar. Tente novamente');
            setLoading(false);
        });
    }
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
            <DialogTitle id="simple-dialog-title">Inserir horário desejado de chegada no agendamento</DialogTitle>
            <Typography variant="subtitle1" style={{ marginLeft: '20px', marginRight: '10px' }}>Com esse horário o roterizador vai planejar uma rota, com objetivo de chegar próximo ao horário definido. Caso o cliente tenha janela de entrega, o horário do agendamento substitui a janela de entrega (apenas nessa rota).</Typography>
            {error ?
                <div style={{ color: vermelhoPastel }}>
                    <Typography variant="h6" align="center"><ErrorIcon style={{ marginLeft: '20px', marginRight: '10px' }}></ErrorIcon>{error}</Typography>
                </div>
                : <></>}
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center">

                        <Grid item xs={12} sm={6}>
                            <TextField label={"Hora minima"} error={(inicial == '')} variant="outlined" type="time" value={inicial} onChange={(c) => { setInicial(c.target.value) }} fullWidth>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label={"Hora máxima"} style={{ paddingLeft: '5px' }} error={(final == '')} variant="outlined" type="time" value={final} onChange={(c) => { setFinal(c.target.value) }} fullWidth>
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center">

                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle1" >Caso essa rota dure MAIS que UM dia (rota semanal),  <Link onClick={() => { setDataVisivel(!dataVisivel) }} color="inherit">
                                <b>{'CLIQUE AQUI'}</b>
                            </Link> para especificar a DATA do agendamento.</Typography>
                        </Grid>
                        {dataVisivel ?
                            <Grid item xs={12} sm={12}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    fullWidth
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid> : <></>}
                    </Grid>
                    <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center">

                        <Button variant="contained" color="primary" onClick={salvarAgendamento} loading={loading}>
                            Salvar
                        </Button>
                        <Button style={{ marginLeft: '11px' }} variant="outlined" color="primary" onClick={handleClose} >
                            Fechar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};