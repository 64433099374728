import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackbarCustom(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.limpar();
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={props.open || open} autoHideDuration={6000} anchorOrigin={{ vertical:'top', horizontal:'center' }} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.severity}>
          {props.messagem}
        </Alert>
      </Snackbar>
    </div>
  );
}