import { useEffect, useState } from "react"
import { Chip, Typography } from "@mui/material";
import { getWithStatus } from "../../../API/PacoteEvidenciaDocumento";
import { backgroundColor, descricaoStatus, textColor } from "../Config";

export const Status = ({ pacoteDocumentoId }) => {
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getWithStatus(pacoteDocumentoId)
            .then(resp => {
                setStatus(resp.situacaoEntrega);
            }).catch(error => {
                setStatus(null);
            }).finally(e => {
                setLoading(false);
            });
    }, [pacoteDocumentoId]);

    const getDescricaoStatus = (status) => descricaoStatus[status] || descricaoStatus.default;
    const getBgColor = (status) => backgroundColor[status] || backgroundColor.default;
    const getColor = (status) => textColor[status] || textColor.default;

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <Chip
                    label={
                        <Typography
                            sx={{
                                fontSize: '14px',
                            }}
                        >
                            {getDescricaoStatus(status ?? -1)}
                        </Typography>
                    }
                    sx={{
                        minWidth: "120px",
                        backgroundColor: getBgColor(status),
                        color: getColor(status)
                    }}
                />
            )}

        </>
    )
}