import { getEmpresa } from '../../Router/auth';

export const columnsGrid = [
    { label: 'Nome', field: 'nome', padding: 'default' },
    { label: 'Apelido', field: 'apelido', padding: 'default' },
    { label: 'Usuário', field: 'userName', padding: 'default' },
    {
        label: 'Função', field: 'tipo', padding: 'default', type: 'option',
        options: [
            { value: 1, label: 'Motorista' },
            { value: 2, label: 'Monitoramento' },
            { value: 6, label: 'Análise de Logística' },
            { value: 3, label: 'Master' }
         ]
    },
    { label: "Ativo", field: 'ativo', type: "boolWithIcon", padding: 'default' }
];
/*
const categoriaCarteira = [
    { value: 'A', label: 'A' },
    { value: 'AB', label: 'AB' },
    { value: 'AC', label: 'AC' },
    { value: 'AD', label: 'AD' },
    { value: 'AE', label: 'AE' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' }
]
*/

const getPrefixoEmpresa = () => {
    return getEmpresa().prefixo;
}

export const camposForm = [
    { name: "nome", label: "Nome", type: "text", size: 3, required: true, autoFocus: true },
    { name: "apelido", label: "Apelido", type: "text", size: 2 },
    { name: "cpf", label: "CPF", type: "text", size: 2, required: false },
    { name: "telefone", label: "Telefone", type: "text", size: 2, required: false },
    { name: "email", label: "E-mail", type: "text", size: 3, required: false },
    { grupo: "Permissão e dados para acesso ao sistema", name: "tipo", label: "Tipo", type: "option", size: 3, required: true, options: [{ value: 1, label: 'Motorista' }, { value: 2, label: 'Monitoramento' }, { value: 6, label: 'Análise de Logística' }, { value: 3, label: 'Master' }, { value: 4, label: 'Usuário técnico para automação' }, { value: 9, label: 'Sistema Externo - API' }] },
    { name: "userName", label: "Usuário", type: "text", size: 4, required: true, prefix: getPrefixoEmpresa},
    { name: "passwordHasher", label: "Senha", type: "password", size: 4, required: true },
    { name: "ativo", label: "Ativo", type: "bool", size: 1, required: true },
]

