import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, put, getById } from '../../API/Veiculos';
import { SubmissionError } from 'redux-form'
import { camposForm } from './Config';
import { getUser } from '../../Router/auth';
import { VeiculosNewRoute } from '../../Router/Routing';
import axios from 'axios'
import { getAll as getMotoristas } from '../../API/Motoristas';

class FormConfig extends Component {

    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l = true;
        this.state = { alertSucess: false, loading: l, values: { ativo: true, tipoRoteirizacao: -1 }, camposForm: camposForm, criticalErro: '' };
    }

    create = async values => {
        if (values.motoristaId === -1) values.motoristaId = null;
        if (values.localInicioId === -1) values.localInicioId = null;
        if (values.localFimId === -1) values.localFimId = null;

        var ss = values.inicioRota;
        if (values.n_latitude) {
            values.raio = values.n_raio;
            values.longitude = values.n_longitude;
            values.latitude = values.n_latitude
        }

        if (values.inicioRota)
            values.inicioRota = new Date('2020-01-01T' + values.inicioRota + ':00Z')


        if (values.capacidadeDeCargaQuilos) {
            values.capacidadeDeCargaQuilos = values.capacidadeDeCargaQuilos.toString().replaceAll('.', '').replaceAll(',', '.')
        } else
            values.capacidadeDeCargaQuilos = '0.0';

        if (!values.capacidadeDeCargaVolumes) {
            values.capacidadeDeCargaVolumes = '0';
        } else {
            values.capacidadeDeCargaVolumes = (values.capacidadeDeCargaVolumes).toLocaleString('pt-BR');
        }
        if (values.capacidadeDeCargaCubagem) {
            values.capacidadeDeCargaCubagem = values.capacidadeDeCargaCubagem.toString().replaceAll('.', '').replaceAll(',', '.')
        } else
            values.capacidadeDeCargaCubagem = '0.0';


        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
        values.inicioRota = ss;
    }

    update = async values => {

        if (values.motoristaId === -1) values.motoristaId = null;
        if (values.localInicioId === -1) values.localInicioId = null;
        if (values.localFimId === -1) values.localFimId = null;

        var ss = values.inicioRota;
        if (values.inicioRota)
            values.inicioRota = new Date('2020-01-01T' + values.inicioRota + ':00Z')

        if (values.n_latitude) {
            values.raio = values.n_raio;
            values.longitude = values.n_longitude;
            values.latitude = values.n_latitude
        }
        if (values.capacidadeDeCargaQuilos) {
            values.capacidadeDeCargaQuilos = values.capacidadeDeCargaQuilos.toString().replaceAll('.', '').replaceAll(',', '.')
        } else
            values.capacidadeDeCargaQuilos = '0.0';

        if (!values.capacidadeDeCargaVolumes) {
            values.capacidadeDeCargaVolumes = '0';
        } else {
            values.capacidadeDeCargaVolumes = (values.capacidadeDeCargaVolumes).toLocaleString('pt-BR');
        }
        if (values.capacidadeDeCargaCubagem) {
            values.capacidadeDeCargaCubagem = values.capacidadeDeCargaCubagem.toString().replaceAll('.', '').replaceAll(",", ".");
        } else
            values.capacidadeDeCargaCubagem = '0.0';

        if (values.capacidadeDeCargaValorMaximo) {
            values.capacidadeDeCargaValorMaximo = values.capacidadeDeCargaValorMaximo.toString().replaceAll('.', '').replaceAll(",", ".");
        } else
            values.capacidadeDeCargaValorMaximo = '0.0';


        var id = this.props.match.params.id;
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
        values.inicioRota = ss;
    }

    limpar = () => {
        this.setState({ alertSucess: false });
    }

    async componentDidMount() {
        let id = this.props.match.params.id;

        if (id) {
            getById(id).then((value) => {
                if (value !== undefined) {
                    if (value.inicioRota)
                        value.inicioRota = value.inicioRota.split('T')[1].slice(0, -3);
                    this.setState({ values: value });
                }
                this.setState({ loading: false });
            }).catch((e) => {
                throw new Error('Registro não encontrado!');
            });
        } else {
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    empresaId: getUser().empresaId
                }
            }));
        }

        var retornoApi = await axios.all([getMotoristas()])
            .then(axios.spread((motorista) => {
                return {
                    motorista
                };
            }))

        var campos = camposForm;
        var field = campos.find(e => e.name === "motoristaId");

        if (retornoApi['motorista']) {
            field.options = retornoApi['motorista'].map(option => {
                return {
                    value: option.id,
                    label: option.pessoaPortal.nome + ' (' + option.pessoaPortal.apelido + ')'
                }
            })
        }

        this.setState({ camposForm: campos });
    }

    render() {
        let id = this.props.match.params.id;

        if (id) {
            return (
                <Form
                    title="Editar Veículo"
                    form="editVeiculo"
                    fields={this.state.camposForm}
                    onSubmit={this.update}
                    LinkNew={VeiculosNewRoute}
                    CanBack={true}
                    AlertSucess={this.state.alertSucess}
                    initialValues={this.state.values}
                    loading={this.state.loading}
                    limpar={this.limpar}>
                </Form>
            );
        } else {
            return (
                <Form
                    title="Novo Veículo"
                    form="newVeiculo"
                    fields={this.state.camposForm}
                    onSubmit={this.create}
                    LinkNew={VeiculosNewRoute}
                    CanNotSave={true}
                    CanBack={true}
                    AlertSucess={this.state.alertSucess}
                    initialValues={this.state.values}
                    limpar={this.limpar}>
                </Form>
            );
        }
    }
}

export default FormConfig; 