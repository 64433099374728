import React from 'react';
import { Button, CircularProgress, Box } from '@mui/material';

export default function ButtonCustom(props) {
    const buttonProgressStyle = {
        color: 'black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    };

    const wrapperStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
    };

    return (
        <Box sx={wrapperStyle}>
            <Button
                {...props}
                disabled={props.loading || props.disabled}
                sx={{
                    width: '100%',
                    height: '100%',
                    ...props.style,
                    ...props.sx
                }}
            >
                {props.children}
            </Button>

            {props.loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        ...buttonProgressStyle,
                        ...props.styleloading,
                    }}
                />
            )}
        </Box>
    );
}
