import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import VisitaIcon from '@material-ui/icons/HowToReg';
import EntregaIcon from '../../../Icons/EntregaIcon';
import RecolhaIcon2 from '@material-ui/icons/SaveAlt';
import Typography from '@material-ui/core/Typography';
import { getEmpresa } from '../../../Router/auth';

export default function TipoParadaPlanejada(props) {
    const [valor, setValor] = React.useState(props.tipoParadaPlanejada);

    var tiposParadasPlanejadas = getEmpresa().tiposParadasPlanejadas;
    var tiposHabilitados = tiposParadasPlanejadas.split(',');

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant="caption" style={{paddingLeft: '4px'}}>Selecione o tipo de parada:</Typography>
            <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group" style={{ height: '100%' }}>
                {tiposHabilitados.includes('1') ? <Button startIcon={EntregaIcon} variant={valor === 1 ? "contained" : ""} onClick={()=> { setValor(1); props.changeTipo(1);}}>Entrega</Button> : <></>}
                {tiposHabilitados.includes('2') ?<Button startIcon={<RecolhaIcon2 />} variant={valor === 2 ? "contained" : ""} onClick={()=> { setValor(2); props.changeTipo(2);}}>Recolha</Button> : <></>}
                {tiposHabilitados.includes('3') ? <Button startIcon={<VisitaIcon />} variant={valor === 3 ? "contained" : ""} onClick={()=> { setValor(3); props.changeTipo(3);}}>Visita</Button> : <></>}
            </ButtonGroup>
        </div>
    );
}