import { StyledLink } from "./LinkTo.style";

export const LinkTo = (props) => {

    const abrirRota = (e) => {
        e.preventDefault();
        window.open(`${props.path}`);
    }

    return (
        <StyledLink href="" onClick={abrirRota}>
            {props.children}
        </StyledLink>
    )
}