import React, { useEffect, useCallback } from 'react';
import * as storage from "../../Utils/StorageKeys";
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAllRotaAnalise } from '../../API/RotaAnalise';
import { getById as getByIdVeiculos } from '../../API/Veiculos';
import { getById as getByIdmotoristas } from '../../API/Motoristas';
import { getById as getByIdRegioes } from '../../API/Regiao';
import { getFormatDataToString2 } from '../../Utils/FormatDateTime';
import { columnsGrid } from './Config';
import { KeyboardDatePicker } from '@material-ui/pickers';
import debounce from "lodash.debounce";
import { Alert, AlertTitle } from '@material-ui/lab';
import { getUser } from '../../Router/auth';
import MultiplaSelecao from '../../Components/MultiplaSelecao/MultiplaSelecao'

function select(object, campo) {
    return object[campo];
}

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadingEmpresa, setLoadingEmpresa] = React.useState(true);
    const [regioesIds, setRegioesIds] = React.useState([]);
    const [veiculosIds, setVeiculosIds] = React.useState([]);
    const [motoristasIds, setMotoristasIds] = React.useState([]);

    const DATA_INICIO = new Date().setDate(new Date().getDate() - 5);
    const DATA_FIM = new Date().setDate(new Date().getDate() + 10);

    let [dataInicio, setDataInicio] = React.useState(getDataLocalStorage(storage.rota_data_inicio, DATA_INICIO));
    let [dataFim, setDataFim] = React.useState(getDataLocalStorage(storage.rota_data_fim, DATA_FIM));

    useEffect(() => {
        localStorage.setItem(storage.rota_data_inicio, getFormatDataToString2(dataInicio));
    }, [dataInicio]);

    useEffect(() => {
        localStorage.setItem(storage.rota_data_fim, getFormatDataToString2(dataFim));
    }, [dataFim]);

    useEffect(async () => {
        loadRow();
    }, [])

    useEffect(() => {
        loadRow(regioesIds, veiculosIds, motoristasIds);
    }, [regioesIds, veiculosIds, motoristasIds]);

    const handleDataInicioChange = async (date) => {
        dataInicio = date;
        setDataInicio(dataInicio);
        loadRow();
    };

    const handleDataFimChange = (date) => {
        dataFim = date;
        setDataFim(dataFim);
        loadRow();
    };

    function getDataLocalStorage(localStorageName, defaultDate) {
        const textoArmazenamentoLocal = localStorage.getItem(localStorageName);

        if (textoArmazenamentoLocal) {
            let date = new Date(textoArmazenamentoLocal);

            if (!isNaN(date) && date !== undefined) {
                return date;
            }
            return defaultDate;
        }
        return defaultDate;
    }

    const debouncedChangeHandler = useCallback(debounce(handleDataInicioChange, 1000), []);
    const debouncedChangeHandlerFim = useCallback(debounce(handleDataFimChange, 1000), []);

    function regiaoAlterada(ids) {
        setRegioesIds(ids);
    }

    function veiculoAlterado(ids) {
        setVeiculosIds(ids);
    }

    function motoristaAlterado(ids) {
        setMotoristasIds(ids);
    }

    const camposFiltros = () => {

        return <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    style={{ width: '150px' }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data inicial"
                    value={dataInicio}
                    onChange={debouncedChangeHandler}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    style={{ marginLeft: '15px', width: '150px' }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data final"
                    value={dataFim}
                    onChange={debouncedChangeHandlerFim}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <div style={{ marginLeft: '15px', width: '300px', marginTop: '16px', marginBottom: '8px' }}>
                    {<MultiplaSelecao
                        label="Região"
                        placeholder="Selecione a/as regiões desejadas"
                        localStorageName={storage.rota_analise_regioes_selecionadas}
                        endpoint="api/RotaRegioes/lookupMultiplo"
                        onChange={regiaoAlterada}
                    ></MultiplaSelecao>}
                </div>
                <div style={{ marginLeft: '15px', width: '300px', marginTop: '16px', marginBottom: '8px' }}>
                    <MultiplaSelecao
                        label="Veículo"
                        placeholder="Selecione o/os veículos desejados"
                        localStorageName={storage.rota_analise_veiculos_selecionados}
                        endpoint="api/Veiculos/lookupMultiplo"
                        onChange={veiculoAlterado}
                    ></MultiplaSelecao>
                </div>
                <div style={{ marginLeft: '15px', width: '300px', marginTop: '16px', marginBottom: '8px' }}>
                    <MultiplaSelecao
                        label="Motorista"
                        placeholder="Selecione a/as regiões desejadas"
                        localStorageName={storage.rota_analise_motoristas_selecionados}
                        endpoint="api/Motoristas/lookupMultiplo"
                        onChange={motoristaAlterado}
                    ></MultiplaSelecao>
                </div>
            </div>
        </>
    }

    async function loadRow(idsRegioesLocal = [], idsVeiculosLocal = [], idsMotoristasLocal = []) {
        setRows([]);
        setLoading(true);
        setLoadingEmpresa(true);

        await getAllRotaAnalise(dataInicio, dataFim, idsMotoristasLocal, idsVeiculosLocal, idsRegioesLocal).then((value) => {
            if (value !== undefined) {
                setRows(value);
                setLoading(false);
                const veiculosId = [], motoristaId = [], regiaoId = [];
                value.forEach(rota => {
                    var v = select(rota, 'veiculoId');
                    if (veiculosId.indexOf(v) < 0)
                        veiculosId.push(v)
                });
                value.forEach(rota => {
                    var v = select(rota, 'motoristaId');
                    if (motoristaId.indexOf(v) < 0)
                        motoristaId.push(v)
                });
                value.forEach(rota => {
                    var v = select(rota, 'regiaoId');
                    if (regiaoId.indexOf(v) < 0)
                        regiaoId.push(v)
                });

                veiculosId.forEach(veiculoId => {
                    if (veiculoId > 0)
                        getByIdVeiculos(veiculoId).then(v => {
                            var r = value.filter(e => e.veiculoId === veiculoId);
                            if (r != null)
                                r.forEach(x => x.veiculoDescricao = v.placa + ' (' + v.apelido + ')');
                            setRows([]);
                            setRows(value);
                        }).catch(e => console.log(e));
                });
                motoristaId.forEach(motoId => {
                    if (motoId > 0)
                        getByIdmotoristas(motoId).then(v => {
                            var r = value.filter(e => e.motoristaId === motoId);
                            if (r != null && v.registros.length > 0)
                                r.forEach(x => x.motoristaDescricao = v.registros[0].descricao);
                            setRows([]);
                            setRows(value);
                        }).catch(e => console.log(e));

                });
                regiaoId.forEach(regId => {
                    if (regId > 0)
                        getByIdRegioes(regId).then(v => {
                            var r = value.filter(e => e.regiaoId === regId);
                            if (r != null)
                                r.forEach(x => x.regiaoDescricao = v.descricao);
                            setRows([]);
                            setRows(value);
                        }).catch(e => console.log(e));

                });
            }
            setLoading(false);

        });

        // coluna id
        var indexId = columnsGrid.findIndex(el => {
            return el.field === String('rotaIdInt');
        });
        if (getUser().role === 'RLuOKTh1Ko' && indexId === -1)
            columnsGrid.unshift({ label: 'Id', field: 'rotaIdInt', type: 'text', padding: 'default' });

        setColumns(columnsGrid);
        setLoadingEmpresa(false);
    }

    return (
        <>
            <div style={{ with: '100%' }} maxWidth='true'>
                <Alert severity="info" >
                    <AlertTitle>Atenção </AlertTitle>
                    Abaixo, há um resumo das rotas comparando a quilometragem prevista e a realizada.
                    É importante atentar que a quilometragem realizada é uma <strong>estimativa aproximada </strong>,
                    pois os cálculos são baseados nas informações recebidas do GPS (do celular ou rastreador),
                    sujeitas a variações devido à instabilidade de sinal em algumas áreas.

                </Alert>
            </div>

            <GridTable
                rows={rows}
                columns={columns}
                atualizar={loadRow}
                //LinkEdit={RotasEditRoute}
                camposFiltros={camposFiltros()}
                rowsPerPage={50}
                LinkEditFieldId='rotaIdInt'
                CanRemove={false}
                loading={loading || loadingEmpresa} />
        </>
    );
}