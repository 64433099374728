import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SharedSnackbarProvider } from './Context/SharedSnackbar.context';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from './Router/Routing'
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

import { ThemeProvider as ThemeProviderV4, createTheme as createThemeV4 } from '@material-ui/core/styles';
import { ThemeProvider as ThemeProviderV5, createTheme as createThemeV5 } from '@mui/material';

const themeMuiV4 = createThemeV4({
  palette: {
    primary: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#000030',
      light: '#000030',
    },
  },
});

const themeMuiV5 = createThemeV5({
  palette: {
    primary: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#000030',
      light: '#000030',
    },
  },
});

const reducer = combineReducers({
  form: reduxFormReducer // mounted under "form"
})
const store = (window.devToolsExtension
  ? window.devToolsExtension()(createStore)
  : createStore)(reducer)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SharedSnackbarProvider>
        <ThemeProviderV5 theme={themeMuiV5}>
          <ThemeProviderV4 theme={themeMuiV4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <Router>
                <Routing />
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProviderV4>
        </ThemeProviderV5>
      </SharedSnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
