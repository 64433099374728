import React, { useEffect, useState } from 'react';
import { Marker, Popup, LayerGroup, Polyline, Tooltip } from 'react-leaflet'
import { verdePastel, vermelhoPastel } from './MarkerColors';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import Avatar from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as entregaImagem } from '../../img/icon/localPlanejado.svg';
import { getAll } from '../../API/NotaFiscalItens';
import { getAll as getAllNotas } from '../../API/NotasFiscais';
import { getById } from '../../API/Entregas';
import { getByPessoaId } from '../../API/JanelaHorario';
import Typography from '@material-ui/core/Typography';
import { getFormatDataTimeToString, getFormatTimeHMToString, getDiasSemanas } from '../../Utils/FormatDateTime'
import Button from '@material-ui/core/Button';
import { ClientesEditRoute, EntregasEditRoute2 } from '../../Router/Routing';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { getFormatEndereco_01 } from '../../Utils/Endereco';
import { localizacaoEstaForaDoRario } from '../../Utils/Localizacao';

const StyledBadge = withStyles((theme) => ({
    badge: {
        top: '-10px', left: '2px', backgroundColor: "#625b5b", color: "white", fontSize: '13px'
    },
}))(Badge);

const StyledBadgeRealizado = withStyles((theme) => ({
    badge: {
        top: '0px', left: '2px',
        backgroundColor: props => props.color2, fontSize: '13px', color: "white"
    },
}))(Badge);

const StyledBadgeAlerta = withStyles((theme) => ({
    badge: {
        top: '-10px', left: '-10px', backgroundColor: "#f37925", color: "white", fontSize: '20px', border: '1px solid'
    },
}))(Badge);

function MarkerEntrega(props) {
    const [visivel, setVisivel] = useState(false);

    var verde = false;
    var vermelho = false;

    var latitude = 0;
    var longitude = 0;

    if (props.paradaPlanejada) {
        if (props.paradaPlanejada.situacao === 1) {
            verde = true;
        }
    }

    if (props.paradaPlanejada.endereco) {
        latitude = props.paradaPlanejada.endereco.latitude;
        longitude = props.paradaPlanejada.endereco.longitude;
    } else {
        if (props.entrega && props.entrega.endereco) {
            latitude = props.entrega.endereco.latitude;
            longitude = props.entrega.endereco.longitude;
        }
    }
    if (props.entrega && props.entrega.situacao) {
        if (props.entrega.situacao === 7) { verde = false; vermelho = false; }
        if (props.entrega.situacao === 1) { verde = true; vermelho = false; }
        if (props.entrega.situacao === 2) { verde = false; vermelho = true; }
        if (props.entrega.situacao === 3) { verde = true; vermelho = false; }
    }

    if (latitude === 0 || longitude === 0 || latitude === null || longitude === null)
        return <></>;

    var sequencia = 0;
    var sequenciaRealizada = 0;

    if (props.paradaPlanejada.sequencia !== null)
        sequencia = props.paradaPlanejada.sequencia + 1;
    if (props.paradaPlanejada.sequenciaRealizada !== null)
        sequenciaRealizada = props.paradaPlanejada.sequenciaRealizada;

    var color = verdePastel;
    if (sequencia !== sequenciaRealizada)
        color = vermelhoPastel;

    var distancia = localizacaoEstaForaDoRario(latitude,
        longitude,
        props.paradaPlanejada.latitudeRealizado,
        props.paradaPlanejada.longitudeRealizado, 50);

    if (distancia > 0) {
        props.paradaPlanejada.foraDoRaioDistancia = distancia;
        props.paradaPlanejada.foraDoRaio = true;
    }

    var entregaIco = L.divIcon({
        html: ReactDOMServer.renderToString(
            <>
                <StyledBadgeRealizado badgeContent={sequenciaRealizada} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }} color2={color}>
                    <StyledBadge badgeContent={sequencia}>
                        <StyledBadgeAlerta badgeContent={props.paradaPlanejada.foraDoRaio ? '!' : null} color2={color} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                            <Avatar variant="rounded" style={{
                                marginLeft: '-10px',
                                marginTop: '-10px',
                                height: '30 px',
                                width: '30 px',
                                backgroundColor: (verde ? verdePastel : vermelho ? vermelhoPastel : '')
                            }}>
                                <SvgIcon component={entregaImagem} style={{ width: (35 - 5) + 'px', height: (35 - 5) + 'px' }} />
                            </Avatar>
                        </StyledBadgeAlerta>
                    </StyledBadge>
                </StyledBadgeRealizado>
            </>
        )
    });

    return <><Marker
        icon={entregaIco}
        position={[latitude, longitude]}
        radius={props.radius}
        eventHandlers={{
            click: (e) => {
                setVisivel(!visivel);
                setInterval(() => { setVisivel(false) }, 60000);
            },
        }}
    >
        <Popup >
            <Informacao entrega={props.entrega} sequencia={sequencia} sequenciaRealizada={sequenciaRealizada} paradaPlanejada={props.paradaPlanejada} />
        </Popup>
    </Marker>

        {visivel && props.paradaPlanejada.latitudeRealizado !== 0 ?
            <LayerGroup>
                <Marker color={vermelhoPastel} position={[props.paradaPlanejada.latitudeRealizado, props.paradaPlanejada.longitudeRealizado]}>
                    <Tooltip direction="bottom" offset={[-15, 20]} opacity={1} permanent>
                        Local onde o motorista clicou como "Realizado"
                    </Tooltip>
                </Marker>
                <Polyline pathOptions={{
                    color: "#f37925", weight: 6, dashArray: '10,15',
                    lineJoin: 'round'
                }} positions={[
                    [props.paradaPlanejada.latitudeRealizado, props.paradaPlanejada.longitudeRealizado],
                    [latitude, longitude]
                ]}>

                </Polyline></LayerGroup>


            : <></>}
    </>;
}

const Informacao = props => {
    const [itens, setItens] = useState([]);
    const [janelas, setJanelas] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loadedJanela, setLoadedJanela] = useState(false);

    useEffect(() => {
        setLoaded(false);
        setItens([]);

        getAllNotas(props.entrega.id).then(async (value) => {
            if (value !== undefined) {
                for (let index = 0; index < value.length; index++) {
                    const notaFiscal = value[index];
                    await getAll(notaFiscal.id).then((itens) => {
                        if (itens !== undefined)
                            setItens(prev => [...prev, ...itens]);
                    });
                }
            }
            setLoaded(true);
        });

        getById(props.entrega.id).then(async (value) => {
            if (value && value !== undefined) {
                if (value.destinatarioId && value.destinatarioId !== undefined && value.destinatarioId > 0) {
                    getByPessoaId(value.destinatarioId).then(async (valueJanelas) => {
                        if (valueJanelas !== undefined) {

                            for (let index = 0; index < valueJanelas.length; index++) {
                                const janela = valueJanelas[index];
                                var j = {
                                    inicio: getFormatTimeHMToString(new Date(janela.inicio)),
                                    fim: getFormatTimeHMToString(new Date(janela.fim)),
                                    diasDaSemana: [janela.diaDaSemana]
                                }
                                var lii = janelas.find(function (el) {
                                    return el.inicio === j.inicio && el.fim === j.fim;
                                });
                                if (lii && lii !== undefined) {
                                    var rr = lii.diasDaSemana.find(x => { return x === janela.diaDaSemana });
                                    if (rr === undefined)
                                        lii.diasDaSemana.push(janela.diaDaSemana);
                                } else {
                                    janelas.push(j);
                                    setJanelas(janelas);
                                }
                            }
                        }
                        setLoadedJanela(true);
                    });
                }
            }
        });
    }, []);

    if (props.entrega.destinatario === undefined)
        return <></>

    var endereco = props.entrega.endereco;
    if (props.paradaPlanejada.endereco) {
        endereco = props.paradaPlanejada.endereco;
    }


    return (<div >
        <Typography variant="subtitle1"><b>Entrega</b></Typography>
        <Typography variant="body2">
            <b>Situação: </b>{props.entrega.situacao === 1 ? "Entregue" : "Em rota"}<br />
            {props.paradaPlanejada !== null && props.paradaPlanejada.comentario !== ' ' ?
                <><b>Comentário: </b> {props.paradaPlanejada.comentario}<br /></>
                : <></>}
            <b>Sequência planejada/realizada: </b>{props.sequencia > 0 ? props.sequencia : " - "} / {props.sequenciaRealizada > 0 ? props.sequenciaRealizada : " - "}<br />
            <b>Cliente: </b>{props.entrega.destinatario.descricao}<br />
            {props.entrega.dataInicio === null ?
                <><b>Data: </b> {getFormatDataTimeToString(props.entrega.data ?? props.paradaPlanejada.dataRealizada)}<br /></>
                :
                <><b>Data inicio: </b> {getFormatDataTimeToString(props.entrega.dataInicio)}<br />
                    <b>Data fim: </b> {getFormatDataTimeToString(props.entrega.dataFim)}<br />
                </>}

            <b>Endereço:</b> {getFormatEndereco_01(endereco)}
            <br />
            <br />
            <b>Previsão de horário</b> <br />
            Chegada: {getFormatTimeHMToString(props.paradaPlanejada.chegadaPrevista)} ... Saída: {getFormatTimeHMToString(props.paradaPlanejada.saidaPrevista)}
            <br />
            <br />
            <b>Janela de entrega:</b><br />
            {loadedJanela ? <>
                {janelas.length > 0 ? janelas.map(j => {
                    return <>{getDiasSemanas(j.diasDaSemana)}: {j.inicio} - {j.fim}<br />
                    </>
                })

                    : <> Nenhuma janela</>}
            </> :
                <>Carregando...<br />
                </>}
            <br />

        </Typography>
        {loaded ? (itens.length > 0 ? (
            <>
                <Typography variant="body2">
                    Produtos: <br />
                    {itens.map(e => {
                        return (<>
                            <b>{e.quantidadeComUnidadeComercial}</b><font color="#6a6a6a00">. . .</font>{e.descricao.substring(0, 22)}<br />
                        </>)
                    })}<br />
                </Typography>
            </>
        ) : <Typography variant="body2">
            Nenhum produto encontrado<br />
        </Typography>) : (
            <Typography variant="body2">
                Carregando produtos...<br />
            </Typography>
        )
        }

        {props.paradaPlanejada.latitudeRealizado !== 0 ?

            <Typography variant="body2" style={{ color: vermelhoPastel }}>
                <b>ATENÇÃO:</b> Houve uma divergência nessa parada.
                O local onde o motorista clicou como "Realizado", está divergente do local original previsto.
                Confira com o motorista se o endereço do cliente está correto. Caso necessário, <a onClick={() => { window.open(ClientesEditRoute + props.entrega.destinatarioId) }}>clique aqui</a> para corrija-lo.
            </Typography>
            : <></>}

        <Typography variant="body2">
            <b>Endereço original:</b> <a title='Clique para abrir no Google Maps' onClick={() => { window.open('https://www.google.com.br/maps/place/' + props.entrega.endereco.latitude + "," + props.entrega.endereco.longitude, '_blank') }}>Google Maps</a><br />
            {props.paradaPlanejada.latitudeRealizado !== 0 ?
                <><b>Local onde o motorista clicou:</b> <a title='Clique para abrir no Google Maps' onClick={() => { window.open('https://www.google.com.br/maps/place/' + props.paradaPlanejada.latitudeRealizado + "," + props.paradaPlanejada.longitudeRealizado, '_blank') }}>Google Maps</a></>
                : <></>}
        </Typography>

        <Button variant="contained" color="primary" onClick={() => (window.open(EntregasEditRoute2 + props.entrega.id))} >Ver entrega</Button>
        <Button variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => (window.open(ClientesEditRoute + props.entrega.destinatarioId))} >Ver destinatário</Button>
    </div>);
}

export default MarkerEntrega;

