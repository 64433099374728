import EntregaAvatar from '../../Icons/EntregaAvatar';
import LocalPlanejadoAvatar from '../../Icons/LocalPlanejadoAvatar';
import LocalInternoAvatar from '../../Icons/LocalInternoAvatar';
import RecolhaAvatar from '../../Icons/RecolhaAvatar';
import VisitaAvatar from '../../Icons/VisitaAvatar';
import { getFormatTimeHMToString, getFormatDataToString, getFormatDataTimeToString, getFormatDataTimeToStringComAsSemAno, formatarAnoData } from '../../Utils/FormatDateTime';
import WarningIcon from '@material-ui/icons/Warning';
import Form from "../Destinatario/Form";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import JanelaParada from './Componentes/JanelaParada';
import ChipComDialog from './Componentes/ChipComDialog'
import OpcaoMenu from './Componentes/OpcaoMenu'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import LastIcon from '@material-ui/icons/LastPage';
import FirstIcon from '@material-ui/icons/FirstPage';
import MessageIcon from '@material-ui/icons/Message';
import { opcaoSituacao as opcaoSituacaoEntrega } from '../Entrega/Config';
import AddToPhotosIcon from '@material-ui/icons/AddCircle';
import { ReactComponent as observacaoMotorista } from '../../img/icon/observacaoMotorista.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import { formatarNumeroParaReais } from '../../Utils/Money';
import { Box } from '@mui/material';
import { LARANJA_DA_GRAFOS } from '../../Utils/ColorsGrafos';
import { DescricaoParada } from './Componentes/DescricaoParada';

export const camposForm2 = [

	{ name: "rotaId", label: "Rota", type: "lookup_assincrono", size: 4, required: false, endpoint: "api/Rotas/lookup", linkToCriar: false, podeAlterar: false },
	{ name: "data", label: "Data", type: "date", size: 4, required: false },
	{ name: "situacao", label: "Situação", type: "option", size: 4, required: false, options: opcaoSituacaoEntrega, disabled: true },

	{ name: "tipo", type: "component", component: '', size: 12 },

	{ name: "destinatarioId", label: "Destinatário", type: "lookup_assincrono", size: 6, required: true, endpoint: "api/Pessoas/lookup", linkToCriar: true, componentToCriar: Form, podeAlterar: false },
	{ name: "enderecoId", label: "Endereço", type: "lookup_assincrono", size: 6, required: true, endpoint: "api/Enderecos/lookup", disabled: false, lookup_parametro: '' },
	{ name: "ObservacaoParaAplicativo", label: "Observação para o motorista", type: "text", size: 12, required: false, disabled: false },

]
export const camposForm_Edit = [
	{ name: "rotaId", label: "Rota", type: "lookup_assincrono", size: 3, required: false, endpoint: "api/Rotas/lookup", linkToCriar: false, podeAlterar: false },
	{ name: "situacao", label: "Situação", type: "option", size: 3, required: false, options: opcaoSituacaoEntrega, disabled: true },
	{ name: "data", label: "Data", type: "date", size: 2, required: false },
	{ name: "dataInicio", label: "Início", type: "datetime", size: 2, disabled: true },
	{ name: "dataFim", label: "Fim", type: "datetime", size: 2, disabled: true },

	{ name: "destinatarioId", label: "Destinatário", type: "lookup_assincrono", size: 12, required: true, endpoint: "api/Pessoas/lookup", linkToCriar: true, componentToCriar: Form, podeAlterar: true },
	{ name: "endereco", label: "Endereço", type: "text", size: 12, required: true, disabled: true },
	{ name: "comentario", label: "Comentário", type: "text", size: 12, required: false, disabled: true },
]

export const opcaoSituacao = [
	{ value: 1, label: 'Realizado' },
	{ value: 0, label: 'Aguardando' },
	{ value: 2, label: 'Não realizado' }];

export const columnsGrid = [
	{ label: <>Previsto /<br />Realizado</>, field: 'sequencia', type: 'customWithRow', padding: 'default', function: getSequencia },
	{ label: 'Tipo', field: 'tipoEntrega', type: 'customWithRow', padding: 'default', function: getTipo },
	{ label: 'Número', field: 'numDocumentos', padding: 'default' },
	{ label: 'Descrição', field: 'descricao', type: 'customWithRow', padding: 'default', function: getDescricao },
	{ label: 'Prazo de Entrega', field: 'previsaoEntrega', type: 'customWithRow', padding: 'default', function: (row) => getPrazoEntrega(row) },
	{ label: 'Peso', field: 'pesoTotal', type: 'customWithRow', padding: 'default', width: 150, function: (row) => getPeso(row.pesoTotal) },
	{ label: 'Cubagem', field: 'cubagem', type: 'customWithRow', padding: 'default', function: getCubagem },
	{ label: 'Volume', field: 'volumeTotal', type: 'customWithRow', padding: 'default', function: (row) => { return (row.volumeTotal > 0 ? row.volumeTotal + ' Vol' : '') } },
	{ label: 'Valor da Carga', field: 'valorTotal', type: 'customWithRow', padding: 'default', width: 200, function: (row) => formatarNumeroParaReais(row.valorTotal) },
	{ label: 'Valor do Frete', field: 'valorFrete', type: 'customWithRow', padding: 'default', width: 200, function: (row) => formatarNumeroParaReais(row.valorFrete) },
	{ label: 'Previsão de horário', field: 'chegadaPrevista', type: 'customWithRow', padding: 'default', function: getPrevisao },
	{ label: '', field: 'acaoStatus', type: 'customWithRow', padding: 'default', function: actionsStatus, align: "center" },
	{ label: '', field: 'acao', type: 'customWithRow', padding: 'default', function: actions, align: "right" },
];

function getPrazoEntrega(row) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box>{formatarAnoData(row.previsaoEntrega)}</Box>
			{(row.agendamentoTransportadora != null && new Date(row.agendamentoTransportadora).getTime() !== new Date('0001-01-01T00:00:00').getTime()) && (
				<Box
					sx={{
						margin: "5px 0",
						color: LARANJA_DA_GRAFOS
					}}
				>
					Agend.
					<br />
					{formatarAnoData(row.agendamentoTransportadora)}
				</Box>
			)}
		</Box>
	)
}

function getCubagem(row) {
	if (row.cubagem === null || row.cubagem === undefined) {
		return <></>;
	}

	return <>{row.cubagem.toLocaleString('pt-BR')} m³</>;
}

function getSequencia(row) {
	if (row.sequencia != null && row.sequencia !== undefined)
		return <div style={{ display: 'flex', flexDirection: 'row' }}>
			<div style={{ paddingRight: '10px' }}>{(row.sequencia + 1) + ' / ' + ((row.sequenciaRealizada != null && row.sequenciaRealizada !== undefined) ? row.sequenciaRealizada : '-')}</div>
			<div>{row.adicionadoDuranteRota ? <Tooltip title={title('Adicionado durante a rota')}><AddToPhotosIcon style={{ color: '#bdbdbd' }} /></Tooltip> : <></>}</div>

		</div>

	return <div style={{ display: 'flex', flexDirection: 'row' }}>
		<div style={{ paddingRight: '10px' }}>-</div>
		<div>{row.adicionadoDuranteRota ? <Tooltip title={title('Adicionado durante a rota')}><AddToPhotosIcon style={{ color: '#bdbdbd' }} /></Tooltip> : <></>}</div>

	</div>;
}

function mesagem(row) {
	var handleClick = () => {
	}
	if (row.comentario != null) {
		var xx = row.comentario.toString().replace(' ', '').trim();
		if (row.comentario != null && row.comentario !== 'null' && xx !== "" && xx !== " ")
			return (
				<Tooltip
					title={title("Comentário: " + row.comentario)}>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleClick}
					>
						<MessageIcon />
					</IconButton>
				</Tooltip>
			);
	}
	return <></>;
}

function mesagemParaMotorista(row) {

	if (row.observacaoParaAplicativo != null && row.observacaoParaAplicativo !== 'null' && row.observacaoParaAplicativo !== '' && row.observacaoParaAplicativo !== ' ')
		return (
			<Tooltip title={title("Observação para o motorista: " + row.observacaoParaAplicativo)}>
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={() => { }}
				>
					<SvgIcon component={observacaoMotorista} />
				</IconButton>
			</Tooltip>
		);
	return <></>;
}

function actions(row) {
	return <OpcaoMenu parada={row} />
}

function getPeso(peso) {
	if (peso === undefined || peso === null || peso === 0) {
		return <></>
	}

	let pesoFormatado = Number(peso.toFixed(2));
	return <>
		{pesoFormatado.toLocaleString('pt-BR')} Kg
	</>
}

function getPrevisao(row) {
	return <><div style={{ display: 'flex', alignItems: 'center' }}>
		<Tooltip title={title("Chegada Prevista " + getFormatDataTimeToString(row.chegadaPrevista) + " e saída " + getFormatDataTimeToString(row.saidaPrevista))}>
			<Typography variant="">
				Chegada: {getFormatDataTimeToStringComAsSemAno(row.chegadaPrevista)} <br />
				Saída: {getFormatDataTimeToStringComAsSemAno(row.saidaPrevista)} <br />
				<JanelaParada row={row} />
			</Typography>
		</Tooltip>
	</div>
	</>
}

function title(text, text2) {
	return <div >
		<Typography variant="subtitle1"><b>{text}</b></Typography>
		<Typography variant="body2">{text2}</Typography>
	</div>
}

function mostrarData(data) {
	var dt = new Date(data);
	if (dt.getDate() === 1 && dt.getMonth() === 0 && dt.getFullYear() === 2011)
		return "";
	return " no dia " + getFormatDataToString(data);
}

function actionsStatus(row) {
	var desabilitado = false;

	if (row.desabilitado)//aguardando
		desabilitado = true;
	return <div style={{ display: 'flow-root', alignItems: 'center' }}>
		{mesagem(row)}
		{mesagemParaMotorista(row)}
		{row.janelaAgendamentoFim != null && row.janelaAgendamentoInicio != null ? <ChipComDialog
			title={'Parada com agendamento entre ' + getFormatTimeHMToString(row.janelaAgendamentoInicio) + ' e ' + getFormatTimeHMToString(row.janelaAgendamentoFim) + mostrarData(row.janelaAgendamentoInicio) + ' - Clique para remover agendamento'}
			desabilitado={desabilitado}
			icon={<AccessAlarmIcon />}
			paradaPlanejada={row}
			comDialog={true}
			label={'Com agendamento'}
			campo='janela' />
			: <></>}
		{row.primeiraParada ? <ChipComDialog
			title='Fixado como PRIMEIRA parada para roteirizar. Clique para remover fixação'
			desabilitado={desabilitado}
			icon={<FirstIcon />}
			paradaPlanejada={row}
			comDialog={true}
			label={'Fixado - Primeira parada'}
			campo='primeiraParada' />
			: <></>}
		{row.ultimaParada ? <ChipComDialog
			title='Fixado como ÚLTIMA parada para roteirizar. Clique para remover fixação'
			desabilitado={desabilitado}
			icon={<LastIcon />}
			comDialog={true}
			paradaPlanejada={row}
			label={'Fixado - Última parada'}
			campo='ultimaParada' />
			: <></>}
		{row.atrasado || row.tempoEsperandoNoClienteSegundos > 0 || row.rotaEmDataDiferente ? <ChipComDialog
			title={<>
				{row.atrasado || row.tempoEsperandoNoClienteSegundos > 0 ?
					<>A previsão para chegada, é fora do horário descrito na janela do cliente.<br></br><br></br></>
					: <>O cliente possui janelas de entrega, porém não há janelas para o dia desta rota.  <br></br><br></br></>}
				<>A rota funcionará normalmente, mesmo com essa divergência.</></>}

			desabilitado={false}
			icon={<WarningIcon style={{ color: '#fff4e5' }} />}
			paradaPlanejada={row}
			label={'Divergência com a janela'}
			campo='ultimaParada'
			style={{ color: '#fff4e5', backgroundColor: '#ed6c02' }}

		/>
			: <></>}
	</div>

}

function getDescricao(row, history) {
	return (
		<DescricaoParada {...row} history={history} />
	)
}

function getTipo(row) {
	if (row.entregaId) {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<EntregaAvatar color={getColorBySitucaoEntrega(row)} />
				<Typography variant="">
					Entrega: {getOpcaoSitucaoParada(row)}
					<br />
					{(row.motivo) && (
						<i>{row.motivo.titulo}</i>
					)}
				</Typography>
			</div>
		)
	}
	if (row.localId) {
		if (row.tipoLocal === 0) //Interno
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<LocalInternoAvatar color={getColorBySitucaoEntrega(row)} />
					<Typography variant="">
						Local interno<br />
						{getOpcaoSitucaoParada(row)}
					</Typography>
				</div>
			)

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<LocalPlanejadoAvatar color={getColorBySitucaoEntrega(row)} />
				<Typography variant="">
					Local planejado<br />
					{getOpcaoSitucaoParada(row)}
				</Typography>
			</div>
		)
	}
	if (row.tipo === 2) { //Recolha
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<RecolhaAvatar color={getColorBySitucaoEntrega(row)} />
				<Typography variant="">
					Recolha<br />
					{getOpcaoSitucaoParada(row)}
				</Typography>
			</div>
		)
	}
	if (row.tipo === 3) { //Visita
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<VisitaAvatar color={getColorBySitucaoEntrega(row)} />
				<Typography variant="">
					Visita<br />
					{getOpcaoSitucaoParada(row)}
				</Typography>
			</div>
		)
	}
}

function getOpcaoSitucaoParada(row) {
	if (row.entregaId && row.situacaoParada === 2)
		return 'Devolvida';

	if (row.entregaId > 0) {
		var t = opcaoSituacao.filter(function (item) {
			return item.value === row.situacaoParada;
		});
		return t.length > 0 ? t[0].label : '';
	}
	if (row.situacaoParada === 1)
		return 'Realizada';
	if (row.situacaoParada === 2)
		return 'Não realizada';
	return 'Aguardando';
}

function getColorBySitucaoEntrega(row) {
	if (row.entregaId && row.entregaSituacao === 2)
		return 'red';

	if (row.situacaoEntrega === 1)
		return 'green';
	if (row.situacaoEntrega === 2)
		return 'red';
	return '';
}


