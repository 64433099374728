import { instance } from './axios'

export async function getVeiculos(filtroAtivo = null) {
  try {
    let query = ""
    if (filtroAtivo !== -1) {
      query = `?filtroAtivo=${filtroAtivo}`;
    }

    const response = await instance.get(`/api/Veiculos/GetAll${query}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function post(registro) {
  try {

    const response = await instance.post('/api/Veiculos', registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function put(id, registro) {
  try {

    const response = await instance.put('/api/Veiculos/' + id, registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/Veiculos/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteById(id) {
  try {
    const response = await instance.delete('/api/Veiculos/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}