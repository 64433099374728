
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    Grid
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { Skeleton } from "@mui/material";

export default function CardInformacoes(props) {
    return <>
        <Grid item xs={props.size} lg={props.size} sm={props.size} xl={props.size}>
            <Card variant='outlined' style={{ height: '80px' }}>
                <CardContent style={{ display: 'flex', alignItems: 'center', padding: '2px', marginTop: '5px' }}>
                    <IconButton aria-label="camera" style={{ marginRight: '4px' }}>
                        <Avatar style={{ backgroundColor: props.backgroundColor }}>
                            {props.icon}
                        </Avatar>
                    </IconButton>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <Typography
                                color="textSecondary"
                                style={{ fontSize: '20px', marginTop: '3px' }}
                            >
                                {props.title}:
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    marginLeft: '10px',
                                    marginTop: '4px',
                                    color: 'rgb(100 100 100)'
                                }}
                            >
                                {props.info === "" ? <Skeleton style={{ width: '50px' }} /> : props.info}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    </>
}