export const EmpresaIds = {
    EmpresaDoPedro: 56,
    Panorama: 69,
    Construcolor: 74,
    Rodosuper: 95,
    Transsd: 101,
    Lanto: 108,
    ExpressoJA: 114,
    Gessner: 117,
    TransSdSao: 123,
    LantoSao: 125,
    JJSul: 127,
    Nutricol: 128,
    Krambeck: 158,
    Barracao: 160,
    metalnox: 162
}

export function EhTransportadora(empresaId){
    return empresaId === EmpresaIds.Transsd || empresaId === EmpresaIds.Lanto || empresaId === EmpresaIds.ExpressoJA
    || empresaId === EmpresaIds.LantoSao || empresaId === EmpresaIds.TransSdSao || empresaId === EmpresaIds.JJSul;
}