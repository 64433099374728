import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react"
import { getFormatEndereco_01 } from "../../../Utils/Endereco";
import ControleDeAcesso from "../../../Components/ControleDeAcesso/ControleDeAcesso";
import { Roles } from "../../../Utils/Roles";
import ErrorIcon from '@mui/icons-material/Error';
import CreateIcon from '@mui/icons-material/Create';
import ChipMultiplosEnderecos from "./ChipMultiplosEnderecos";
import { ChipEditarEndereco } from "./ChipEditarEndereco";
import { StatusRoteirizacao } from "../../../Enums/StatusRoteirizacao";

export const DescricaoParada = (props) => {
    const [descricao, setDescricao] = useState();

    useEffect(() => {
        var desc = '';
        var nomeFantasia = props.nomeFantasia;
        var razaoSocial = props.razaoSocial;
        var documentoDestinatario = props.documentoDestinatario;

        if (props.destinatario) {
            nomeFantasia = props.destinatario.nomeFantasia;
            razaoSocial = props.destinatario.razaoSocial;
            documentoDestinatario = props.destinatario.cnpj ? props.destinatario.cnpj : props.destinatario.cpf
        }

        if (documentoDestinatario === 'null' || documentoDestinatario == null)
            documentoDestinatario = '';

        if (razaoSocial === nomeFantasia && nomeFantasia !== undefined && razaoSocial !== undefined && nomeFantasia !== 'undefined' && razaoSocial !== 'undefined') {
            desc = razaoSocial + (documentoDestinatario !== '' ? " (" + documentoDestinatario + ")" : '');
        } else if (nomeFantasia !== undefined && razaoSocial !== undefined && nomeFantasia !== 'undefined' && razaoSocial !== 'undefined' && razaoSocial != null && razaoSocial !== 'null' && razaoSocial !== '' && nomeFantasia != null && nomeFantasia !== 'null' && nomeFantasia !== '') {
            desc = nomeFantasia + " | " + razaoSocial + (documentoDestinatario !== '' ? " (" + documentoDestinatario + ")" : '');
        } else {
            desc = props.descricao;
        }

        setDescricao(desc);
    }, [props]);

    return (
        <Box sx={{ width: "400px" }} >
            <Typography>{descricao}<br />{getFormatEndereco_01(props.endereco)}</Typography>

            <ControleDeAcesso rolesPermitidas={[Roles.Logistica, Roles.Master]}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {props.enderecoEncontradoNoRoteirizador === false ?
                        (
                            <ChipEditarEndereco
                                sx={{
                                    backgroundColor: '#fdecea',
                                    color: '#f44336'
                                }}
                                icon={<ErrorIcon style={{ color: '#f44336' }} />}
                                label="End. não encontrado - Clique para ajustar"
                                pessoaEnderecoId={props.pessoaEnderecoId}
                                rotaId={props.rotaId}
                                history={props.history}
                            />
                        ) : (
                            <ChipEditarEndereco
                                icon={<CreateIcon />}
                                label="Editar este endereço"
                                pessoaEnderecoId={props.pessoaEnderecoId}
                                rotaId={props.rotaId}
                                history={props.history}
                            />
                        )}

                    <ChipMultiplosEnderecos paradaPlanejada={props} />
                </div>
            </ControleDeAcesso>
        </Box>
    )
}