import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LastIcon from '@material-ui/icons/LastPage';
import LooksOneIcon from '@material-ui/icons/FirstPage';
import Typography from '@material-ui/core/Typography';
import InserirAgendamento from './InserirAgendamento';
import FixarParada from './FixarParada';
import ComentarioMotorista from './ComentarioMotorista';
import { ReactComponent as observacaoMotorista } from '../../../img/icon/observacaoMotorista.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Roles } from '../../../Utils/Roles';
import ControleDeAcesso from '../../../Components/ControleDeAcesso/ControleDeAcesso';

const ITEM_HEIGHT = 55;

export default function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '40ch',
                    },
                }}
            >
                <ControleDeAcesso rolesPermitidas={[Roles.Master, Roles.Logistica]}>
                    <InserirAgendamento onClick={handleClose} disabled={props.parada.desabilitado} paradaPlanejada={props.parada} />
                </ControleDeAcesso>

                <ControleDeAcesso rolesPermitidas={[Roles.Master, Roles.Logistica]}>
                    <FixarParada onClick={handleClose} disabled={props.parada.desabilitado} paradaPlanejada={props.parada} title='Fixar como primeira parada' label='primeira' campo='primeiraParada'>
                            <ListItemIcon>
                                <LooksOneIcon fontSize="default" />
                            </ListItemIcon>
                        <Typography variant="inherit">Fixar como primeira parada</Typography>
                    </FixarParada>
                </ControleDeAcesso>

                <ControleDeAcesso rolesPermitidas={[Roles.Master, Roles.Logistica]}>
                    <FixarParada onClick={handleClose} disabled={props.parada.desabilitado} paradaPlanejada={props.parada} title='Fixar como última parada' label='última' campo='ultimaParada'>
                        <ListItemIcon>
                            <LastIcon fontSize="default" />
                        </ListItemIcon>
                        <Typography variant="inherit">Fixar como última parada</Typography>

                    </FixarParada>
                </ControleDeAcesso>

                <ControleDeAcesso rolesPermitidas={[Roles.Master, Roles.Logistica, Roles.Monitoramento]}>
                    <ComentarioMotorista onClick={handleClose} disabled={props.parada.desabilitado} paradaPlanejada={props.parada} title='Editar o comentário para o motorista' label='última' campo='ultimaParada'>
                        <ListItemIcon>
                            <SvgIcon component={observacaoMotorista} />
                        </ListItemIcon>
                        <Typography variant="inherit">Comentário para o motorista</Typography>
                    </ComentarioMotorista>
                </ControleDeAcesso>
            </Menu>
        </div>
    );
}