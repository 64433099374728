import { TextField } from "@mui/material";
import { useState } from "react";
import { REGEX_LATLNG } from "../../Utils/RegexUtils";

export const TextFieldLatLng = (props) => {
    const [error, setError] = useState(false);

    const handleChangeCoords = (e) => {
        var text = e.target.value;

        var latlng = text.split(",")

        var lat = !isNaN(Number(latlng[0])) ? latlng[0] : 0;
        var lng = !isNaN(Number(latlng[1])) ? latlng[1] : 0;

        props.setCoords({
            lat: Number(lat),
            lng: Number(lng)
        });

        setError(!REGEX_LATLNG.exec(text));
    }

    return (
        <TextField
            fullWidth
            label={props.label}
            error={error}
            onChange={handleChangeCoords}
            {...props}
        />
    );
}