import { useContext, useState } from "react";
import { buscaCompletaNotaFiscal } from "../../API/BuscaCompleta";
import Grid from '@material-ui/core/Grid'
import { Skeleton } from "@mui/material";
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@material-ui/core/TextField';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LinkTo } from "./components/LinkTo/LinkTo";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as pinEdit } from '../../img/icon/pinEdit.svg';
import { ResultSearchTable } from "./components/ResultSearchTable/ResultSearchTable";
import { formatarNumeroParaReais } from "../../Utils/Money";
import { getFormatDataTimeToString, getDataInicioDataFim } from "../../Utils/FormatDateTime";
import { createUrl } from "../../Utils/BlobStorage";
import { BuscaCompletaContext } from "../../Context/BuscaCompletaContext";
import {
    BuscaCompletaForm,
    LoadingContainer,
    NoneResultMessage,
    GridInformacoes,
    EvidenciasContainer,
    AcoesContainer,
    SituacaoEntregaComponent
} from "./BuscaCompleta.style";
import { BoxImage } from "./components/BoxImage/BoxImage";
import { getEmpresa, getUser } from "../../Router/auth";
import { opcaoSituacao as opcaoSituacaoRota } from "../Rota/Config";
import { opcaoSituacao as opcaoSituacaoEntrega } from "../Entrega/Config";
import { ClientesEditRoute, DocumentosEditRoute, RotasEditRoute } from "../../Router/Routing";
import { DialogEvidencia } from "../../Components/DialogParaEvidencia/DialogEvidencia";
import { DialogSelectRota } from "../ParadaPlanejada/Componentes/DialogSelectRota";
import { DialogSelectStatus } from "../ParadaPlanejada/Componentes/DialogSelectStatus";
import { SharedSnackbarConsumer } from "../../Context/SharedSnackbar.context";
import { Roles } from "../../Utils/Roles";
import ControleDeAcesso from "../../Components/ControleDeAcesso/ControleDeAcesso";

function BuscaCompleta() {
    const {
        moreInfo,
        setMoreInfo,
        isLoading,
        podeCarregarMais,
        setPodeCarregarMais,
        setIsLoadingCarregarMais
    } = useContext(BuscaCompletaContext);

    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [noneResult, setNoneResult] = useState(false);

    const [dialogEvidenciaOpen, setDialogEvidenciaOpen] = useState(false);
    const [evidencia, setEvidencia] = useState(null);
    const [latLngEvidencia, setLatLngEvidencia] = useState(null);

    const [openTransferir, setOpenTransferir] = useState(false);
    const [openAlterarStatus, setOpenAlterarStatus] = useState(false);

    const [filtroApi, setFiltroApi] = useState({
        filtro: searchTerm,
        page: 0,
        maxRegistro: 10,
        empresaId: getUser().empresaId
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        setRows([]);
        setMoreInfo(null);
        setLoading(true);
        setNoneResult(false);

        setFiltroApi(prevState => ({
            ...prevState,
            page: 0
        }));

        filtroApi.page = 0;

        getResponseBuscaCompleta(filtroApi);
    }

    const getResponseBuscaCompleta = (filtroApi) => {
        buscaCompletaNotaFiscal(filtroApi)
            .then((resp) => {
                setNoneResult(true);
                if (resp !== undefined && resp != null && resp.length > 0) {
                    isPermitidoCarregarMais(resp);
                    setRows(resp);
                    setLoading(false);
                    setNoneResult(false);
                }

                setLoading(false);
            })
            .catch((error) => {
                setRows([]);
                setLoading(false);
                setNoneResult(true);
            });
    }

    const carregarMais = async () => {
        if (!podeCarregarMais) {
            return
        }

        setIsLoadingCarregarMais(true);

        let nextPage = filtroApi.page + 1;

        setFiltroApi(prevState => ({
            ...prevState,
            page: nextPage
        }));

        filtroApi.page = nextPage;

        buscaCompletaNotaFiscal(filtroApi)
            .then((resp) => {
                if (resp !== undefined && resp != null && resp.length > 0) {
                    isPermitidoCarregarMais(resp);
                    let r = rows.concat(resp);
                    setRows(r);
                    setIsLoadingCarregarMais(false);
                }
            })
            .catch((error) => {
                setRows([]);
            });
    }

    const isPermitidoCarregarMais = (respRows) => {
        // logica para a paginação
        setPodeCarregarMais(false);

        // o backend sempre retorna um registro a mais para verificar
        // se podem ser carregados mais registros.
        if (respRows.length > filtroApi.maxRegistro) {
            // caso retorne mais registros do que o filtro passa
            // então o ultimo registro é removido pois ele so serve para verificação
            respRows.pop();
            setPodeCarregarMais(true);
        }
    }

    const handlesearchTerm = (e) => {
        let term = e.target.value;

        setSearchTerm(term);
        setFiltroApi({
            ...filtroApi,
            filtro: term
        });
    }

    const getSituaçãoRota = (status) => {
        var situacaoRota = opcaoSituacaoRota.filter(function (item) {
            return item.value === status;
        });

        if (situacaoRota.length <= 0) {
            return "";
        }

        return situacaoRota.shift().label;
    }

    const getSituacaoEntrega = (situacao) => {
        var situacaoEntrega = opcaoSituacaoEntrega.filter(function (item) {
            return item.value === situacao;
        })

        if (situacaoEntrega.length <= 0) {
            return "";
        }

        return situacaoEntrega.shift().label;
    }

    const handleCloseDialogEvidencia = () => {
        setDialogEvidenciaOpen(false);
    }

    const handleNovaSituacao = (novaSituacao) => {
        getResponseBuscaCompleta(filtroApi);

        setMoreInfo(prevMoreInfo => ({
            ...prevMoreInfo,
            entrega: {
                ...prevMoreInfo.entrega,
                situacao: novaSituacao
            }
        }));
    }

    const isRotaDinamica = () => {
        return getEmpresa().adicionarParadaDuranteRota;
    }

    return (
        <>
            <Grid
                container
                spacing={3}
                direction="row"
                alignItems="stretch"
            >
                <Grid
                    item
                    xl={8}
                    lg={8}
                    md={12}
                >
                    <Typography variant="h5" style={{ marginBottom: 30 }}>
                        <b>Busca Completa</b>
                    </Typography>

                    <BuscaCompletaForm onSubmit={handleSubmit}>
                        <TextField
                            label="Buscar (Nome do cliente, chave/nº pedido, CPF/CNPJ)"
                            variant="filled"
                            value={searchTerm}
                            onChange={handlesearchTerm}
                            style={{ width: "100%", marginRight: "10px" }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ width: "150px" }}
                        >
                            Buscar
                        </Button>
                    </BuscaCompletaForm>

                    <Grid
                        item
                        lg={12}
                    >
                        {(loading) &&
                            <LoadingContainer>
                                <CircularProgress />
                            </LoadingContainer>
                        }

                        {(rows.length > 0 && !loading) &&
                            <Box mt={4}>
                                <ResultSearchTable rows={rows} carregarMais={carregarMais} />
                            </Box>
                        }

                        {(noneResult) && (
                            <NoneResultMessage>Nenhum resultado encontrado.</NoneResultMessage>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    xl={4}
                    lg={4}
                    md={12}
                >
                    {(moreInfo) && (
                        <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                        >
                            <Grid
                                container
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "30px"
                                }}
                            >
                                <Grid
                                    item
                                    xl={4}
                                    lg={12}
                                    sm={6}
                                    xs={6}
                                >
                                    <Typography variant="h5">
                                        <b>Informações</b>
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xl={8}
                                    lg={12}
                                    sm={6}
                                    xs={6}
                                >
                                    <AcoesContainer>
                                        <ControleDeAcesso rolesPermitidas={[Roles.Master,Roles.Suporte, Roles.Logistica]}>
                                            <div>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    startIcon={<SwapHorizIcon />}
                                                    onClick={() => setOpenTransferir(true)}
                                                >
                                                    Transferir de rota
                                                </Button>
                                                <SharedSnackbarConsumer>
                                                    {({ openSnackbar }) => (
                                                        <DialogSelectRota
                                                            open={openTransferir}
                                                            rotaDinamica={() => isRotaDinamica()}
                                                            onClose={() => { setOpenTransferir(false) }}
                                                            idsParadas={[moreInfo.paradaPlanejada ? moreInfo.paradaPlanejada.id : null]}
                                                            idsEntregas={[moreInfo.entrega.id]}
                                                            idsNotasFiscais={[moreInfo.documento ? moreInfo.documento.id : null]}
                                                            rotaId={moreInfo.rota ? moreInfo.rota.id : null}
                                                            openSnackbar={openSnackbar}
                                                        />
                                                    )}
                                                </SharedSnackbarConsumer>
                                            </div>
                                        </ControleDeAcesso>

                                        {(getUser().role === 'RLuOKTh1Ko' || getUser().role === 'Master') ? (
                                            <div>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    startIcon={<SvgIcon component={pinEdit} />}
                                                    onClick={() => setOpenAlterarStatus(true)}
                                                >
                                                    Alterar status
                                                </Button>
                                                <DialogSelectStatus
                                                    open={openAlterarStatus}
                                                    onClose={() => { setOpenAlterarStatus(false) }}
                                                    idsParadas={[moreInfo.paradaPlanejada !== null  ? moreInfo.paradaPlanejada.id : -1]}
                                                    rotaId={moreInfo.rota ? moreInfo.rota.id : null}
                                                    handleNovaSituacao={handleNovaSituacao}
                                                />
                                            </div>
                                        ) : null}
                                    </AcoesContainer>
                                </Grid>
                            </Grid>

                            <GridInformacoes
                                item
                            >
                                <Typography variant="h6">
                                    <b>DESTINATÁRIO</b>
                                </Typography>
                                {(moreInfo.entrega.destinatario != null) ? (
                                    <>
                                    <ControleDeAcesso rolesPermitidas={[Roles.Master,Roles.Suporte]} retornoCasoFalso={
                                        <Typography component="p">
                                            <b>Nome:</b> {moreInfo.entrega.destinatario.nomeDescricao}
                                        </Typography>
                                    }>
                                    <Typography component="p">
                                            <b>Nome:</b> {moreInfo.entrega.destinatario.nomeDescricao} - <LinkTo path={`${ClientesEditRoute}${moreInfo.entrega.destinatario.id}`}>Acessar destinatário</LinkTo>
                                        </Typography>
                                    </ControleDeAcesso>
                                        <Typography component="p">
                                            <b>CPF/CNPJ:</b> {moreInfo.entrega.destinatario.cpfCnpj}
                                        </Typography>
                                        <Typography component="p">
                                            <b>Telefone:</b> {moreInfo.entrega.destinatario.telefone}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography component="p">
                                        Nenhum destinatário
                                    </Typography>
                                )}
                            </GridInformacoes>

                            <GridInformacoes
                                item
                            >
                                <Typography variant="h6">
                                    <b>DOCUMENTO</b>
                                </Typography>

                                {(moreInfo.documento != null) ? (
                                    <>
                                        <Typography component="p">
                                            <b>Chave:</b> {moreInfo.documento.chave} - <LinkTo path={`${DocumentosEditRoute}${moreInfo.documento.id}`}>Acessar documento</LinkTo>
                                        </Typography>
                                        <Typography component="p">
                                            <b>Número do documento:</b> {moreInfo.documento.numeroDocumentoFiscal}
                                        </Typography>
                                        <Typography component="p">
                                            <b>Data de emissão:</b> {getFormatDataTimeToString(moreInfo.documento.dataEmissao)}
                                        </Typography>

                                        <Box p={1}></Box>

                                        <Typography component="p">
                                            <b>Informação complementar:</b> {moreInfo.documento.informacaoComplementar}
                                        </Typography>

                                        <Box p={1}></Box>

                                        <Typography component="p">
                                            <b>Valor total:</b> {formatarNumeroParaReais(moreInfo.documento.valorTotal)}
                                        </Typography>
                                        <Typography component="p">
                                            <b>Peso total:</b> {moreInfo.documento.peso} Kg
                                        </Typography>
                                        <Typography component="p">
                                            <b>Volume:</b> {moreInfo.documento.volume} Vol
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography component="p">
                                        Nenhum documento
                                    </Typography>
                                )}
                            </GridInformacoes>

                            <GridInformacoes
                                item
                            >
                                <Typography variant="h6">
                                    <b>ROTA</b>
                                </Typography>

                                {(moreInfo.rota != null) ? (
                                    <>
                                        <Typography component="p">
                                            <b>Rota ID:</b> {moreInfo.rota.id} - <LinkTo path={`${RotasEditRoute}${moreInfo.rota.id}`}>Acessar rota</LinkTo>
                                        </Typography>
                                        <Typography component="p">
                                            <b>Situação da rota:</b> {getSituaçãoRota(moreInfo.rota.status)}
                                        </Typography>
                                        <Typography component="p">
                                            <b>Data:</b> {getFormatDataTimeToString(moreInfo.rota.data)}
                                        </Typography>
                                        <Typography component="p">
                                            <b>Veículo:</b> {moreInfo.rota.veiculoNome}
                                        </Typography>

                                        {(moreInfo.rota.regiao != null) ? (
                                            <Typography component="p">
                                                <b>Região:</b> {moreInfo.rota.regiao.descricao}
                                            </Typography>
                                        ) : (
                                            isLoading.regiao ? (
                                                <Skeleton />
                                            ) : (
                                                <></>
                                            )
                                        )}

                                        {(moreInfo.rota.motorista != null) ? (
                                            <Typography component="p">
                                                <b>Motorista:</b> {moreInfo.rota.motorista.descricao}
                                            </Typography>
                                        ) : (
                                            isLoading.motorista ? (
                                                <Skeleton />
                                            ) : (
                                                <></>
                                            )
                                        )}
                                    </>
                                ) : (
                                    <Typography component="p">
                                        Nenhuma rota
                                    </Typography>
                                )}
                            </GridInformacoes>

                            <GridInformacoes
                                item
                            >
                                <Typography variant="h6">
                                    <b>ENTREGA</b>
                                </Typography>

                                <Typography component="p">
                                    <b>Situação da entrega:</b> <SituacaoEntregaComponent situacao={moreInfo.entrega.situacao}>{getSituacaoEntrega(moreInfo.entrega.situacao).toUpperCase()}</SituacaoEntregaComponent>
                                </Typography>

                                {(moreInfo.entrega.dataInicio != null && moreInfo.entrega.dataFim != null) && (
                                    <Typography component="p">
                                        <b>Data/hora:</b> {getDataInicioDataFim(moreInfo.entrega.dataInicio, moreInfo.entrega.dataFim)}
                                    </Typography>
                                )}

                                <Box p={1}></Box>

                                {(moreInfo.evidencias != null) ? (
                                    <>
                                        < Typography component="p">
                                            <b>Evidências:</b> (Clique para ampliar a imagem)
                                        </Typography>

                                        <EvidenciasContainer>
                                            {moreInfo.evidencias.map((evidencia) => (
                                                <BoxImage
                                                    key={evidencia.id}
                                                    src={createUrl(evidencia.pathServidor)}
                                                    onClick={() => {
                                                        setDialogEvidenciaOpen(true);
                                                        setLatLngEvidencia({ lat: evidencia.latitude, lng: evidencia.longitude });
                                                        setEvidencia(evidencia);
                                                    }}
                                                />
                                            ))}
                                        </EvidenciasContainer>
                                    </>
                                ) : (
                                    isLoading.evidencias ? (
                                        <EvidenciasContainer>
                                            {Array.from({ length: 5 }, (_, index) => (
                                                <Skeleton
                                                    key={index}
                                                    variant="rectangular"
                                                    width={100}
                                                    height={100}
                                                />
                                            ))}
                                        </EvidenciasContainer>
                                    ) : (
                                        <></>
                                    ))}
                            </GridInformacoes>
                        </Grid>
                    )}
                </Grid>
            </Grid >

            <DialogEvidencia
                open={dialogEvidenciaOpen}
                latLng={latLngEvidencia}
                evidencia={evidencia}
                handleClose={handleCloseDialogEvidencia}
            >
            </DialogEvidencia>
        </>
    )
}

export default BuscaCompleta;