import { createContext, useState } from "react";

export const NFContext = createContext();

export const NFProvider = ({ children }) => {
    const [openAdicionarNF, setOpenAdicionarNF] = useState(false);

    return (
        <NFContext.Provider value={{
            openAdicionarNF, setOpenAdicionarNF,
        }}>
            {children}
        </NFContext.Provider>
    )
}
