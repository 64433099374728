import { getUser } from "../../Router/auth";
import React from 'react';
import { instance } from '../../API/axios';
import { GRAY} from '../../Utils/ColorsGrafos';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CardInformacoes from "../CardInformacoes/CardInformacoes";

export default function Evidencia(props) {
    const [info, setInfo] = React.useState("");
    

    React.useEffect(async () => {
        var filtro = {
            rotasIds: props.rotas.map(x => x.id),
            empresaId: getUser().empresaId,
        }

        var response = await instance.post("api/Dashboard/GetQuantEvidenciasByRotasIds", filtro);

        if(response.status == 200){
            setInfo(response.data.quantidadeDeEvidencias.toString());
            
        } else{
            setInfo("");
        }
    }, [props.rotas]);

    return (
        <>
            <CardInformacoes
                title={"Evidências"}
                info={info}
                backgroundColor={GRAY}
                icon={<CameraAltIcon/>}
                size={props.size}
            />
        </>
    );
}