import { useMemo, useRef } from "react"
import { Marker } from "react-leaflet"

function DraggableMarker({ position, event }) {
    const markerRef = useRef(null)

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    event(marker.getLatLng())
                }
            },
        }),
        [event]
    )

    return (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}>
        </Marker>
    )
}

export default DraggableMarker;