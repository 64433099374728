import { instance } from './axios'

export async function getByIdByPessoa(id) {
  try {
    const response = await instance.get('/api/Enderecos/ByPessoa/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAllByIdByPessoa(id) {
  try {
    const response = await instance.get('/api/Enderecos/ManyByPessoa/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getById(id) {
  try {
    const response = await instance.get('/api/Enderecos/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getByIdPessoaEndereco(id) {
  try {
    const response = await instance.get('/api/Enderecos/PessoaEndereco/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function post(registro) {
  try {
    const response = await instance.post('/api/Enderecos', registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function put(id, registro) {
  try {
    const response = await instance.put('/api/Enderecos/' + id, registro);
    if (response.status === 201 || response.status === 200) {
      return { data: response.data, result: 'ok' };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteById(id) {
  try {
    const response = await instance.delete('/api/Enderecos/' + id);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAllForAjusteEnderecoManual(filtroApi) {
  try {
    const params = new URLSearchParams();

    if (filtroApi.page != null) {
      params.append("page", filtroApi.page);
    }

    if (filtroApi.maxRegistro != null) {
      params.append("maxRegistro", filtroApi.maxRegistro);
    }

    if (filtroApi.empresaId != null) {
      params.append("empresaId", filtroApi.empresaId);
    }

    if (filtroApi.dataInicio != null) {
      params.append("dataInicio", filtroApi.dataInicio);
    }

    if (filtroApi.dataFim != null) {
      params.append("dataFim", filtroApi.dataFim);
    }

    const response = await instance.get(`/api/Enderecos/GetAllForAjusteEnderecoManual?${params.toString()}`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function putAjusteEnderecoManual(id, registro) {
  try {
    const response = await instance.put(`/api/Enderecos/PutAjusteEnderecoManual/${id}`, registro);

    if (response.status === 201 || response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}