export const estadosDoBrasil = [
    { value: 'AC', label:'Acre (AC)'},
    { value: 'AL', label:'Alagoas (AL)'},
    { value: 'AP', label:'Amapá (AP)'},
    { value: 'AM', label:'Amazonas (AM)'},
    { value: 'BA', label:'Bahia (BA)'},
    { value: 'CE', label:'Ceará (CE)'},
    { value: 'DF', label:'Distrito Federal (DF)'},
    { value: 'ES', label:'Espírito Santo (ES)'},
    { value: 'GO', label:'Goiás (GO)'},
    { value: 'MA', label:'Maranhão (MA)'},
    { value: 'MT', label:'Mato Grosso (MT)'},
    { value: 'MS', label:'Mato Grosso do Sul (MS)'},
    { value: 'MG', label:'Minas Gerais (MG)'},
    { value: 'PA', label:'Pará (PA)'},
    { value: 'PB', label:'Paraíba (PB)'},
    { value: 'PR', label:'Paraná (PR)'},
    { value: 'PE', label:'Pernambuco (PE)'},
    { value: 'PI', label:'Piauí (PI)'},
    { value: 'RJ', label:'Rio de Janeiro (RJ)'},
    { value: 'RN', label:'Rio Grande do Norte (RN)'},
    { value: 'RS', label:'Rio Grande do Sul (RS)'},
    { value: 'RO', label:'Rondônia (RO)'},
    { value: 'RR', label:'Roraima (RR)'},
    { value: 'SC', label:'Santa Catarina (SC)'},
    { value: 'SP', label:'São Paulo (SP)'},
    { value: 'SE', label:'Sergipe (SE)'},
    { value: 'TO', label:'Tocantins (TO)'}
]

export const camposForm = [
    
    { name: "razaoSocial",  label: "Razão Social", type: "text", size: 5, required: true },
    { name: "nomeFantasia",  label: "Nome Fantasia", type: "text", size: 3, required: false },
    { name: "cpfCnpj", label: "CPF ou CNPJ", type: "text", size: 2, required: false },
    { name: "telefone", label: "Telefone", type: "text", size: 2, required: false },
    
    { grupo: "Pesquisa para ajudar no preenchimento do endereço", name: "pesquisar", label: "Digite o endereço para pesquisar", type: "lookup_localizacao", size: 12 },
    
    { grupo: "Endereço", name: "logradouro", label: "Logradouro", type: "text", size: 5, required: true },
    { name: "numero", label: "Número", type: "text", size: 2, required: true },
    { name: "bairro", label: "Bairro", type: "text", size: 5, required: true },
    { name: "nomeMunicipio", label: "Município", type: "text", size: 3, required: true },
    { name: "sigaUF", label: "Estado", type: "option", size: 2, required: true, options: estadosDoBrasil },
    { name: "cep", label: "CEP", type: "text", size: 2, required: true },
    { name: "complemento", label: "Complemento", type: "text", size: 5, required: false },
    
]