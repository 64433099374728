import React, { useEffect } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll } from '../../API/Evidencias';
import { columnsGrid } from './Config';

export default function GridList(props) {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const idRota = props.filter?.rotaId || '-1'; 
                const idEntrega = props.filter?.entregaId || '-1'; 

                setLoading(true);
                const result = await getAll(idRota, idEntrega);

                if (result !== undefined) {
                    setRows(result);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false); // Ensure loading state is reset on error
            }
        }

        fetchData();
        setColumns(columnsGrid);
    }, [props.filter])

    return (
        <GridTable title="Evidências"
            rows={rows}
            columns={columns}
            loading={loading}
            CanRemove={false} />
    );
}