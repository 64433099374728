import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const HorizontalTabs = forwardRef((props, ref) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value ?? 0);

  const handleChange = (event, index) => {
    const abaSelecionada = React.Children.toArray(props.abas)[index];
    setValue(abaSelecionada.props.index);
  };

  useImperativeHandle(ref, () => ({
    alterarTab: (newtab) => { handleChange(null, newtab) },
  }));

  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { value });
    }
    return child;
  });

  const abasWithProps = React.Children.map(props.abas, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...a11yProps(child.props.index) });
    }
    return child;
  });

  return (
    <div >
      <Tabs
        variant={(props.centered ? "" : "scrollable")}
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
        style={{ paddingBottom: '14px' }}
        centered
      >
        {abasWithProps}
      </Tabs>
      {childrenWithProps}
    </div>
  );

})

export default HorizontalTabs;
