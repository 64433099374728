import { situacaoEntrega } from "../../Enums/SituacaoEntrega";
import { AZUL_PASTEL, VERDE_PASTEL, VERMELHO_PASTEL } from "../../Utils/ColorsGrafos";
import { Status } from "./components/Status"

export const camposForm = [
    { name: "descricao", label: "Descricao", type: "text", size: 12, required: true }
]

export const columnsGrid = [
    { label: "Identificador", field: "identificador" },
    { label: "Status", field: "status", type: "customWithRow", function: (row) => { return <Status pacoteDocumentoId={row.id} /> } }
]

export const descricaoStatus = {
    [situacaoEntrega.Aguardando]: "Aguardando",
    [situacaoEntrega.EmRota]: "Em rota",
    [situacaoEntrega.Entregue]: "Entregue",
    [situacaoEntrega.Devolvido]: "Devolvido",
    [situacaoEntrega.Cancelada]: "Cancelada",
    default: "Desconhecida"
};

export const backgroundColor = {
    [situacaoEntrega.Aguardando]: "",
    [situacaoEntrega.EmRota]: AZUL_PASTEL,
    [situacaoEntrega.Entregue]: VERDE_PASTEL,
    [situacaoEntrega.Devolvido]: VERMELHO_PASTEL,
    [situacaoEntrega.Cancelada]: VERMELHO_PASTEL,
    default: ""
};

export const textColor = {
    [situacaoEntrega.Aguardando]: "#000000",
    [situacaoEntrega.EmRota]: "#FFFFFF",
    [situacaoEntrega.Entregue]: "#FFFFFF",
    [situacaoEntrega.Devolvido]: "#FFFFFF",
    [situacaoEntrega.Cancelada]: "#FFFFFF",
    default: "#000000"
};