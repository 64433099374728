import { estadosDoBrasil } from '../Destinatario/Config';
import Chip from '@material-ui/core/Chip';

export const columnsGrid = [
    {
        label: 'Nome do endereço (Apelido)',
        field: 'nome',
        padding: 'default'
    },
    {
        label: 'Logradouro',
        field: 'endereco.logradouro',
        padding: 'default'
    },
    {
        label: 'Número',
        field: 'endereco.numero',
        padding: 'default'
    },
    {
        label: 'Complemento',
        field: 'endereco.complemento',
        padding: 'default'
    },
    {
        label: 'Bairro',
        field: 'endereco.bairro',
        padding: 'default'
    },
    {
        label: 'Cidade',
        field: 'endereco.nomeMunicipio',
        padding: 'default'
    },
    {
        label: 'Estado',
        field: 'endereco.sigaUF',
        padding: 'default'
    },
    { label: 'Situação', field: 'enderecoOrigemId', type: 'customWithRow', padding: 'default', function: getSituacao },

];

function getSituacao(row) {
    if (row.enderecoOrigemId)
        return <Chip variant="default" style={{ backgroundColor: 'rgb(3, 169, 244)', color: 'white', fontSize: '14px', padding: '12px', letterSpacing: '1px' }} label={'Substituto'} />
    if (row.enderecoFiscal)
        return <Chip variant="default" style={{ fontSize: '14px', padding: '12px', letterSpacing: '1px' }} label={'End. Fiscal'} />
    return '';
}

export const camposForm = [
    { name: "enderecoOrigemId", label: "Endereço que está sendo substituido", type: "lookup_assincrono", endpoint: "api/Enderecos/lookup", size: 12, required: true, options: [], disabled: true },
    { grupo: "Pesquisa para ajudar no preenchimento do endereço", name: "pesquisar", label: "Digite o endereço para pesquisar", type: "lookup_localizacao", size: 12 },
    { grupo: "Identificação do endereço", name: "nome", label: "Nome do endereço (Apelido para facilitar a identificação)", type: "text", size: 12, required: true },
    { grupo: "Endereço", name: "logradouro", label: "Logradouro", type: "text", size: 12, required: true },
    { name: "numero", label: "Numero", type: "text", size: 2, required: true },
    { name: "bairro", label: "Bairro", type: "text", size: 4, required: true },
    { name: "nomeMunicipio", label: "Municipio", type: "text", size: 4, required: true },
    { name: "sigaUF", label: "Estado", type: "option", size: 2, required: true, options: estadosDoBrasil },
    { name: "cep", label: "CEP", type: "text", size: 6, required: true },
    { name: "complemento", label: "Complemento", type: "text", size: 4, required: false },
    { name: "latitude", label: "Latitude", type: "text", size: 1, required: false },
    { name: "longitude", label: "Longitude", type: "text", size: 1, required: false },
    { name: "localizacao", label: "Local", type: "ponto_localizacao", size: 12, required: false, disabled: true },
]
