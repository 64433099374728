import { instance } from './axios';

export async function getAll(rotaId) {
	try {
		const response = await instance.get(`/api/ParadaPlanejadas/GetParadasPlanejadasByRota/${rotaId}`);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		throw error;
	}
}

export async function getById(id) {
	try {
		const response = await instance.get('/api/ParadaPlanejadas/' + id);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		throw error;
	}
}

export async function deleteById(id) {
	try {
		const response = await instance.delete('/api/ParadaPlanejadas/' + id);
		if (response.status === 200) {
			return response.data;
		} else if (response.status === 403) {
			return response.message;
		}
	} catch (error) {
		throw error;
	}
}

export async function update(id, registro) {
	try {
		const response = await instance.put('/api/ParadaPlanejadas/updadeParada/' + id, registro);
		if (response.status === 201 || response.status === 200) {
			if (response.data.message)
				return { data: response.data.message, result: 'nok' };
			return { data: response.data, result: 'ok' };
		}
	} catch (error) {
		throw error;
	}
}

export async function post(registro) {
	try {

		const response = await instance.post('/api/ParadaPlanejadas', registro);
		if (response.status === 201 || response.status === 200) {
			return { data: response.data, result: 'ok' };
		}
	} catch (error) {
		throw error;
	}
}

export async function get_paradas_planejadas(rota_id) {
	try {
		const response = await instance.get(`/api/ParadaPlanejadas/monitoramento?rotaId=${rota_id}`);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		throw error;
	}
}

export async function atualizarStatus(rotaId, statusId, IdsParadasPlanejadas) {
	try {
		if (statusId === -1) {
			return null;
		}
		const response = await instance.put(`/api/ParadaPlanejadas/AtualizarStatus?rotaId=${rotaId}&novoStatus=${statusId}`, IdsParadasPlanejadas);
		if (response.status === 200) {
			return response.data;
		} else {
			return null;
		}
	} catch (error) {
		return null;
	}
}

export async function updateSequenciaFixada(rotaId, paradasPlanejadas) {
	try {
		const response = await instance.put(`/api/ParadaPlanejadas/UpdateSequenciaFixada/${rotaId}`, paradasPlanejadas);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		throw new Error(error.response.data.message);
	}
}