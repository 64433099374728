import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const FiltroAtivo = ({value, onChange}) => {
    return (
        <FormControl
            variant="standard"
            sx={{
                minWidth: "150px"
            }}
        >
            <InputLabel>Ativo</InputLabel>
            <Select
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            >
                <MenuItem value={-1}>Todos</MenuItem>
                <MenuItem value={true}>Ativos</MenuItem>
                <MenuItem value={false}>Desativos</MenuItem>
            </Select>
        </FormControl>
    )
}