export const columnsGrid = [
    {
        label: 'Razão Social',
        field: 'razaoSocial',
        padding: 'default'
    },
    {
        label: 'Nome Fantasia',
        field: 'nomeFantasia',
        padding: 'default'
    },
    /*{
        label: 'Janelas',
        field: 'janelas',
        width: '150px',
        //type: 'customWithRow', 
        padding: 'default', 
        //function: StatusJanelasEntrega
    },*/
    {
        label: 'CPF',
        field: 'cpf',
        padding: 'default'
    },
    {
        label: 'CNPJ',
        field: 'cnpj',
        padding: 'default'
    }
];

export const camposForm = [
    { name: "razaoSocial",  label: "Razão Social", type: "text", size: 6, required: true },
    { name: "nomeFantasia",  label: "Nome Fantasia", type: "text", size: 6, required: false },
    { name: "cpfCnpj", label: "CPF ou CNPJ", type: "text", size: 3, required: true },
    { name: "telefone", label: "Telefone", type: "text", size: 3, required: false },
    { name: "email", label: "E-mail", type: "text", size: 6, required: false },

    /*{ grupo: "Pesquisa para ajudar no preenchimento do endereço", name: "pesquisar", label: "Digite o endereço para pesquisar", type: "lookup_localizacao", size: 12 },
    { grupo: "Endereço", name: "logradouro", label: "Logradouro", type: "text", size: 12, required: true },
    { name: "numero", label: "Numero", type: "text", size: 2, required: true },
    { name: "bairro", label: "Bairro", type: "text", size: 4, required: true },
    { name: "nomeMunicipio", label: "Municipio", type: "text", size: 4, required: true },
    { name: "sigaUF", label: "Estado", type: "option", size: 2, required: true, options: estadosDoBrasil },
    { name: "cep", label: "CEP", type: "text", size: 6, required: true },
    { name: "complemento", label: "Complemento", type: "text", size: 6, required: false },
    */
    // { name: "tipo", label: "Tipo", type: "option", size: 2, required: true , options: [{ value: 0, label: 'Local interno' }, { value: 1, label: 'Local planejado' }, { value: 2, label: 'Posto de Combustivel' }, { value: 3, label: 'Oficina' }] },
    //{ name: "empresaId", label: "Empresa", type: "lookup", size: 12, required: true, options: [], disabled: true },
    //{ name: "ehInicioDeRota", label: "Local usado para inicio de rota?", type: "bool", size: 2, required: true },
    //{ name: "ehFimDeRota", label: "Local usado para fim de rota?", type: "bool", size: 2, required: true },
    //{ name: "posicao", label: "Posição", grupo:"Posição", type: "mapCircle", size: 12, required: true },
]
