import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function FormatacaoDiferente_Form(props){
    return <div>
        <div>
            <Typography variant="caption" display="block" gutterBottom>
                {props.label}
            </Typography>
                    
            <div style={{display: 'block', height: 8, width: '100%'}}></div>
            <Typography variant={'subtitle1'} display="block" gutterBottom>
            {props.label === "Valor da Carga" || props.label === "Valor do Frete"? 
            <>
                {parseFloat(props.value).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                })}
            </> :
            <>
            {props.value}
            </>}
            
            </Typography>       
    </div></div>;

       
};


