import { Box } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { EtiquetaKrambeck } from "../../Components/Etiquetas/components/EtiquetaKrambeck";

const doc = {
    id: 603509,
    chave: "42240878515624002778550630000000011149594040",
    dataEmissao: "2024-08-12T14:41:51",
    valor: 1.9,
    numeroDocumentoFiscal: "1",
    idExterno: null,
    naturezaOperacao: "5102-VENDA DE MERC.ADQ.OU RECEB.DE TERC.",
    informacaoComplementar: "ICMS DESCONSIDERADO NA BC DE PIS E COFINS CONFORME (RE) 574.706/PR E PARECER SEI NO 7.698/2021||TRIB APROX R$: 0.15(FED.), 0.32(EST.) FONTE: IBPT/EMPRESOMETRO.COM.BR       M2L5P8|VENDEDOR: CB SISTEMAS|TITULOS: 5951193|TIPO COBR.: 3-BOLETO VIA E-MAIL|CELULAR: 4799914-9473",
    rotaId: null,
    rota: null,
    pesoBruto: 0,
    pesoLiquido: 0,
    quantidadeVolume: 1,
    destinatarioId: 60055,
    destinatario: {
        id: 60055,
        cpf: "",
        cnpj: "73241333000186",
        razaoSocial: "CB SISTEMAS LTDA",
        nomeFantasia: null,
        enderecoId: 152886,
        endereco: {
            id: 152886,
            empresaId: null,
            logradouro: null,
            numero: null,
            complemento: null,
            bairro: null,
            codigoMunicipio: null,
            nomeMunicipio: null,
            sigaUF: null,
            cep: null,
            latitude: null,
            longitude: null
        }
    },
    enderecoEntregaId: 152886,
    enderecoEntrega: {
        id: 152886,
        empresaId: 74,
        logradouro: "R JOAO PESSOA",
        numero: "1283 SALA 201",
        complemento: null,
        bairro: "VELHA",
        codigoMunicipio: null,
        nomeMunicipio: "BLUMENAU",
        sigaUF: "SC",
        cep: "89036001",
        latitude: null,
        longitude: null
    },
    entregaId: 633515,
    entrega: {
        situacaoEntrega: 7,
        rotaId: 69245
    }
}

function Etiqueta() {
    const contentRef = useRef();
    const reactToPrintFn = useReactToPrint({ contentRef });

    return (
        <>
            <Box sx={{
                zoom: "1.5"
            }}>
                <Box ref={contentRef}>
                    <EtiquetaKrambeck documento={doc} volume={1} />
                </Box>
            </Box>
            <button onClick={reactToPrintFn}>Print</button>
        </>
    );
}

export default Etiqueta;