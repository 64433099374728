import styled from 'styled-components';
import * as color from '../../Utils/ColorsGrafos';
import Grid from '@material-ui/core/Grid'


export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

export const SubContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const BuscaCompletaForm = styled.form`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 10px;

    & > *:not(:last-child) {
        margin-right: 20px;
    }
`;

export const TableContainer = styled.div`
    height: 700px;
    overflow-y: auto;
    margin: 20px 0;
    position: relative;
`;

export const NoneResultMessage = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    font-size: 20px;
`;

export const LoadingContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const GridInformacoes = styled(Grid)`
    margin-bottom: 30px;
`;

export const EvidenciasContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * {
        margin: 5px 5px;
    }

    & > :first-child {
        margin-left: 0;
    }

    & > :last-child {
        margin-right: 0;
    }
`;

export const AcoesContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    
    & > * {
        margin: 0 2px;
    }

    @media screen and (max-width: 1920px) {
        justify-content: start;
        margin-top: 30px;
    }

    @media screen and (max-width: 1275px) {
        justify-content: end;
        margin-top: 0px;
    } 
`;

/*
Entregue = 1,
Devolvido = 2,
EntregueParcial = 3,
Cancelada = 4,
Reagendada = 5,
Aguardando = 7
*/

export const SituacaoEntregaComponent = styled.span`
    ${props => {
        switch (props.situacao) {
            case 1:
                return `
                    color: ${color.GREEN}
                `;
            case 2:
                return `
                    color: ${color.RED}
                `;
            default:
                return `
                    color: ${color.BLACK}
                `;
        }
    }}
`;
