import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DraggableTable from "./DraggableTable";
import { useRef } from "react";
import DoneIcon from '@mui/icons-material/Done';
import ButtonCustom from "../Button/Button";
import { SequenciaFixadaContext } from "../../Context/SequenciaFixadaContext";

export default function DialogDraggableTable({
    open,
    rows,
    onClose,
    onConfirm
}) {
    const draggableTableRef = useRef();

    const onConfirmHandler = () => {
        if (draggableTableRef.current) {
            let customRows = draggableTableRef.current.getCustomRows();
            onConfirm(customRows);
        }
    }

    return (
        <SequenciaFixadaContext.Consumer>
            {value => (
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth={"lg"}
                    onClose={onClose}
                >
                    <DialogTitle>Definição da sequência manual</DialogTitle>
                    <DialogContent dividers>
                        <Alert severity="warning">
                            ATENÇÃO! Ao modificar manualmente a sequência das paradas (nesta tela) e clicar em "CONFIRMAR",
                            essa será a ordem enviada ao motorista. No entanto, se após a roteirização manual, houver alguma
                            alteração na rota (como a inserção ou exclusão de uma parada, por exemplo), o sistema descartará
                            a roteirização manual salva anteriormente e fará a roteirização automática novamente.
                        </Alert>
                        <DraggableTable ref={draggableTableRef} rows={rows} />
                    </DialogContent>
                    <DialogActions>
                        <ButtonCustom
                            autoFocus
                            loading={value.loading}
                            color="primary"
                            variant="contained"
                            startIcon={<DoneIcon />}
                            onClick={onConfirmHandler}
                        >
                            Confirmar
                        </ButtonCustom>
                    </DialogActions>
                </Dialog>
            )}
        </SequenciaFixadaContext.Consumer>
    );
}
