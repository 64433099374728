import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, put, getById } from '../../API/Entregas';
import { getByIdByPessoa as getEnderecoByPessoa } from '../../API/Enderecos';
import { camposForm2, camposForm_Edit } from './Config';
import { getUser } from '../../Router/auth';
import { getFormatDataToDataApi } from '../../Utils/FormatDateTime';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import TabHorizontal, { TabPanel } from '../../Components/TabHorizontal/Tab';
import Tab from '@material-ui/core/Tab';
import NotasFiscaisGrid from '../Documentos/NotaFiscal/GridList';
import { EntregasNewRoute2 } from '../../Router/Routing';
import { SubmissionError } from 'redux-form';
import EvidenciasGrid from '../Evidencia/GridList';
import { getFormatEndereco_01 } from '../../Utils/Endereco';
import { Roles } from '../../Utils/Roles';

class FormConfig extends Component {

    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l = true;

        this.state = { loading: l, loadingField: true, values: { situacao: 7 }, camposForm: camposForm2, alertSucess: false };
    }

    limpar = () => {
        this.setState({ alertSucess: false, alertError: false });
    }

    create = async values => {
        if (values.rotaId === -1)
            values.rotaId = null;
        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            this.setState({ alertError: true });
            throw new SubmissionError({ name: 'Entrega does not exist', _error: 'Login failed!' })
        }
    }

    update = async values => {
        if (values.rotaId === -1)
            values.rotaId = null;
        var id = this.props.match.params.id;
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            this.setState({ alertError: true });
            throw new SubmissionError({ name: 'Entrega does not exist', _error: 'Login failed!' })
        }
    }

    changeDestinatario = value => {
        return this.changeDestinatarioById(value);
    }

    changeDestinatarioById = async id => {
        var retorno = await getEnderecoByPessoa(id);
        if (retorno !== undefined) {
            var informacao = getFormatEndereco_01(retorno);//retorno.logradouro+", "+retorno.numero+", "+retorno.bairro+", "+retorno.nomeMunicipio;

            return {
                campos: [
                    { campo: 'endereco', value: informacao },
                    { campo: 'enderecoId', value: retorno.id }
                ]
            };
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;

        if (id) {

            if (getUser().role === Roles.Monitoramento) {
                var destinatario = camposForm_Edit.find(e => e.name === "destinatarioId");
                destinatario.disabled = true;
                destinatario.podeAlterar = false;
                destinatario.linkToCriar = false;
                var rota = camposForm_Edit.find(e => e.name === "rotaId");
                rota.disabled = true;
                var data = camposForm_Edit.find(e => e.name === "data");
                data.disabled = true;
            }

            this.setState({ camposForm: camposForm_Edit });

            getById(id).then(async (value) => {
                if (value !== undefined) {
                    var retorno = await getEnderecoByPessoa(value.destinatarioId);
                    if (retorno !== undefined) {
                        var informacao = retorno.logradouro + ", " + retorno.numero + ", " + retorno.bairro + ", " + retorno.nomeMunicipio;
                        value.endereco = informacao;
                    }
                    this.setState({ values: value });
                }
                this.setState({ loading: false });

            }).catch((e) => {
                throw new Error('Registro não encontrado!');
            });
        } else {
            var query = new URLSearchParams(this.props.location.search);

            var rotaId = query.get('rotaId');
            if (rotaId)
                this.state.values.rotaId = parseInt(rotaId);

            this.state.values.empresaId = getUser().empresaId;
            this.state.values.data = getFormatDataToDataApi(new Date());
            this.state.values.situacao = 7;
            this.state.values.destinatarioId = -1;
            this.setState({ values: this.state.values });

            this.setState({ loadingField: false });
        }

        var destin = this.state.camposForm.find(e => e.name === "destinatarioId");
        destin.onChange = this.changeDestinatario;

        this.setState({ loadingField: false });
    }

    render() {
        let id = this.props.match.params.id;
        if (id) {
            return (
                <div>
                    <TabHorizontal abas={[
                        <Tab label="Entrega" index={0} />,
                        <Tab label="Notas Fiscais / Pedidos" index={1} />,
                        <Tab label="Evidências" index={2} />
                    ]}>
                        <TabPanel index={0}>
                            <Form title="Editar" form="entregaFormEdit" fields={this.state.camposForm} AlertSucess={this.state.alertSucess} onSubmit={this.update}
                                LinkNew={EntregasNewRoute2} CanBack={true} CanOnlyBack={getUser().role === Roles.Monitoramento} initialValues={this.state.values} loading={this.state.loading || this.state.loadingField}
                                limpar={this.limpar}
                            />
                        </TabPanel>
                        <TabPanel index={1}>
                            <NotasFiscaisGrid filter={{ entregaId: id }} />
                        </TabPanel>
                        <TabPanel index={2}>
                            <EvidenciasGrid filter={{ entregaId: id }} />
                        </TabPanel>
                    </TabHorizontal>
                </div>
            );
        } else {

            return (<>
                <Form title="Entrega"
                    form="entregaForm" fields={this.state.camposForm} onSubmit={this.create} AlertSucess={this.state.alertSucess} LinkNew={EntregasNewRoute2}
                    CanNotSave={true} CanBack={true} initialValues={this.state.values} loading={this.state.loadingField}
                    limpar={this.limpar}
                >
                </Form>
            </>
            );
        }
    }
}

class FormSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <FormConfig openSnackbar={openSnackbar} {...this.props} >
                    </FormConfig>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default FormSnackBar;