
export const columnsGrid = [
    {
        label: 'Descrição',
        field: 'descricao',
        padding: 'default'
    },{
        label: 'Tipo',
        field: 'tipo',
        type: 'option',
        options: [{ value: 0, label: 'Local interno' }, { value: 1, label: 'Local planejado' }, { value: 2, label: 'Posto de Combustivel' }, { value: 3, label: 'Oficina' }],
        padding: 'default'
    }
];
