
import React, {useEffect} from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll, deleteById } from '../../API/Local';
import { LocaisNewRoute, LocaisEditRoute } from '../../Router/Routing';
import { columnsGrid } from './Config';
import { getAll as getAllEmpresa } from '../../API/Empresa';

export default function GridList() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    //const [loadingEmpresa, setLoadingEmpresa] = React.useState(true);

    async function loadRow()
    {
        setRows([]);
        setLoading(true);
        //setLoadingEmpresa(true);

        getAll().then((value) => {
            if (value != undefined)
                setRows(value);
            setLoading(false);
        });

        /*var empresas = await getAllEmpresa();
        var campos = columnsGrid;
        var empresaField = campos.find(e => e.field == "empresaId");
        empresaField.options = empresas.map(empresa =>{
            return {
                value:empresa.id,
                label:empresa.nome
            }
        })*/
        setColumns(columnsGrid);
        //setLoadingEmpresa(false);

    }
    useEffect(async () => {
        loadRow();
    }, [])

    async function excluirClick(ids){
        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            await deleteById(id);    
        }
        loadRow();
    } 
    
    return (
            <GridTable title="Locais" titleNovo="Novo"
                rows={rows} 
                columns={columns} 
                LinkNew={LocaisNewRoute} 
                LinkEdit={LocaisEditRoute} 
                loading={loading } 
                excluirClick={excluirClick}/>
    );
}