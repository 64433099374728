import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, put, getById } from '../../API/Local';
import { SubmissionError } from 'redux-form'
import { camposForm } from './Config';
import { getUser } from '../../Router/auth';
import { LocaisNewRoute } from '../../Router/Routing';

class FormConfig extends Component {

    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l = true;
        this.state = { alertSucess: false, loading: l, values: {tipo: 1}, camposForm: camposForm };
    }

    create = async values => {
        if (values.n_latitude) {
            values.raio = values.n_raio;
            values.longitude = values.n_longitude;
            values.latitude = values.n_latitude
        }

        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
    }

    update = async values => {
        if (values.n_latitude) {
            values.raio = values.n_raio;
            values.longitude = values.n_longitude;
            values.latitude = values.n_latitude
        }

        var id = this.props.match.params.id;
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
    }

    limpar = () => {
        this.setState({ alertSucess: false });
    }

    async componentDidMount() {
        let id = this.props.match.params.id;
        if (id) {
            getById(id).then((value) => {
                value.localizacao = { lat: value.latitude, lng: value.longitude };
                if (value !== null && value !== undefined) {
                    this.setState({ values: value });
                }
                this.setState({ loading: false });
            });
        } else {
            this.setState((prevState) => ({
                values: { ...prevState.values, empresaId: getUser().empresaId },
            }));
        }

        this.setState({ camposForm: camposForm });
    }

    render() {

        let id = this.props.match.params.id;
        if (id) {
            return (
                <Form
                    title="Editar Local"
                    form="editLoca"
                    fields={this.state.camposForm}
                    onSubmit={this.update}
                    LinkNew={LocaisNewRoute}
                    CanBack={true}
                    AlertSucess={this.state.alertSucess}
                    initialValues={this.state.values}
                    loading={this.state.loading}
                    limpar={this.limpar}>
                </Form>
            );
        } else {
            return (
                <Form
                    title="Novo Local"
                    form="newLoca"
                    fields={this.state.camposForm}
                    onSubmit={this.create}
                    CanNotSave={true}
                    LinkNew={LocaisNewRoute}
                    CanBack={true}
                    AlertSucess={this.state.alertSucess}
                    initialValues={this.state.values}
                    limpar={this.limpar}>
                </Form>
            );
        }
    }
}

export default FormConfig

