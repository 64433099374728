export const columnsGrid = [
    {
        label: 'Descrição',
        field: 'descricao',
        padding: 'default'
    },{
        label: 'Tipo',
        field: 'tipo',
        type: 'option',
        options: [{ value: 0, label: 'Local interno' }, { value: 1, label: 'Local planejado' }, { value: 2, label: 'Posto de Combustivel' }, { value: 3, label: 'Oficina' }],
        padding: 'default'
    },
    /*{
        label: 'Empresa',
        field: 'empresaId',
        type: 'lookup',
        options: [],
        padding: 'default'
    }*/
];

export const camposForm = [
    { name: "descricao", label: "Descrição", type: "text", size: 6, required: true, autoFocus: true },
    { name: "tipo", label: "Tipo", type: "option", size: 2, required: true , options: [{ value: 0, label: 'Local interno' }, { value: 1, label: 'Local planejado' }, { value: 2, label: 'Posto de Combustivel' }, { value: 3, label: 'Oficina' }] },
    //{ name: "empresaId", label: "Empresa", type: "lookup", size: 12, required: true, options: [], disabled: true },
    //{ name: "ehInicioDeRota", label: "Local usado para inicio de rota?", type: "bool", size: 2, required: true },
    //{ name: "ehFimDeRota", label: "Local usado para fim de rota?", type: "bool", size: 2, required: true },
    
    //{ grupo: "Pesquisa para ajudar achar o local/endereço", name: "pesquisar", label: "Digite o endereço para pesquisar", type: "lookup_localizacao", size: 10 },
    //{ name: "latitude", label: "Lat", type: "text", size: 1, required: false, autoFocus: true,disabled: true },
    //{ name: "longitude", label: "Lng", type: "text", size: 1, required: false, autoFocus: true,disabled: true },
    
    //{ name: "posicao", label: "Posição", grupo:"Posição", type: "mapCircle", size: 12, required: true },
    { grupo: "Pesquisa para ajudar no preenchimento do endereço", name: "pesquisar", label: "Digite o endereço para pesquisar", type: "lookup_localizacao", size: 12 },
    { name: "localizacao", label: "Local", type: "ponto_localizacao", size: 12, required: false, disabled: true },
]