import { Box } from "@mui/material";
import styled from "styled-components";

export const BoxContainer = styled(Box)`
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Image = styled(Box)`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
`;