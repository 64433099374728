import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post } from '../../API/Local';
import { put, getById } from '../../API/Pessoas';
import { SubmissionError } from 'redux-form'
import {camposForm} from './Config';
import { getUser } from '../../Router/auth';
import TabHorizontal, { TabPanel } from '../../Components/TabHorizontal/Tab';
import Tab from '@material-ui/core/Tab';
import { LocaisNewRoute } from '../../Router/Routing';
import { JanelasEntregasCliente} from '../JanelaEntrega/JanelasEntregasCliente';
import EnderecosGrid from '../Endereco/GridList';

class FormConfig extends Component {

    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l=true;
        this.state = { alertSucess: false, loading: l, values:{}, camposForm: camposForm };
    }

    create = async values => {
        
        if (values.n_latitude){
            values.raio=values.n_raio;
            values.longitude=values.n_longitude;
            values.latitude=values.n_latitude
        }

        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
    }
    update = async values => {

        if (values.n_latitude){
            values.raio=values.n_raio;
            values.longitude=values.n_longitude;
            values.latitude=values.n_latitude
        }

        var id = this.props.match.params.id;
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
    }

    limpar = () =>{
        this.setState({ alertSucess: false });
    }

    async componentDidMount() {
        let id = this.props.match.params.id;
        
        if (id)
        {
            getById(id).then((value) => {
                if (value != undefined)
                {
                    value.cpfCnpj = value.cpf? value.cpf:value.cnpj;
                    this.setState({ values: value });
                }
                this.setState({ loading: false });
            });
        } else{
            this.state.values.empresaId = getUser().empresaId;
            this.setState({ values: this.state.values });
        }
        this.setState({ camposForm: camposForm });


        this.divRef = React.createRef();
        var query = new URLSearchParams(this.props.location.search);
        var tab = query.get('tab');
        if (tab == 'endereco')
        {
            this.setState({ tabIndex: 2 });
            this.divRef.current?.alterarTab(2);
        }
    }

    render() {
        let id = this.props.match.params.id;
        if (id)
        {
            return (
                <TabHorizontal value={this.state.tabIndex??0} ref={this.divRef} abas={[
                    <Tab label="Cliente" index={0} />,
                    <Tab label="Janelas de entregas" index={1} />,
                    <Tab label="Endereços" index={2} />
                ]}>
                    <TabPanel index={0}>
                        <Form title="Editar Cliente" form="editCliente" fields={this.state.camposForm} onSubmit={this.update} LinkNew={LocaisNewRoute} CanBack={true} AlertSucess={this.state.alertSucess} initialValues={this.state.values} loading={this.state.loading} limpar={this.limpar} />
                    </TabPanel>
                    <TabPanel index={1}>
                        <JanelasEntregasCliente clienteId={id}/>
                    </TabPanel>
                    <TabPanel index={2}>
                        <EnderecosGrid filter={{ pessoaId: id }} />                        
                    </TabPanel>
                </TabHorizontal>
            );
        }else{
            return (
                <Form title="Novo Cliente" form="newCliente" fields={this.state.camposForm} onSubmit={this.create} CanNotSave={true} LinkNew={LocaisNewRoute} CanBack={true} AlertSucess={this.state.alertSucess} initialValues={this.state.values} limpar={this.limpar}>
                
                </Form>
            );
        }
    }
}
export default FormConfig